import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useProtected from '@/components/hooks/use-protected'

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    marginTop: 0,
    height: `100%`,
    width: `100%`,
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
    },
    overflowX: 'hidden',
  },
}))

type Props = {
  children?: React.ReactNode
}

const UploadPortalLayout = ({ children }: Props) => {
  const classes = useStyles()
  const { hasPermission, isEnabledRoute } = useProtected()
  return <main className={classes.main}>{children}</main>
}

export default UploadPortalLayout
