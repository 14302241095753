import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import useUrlParams from '@/components/hooks/use-url-params'
import { Context } from '@/shared/store/reducers/global-reducer'
import UploadPortalUploadFilesForm from './components/upload-files-form'

const UploadPortalUploadFiles = (props: any) => {
  const { project, projectNumber, setProjectNumber, setProject } = props
  const { slug } = useUrlParams({ slug: false })
  const navigate = useNavigate()
  const { store } = useContext(Context)
  const { activeClient } = store

  useEffect(() => {
    if (!project) {
      if (!projectNumber) navigate(`/p/${slug}`)
      else navigate(`/p/${slug}/new-project`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNumber, project])

  if (!project) return null

  return (
    <UploadPortalUploadFilesForm
      client={activeClient}
      project={project}
      handleBack={() => {
        if (project?.id) {
          navigate(`/p/${slug}`)
        } else {
          navigate(`/p/${slug}/new-project`)
        }
      }}
      handleStartNewUpload={() => {
        setProjectNumber('')
        setProject(null)
        navigate(`/p/${slug}`)
      }}
    />
  )
}

export default UploadPortalUploadFiles
