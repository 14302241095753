import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react'
import { connectSearchBox, InstantSearch } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { useNavigate, useLocation } from 'react-router-dom'
import _debounce from 'lodash/debounce'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'
import PagCircularIndeterminate from '@/components/pag-loading'
import { Context } from '@/shared/store/reducers/global-reducer'
import ProjectsPopper from './projects-list'
import BidsPopper from './bids-list'
import DeliverablesPopper from './deliverables-list'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid lightgray',
    boxShadow: 'none',
  },
  searchTextbox: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  deleteIconButton: {
    padding: 0,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  menuTitle: {
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
    paddingRight: 16,
    color: 'gray',
  },
}))

const PagSearchTextBox = (props: any) => {
  const classes = useStyles()

  const {
    currentRefinement,
    isSearchStalled,
    refine,

    searchPhrase,
    searchTextboxRef,
    setSearchStalled,
    changeSearchPhrase,
  } = props

  useEffect(() => {
    setSearchStalled(isSearchStalled)

    return () => {}
  }, [isSearchStalled])

  const onChangeSearchPhrase = (e: any) => {
    e.persist()
    const phrase = e.target.value
    refine(phrase)
    changeSearchPhrase(phrase)
  }

  const debouncedTriggerChangeSearchField = useCallback(
    _debounce((e: any) => {
      onChangeSearchPhrase(e)
    }, 500),
    []
  )

  return (
    <div className={classes.searchTextbox}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputRef={searchTextboxRef}
        inputProps={{ 'aria-label': 'search' }}
        defaultValue={searchPhrase}
        onChange={(e) => debouncedTriggerChangeSearchField(e)}
      />
    </div>
  )
}

const CustomSearchBox: any = connectSearchBox(PagSearchTextBox)

const PagSearchBox = (props: any) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { store, dispatch } = useContext(Context)
  const location = useLocation()
  const { algoliaSearchSecuredApiKey, activeClient, activeBrand, clients } = store

  const anchorElRef = useRef(null as any)
  const searchTextboxRef = useRef(null as any)
  const [open, setOpen] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [searchStalled, setSearchStalled] = useState(false)

  const handleClose = useCallback((event: any) => {
    if (event && anchorElRef && anchorElRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }, [])

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onSearchAll = useCallback((e: any) => {
    handleClose(e)
    navigate('/search/all')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchClient = useMemo(() => {
    if (!algoliaSearchSecuredApiKey) return null
    return algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey)
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if (
      ['/search/all', '/search/projects', '/search/bids'].indexOf(location.pathname) < 0 &&
      searchPhrase !== ''
    ) {
      setSearchPhrase('')
      searchTextboxRef.current.value = ''
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    dispatch({ type: 'ChangeSearchPhrase', searchPhrase: searchPhrase })
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase])

  useEffect(() => {
    if (searchClient) searchClient.clearCache()
    if (open && searchTextboxRef.current) searchTextboxRef.current.focus()
  }, [open, searchClient])

  const id = open ? 'search-result-popper' : undefined

  const renderPopper = useMemo(() => {
    return (
      <Popper
        id={id}
        open={open}
        anchorEl={anchorElRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: '100%' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
          >
            <Paper>
              {searchStalled && <PagCircularIndeterminate />}

              <ClickAwayListener onClickAway={handleClose}>
                <List onKeyDown={handleListKeyDown} style={{ maxHeight: 500, overflow: 'auto' }}>
                  <ListItem button onClick={onSearchAll}>
                    <div>
                      <IconButton className={classes.iconButton} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <span>View all results with </span>
                      <b>{searchPhrase}</b>
                    </div>
                  </ListItem>
                  <Divider />
                  <ProjectsPopper
                    {...{
                      activeClient,
                      activeBrand,
                      clients,
                      handleClose,
                      searchPhrase,
                    }}
                  />
                  <Divider />
                  <BidsPopper
                    {...{
                      activeClient,
                      activeBrand,
                      clients,
                      handleClose,
                      searchPhrase,
                    }}
                  />
                  <Divider />
                  <DeliverablesPopper
                    {...{
                      activeClient,
                      activeBrand,
                      clients,
                      handleClose,
                      searchPhrase,
                    }}
                  />
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    )
  }, [activeBrand, activeClient, clients, open, searchPhrase, searchStalled])

  if (!searchClient) return null

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="Pseudo"
      // searchState={searchState}
      // onSearchStateChange={onSearchStateChange}
    >
      <Paper
        aria-describedby={id}
        ref={anchorElRef}
        className={classes.root}
        onClick={() => setOpen(true)}
      >
        <CustomSearchBox
          {...{
            searchPhrase,
            searchTextboxRef,
            setSearchStalled,
            changeSearchPhrase: setSearchPhrase,
          }}
        />

        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>

      {renderPopper}
    </InstantSearch>
  )
}

export default PagSearchBox
