import gql from 'graphql-tag'
import ProjectCostEntryItemType from '../types/enum-ProjectCostEntryItemType'

const ADD_PROJECT_COST_ENTRY_ITEM = gql`
  mutation addProjectCostEntryItem(
    $tenantId: Int!
    $projectCostEntryId: Int!
    $type: ProjectCostEntryItemType!
    $reportingPeriodStart: Date
    $reportingPeriodEnd: Date
    $bidId: Int
    $bidDate: Date
    $bidNumber: String
    $sequence: Int
    $amount: Float
    $notes: String
    $savingTypeId: Int
    $customFieldsMetaData: JSON
    $markupAmount: Float
    $markupPercentage: Float
  ) {
    addProjectCostEntryItem(
      tenantId: $tenantId
      projectCostEntryId: $projectCostEntryId
      type: $type
      reportingPeriodStart: $reportingPeriodStart
      reportingPeriodEnd: $reportingPeriodEnd
      bidId: $bidId
      bidDate: $bidDate
      bidNumber: $bidNumber
      sequence: $sequence
      amount: $amount
      notes: $notes
      savingTypeId: $savingTypeId
      customFieldsMetaData: $customFieldsMetaData
      markupAmount: $markupAmount
      markupPercentage: $markupPercentage
    ) {
      id
      projectCostEntryId
      type
      reportingPeriodStart
      reportingPeriodEnd
      sequence
      amount
      notes
      savingTypeId
      customFieldsMetaData
      bidId
      bidDate
      bidNumber
      markupAmount
      markupPercentage
    }
  }
`
const UPDATE_PROJECT_COST_ENTRY_ITEM = gql`
  mutation updateProjectCostEntryItem(
    $tenantId: Int!
    $id: Int!
    $projectCostEntryId: Int!
    $type: ProjectCostEntryItemType!
    $reportingPeriodStart: Date
    $reportingPeriodEnd: Date
    $bidId: Int
    $bidDate: Date
    $bidNumber: String
    $amount: Float
    $notes: String
    $savingTypeId: Int
    $customFieldsMetaData: JSON
    $markupAmount: Float
    $markupPercentage: Float
  ) {
    updateProjectCostEntryItem(
      tenantId: $tenantId
      id: $id
      projectCostEntryId: $projectCostEntryId
      type: $type
      reportingPeriodStart: $reportingPeriodStart
      reportingPeriodEnd: $reportingPeriodEnd
      bidId: $bidId
      bidDate: $bidDate
      bidNumber: $bidNumber
      amount: $amount
      notes: $notes
      savingTypeId: $savingTypeId
      customFieldsMetaData: $customFieldsMetaData
      markupAmount: $markupAmount
      markupPercentage: $markupPercentage
    ) {
      id
      projectCostEntryId
      type
      reportingPeriodStart
      reportingPeriodEnd
      sequence
      amount
      notes
      savingTypeId
      customFieldsMetaData
      bidId
      bidDate
      bidNumber
      markupAmount
      markupPercentage
    }
  }
`

const DELETE_PROJECT_COST_ENTRY_ITEM = gql`
  mutation deleteProjectCostEntryItem($tenantId: Int!, $id: Int!) {
    deleteProjectCostEntryItem(tenantId: $tenantId, id: $id) {
      success
      error
    }
  }
`

const DELETE_PROJECT_COST_ENTRY_ITEMS = gql`
  mutation deleteProjectCostEntryItems($tenantId: Int!, $ids: [Int!]!) {
    deleteProjectCostEntryItems(tenantId: $tenantId, ids: $ids) {
      success
      data
      error
    }
  }
`

export {
  ADD_PROJECT_COST_ENTRY_ITEM,
  UPDATE_PROJECT_COST_ENTRY_ITEM,
  DELETE_PROJECT_COST_ENTRY_ITEM,
  DELETE_PROJECT_COST_ENTRY_ITEMS,
}
