import React, { useState, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import PagSearchBox from '@/components/pag-search-box'
import ProjectEditWidget from '@/widgets/project-edit-widget'
import ProfileMenu from './profile-menu'
import TitleLogo from '@/assets/imgs/prodigy-logo.svg'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'
import { hasPermission } from '@/utils/role-permission'

const useStyles = makeStyles((theme) => ({
  titleLogo: {
    display: 'flex',
    '& img': {
      width: 110,
    },
    [theme.breakpoints.up('md')]: {
      // marginLeft: theme.spacing(2),
      '& img': {
        width: 120,
      },
    },
  },
  userProfileMenuWrap: {
    position: 'relative',
    marginLeft: 30,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const CreateProjectButton = ({ activeClient, activeBrand, dispatch, classes }: any) => {
  const navigate = useNavigate()
  const [enableNewProject, setEnableNewProject] = useState(false)

  const handleSubmitProject = (p: IProject) => {
    setEnableNewProject(false)
    navigate(`/${p.clientId}/${p.brandId}/${p.id}/project`)
  }

  return (
    <ClickAwayListener onClickAway={() => setEnableNewProject(false)}>
      <div className="pag-flex">
        <span className="pag-blue pag-bold" onClick={() => setEnableNewProject(!enableNewProject)}>
          Create New Project
        </span>

        {enableNewProject && (
          <ProjectEditWidget
            widgetConfig={{
              order: 0,
              area: 'Normal',
              permissions: [],
              style: {
                position: 'absolute',
                top: 54,
              },
            }}
            inputs={{
              client: activeClient || null,
              brand: activeBrand || null,
            }}
            outputs={{
              handleCancel: () => {
                setEnableNewProject(false)
              },
              handleSubmit: (e) => {
                handleSubmitProject(e)
              },
            }}
          />
        )}
      </div>
    </ClickAwayListener>
  )
}

const AppHeader = (props: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { isAuthenticated, logout, user } = useAuth0()
  const { store, dispatch } = useContext(Context)
  const { userPermissions, activeClient, activeBrand, tenants } = store
  const readOnly = useMemo(
    () => !hasPermission(userPermissions, `write:projects`),
    [userPermissions]
  )

  return (
    <>
      <div className={classes.titleLogo}>
        <img
          src={TitleLogo}
          style={{ cursor: 'pointer' }}
          alt="logo"
          onClick={() => navigate(`/`)}
        />
      </div>

      <div className="pag-flex pag-right">
        {Boolean(isAuthenticated) && Boolean(activeClient) && (
          <>
            <CreateProjectButton
              {...{
                activeClient,
                activeBrand,
                dispatch,
                classes,
              }}
            />

            <div style={{ marginLeft: '2rem', minWidth: '300px', position: 'relative' }}>
              <PagSearchBox />
            </div>
          </>
        )}
        {isAuthenticated && Boolean(tenants?.length) && (
          <div className={classes.userProfileMenuWrap}>
            <ProfileMenu user={user} handleLogout={logout} />
          </div>
        )}
      </div>
    </>
  )
}

export default AppHeader
