import React from 'react'
import ReactDOM from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from 'styled-components'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

const theme = createTheme({
  palette: {
    primary: {
      main: '#225099',
    },
  },
})

const renderPartialApp = () => {
  if (process.env.REACT_APP_BUGSNAG_ENABLED !== 'true') {
    return <App />
  }

  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
    plugins: [new BugsnagPluginReact(React)],
  })
  const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
  const ErrorBoundary = plugin.createErrorBoundary()

  return ErrorBoundary ? (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  ) : (
    <App />
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {renderPartialApp()}
    </ThemeProvider>
  </MuiThemeProvider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
