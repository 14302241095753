import React, { useState, useEffect, useMemo, useContext } from 'react'
import clsx from 'clsx'
import { useLazyQuery } from '@apollo/react-hooks'
import { useNavigate } from 'react-router-dom'

import _capitalize from 'lodash/capitalize'
import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'
import _set from 'lodash/set'

import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import useUrlParams from '@/components/hooks/use-url-params'
import PagCircularIndeterminate from '@/components/pag-loading'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import ProjectOverview from '../components/project-dashboard/project-overview-component'
import ProjectDashboardBreadCrumb from '../components/project-dashboard/project-dashboard-breadcrumb-component'
import BidsSearchContainer from './search-containers/bids-search-container'
import DeliverablesSearchContainer from './search-containers/deliverables-search-container'
import DeliverablesContainer from './deliverables-container'
import BudgetContainer from './budget-container'
import IntegrationContainer from './integration-container'

import ProjectCostWidget from '@/widgets/project-cost-widget/index'
import ProjectItemWidget from '@/widgets/project-item-widget'
import PagNumberFormatInput from '@/components/pag-form/pag-number-format-Input'
import { errorHandler } from '@/shared/error-handler'
import { GET_BID, GET_PROJECT_WITHOUT_BIDS, GET_EXCHANGE_RATES } from '@/shared/graphql'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { CURRENCIES } from '@/shared/constants/currency'
import { stringToCurrency } from '@/utils/numeric'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'hidden',
    padding: theme.spacing(1),
  },
  projectItemsWrapper: {
    paddingTop: 4,
  },
  section: {
    padding: theme.spacing(1),
  },
  sectionTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    height: 4,
    backgroundColor: theme.palette.text.primary,
  },
  metric: {
    background: '#E8EEF5',
    border: '1px solid #225099',
    padding: theme.spacing(1),
  },
}))

const Metric = (props: any) => {
  const { value, label, prefix, suffix } = props
  const classes = useStyles()

  return (
    <div className={classes.metric}>
      <Typography variant="h5" align="center" color="primary" gutterBottom>
        <b>
          {prefix || ''}
          {value}
          {suffix}
        </b>
      </Typography>
      <Typography variant="h6" align="center" color="primary">
        {label}
      </Typography>
    </div>
  )
}

const ProjectDashboardContainer = () => {
  const { clientId, brandId, projectId, bidId, tabName } = useUrlParams({
    clientId: true,
    brandId: true,
    projectId: true,
    bidId: true,
    tabName: false,
  })

  const navigate = useNavigate()
  const mountedRef = useMounted(true)
  const classes = useStyles()
  const { store, dispatch } = useContext(Context)
  const {
    userPermissions,
    tenants,
    clients,
    activeTenant,
    activeClient,
    activeBrand,
    activeProject,
    exchangeRates,
  } = store

  const { getTokenSilently } = useAuth0()

  const [getProject, getProjectRes] = useLazyQuery(GET_PROJECT_WITHOUT_BIDS, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
      dispatch({
        type: 'SetActiveProject',
        payload: { project: null, bid: null },
      })
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const tempProject = res.project || null
      let tempClientId = clientId
      let tempBrandId = brandId

      if (tempProject) {
        if (tempClientId !== tempProject?.clientId) {
          tempClientId = tempProject?.clientId || tempProject?.brand?.clientId
        }

        if (tempBrandId !== tempProject?.brandId) {
          tempBrandId = tempProject?.brandId
        }

        if (tempProject.metaData && typeof tempProject.metaData == 'string') {
          tempProject.metaData = JSON.parse(tempProject.metaData)
        }
      }

      let tempActiveTenant = activeTenant
      let tempActiveClient = activeClient
      let tempActiveBrand = activeBrand

      if (tempClientId !== tempActiveClient?.id) {
        tempActiveClient = tempProject?.client || tempProject?.brand?.client || null
        const tenantId = tempProject?.client?.tenantId
        if (tenantId) {
          tempActiveTenant = tenants.find((t: any) => t.id === tenantId)
        }
      }
      if (tempBrandId !== tempActiveBrand?.id) {
        tempActiveBrand = tempProject?.brand || null
      }

      if (bidId) {
        dispatch({
          type: 'SetPartial',
          payload: {
            activeTenant: tempActiveTenant || activeTenant,
            activeClient: tempActiveClient,
            activeBrand: tempActiveBrand,
            activeProject: tempProject,
            bidsViewMode: 'All',
          },
        })
      } else {
        dispatch({
          type: 'SetPartial',
          payload: {
            activeTenant: tempActiveTenant || activeTenant,
            activeClient: tempActiveClient,
            activeBrand: tempActiveBrand,
            activeProject: tempProject,
            activeBid: null,
            bidsViewMode: 'All',
          },
        })
      }

      if (tempClientId !== clientId || tempBrandId !== brandId) {
        navigate(`/${tempClientId}/${tempBrandId}/${tempProject.id}/${tabName}`)
        return
      }
    },
    fetchPolicy: 'network-only',
  })

  const [getBid, getBidRes] = useLazyQuery(GET_BID, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
      dispatch({
        type: 'SetPartial',
        payload: { bid: null },
      })
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return

      const tempBid = res?.bid || null

      dispatch({
        type: 'SetPartial',
        payload: {
          activeBid: tempBid,
          bidsViewMode: 'All',
        },
      })
    },
    fetchPolicy: 'network-only',
  })

  const [getExchangeRates] = useLazyQuery(GET_EXCHANGE_RATES, {
    onError: (err: any) => {
      errorHandler(err)
    },
    onCompleted: (res: any) => {
      if (!mountedRef.current) return
      const temp = res?.exchangeRates || null
      dispatch({
        type: 'SetExchangeRates',
        payload: { exchangeRates: temp },
      })
    },
    fetchPolicy: 'network-only',
  })

  const [reportingPeriodStart, setReportingPeriodStart] = useState(null as any)
  const [reportingPeriodEnd, setReportingPeriodEnd] = useState(null as any)

  useEffect(() => {
    const client = !clientId ? null : _find(clients, (c) => c.id === clientId)
    if (activeClient === client) return
    dispatch({ type: 'SetPartial', payload: { activeClient: client } })
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClient, clients, clientId])

  useEffect(() => {
    const brand =
      !brandId || !activeClient ? null : _find(activeClient.brands || '', (b) => b.id === brandId)
    if (activeBrand === brand) return
    dispatch({
      type: 'SetPartial',
      payload: { activeBrand: brand, activeProject: null, activeBid: null },
    })
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClient, activeBrand, brandId])

  useEffect(() => {
    if (!mountedRef.current || !userPermissions?.length || !activeTenant || !projectId) return

    let abortController: any

    try {
      abortController = new AbortController()
      callPagApi(
        '',
        getTokenSilently,
        getProject,
        { tenantId: activeTenant.id, id: projectId },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissions, activeTenant, projectId])

  useEffect(() => {
    if (!bidId) {
      dispatch({ type: 'SetPartial', payload: { activeBid: null, bidsViewMode: 'All' } })
      return
    }

    if (!mountedRef.current || !activeTenant?.id || !userPermissions?.length) return

    let abortController: any
    try {
      abortController = new AbortController()
      callPagApi(
        '',
        getTokenSilently,
        getBid,
        { tenantId: activeTenant.id, id: bidId },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [userPermissions, activeTenant, bidId])

  useEffect(() => {
    if (!mountedRef.current || !activeProject) return

    let abortController: any
    const currency = activeProject?.currency?.currency
    const baseCurrency = exchangeRates?.base
    if (currency === baseCurrency) return

    try {
      abortController = new AbortController()
      callPagApi('', getTokenSilently, getExchangeRates, { base: currency }, abortController.signal)
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [activeProject?.currency?.currency]) // , exchangeRates?.base

  const handleSubmit = (fieldName: string, value: any) => {
    const tempProject = _cloneDeep(activeProject)
    _set(tempProject, fieldName, value)
    dispatch({ type: 'SetActiveProject', payload: { project: tempProject } })
  }

  const renderContent = useMemo(() => {
    const tempTabName = _capitalize(tabName)
    if (getProjectRes.loading || getBidRes.loading) {
      return <PagCircularIndeterminate />
    }

    if (tempTabName === 'Project') {
      return (
        <Paper className={clsx(classes.root, 'pag-app-content')} elevation={0}>
          {activeProject ? (
            <Grid container spacing={4}>
              <Grid item xs={9}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <ProjectOverview />
                  </Grid>

                  {/* <Grid
                      item
                      xs={12}
                    >
                      <br/>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <div className={classes.section}>
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={4}
                        >
                          <Grid
                            item
                            xs
                          >
                            <Metric
                              value={30}
                              label="Metric Name"
                            />
                          </Grid>

                          <Grid
                            item
                            xs
                          >
                            <Metric
                              value={'500,000'}
                              label="Metric Name"
                              prefix={CURRENCIES[_get(activeProject, ['currency', 'currency']) || ''] || '$'}
                            />
                          </Grid>

                          <Grid
                            item
                            xs
                          >
                            <Metric
                              value={120}
                              label="Metric Name"
                            />
                          </Grid>

                          <Grid
                            item
                            xs
                          >
                            <Metric
                              value={10}
                              label="Metric Name"
                              suffix={" Days"}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid> */}

                  <Grid item xs={12}>
                    <br />
                    <br />
                  </Grid>

                  {/* Project Cost Widgets */}
                  <Grid item xs={12}>
                    <div className={classes.sectionTitle}>
                      <Grid container alignItems="center" spacing={0}>
                        <Grid item xs={5}>
                          <Typography variant="h5" gutterBottom>
                            <b>Project Cost</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Grid container spacing={1}>
                            <Grid item xs>
                              <Typography variant="h6" align="right" gutterBottom>
                                Reporting Period : &nbsp;&nbsp;
                              </Typography>
                            </Grid>
                            <Grid item xs style={{ maxWidth: 140, marginTop: -17 }}>
                              <PagDatePicker
                                key={`project-cost-entry-item-filters-periodStart`}
                                margin="none"
                                name="reportingPeriodStart"
                                label="Start"
                                format="MM/yyyy"
                                value={reportingPeriodStart}
                                // inputVariant='outlined'
                                allowKeyboardControl={true}
                                size="small"
                                // fullWidth
                                views={['month', 'year']}
                                onChange={(e: any) => {
                                  setReportingPeriodStart(e)
                                }}
                              />
                            </Grid>
                            <Grid item xs style={{ maxWidth: 140, marginTop: -17 }}>
                              <PagDatePicker
                                key={`project-cost-entry-item-filters-endDate`}
                                margin="none"
                                name="reportingPeriodEnd"
                                label="End"
                                format="MM/yyyy"
                                value={reportingPeriodEnd}
                                // inputVariant='outlined'
                                allowKeyboardControl={true}
                                size="small"
                                // fullWidth
                                views={['month', 'year']}
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                onChange={(e: any) => {
                                  setReportingPeriodEnd(e)
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider className={classes.divider} />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.section}>
                      <ProjectCostWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Main',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          exchangeRates: exchangeRates,
                          reportingPeriodStart,
                          reportingPeriodEnd,
                        }}
                        outputs={{
                          handleSubmit: () => {},
                          handleCancel: () => {},
                        }}
                      />
                    </div>
                  </Grid>

                  {/* Bids summary */}
                  <Grid item xs={12}>
                    <br />
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.sectionTitle}>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Typography variant="h5" gutterBottom>
                            <b>Bids</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" align="right" gutterBottom>
                            <span
                              className="pag-blue"
                              onClick={() => navigate(`/${clientId}/${brandId}/${projectId}/bids`)}
                            >
                              View All Bids
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.sectionTitle}>
                      <Divider className={classes.divider} />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.section}>
                      <BidsSearchContainer
                        {...{
                          clientId,
                          brandId,
                          projectId,
                          bidId,

                          filterable: true,
                          filters: projectId ? `projectId:${projectId}` : '',
                          noBreadcrumb: true,
                        }}
                      />
                    </div>
                  </Grid>

                  {/* Deliverables summary */}
                  <Grid item xs={12}>
                    <br />
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.sectionTitle}>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <Typography variant="h5" gutterBottom>
                            <b>Deliverables</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" align="right" gutterBottom>
                            <span
                              className="pag-blue"
                              onClick={() =>
                                navigate(`/${clientId}/${brandId}/${projectId}/deliverables`)
                              }
                            >
                              View All Deliverables
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.sectionTitle}>
                      <Divider className={classes.divider} />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.section}>
                      {/* <DeliverablesContainer
                        {...{
                          projectId,
                          filterable: Boolean(projectId),
                          noBreadcrumb: true,
                          editable: false
                        }}
                      /> */}
                      <DeliverablesSearchContainer
                        {...{
                          clientId,
                          brandId,
                          projectId,
                          bidId,
                          filterable: true,
                          filters: projectId ? `projectId:${projectId}` : '',
                          noBreadcrumb: true,
                          editable: false,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                {/* Project Item Widgets */}
                <Paper className={clsx(classes.projectItemsWrapper)} elevation={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'details',
                          label: 'Project Details',
                          type: 'text',
                          dataType: 'string',
                          InputProps: {
                            key: activeProject?.details || '',
                            className: 'overview',
                            defaultValue: activeProject?.details || '',
                            label: 'Project Details',
                            size: 'small',
                            variant: 'outlined',
                            multiline: true,
                            minRows: 2,
                            maxRows: 6,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('details', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'description',
                          type: 'text',
                          dataType: 'string',
                          InputProps: {
                            key: activeProject?.description || '',
                            className: 'overview',
                            defaultValue: activeProject?.description || '',
                            label: 'Project Description',
                            size: 'small',
                            variant: 'outlined',
                            multiline: true,
                            minRows: 2,
                            maxRows: 6,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('description', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'projectTypeId',
                          type: 'select',
                          InputProps: {
                            className: 'overview',
                            label: 'PROJECT TYPE',
                            size: 'small',
                            value: activeProject?.projectTypeId || null,
                            variant: 'outlined',
                            placeholder: 'Choose a Project Type',
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('projectTypeId', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'tagIds',
                          type: 'select',
                          isCreatable: true,
                          InputProps: {
                            className: 'overview',
                            label: 'TAGS',
                            size: 'small',
                            value: activeProject?.tagIds || [],
                            variant: 'outlined',
                            placeholder: 'Click to add tags',
                            InputLabelProps: {
                              shrink: true,
                            },
                            AutocompleteProps: {
                              multiple: true,
                            },
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('tagIds', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'campaignId',
                          type: 'select',
                          isCreatable: true,
                          InputProps: {
                            className: 'overview',
                            label: 'CAMPAIGN',
                            size: 'small',
                            value: activeProject?.campaignId || null,
                            variant: 'outlined',
                            placeholder: 'Enter a campaign',
                            InputLabelProps: {
                              shrink: true,
                            },
                            AutocompleteProps: {},
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('campaignId', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          label: 'Budget',
                          name: 'budgetTotal',
                          type: 'text',
                          dataType: 'float',
                          InputProps: {
                            key: activeProject?.budgetTotal || '',
                            className: 'overview',
                            defaultValue: stringToCurrency(activeProject?.budgetTotal),
                            label: 'Approved Budget',
                            name: 'budgetTotal',
                            disabled: true,
                            size: 'small',
                            variant: 'outlined',
                            InputProps: {
                              inputComponent: PagNumberFormatInput,
                              readOnly: true,
                              style: { color: 'inherit' },
                            },
                            inputProps: {
                              allowNegative: true,
                              allowEmptyFormatting: true,
                              thousandSeparator: true,
                              isNumericString: true,
                              decimalScale: 2,
                              // fixedDecimalScale: true,
                              prefix: CURRENCIES[activeProject?.currency?.currency || ''] || '$',
                            },
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'budgetLineItem',
                          type: 'text',
                          dataType: 'string',
                          InputProps: {
                            key: activeProject?.budgetLineItem || '',
                            className: 'overview',
                            defaultValue: activeProject?.budgetLineItem || '',
                            label: 'Budget Line Item',
                            size: 'small',
                            variant: 'outlined',
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('budgetLineItem', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'currencyId',
                          type: 'select',
                          // isCreatable: true,
                          InputProps: {
                            className: 'overview',
                            label: 'currency',
                            size: 'small',
                            value: activeProject?.currencyId || null,
                            variant: 'outlined',
                            placeholder: 'currency',
                            InputLabelProps: {
                              shrink: true,
                            },
                            AutocompleteProps: {},
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('currencyId', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'budgetRate',
                          type: 'text',
                          dataType: 'float',
                          InputProps: {
                            key: activeProject?.budgetRate || '',
                            className: 'overview',
                            defaultValue: stringToCurrency(activeProject?.budgetRate || 0),
                            label: 'Estimated Budget',
                            name: 'budgetRate',
                            size: 'small',
                            variant: 'outlined',
                            InputProps: {
                              inputComponent: PagNumberFormatInput,
                            },
                            inputProps: {
                              allowNegative: true,
                              allowEmptyFormatting: true,
                              thousandSeparator: true,
                              isNumericString: true,
                              decimalScale: 2,
                              // fixedDecimalScale: true,
                              prefix: CURRENCIES[activeProject?.currency?.currency || ''] || '$',
                            },
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        outputs={{
                          handleCancel: (e: any) => {},
                          handleSubmit: (v: any) => handleSubmit('budgetRate', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'bidTypeId',
                          label: 'Bid Settings',
                          type: 'select',
                          InputProps: {
                            className: 'overview',
                            label: 'BID TYPE',
                            size: 'small',
                            value: activeProject?.bidTypeId || null,
                            variant: 'outlined',
                            placeholder: 'Select a Project Bid Type',
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('bidTypeId', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'preBidDate',
                          type: 'date',
                          InputProps: {
                            key: activeProject?.preBidDate || '',
                            className: 'overview',
                            label: 'PRE-BID DATE',
                            size: 'small',
                            value: activeProject?.preBidDate || null,
                            emptyLabel: 'Add a date',
                            inputVariant: 'outlined',
                            allowKeyboardControl: true,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('preBidDate', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'files',
                          label: 'Files',
                          type: 'files',
                          dataType: 'string',
                          InputProps: {
                            defaultValue: activeProject?.files || [],
                            label: 'Files',
                            value: activeProject?.files || [],
                            size: 'small',
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('files', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'metaData.projectTeam',
                          label: 'Project Team',
                          type: 'metaData',
                          dataType: 'string',
                          InputProps: {
                            value: activeProject?.metaData?.projectTeam || [],
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('metaData.projectTeam', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'agencyProducerId',
                          label: 'Agency Producer',
                          type: 'select',
                          isCreatable: true,
                          InputProps: {
                            className: 'overview',
                            label: 'AGENCY PRODUCER',
                            size: 'small',
                            value: activeProject?.agencyProducerId || null,
                            variant: 'outlined',
                            placeholder: 'Select a producer',
                            InputLabelProps: {
                              shrink: true,
                            },
                            AutocompleteProps: {},
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('agencyProducerId', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'deliveryDate',
                          type: 'date',
                          InputProps: {
                            key: activeProject?.deliveryDate || '',
                            className: 'overview',
                            label: 'DELIVERY DATE',
                            size: 'small',
                            value: activeProject?.deliveryDate || null,
                            emptyLabel: 'Add a date',
                            inputVariant: 'outlined',
                            allowKeyboardControl: true,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('deliveryDate', v),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'productionStartDate',
                          type: 'date',
                          InputProps: {
                            key: activeProject?.productionStartDate || '',
                            className: 'overview',
                            label: 'PRODUCTION DATE',
                            size: 'small',
                            value: activeProject?.productionStartDate || null,
                            emptyLabel: 'Add a date',
                            inputVariant: 'outlined',
                            allowKeyboardControl: true,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('productionStartDate', v),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'actualizedDate',
                          type: 'date',
                          InputProps: {
                            key: activeProject?.actualizedDate || '',
                            className: 'overview',
                            label: 'ACTUALIZED DATE',
                            size: 'small',
                            value: activeProject?.actualizedDate || null,
                            emptyLabel: 'Add a date',
                            inputVariant: 'outlined',
                            allowKeyboardControl: true,
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('actualizedDate', v),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'metaData.shoots',
                          label: 'Shoots',
                          type: 'metaData',
                          InputProps: {
                            className: 'overview',
                            size: 'small',
                            value: activeProject?.metaData?.shoots || [],
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => handleSubmit('metaData.shoots', v),
                        }}
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: "Sidebar",
                          permissions: [],
                          visible: true,
                          style: {}
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'spots',
                          label: 'Spots',
                          type: 'deliverable',
                          InputProps: {
                            className: 'overview',
                            size: 'small',
                            value: _get(activeProject, ['deliverables'], []),
                            InputLabelProps: {
                              shrink: true
                            }
                          }
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) => {
                            handleSubmit('metaData', v);
                          }
                        }}
                      />
                    </Grid> */}

                    <Grid item xs={12}>
                      <ProjectItemWidget
                        widgetConfig={{
                          order: 0,
                          area: 'Sidebar',
                          permissions: [],
                          visible: true,
                          style: {},
                        }}
                        config={{}}
                        inputs={{
                          project: activeProject,
                          name: 'metaData.costDriversOptimizers',
                          label: 'Drivers / Optimizers',
                          type: 'metaData',
                          dataType: 'string',
                          InputProps: {
                            value: activeProject?.metaData?.costDriversOptimizers || [],
                          },
                        }}
                        outputs={{
                          handleCancel: () => {},
                          handleSubmit: (v: any) =>
                            handleSubmit('metaData.costDriversOptimizers', v),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <PagCircularIndeterminate />
          )}
        </Paper>
      )
    } else if (tempTabName === 'Bids') {
      return (
        <Paper className={clsx(classes.root, 'pag-app-content')} elevation={0}>
          <BidsSearchContainer
            {...{
              clientId,
              brandId,
              projectId,
              bidId,

              filterable: Boolean(projectId),
              filters: projectId ? `projectId:${projectId}` : '',
              noBreadcrumb: true,
            }}
          />
        </Paper>
      )
    } else if (tempTabName === 'Deliverables') {
      return (
        <Paper className={clsx(classes.root, 'pag-app-content')} elevation={0}>
          {/* <DeliverablesSearchContainer
            {...{
              clientId,
              brandId,
              projectId,
              bidId,

              filterable: Boolean(projectId),
              filters: projectId ? `projectId:${projectId}` : '',
              noBreadcrumb: true,

              editable: true
            }}
          /> */}
          <DeliverablesContainer
            {...{
              projectId,
              filterable: Boolean(projectId),
              noBreadcrumb: true,
              editable: true,
            }}
          />
        </Paper>
      )
    } else if (tempTabName === 'Budget') {
      return (
        <Paper className={clsx(classes.root, 'pag-app-content')} elevation={0}>
          <BudgetContainer
            {...{
              projectId,
              filterable: Boolean(projectId),
              noBreadcrumb: true,
              editable: true,
            }}
          />
        </Paper>
      )
    } else {
      return (
        <Paper className={clsx(classes.root, 'pag-app-content')} elevation={0}>
          <IntegrationContainer tabName={tempTabName} />
        </Paper>
      )
    }
  }, [
    activeProject,
    clientId,
    brandId,
    projectId,
    bidId,
    tabName,
    reportingPeriodStart,
    reportingPeriodEnd,
    exchangeRates,
    getProjectRes.loading,
    getBidRes.loading,
  ])

  return (
    <>
      <ProjectDashboardBreadCrumb
        {...{
          clientId,
          brandId,
          projectId,
          bidId,
          tabName,
        }}
      />
      {renderContent}
    </>
  )
}

export default ProjectDashboardContainer
