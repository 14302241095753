import gql from 'graphql-tag'

const GET_PROJECT_COST_BY_PROJECT = gql`
  query projectCostByProject(
    $tenantId: Int!
    $projectId: Int!
    $projectCostEntryItemsFilter: ProjectCostEntryItemsFilterInput
  ) {
    projectCostByProject(
      tenantId: $tenantId
      projectId: $projectId
      projectCostEntryItemsFilter: $projectCostEntryItemsFilter
    ) {
      id
      projectCostEntries {
        id
        projectCostId
        projectCostTypeId
        currencyId
        vendorId
        description
        sortOrder
        projectCostType {
          id
          name
          description
          customFieldsMetaData
        }
        projectCostEntryItems {
          id
          projectCostEntryId
          type
          sequence
          amount
          notes
          savingTypeId
          customFieldsMetaData
          reportingPeriodStart
          reportingPeriodEnd
          bidId
          bidDate
          bidNumber
          markupAmount
          markupPercentage
        }
        vendor {
          id
          name
          diversity
        }
        currency {
          id
          currency
        }
      }
    }
  }
`

export { GET_PROJECT_COST_BY_PROJECT }
