import React, { useEffect, useContext, useMemo, useState } from 'react'
import { ElfsightWidget } from 'react-elfsight-widget'

import useUrlParams from '@/components/hooks/use-url-params'
import PagCircularIndeterminate from '@/components/pag-loading'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IClient } from '@/shared/models/client'

const CalendarContainer = () => {
  const { clientId } = useUrlParams({ clientId: true })
  const { store } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const { clients } = store
  const client = clients.find((c: IClient) => c.id === clientId)

  useEffect(() => {
    let interval: any
    if (!client?.calendarWidget) return

    interval = setInterval(() => {
      const temp = document.querySelectorAll('[data-app="eapps-events-calendar"]')
      if (temp.length) {
        clearInterval(interval)
        setLoading(false)
      }
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [client])

  const widgetID = useMemo(
    () => (client?.calendarWidget ? client.calendarWidget.split('elfsight-app-')[1] : ''),
    [client?.calendarWidget]
  )

  return (
    <div>
      {Boolean(widgetID) && <ElfsightWidget widgetID={widgetID} />}
      {Boolean(widgetID) && loading && <PagCircularIndeterminate />}
    </div>
  )
}

export default CalendarContainer
