import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import { useLazyQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'

import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import { HeadCell } from '@/components/pag-table/pag-table-head'
import { errorHandler } from '@/shared/error-handler'
import { GET_BENCHMARK_TOTALS_IN_BIDS } from '@/shared/graphql/queryGetBids'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { CURRENCIES } from '@/shared/constants/currency'
import { hasPermission } from '@/utils/role-permission'

const headCells = [
  {
    id: 'client.name',
    align: 'left',
    disablePadding: true,
    label: 'Company',
    sortable: false,
    isLink: false,
  },
  {
    id: 'bidNumber',
    align: 'left',
    disablePadding: true,
    label: 'Bid Number',
    sortable: true,
    isLink: false,
  },
  {
    id: 'brand.name',
    align: 'left',
    disablePadding: true,
    label: 'Brand',
    sortable: false,
    isLink: false,
  },
  {
    id: 'project.agency.name',
    align: 'left',
    disablePadding: true,
    label: 'Agency Name',
    sortable: false,
    isLink: false,
  },
  {
    id: 'bidType',
    align: 'left',
    disablePadding: true,
    label: 'Bid Type',
    sortable: true,
    isLink: false,
  },
  {
    id: 'project.vendor.name',
    align: 'left',
    disablePadding: true,
    label: 'Vendor',
    sortable: false,
    isLink: false,
  },
  {
    id: 'bidTotalCost',
    align: 'right',
    disablePadding: true,
    label: 'Bid Total',
    sortable: true,
    isLink: false,
  },
  {
    id: 'bidStatus.status',
    align: 'right',
    disablePadding: true,
    label: 'Bid Status',
    sortable: true,
    isLink: false,
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
  },
  tdArrow: {
    paddingTop: 8,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 6,
    verticalAlign: 'bottom',
  },
  green: {
    color: 'green',
  },
  arrow: {
    cursor: 'pointer',
  },
}))

const RenderHeadCellValue = (headCell: HeadCell, orderBy: any) => {
  if (headCell.sortable)
    return (
      <TableSortLabel
        // active={orderBy === headCell.id}
        hideSortIcon={true}
      >
        {headCell.label}
      </TableSortLabel>
    )
  return headCell.label
}

const BidsTableView = (props: any) => {
  const {
    activeProject,
    selectedBids,
    bidsViewMode,
    onDetailView,
    onSelectBid,

    getTokenSilently,
    onUpdatedBids,

    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,
  } = props
  const classes = useStyles()
  const mountedRef = useMounted(true)
  const sentinel: any = useRef(null)
  const { store } = useContext(Context)
  const { userPermissions, exchangeRates, activeTenant } = store

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('bidNumber')
  const [token, setToken] = useState('')

  // Get BidItem Category Groups in Bids
  const [benchmarkTotalsInBids, benchmarkTotalsInBidsRes] = useLazyQuery(
    GET_BENCHMARK_TOTALS_IN_BIDS,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {},
      fetchPolicy: 'network-only',
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (!searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  // const handleRequestSort = (event: any, property: any) => {
  //   const isDesc = orderBy === property && order === 'desc';
  //   setOrder(isDesc ? 'asc' : 'desc');
  //   setOrderBy(property);
  // };

  const hasPermissionEnableBenchmarksBids = useMemo(
    () => hasPermission(userPermissions, 'enablebenchmarks:bids'),
    [userPermissions]
  )

  useEffect(() => {
    if (
      !mountedRef.current ||
      !activeTenant?.id ||
      !hasPermissionEnableBenchmarksBids ||
      !hits ||
      !hits.length
    )
      return

    let abortController = new AbortController()

    const bidIds = hits.map((b: any) => b.id)
    const callApi = async () => {
      try {
        const temp = await callPagApi(
          '',
          getTokenSilently,
          benchmarkTotalsInBids,
          { tenantId: activeTenant.id, bidIds },
          abortController.signal
        )

        if (mountedRef.current) {
          setToken(temp || '')
        }
      } catch (e) {}
    }
    callApi()

    return () => {
      if (abortController) abortController.abort()
    }
  }, [hasPermissionEnableBenchmarksBids, activeTenant, hits])

  useEffect(() => {
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [hits, observer, onSentinelIntersection])

  // After getting Bid Item Categories
  useEffect(() => {
    if (!mountedRef.current) return

    try {
      const temp = _get(benchmarkTotalsInBidsRes, ['data', 'benchmarkTotalsInBids', 'data']) || []

      if (temp.length) {
        for (const bechmarkTotal of temp) {
          const bid = hits.find((hit: any) => hit.id === bechmarkTotal.bidId)
          if (bid) {
            bid.benchmarkBidTotal = bechmarkTotal.benchmarkBidTotal
          }
        }
        onUpdatedBids()
      }
    } catch (e) {}

    return () => {}
  }, [benchmarkTotalsInBidsRes.data])

  const renderTableView = useMemo(() => {
    const isSelected = (bid: any) => _findIndex(selectedBids, (b: any) => b.id === bid.id) >= 0
    return (
      <div className={classes.root}>
        {nbPages ? (
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="Bid Results"
              size={'medium'}
              aria-label="Bid Results"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {bidsViewMode !== 'All' ? (
                    <TableCell className={classes.th} style={{ width: 30 }}></TableCell>
                  ) : null}

                  {headCells.map((headCell: HeadCell, i) => (
                    <TableCell
                      key={headCell.id}
                      className={classes.th}
                      align={headCell.align as any}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      style={{ width: headCell.width || undefined }}
                    >
                      {RenderHeadCellValue(headCell, orderBy)}
                    </TableCell>
                  ))}

                  {bidsViewMode === 'All' ? <TableCell style={{ width: 30 }}></TableCell> : null}
                </TableRow>
              </TableHead>

              <TableBody>
                {hits.map((bid: any, i: number) => {
                  const isItemSelected = isSelected(bid)
                  const bidStatus = _get(bid, ['bidStatus', 'status']) || ''
                  const bidCurrency = _get(bid, ['currency', 'currency']) || ''
                  const exchangeRate = _get(exchangeRates, [bidCurrency]) || 1

                  return (
                    <TableRow
                      key={bid.objectID}
                      tabIndex={-1}
                      onClick={() => {
                        bidsViewMode === 'All' ? onDetailView(bid) : onSelectBid(bid)
                      }}
                    >
                      {bidsViewMode !== 'All' ? (
                        <TableCell className={classes.td}>
                          <Checkbox
                            className="pag-checkbox"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': bid['id'] }}
                          />
                        </TableCell>
                      ) : null}
                      <TableCell className={classes.td}>
                        {_get(bid, ['client', 'name']) ||
                          _get(bid, ['brand', 'client', 'name']) ||
                          _get(bid, ['project', 'client', 'name']) ||
                          _get(bid, ['project', 'brand', 'client', 'name']) ||
                          ''}
                      </TableCell>
                      <TableCell className={classes.td}>{_get(bid, ['bidNumber']) || ''}</TableCell>
                      <TableCell className={classes.td}>
                        {_get(bid, ['brand', 'name']) ||
                          _get(bid, ['project', 'brand', 'name']) ||
                          ''}
                      </TableCell>
                      <TableCell className={classes.td}>
                        {_get(bid, ['project', 'agency', 'name']) || ''}
                      </TableCell>
                      <TableCell className={classes.td}>{_get(bid, ['bidType']) || ''}</TableCell>
                      <TableCell className={classes.td}>
                        {_get(bid, ['vendor', 'name']) || ''}
                      </TableCell>
                      <TableCell className={classes.td} align="right">
                        <NumberFormat
                          displayType={'text'}
                          value={(_get(bid, ['bidTotalCost']) || 0) * exchangeRate}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={CURRENCIES[activeProject?.currency?.currency || ''] || '$'}
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.td,
                          bidStatus === 'Awarded' ? classes.green : undefined
                        )}
                        align="right"
                      >
                        {bidStatus}
                      </TableCell>
                      {bidsViewMode === 'All' ? (
                        <TableCell className={classes.tdArrow}>
                          <Icon className={classes.arrow}>chevron_right</Icon>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
                <TableRow tabIndex={-1} ref={sentinel}>
                  <TableCell className="pag-infinite-sentinel" colSpan={9}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          <>
            {!searching && (
              <div className="pag-flex" style={{ height: '100%', marginTop: 24 }}>
                <Typography variant="h5">No Bids</Typography>
              </div>
            )}
          </>
        )}
      </div>
    )
  }, [activeProject, bidsViewMode, classes, nbPages, hits, orderBy, searching, selectedBids])

  return renderTableView
}

export default BidsTableView
