import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import PagCircularIndeterminate from '@/components/pag-loading'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  gridItem: {
    margin: '12px',
    padding: '12px',
    width: '100%',
    border: '1px solid lightgray',
  },
}))

const AllSearchView = (props: any) => {
  const {
    hits,
    hasMore,
    refine,

    searching,
  } = props

  const classes = useStyles()
  const sentinel: any = useRef(null)

  const onSentinelIntersection = useCallback(
    (entries: any) => {
      entries.forEach((entry: any) => {
        if (!searching && entry.isIntersecting && hasMore) {
          refine()
        }
      })
    },
    [hasMore, refine]
  )

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [hits, observer, onSentinelIntersection])

  const content = useMemo(() => {
    return (
      <>
        {searching && <PagCircularIndeterminate />}

        {_get(hits, ['length']) ? (
          <>
            <Grid container className={classes.root}>
              {hits.map((hitItem: any, i: any) => (
                <Grid key={`pb-${i}`} item className={classes.gridItem}>
                  {hitItem.projectNumber ? (
                    <div>
                      <div>Project</div>
                      <div>{hitItem.projectName}</div>
                    </div>
                  ) : (
                    <>
                      {hitItem.bidNumber ? (
                        <div>
                          <div>Bid</div>
                          <div>{hitItem.bidNumber}</div>
                        </div>
                      ) : (
                        <div>
                          <div>Deliverable</div>
                          <div>{hitItem.spotName}</div>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              ))}
              <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
            </Grid>
          </>
        ) : (
          <>
            {!searching && (
              <div className="pag-flex" style={{ height: '100%' }}>
                <Typography variant="h5">No Result</Typography>
              </div>
            )}
          </>
        )}
      </>
    )
  }, [hits, searching])

  return <>{content}</>
}

// ProjectsBidsView.propTypes = {
// 	projects: PropTypes.array.isRequired,
// 	detailViewProject: PropTypes.any.isRequired,
// 	handleViewProject: PropTypes.func.isRequired,
// 	handleViewProjectDetail: PropTypes.func.isRequired,
// };

export default AllSearchView
