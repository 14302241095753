import gql from 'graphql-tag'
import CostDriverOptimizerType from '../types/enum-CostDriverOptimizerType'

const ADD_COST_DRIVER_OPTIMIZER = gql`
  mutation addCostDriverOptimizer(
    $tenantId: Int!
    $name: String!
    $clientId: Int!
    $type: CostDriverOptimizerType!
  ) {
    addCostDriverOptimizer(tenantId: $tenantId, name: $name, clientId: $clientId, type: $type) {
      id
      name
      clientId
      type
    }
  }
`
const UPDATE_COST_DRIVER_OPTIMIZER = gql`
  mutation updateCostDriverOptimizer(
    $tenantId: Int!
    $id: Int!
    $name: String
    $clientId: Int
    $type: CostDriverOptimizerType
  ) {
    updateCostDriverOptimizer(
      tenantId: $tenantId
      id: $id
      name: $name
      clientId: $clientId
      type: $type
    )
  }
`

export { ADD_COST_DRIVER_OPTIMIZER, UPDATE_COST_DRIVER_OPTIMIZER }
