import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles, Theme } from '@material-ui/core/styles'

import useMounted from '@/components/hooks/use-mounted'
import PagCircularIndeterminate from '@/components/pag-loading'
import PagCheckbox from '@/components/pag-form/pag-checkbox'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { useAuth0 } from '@/auth/index'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { GET_COST_DRIVERS_OPTIMIZERS } from '@/shared/graphql/queryGetCostDriverOptimizers'
import { ADD_COST_DRIVER_OPTIMIZER } from '@/shared/graphql/mutations/mutationCostDiversOptimizers'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  card: {
    position: 'relative',
    textAlign: 'left',
    overflow: 'visible',
    border: 'none',
  },
  cardHeader: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  overageItem: {
    position: 'relative',
    '& .overage-item-delete-button': {
      display: 'none',
      position: 'absolute',
      right: -16,
      top: 14,
    },
    '&:hover .overage-item-delete-button': {
      display: 'inherit',
    },
  },
  cardAction: {},
  button: {},
  circularProgress: {
    position: 'absolute',
  },
}))

const ProjectItemCostDriversOptimizersComponent = (props: any) => {
  const { validationSchema, InputProps, clientId, loading, handleChange, handleCancel } = props
  const classes = useStyles(props)
  const mountedRef = useMounted(true)
  const saveTypeRef = useRef(null as any)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store

  const [options, setOptions] = useState([] as any)
  const [visibleNewDriver, setVisibleNewDriver] = useState(false)
  const [visibleNewOptimizer, setVisibleNewOptimizer] = useState(false)
  const [newDriverName, setNewDriverName] = useState('')
  const [newOptimizerName, setNewOptimizerName] = useState('')

  const [getCostDriversOptimizers, getCostDriversOptimizersRes] = useLazyQuery(
    GET_COST_DRIVERS_OPTIMIZERS,
    {
      onError: (err) => {
        if (!mountedRef.current) return
        setOptions([])
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        const temp = _orderBy(_get(res, ['costDriversOptimizers', 'data']) || [], ['name'])
        setOptions(temp)
      },
      fetchPolicy: 'network-only',
    }
  )

  const [addCostDriverOptimizer, addCostDriverOptimizerRes] = useMutation(
    ADD_COST_DRIVER_OPTIMIZER,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {},
    }
  )

  useEffect(() => {
    let abortController: any
    try {
      abortController = new AbortController()
      callPagApi(
        '',
        getTokenSilently,
        getCostDriversOptimizers,
        { tenantId: activeTenant?.id },
        abortController.signal
      )
    } catch (e) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant])

  useEffect(() => {
    if (!mountedRef.current || !addCostDriverOptimizerRes.data) return

    const temp = _get(addCostDriverOptimizerRes.data, ['addCostDriverOptimizer'])

    if (temp) {
      setOptions([...options, temp])
      if (saveTypeRef.current === 'COST_DRIVER') {
        setVisibleNewDriver(false)
      } else {
        setVisibleNewOptimizer(false)
      }
      saveTypeRef.current = ''
    }

    return () => {}
  }, [addCostDriverOptimizerRes.data])

  const handleSaveNewDriverOptimizer = (type: string, name: string) => {
    if (!clientId) {
      errorHandler(
        `This project doesn't have Client. Please set a client to this project and try again.`
      )
      return
    }

    let abortController: any
    try {
      abortController = new AbortController()
      saveTypeRef.current = type
      callPagApi(
        '',
        getTokenSilently,
        addCostDriverOptimizer,
        {
          tenantId: activeTenant?.id,
          type,
          name,
          clientId,
        },
        abortController.signal
      )
    } catch (e) {
      if (abortController) abortController.abort()
    }
  }

  const handleChangeCheckbox = (id: number, event: any) => {
    let temp = []
    if (event.target.checked) {
      temp = [...(InputProps.value || []), id]
    } else {
      temp = (InputProps.value || []).filter((item: any) => item !== id)
    }
    handleChange(temp)
  }

  const renderContent = (type: string, visibleAddItem: boolean) => {
    const items = options.filter((item: any) => item.type === type)
    return (
      <Card className={clsx(classes.card)} elevation={0} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          title={type === 'COST_DRIVER' ? 'Cost Drivers' : 'Optimizers'}
          titleTypographyProps={{
            variant: 'h6',
          }}
        />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            {items.map((item: any, index: number) => {
              const value = item?.id || 0
              const checked = Boolean((InputProps.value || []).find((item: any) => item === value))

              return (
                <Grid key={item.id} item xs={12} className={classes.overageItem}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <PagCheckbox
                        label={item.name || ''}
                        labelPlacement="end"
                        size="small"
                        value={value}
                        checked={checked}
                        onChange={(e: any) => handleChangeCheckbox(value, e)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

            {!visibleAddItem && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="text"
                  className={classes.button}
                  // disabled={loading}
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => {
                    if (type === 'COST_DRIVER') {
                      setVisibleNewDriver(true)
                    } else {
                      setVisibleNewOptimizer(true)
                    }
                  }}
                >
                  {type === 'COST_DRIVER' ? 'Add Cost Driver' : 'Add Optimizer'}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {visibleAddItem && (
          <CardActions className={classes.cardAction}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={8}>
                <PagTextInput
                  name="driverName"
                  label={type === 'COST_DRIVER' ? 'Cost Driver' : 'Optimizer'}
                  placeholder={type === 'COST_DRIVER' ? 'Cost Driver Name' : 'Optimizer Name'}
                  size="small"
                  variant="outlined"
                  onChange={(e: any) => {
                    if (type === 'COST_DRIVER') {
                      setNewDriverName(e.target.value)
                    } else {
                      setNewOptimizerName(e.target.value)
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  size="medium"
                  aria-label="update"
                  variant="contained"
                  className={classes.button}
                  disabled={
                    !(type === 'COST_DRIVER' ? newDriverName : newOptimizerName) ||
                    loading ||
                    addCostDriverOptimizerRes.loading
                  }
                  onClick={(e: any) => {
                    handleSaveNewDriverOptimizer(
                      type,
                      type === 'COST_DRIVER' ? newDriverName : newOptimizerName
                    )
                  }}
                >
                  Save
                  {saveTypeRef.current === type && addCostDriverOptimizerRes.loading && (
                    <CircularProgress
                      size={16}
                      color="primary"
                      className={classes.circularProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        )}
      </Card>
    )
  }

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {renderContent('COST_DRIVER', visibleNewDriver)}
        </Grid>
        <Grid item xs={12}>
          {renderContent('COST_OPTIMIZER', visibleNewOptimizer)}
        </Grid>
      </Grid>
      {(getCostDriversOptimizersRes.loading || loading) && <PagCircularIndeterminate size={14} />}
    </div>
  )
}

ProjectItemCostDriversOptimizersComponent.defaultProps = {
  handleChange: (e: any, shouldCallApi: boolean) => {},
  handleCancel: () => {},
}

export default ProjectItemCostDriversOptimizersComponent
