import gql from 'graphql-tag'

const GET_CAMPAIGNS = gql`
  query campaigns(
    $tenantId: Int!
    $clientId: Int!
    $campaignName: String
    $offset: Int
    $limit: Int
  ) {
    campaigns(
      tenantId: $tenantId
      clientId: $clientId
      campaignName: $campaignName
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        clientId
      }
    }
  }
`

export { GET_CAMPAIGNS }
