import React, { useLayoutEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    width: 300,
  },
  buttonsWrapper: {
    borderTop: '1px solid lightgray',
  },
}))

const PagDateRangePicker = (props: any) => {
  const {
    initialDateRange,
    disablePast,
    disableFuture,
    minDate,
    maxDate,
    handleChangeDateRange,
    handleCancel,
  } = props
  const classes = useStyles()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (initialDateRange?.startDate) {
      if (typeof initialDateRange.startDate === 'string') {
        setStartDate(new Date(initialDateRange.startDate))
      } else if (initialDateRange.startDate && initialDateRange.startDate instanceof Date) {
        setStartDate(initialDateRange.startDate)
      }
    } else {
      const d = new Date()
      const day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      setStartDate(new Date(d.setDate(diff)))
    }

    if (initialDateRange?.endDate) {
      if (typeof initialDateRange.endDate === 'string') {
        setEndDate(new Date(initialDateRange.endDate))
      } else if (initialDateRange.endDate && initialDateRange.endDate instanceof Date) {
        setEndDate(initialDateRange.endDate)
      }
    } else {
      setEndDate(new Date())
    }
    return () => {}
  }, [initialDateRange])

  return (
    <Paper className={classes.root} elevation={5}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  autoOk
                  disablePast={disablePast || false}
                  disableFuture={disableFuture || true}
                  value={startDate}
                  onChange={(date) => {
                    if (date) {
                      date.setHours(0)
                      date.setMinutes(0)
                      date.setSeconds(0)
                    }
                    setStartDate(date)
                    if (date && endDate && date > endDate) {
                      setEndDate(null)
                    }
                  }}
                  onError={(error, value) => {
                    setStartDateError(Boolean(error))
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date"
                  label="End Date"
                  autoOk
                  disablePast={disablePast || false}
                  disableFuture={disableFuture || true}
                  value={endDate}
                  onChange={(date) => {
                    if (date) {
                      date.setHours(23)
                      date.setMinutes(59)
                      date.setSeconds(59)
                    }
                    setEndDate(date)
                    if (date && startDate && date < startDate) {
                      setStartDate(null)
                    }
                  }}
                  onError={(error, value) => {
                    setEndDateError(Boolean(error))
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonsWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button color="primary" onClick={() => handleChangeDateRange({})}>
                  Clear
                </Button>
              </Grid>
              <Grid item xs={8} className="pag-text-right">
                <Button color="primary" variant="outlined" onClick={() => handleCancel()}>
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  color="primary"
                  variant="contained"
                  disabled={startDateError || endDateError || !startDate || !endDate}
                  onClick={() => {
                    handleChangeDateRange({ startDate, endDate })
                  }}
                >
                  Select
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  )
}

export default PagDateRangePicker
