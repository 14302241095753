import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 18,
    maxWidth: 320,
    margin: 'auto',
  },
  cardActionArea: {
    display: 'flex',
    fontSize: 24,
  },
  appImage: {
    height: 74,
    objectFit: 'contain',
  },
  appName: {
    marginLeft: 12,
  },
  appContent: {
    fontSize: 14,
    textAlign: 'center',
    height: 198,
    overflow: 'hidden',
  },
}))

const IntegrationAppComponent = ({ name, description, image_url }: any) => {
  const classes = useStyles()
  return (
    <Card variant="outlined" className={clsx(classes.root)}>
      <CardActionArea className={clsx(classes.cardActionArea)}>
        <CardMedia
          component="img"
          className={classes.appImage}
          alt={name}
          image={image_url}
          title={name}
        />
      </CardActionArea>
      <br />
      <div className={classes.appContent}>{description}</div>
    </Card>
  )
}

export default IntegrationAppComponent
