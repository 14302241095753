import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import { useLazyQuery } from '@apollo/react-hooks'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _uniqBy from 'lodash/uniqBy'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagCircularIndeterminate from '@/components/pag-loading'
import { errorHandler } from '@/shared/error-handler'
import { CURRENCIES } from '@/shared/constants/currency'
import { GET_BID_ITEMS_BY_CATEGORY_GROUP } from '@/shared/graphql/queryGetBids'
import { callPagApi } from '@/shared/services/pag-api'

const dispBidItemFields = [
  { fieldPath: ['serviceItem', 'unit'], desc: 'Unit', isCurrency: false },
  { fieldPath: ['serviceQty'], desc: 'Qty', isCurrency: false },
  { fieldPath: ['serviceRate'], desc: 'Bid Rate', isCurrency: true },
  { fieldPath: ['serviceCost'], desc: 'Bid Cost', isCurrency: true },
  {
    fieldPath: ['serviceItem', 'benchmarkRate'],
    desc: 'Benchmark Rate',
    isCurrency: true,
  },
]

const BidItemsByCategoryInBids = (props: any) => {
  const {
    allExpanded,
    category,
    classes,
    enableBenchmarksBids,
    exchangeRates,
    group,
    projectCurrency,
    selectedBids,
    serviceCategoryWithBids,
    token,
  } = props

  const { getTokenSilently } = useAuth0()

  const mountedRef = useMounted(true)
  const [bidItemsByCategoryGroup, setBidItemsByCategoryGroup] = useState([] as any)
  const [expand, setExpand] = useState(allExpanded) // for expanding service category
  const [selectedServices, setSelectedServices] = useState([] as any) // for expanding service

  useEffect(() => {
    if (!mountedRef.current) return
    setExpand(allExpanded)
    return () => {}
  }, [allExpanded])

  // Get BidItems by Category
  const [getBidItemsByCategoryGroupInBids, bidItemsByCategoryGroupInBidsRes] = useLazyQuery(
    GET_BID_ITEMS_BY_CATEGORY_GROUP,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {},
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!mountedRef.current) return

    let abortController = new AbortController()
    const { signal } = abortController
    const bidIds = _map(serviceCategoryWithBids, 'bidId') || []

    if (category && expand && bidIds.length) {
      callPagApi(
        token,
        getTokenSilently,
        getBidItemsByCategoryGroupInBids,
        { bidIds: bidIds, serviceGroup: group, serviceCategory: category },
        signal
      )
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [serviceCategoryWithBids, category, expand])

  useEffect(() => {
    if (!mountedRef.current) return

    const temp =
      _get(bidItemsByCategoryGroupInBidsRes, ['data', 'bidItemsByCategoryGroupInBids', 'data']) ||
      []
    setBidItemsByCategoryGroup(temp)

    return () => {}
  }, [bidItemsByCategoryGroupInBidsRes.data])

  const onSelectService = (service: any) => {
    const temp = _find(selectedServices, service)
    if (!temp) {
      setSelectedServices([...selectedServices, service])
    } else {
      setSelectedServices(selectedServices.filter((s: any) => s !== service))
    }
  }

  const renderBidItemsByCategory = () => {
    const serviceItems = _uniqBy(
      _map(bidItemsByCategoryGroup, (bic) => bic.serviceItem),
      'id'
    )

    return serviceItems.map((serviceItem: any, i: any) => {
      const isActive = Boolean(_find(selectedServices, (s) => s === serviceItem))
      return (
        <React.Fragment key={`service-${_get(serviceItem, ['id'])}`}>
          <TableRow
            hover
            tabIndex={-1}
            className={clsx(
              isActive ? classes.trActive : null,
              isActive ? classes.trBorderBottomActive : null
            )}
            onClick={() => onSelectService(serviceItem)}
          >
            <TableCell className={clsx(classes.td)}>
              E{_get(serviceItem, ['rateId']) || 0}
            </TableCell>
            <TableCell className={classes.td}>{_get(serviceItem, 'service') || ''}</TableCell>

            {selectedBids.map((bid: any, j: number) => {
              const bidCurrency = _get(bid, ['currency', 'currency']) || ''
              const exchangeRate = _get(exchangeRates, [bidCurrency]) || 1
              const temp = bidItemsByCategoryGroup.filter(
                (bi: any) => bi.serviceItemId === serviceItem.id && bi.bidId === bid.id
              )
              const totalCost =
                _reduce(temp, (sum, i) => sum + _get(i, ['serviceCost'], 0), 0) * exchangeRate
              const totalBenchmark =
                _reduce(
                  temp,
                  (sum, i) =>
                    sum + _get(i, ['serviceQty'], 0) * _get(i, ['serviceItem', 'benchmarkRate'], 0),
                  0
                ) * exchangeRate
              const diff = totalCost - totalBenchmark

              return (
                <TableCell
                  key={`${_get(serviceItem, 'service') || ''}-bid-${j}`}
                  className={classes.td}
                >
                  <div className={classes.priceVSBenchmark}>
                    {totalCost ? (
                      <>
                        <div className={classes.price}>
                          <b>
                            <NumberFormat
                              displayType={'text'}
                              value={totalCost}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={CURRENCIES[projectCurrency] || '$'}
                            />
                          </b>
                        </div>
                        <div
                          className={clsx(
                            classes.benchmarkCompare,
                            diff > 0
                              ? classes.textRed
                              : diff < 0
                              ? classes.textGreen
                              : classes.textGray
                          )}
                        >
                          {
                            enableBenchmarksBids && Boolean(totalBenchmark) ? (
                              <>
                                &nbsp;
                                <Icon
                                  style={{
                                    transform:
                                      diff > 0
                                        ? 'rotate(-90deg)'
                                        : diff < 0
                                        ? 'rotate(90deg)'
                                        : 'none',
                                  }}
                                >
                                  {diff ? 'play_circle_outlined' : 'check_circle_outlined'}
                                </Icon>
                                {Boolean(diff) ? (
                                  <NumberFormat
                                    displayType={'text'}
                                    value={Math.abs(diff)}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={CURRENCIES[projectCurrency] || '$'}
                                  />
                                ) : null}
                                <span>
                                  &nbsp;
                                  {Boolean(diff) ? '' : 'Equal To Benchmark'}
                                </span>
                              </>
                            ) : null
                            // <span className={classes.textRed}>
                            //   &nbsp;
                            //   No Benchmark Available
                            // </span>
                          }
                        </div>
                      </>
                    ) : (
                      <div className={classes.price}>
                        <span className={classes.noValue}>-</span>
                      </div>
                    )}
                  </div>
                </TableCell>
              )
            })}
            <TableCell className={classes.categoryTD} align="right">
              <Icon className={clsx(classes.iconArrowBlack)}>
                {isActive ? 'arrow_drop_up' : 'arrow_drop_down'}
              </Icon>
            </TableCell>
          </TableRow>

          {isActive && (
            <>
              {dispBidItemFields.map((field, i) => {
                if (!enableBenchmarksBids && field.desc === 'Benchmark Rate') return null

                return (
                  <TableRow
                    tabIndex={-1}
                    key={`service_field-${field.desc}`}
                    className={clsx(
                      i === dispBidItemFields.length - 1 ? classes.trBorderBottomActive : null
                    )}
                  >
                    <TableCell></TableCell>
                    <TableCell
                      className={clsx(classes.td, classes.serviceTD, classes.borderRightGray)}
                    >
                      <div>{field.desc}</div>
                    </TableCell>
                    {selectedBids.map((bid: any, j: number) => {
                      const temp = bidItemsByCategoryGroup.filter(
                        (bi: any) => bi.serviceItemId === serviceItem.id && bi.bidId === bid.id
                      )
                      // const value = field.desc === 'Qty' ? _reduce(temp, (sum, i) => sum + i.serviceQty, 0) : (_get(temp, [0, ...field.fieldPath]) || '');
                      const value = _get(temp, [0, ...field.fieldPath]) || ''
                      const benchmarkRate = _get(temp, [0, 'serviceItem', 'benchmarkRate']) || 0
                      let diffRate = 0

                      if (benchmarkRate && field.fieldPath[0] === 'serviceRate') {
                        diffRate = (_get(temp, [0, 'serviceRate']) || 0) - benchmarkRate
                      }

                      return (
                        <TableCell
                          key={`bid_item_service-${j}`}
                          className={clsx(classes.td, classes.serviceTD, classes.borderRightGray)}
                        >
                          <div className={classes.priceVSBenchmark}>
                            {value && field.isCurrency ? (
                              <>
                                <div className={classes.price}>
                                  <NumberFormat
                                    displayType={'text'}
                                    value={value}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={CURRENCIES[projectCurrency] || '$'}
                                  />
                                </div>

                                {enableBenchmarksBids && (
                                  <div
                                    className={clsx(
                                      classes.benchmarkCompare,
                                      diffRate > 0
                                        ? classes.textRed
                                        : diffRate < 0
                                        ? classes.textGreen
                                        : classes.textGray
                                    )}
                                  >
                                    {field.fieldPath[0] === 'serviceRate' && value ? (
                                      <>
                                        {
                                          benchmarkRate ? (
                                            <>
                                              &nbsp;
                                              <Icon
                                                style={{
                                                  transform:
                                                    diffRate > 0
                                                      ? 'rotate(-90deg)'
                                                      : diffRate < 0
                                                      ? 'rotate(90deg)'
                                                      : 'none',
                                                }}
                                              >
                                                {diffRate
                                                  ? 'play_circle_outlined'
                                                  : 'check_circle_outlined'}
                                              </Icon>
                                              {diffRate ? (
                                                <NumberFormat
                                                  displayType={'text'}
                                                  value={Math.abs(diffRate)}
                                                  thousandSeparator={true}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  prefix={CURRENCIES[projectCurrency] || '$'}
                                                />
                                              ) : null}
                                              <span>
                                                &nbsp;
                                                {!diffRate ? 'Equal To Benchmark' : ''}
                                              </span>
                                            </>
                                          ) : null
                                          // <span className={classes.textRed}>
                                          //   &nbsp;
                                          //   No Benchmark Available
                                          // </span>
                                        }
                                      </>
                                    ) : null}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className={classes.price}>
                                {value ? value : <span className={classes.noValue}>-</span>}
                              </div>
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                    <TableCell className={clsx(classes.td)}></TableCell>
                  </TableRow>
                )
              })}
            </>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <TableRow tabIndex={-1} className={classes.categoryTR} onClick={() => setExpand(!expand)}>
        <TableCell className={classes.categoryTD} colSpan={2}>
          <b>{category || ''}</b>
        </TableCell>

        {selectedBids.map((sb: any, i: any) => {
          const serviceCategoryWithBid = _find(
            serviceCategoryWithBids,
            (s: any) => s.bidId === sb.id
          )
          const bidTotalCost = _get(serviceCategoryWithBid, 'bidTotalCost') || 0
          const totalBenchmark = _get(serviceCategoryWithBid, 'benchmarkServiceGroupTotal') || 0
          const diff = bidTotalCost - totalBenchmark

          return (
            <TableCell key={`bid-${i}`} className={classes.categoryTD}>
              <div className={classes.priceVSBenchmark}>
                <div className={classes.price}>
                  <b>
                    <NumberFormat
                      displayType={'text'}
                      value={bidTotalCost}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={CURRENCIES[_get(sb, ['currency', 'currency'])] || '$'}
                    />
                  </b>
                </div>

                {enableBenchmarksBids && (
                  <div
                    className={clsx(
                      classes.benchmarkCompare,
                      diff > 0 ? classes.textRed : diff < 0 ? classes.textGreen : classes.textGray
                    )}
                  >
                    {bidTotalCost ? (
                      <>
                        &nbsp;
                        {
                          totalBenchmark ? (
                            <>
                              <Icon
                                style={{
                                  transform:
                                    diff > 0
                                      ? 'rotate(-90deg)'
                                      : diff < 0
                                      ? 'rotate(90deg)'
                                      : 'none',
                                }}
                              >
                                {diff ? 'play_circle_outlined' : 'check_circle_outlined'}
                              </Icon>

                              {diff ? (
                                <NumberFormat
                                  displayType={'text'}
                                  value={Math.abs(diff)}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={CURRENCIES[_get(sb, ['currency', 'currency'])] || '$'}
                                />
                              ) : null}

                              <span>
                                &nbsp;
                                {!diff ? 'Equal To Benchmark' : ''}
                              </span>
                            </>
                          ) : null
                          // <span className={classes.textRed}>
                          //   &nbsp;
                          //   No Benchmark Available
                          // </span>
                        }
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </TableCell>
          )
        })}

        <TableCell className={classes.categoryTD} align="right">
          <Icon className={clsx(classes.arrow)}>
            {expand ? 'arrow_drop_up' : 'arrow_drop_down'}
          </Icon>
        </TableCell>
      </TableRow>

      {expand && (
        <>
          {bidItemsByCategoryGroupInBidsRes && bidItemsByCategoryGroupInBidsRes.loading ? (
            <TableRow>
              <TableCell className={classes.td} colSpan={8}>
                <div style={{ position: 'relative', height: '60px' }}>
                  <PagCircularIndeterminate />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            renderBidItemsByCategory()
          )}
        </>
      )}
    </>
  )
}

export default BidItemsByCategoryInBids
