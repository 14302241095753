export const callPagApi = async (
  token: string = '',
  getToken: any,
  api: any,
  variables: any = null,
  signal: any
) => {
  try {
    if (!token) token = await getToken()

    const params: any = {
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        fetchOptions: {
          signal,
        },
      },
    }

    if (variables) {
      params['variables'] = variables
    }

    api(params)

    return token
  } catch (error) {
    console.error(error)
  }
}
