import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Configure, connectInfiniteHits } from 'react-instantsearch-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import BidsTableView from '@/routes/projects/components/bids/bids-table-view'
import BidDetailsView from '@/routes/projects/components/bid-details'
import BidsCompareView from '@/routes/projects/components/bids-compare'
import { Context } from '@/shared/store/reducers/global-reducer'
import { errorHandler } from '@/shared/error-handler'
import {
  GET_BID_ITEM_CATEGORY_GROUPS,
  GET_BID_ITEM_CATEGORY_GROUPS_IN_BIDS,
} from '@/shared/graphql/queryGetBids'
import { callPagApi } from '@/shared/services/pag-api'
import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const BidsTableViewHits = connectInfiniteHits(PagAlgoliaConnectConditionalResults(BidsTableView))

const BidsSearchContainer = (props: any) => {
  const {
    clientId,
    brandId,
    projectId,
    bidId,

    filterable,
    filters,
    searchPhrase,
    visibleCompareBids,

    noBreadcrumb,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store, dispatch } = useContext(Context)
  const {
    algoliaSearchSecuredApiKey,
    activeTenant,
    activeBid,
    bidsViewMode,
    bidDetailsViewMode,
    selectedBids,
  } = store
  const mountedRef = useMounted(true)
  const [token, setToken] = useState('')
  const [bidItemCategoryGroups, setBidItemCategoryGroups] = useState([] as any)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [updatedBids, setUpdatedBids] = useState(false)
  const [searchState, setSearchState] = useState({ page: 1 } as any)

  useEffect(() => {
    if (mountedRef.current && bidsViewMode !== 'All') {
      dispatch({ type: 'ChangeBidsViewMode', bidsViewMode: 'All' })
    }

    return () => {
      dispatch({ type: 'ChangeSelectedBids', selectedBids: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchClient = useMemo(() => {
    return algoliaSearchSecuredApiKey
      ? algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey)
      : null
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if (!mountedRef.current || !searchClient || !projectId) return
    searchClient.clearCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchClient, projectId])

  // Get BidItem Category Groups
  const [getBidItemCategoryGroups, bidItemCategoryGroupsRes] = useLazyQuery(
    GET_BID_ITEM_CATEGORY_GROUPS,
    {
      onError: (err) => {
        if (!mountedRef.current) return
        errorHandler(err)
      },
      onCompleted: (res) => {},
      fetchPolicy: 'network-only',
    }
  )

  useEffect((): any => {
    if (!mountedRef.current || !activeTenant?.id || !activeBid) return
    let abortController: any

    try {
      abortController = new AbortController()
      const { signal } = abortController
      const callApi = async () => {
        try {
          const temp = await callPagApi(
            '',
            getTokenSilently,
            getBidItemCategoryGroups,
            { tenantId: activeTenant.id, bidId: _get(activeBid, 'id') },
            signal
          )
          if (mountedRef.current) {
            setToken(temp || '')
          }
        } catch (e) {
          if (abortController) abortController.abort()
        }
      }

      callApi()
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBid])

  // After getting Bid Item Categories
  useEffect(() => {
    if (!mountedRef.current) return

    try {
      const temp = _get(bidItemCategoryGroupsRes, ['data', 'bidItemCategoryGroups', 'data']) || []

      if (temp.length) {
        let i = 0
        while (i < temp.length) {
          const tempWithSameCategory = temp.filter(
            (t: any) => t !== temp[i] && t.serviceCategory === temp[i].serviceCategory
          )
          while (tempWithSameCategory.length > 0) {
            temp[i].bidTotalCost += tempWithSameCategory[0].bidTotalCost
            temp[i].rows += tempWithSameCategory[0].rows
            temp.splice(
              temp.findIndex((t: any) => t === tempWithSameCategory[0]),
              1
            )
            tempWithSameCategory.splice(0, 1)
          }
          i++
        }
        setBidItemCategoryGroups(temp)
      } else {
        if (bidItemCategoryGroups.length !== 0) {
          setBidItemCategoryGroups([])
        }
      }
    } catch (e) {
      if (bidItemCategoryGroups.length !== 0) {
        setBidItemCategoryGroups([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItemCategoryGroupsRes.data])

  // Get BidItem Category Groups in Bids
  const [getBidItemCategoryGroupsInBids, bidItemCategoryGroupsInBidsRes] = useLazyQuery(
    GET_BID_ITEM_CATEGORY_GROUPS_IN_BIDS,
    {
      onError: (err) => {
        if (!mountedRef.current) return
        errorHandler(err)
      },
      onCompleted: (res) => {},
      fetchPolicy: 'network-only',
    }
  )

  // After getting Bid Item Categories in Bids
  const bidItemCategoryGroupsInBids = useMemo(() => {
    try {
      let temp =
        _get(bidItemCategoryGroupsInBidsRes, ['data', 'bidItemCategoryGroupsInBids', 'data']) || []

      if (temp.length) {
        let i = 0
        while (i < temp.length) {
          const tempWithSameCategory = temp.filter(
            (t: any) =>
              t !== temp[i] &&
              t.bidId === temp[i].bidId &&
              t.serviceCategory === temp[i].serviceCategory
          )
          while (tempWithSameCategory.length > 0) {
            temp[i].benchmarkServiceGroupTotal += tempWithSameCategory[0].benchmarkServiceGroupTotal
            temp[i].bidTotalCost += tempWithSameCategory[0].bidTotalCost
            temp[i].rows += tempWithSameCategory[0].rows
            temp.splice(
              temp.findIndex((t: any) => t === tempWithSameCategory[0]),
              1
            )
            tempWithSameCategory.splice(0, 1)
          }
          i++
        }
        return temp
      }
      return []
    } catch (e) {
      return []
    }
  }, [bidItemCategoryGroupsInBidsRes.data])

  useEffect(() => {
    if (!mountedRef.current || !activeTenant?.id || bidsViewMode !== 'Compared' || !selectedBids)
      return
    let abortController: any
    try {
      abortController = new AbortController()
      const bidIds = selectedBids.map((b: any) => b.id)
      const callApi = async () => {
        try {
          const temp = await callPagApi(
            '',
            getTokenSilently,
            getBidItemCategoryGroupsInBids,
            { tenantId: activeTenant.id, bidIds },
            abortController.signal
          )

          if (mountedRef.current) {
            setToken(temp || '')
          }
        } catch (e) {
          if (abortController) abortController.abort()
        }
      }
      callApi()
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [bidsViewMode])

  const onSelectBid = (bid: any) => {
    const selectedIndex = _findIndex(selectedBids, (b: any) => b.id === bid.id)
    let temp = selectedBids

    if (selectedIndex < 0) {
      temp = [...temp, bid]
    } else {
      temp = temp.filter((b: any) => b.id !== bid.id)
    }

    dispatch({ type: 'ChangeSelectedBids', selectedBids: temp })
  }

  const onBidDetailView = (bid: any) => {
    if (bid) {
      const clientId = bid.project?.client?.id || bid.project?.brand?.client?.id
      const brandId = bid.project?.brand?.id
      const projectId = bid.project?.id || bid.projectId
      if (brandId && clientId && projectId) {
        navigate(`/${clientId}/${brandId}/${projectId}/bids/${bid.id}`)
      }
    }
  }

  const onUpdatedBids = () => {
    setUpdatedBids(!updatedBids)
  }

  const onSearchStateChange = (nextState: any) => {
    setSearchState(nextState)
  }

  const renderBidsTableViewHits = useMemo(() => {
    return (
      <BidsTableViewHits
        {...{
          selectedBids,
          bidsViewMode,
          onDetailView: onBidDetailView,
          onSelectBid,
          getTokenSilently,
          onUpdatedBids,
        }}
      />
    )
  }, [selectedBids, bidsViewMode])

  return (
    <>
      {/* {
        projectId && !noBreadcrumb && (
          <>
            {renderCrumb}
          </>
        )
      } */}

      <Paper
        // className={clsx('pag-app-content', !noBreadcrumb ? classes.root : undefined)}
        elevation={0}
      >
        {Boolean(bidId) ? (
          <>
            {bidItemCategoryGroupsRes.loading ? null : (
              <div>
                {_get(bidItemCategoryGroups, 'length') ? (
                  <BidDetailsView
                    {...{
                      activeBid,
                      bidItemCategoryGroups,
                      handleChangeBidItemCategoryGroups: setBidItemCategoryGroups,
                    }}
                  />
                ) : (
                  <div className="pag-flex" style={{ height: '100%' }}>
                    <Typography variant="h5">No Bid Items</Typography>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {bidsViewMode === 'Compared' ? (
              <BidsCompareView
                {...{
                  bidDetailsViewMode,
                  selectedBids,
                  loading: _get(bidItemCategoryGroupsInBidsRes, 'loading'),
                  bidItemCategoryGroupsInBids,
                  updatedBids,
                  onDetailView: onBidDetailView,
                }}
              />
            ) : (
              <>
                {Boolean(searchClient) && (
                  <InstantSearch
                    searchClient={searchClient}
                    indexName="Bids"
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                  >
                    {filterable ? (
                      <Configure
                        filters={filters}
                        query={''}
                        // numericFilters={`projectId=${projectId}`}
                      />
                    ) : (
                      <Configure query={searchPhrase} />
                    )}

                    {renderBidsTableViewHits}
                  </InstantSearch>
                )}
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}

export default BidsSearchContainer
