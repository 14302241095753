import gql from 'graphql-tag'

const ADD_MARKET = gql`
  mutation addMarket($tenantId: Int!, $name: String!, $clientId: Int!) {
    addMarket(tenantId: $tenantId, name: $name, clientId: $clientId) {
      id
      name
      clientId
    }
  }
`
const UPDATE_MARKET = gql`
  mutation updateMarket($tenantId: Int!, $id: Int!, $name: String, $clientId: Int) {
    updateMarket(tenantId: $tenantId, id: $id, name: $name, clientId: $clientId)
  }
`

export { ADD_MARKET, UPDATE_MARKET }
