import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagCircularIndeterminate from '@/components/pag-loading'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { Context } from '@/shared/store/reducers/global-reducer'
import { errorHandler } from '@/shared/error-handler'
import { GET_AGENCIES } from '@/shared/graphql'
import { ADD_PROJECT, UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { callPagApi } from '@/shared/services/pag-api'
import { IAgency } from '@/shared/models/agency'
import { IBrand } from '@/shared/models/brand'

import { WidgetConfigType, WidgetConfigDefaultProps } from '../config'
import { NewProjectSchema } from './config'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    marginTop: 12,
    minWidth: 320,
    maxWidth: 320,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    zIndex: 1001,
    overflow: 'visible',
  },
  form: {
    display: 'block',
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
}))

const ProjectEditWidget = (props: any) => {
  const {
    widgetConfig: { order, area, permissions, style },
    config,
    inputs: { client, brand, project },
    outputs: { handleCancel, handleSubmit: onHandleSubmit },
  } = props
  const classes = useStyles(props)
  const mountedRef = useMounted(true)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, clients } = store

  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientName: client?.name || '',
      brandId: brand?.id || 0,
      agencyId: 0,
      projectName: '',
      projectNumber: '',
    },
    resolver: yupResolver(NewProjectSchema),
  })

  const {
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = hookFormMethods

  const [agencyOptions, setAgencyOptions] = useState([] as any)
  const [getAgencies, getAgenciesRes] = useLazyQuery(GET_AGENCIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setAgencyOptions([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (Boolean(mountedRef.current)) {
        setAgencyOptions(_orderBy(res.agencies?.data || [], ['name']))
      }
    },
    fetchPolicy: 'network-only',
  })

  const [addProject, addProjectRes] = useMutation(ADD_PROJECT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (mountedRef.current && res.addProject?.id) {
        onHandleSubmit(res.addProject)
      }
    },
  })

  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    if (!activeTenant?.id) return
    const abortController = new AbortController()
    const { signal } = abortController
    callPagApi('', getTokenSilently, getAgencies, { tenantId: activeTenant.id }, signal) // GET Agencies

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant?.id])

  const brandOptions = useMemo(() => {
    let options = []
    if (client) {
      options = client?.brands || []
    } else if (clients) {
      options = _get(clients, [0, 'brands']) || []
      setValue('clientName', _get(clients, [0, 'name']) || '')
    }

    return options
  }, [client, clients])

  // Save Project
  const onSubmit = (data: any) => {
    let abortController: any

    try {
      abortController = new AbortController()
      const { signal } = abortController

      const payload: any = {
        tenantId: activeTenant?.id,
        brandId: data.brandId,
        agencyId: data.agencyId,
        projectName: (data.projectName || '').trim(),
        projectNumber: (data.projectNumber || '').trim(),
      }

      if (project) {
        payload['id'] = project.id
        callPagApi('', getTokenSilently, updateProject, payload, signal)
      } else {
        callPagApi('', getTokenSilently, addProject, payload, signal)
      }
    } catch (err) {
      console.error('Error in Add Project', err)
      if (abortController) abortController.abort()
    }
  }

  return (
    <Card
      id="new-project-widget"
      className={clsx(classes.root)}
      style={style}
      elevation={24}
      variant="outlined"
    >
      <form name="newProjectWidgetForm" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <CardContent className={classes.cardContent}>
          {getAgenciesRes.loading && <PagCircularIndeterminate size={24} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState }) => (
                  <PagTextInput
                    label="client"
                    placeholder="client"
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e.target.value)}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`clientName`}
                defaultValue={client?.name || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'brandId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: brandOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IBrand) => option.name,
                        // getOptionSelected: (option: any, value: any) => option.value === field.value,
                        size: 'small',
                        disabled: Boolean(brand),
                        disableClearable: true,
                        onChange: (event: object, values: IBrand, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Select Brand',
                        placeholder: 'Select Brand',
                      },
                    }}
                  />
                )}
                control={control}
                name="brandId"
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'agencyId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: agencyOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IAgency) => option.name,
                        // getOptionSelected: (option: any, value: any) => option.value === field.value,
                        size: 'small',
                        disableClearable: false,
                        onChange: (event: object, values: IAgency, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Select Agency',
                        placeholder: 'Select Agency',
                      },
                    }}
                  />
                )}
                control={control}
                name="agencyId"
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState }) => (
                  <PagTextInput
                    label="Project Title"
                    placeholder="Project Title"
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e.target.value || '')}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`projectName`}
                defaultValue={''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState }) => (
                  <PagTextInput
                    label="Job Number"
                    placeholder="Job Number"
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={(e: any) => field.onChange(e.target.value || '')}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`projectNumber`}
                defaultValue={''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button
            color="primary"
            size="medium"
            aria-label="cancel"
            variant="text"
            className={classes.button}
            disabled={addProjectRes.loading || updateProjectRes.loading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            size="medium"
            type="submit"
            aria-label="next"
            variant="contained"
            className={classes.button}
            disabled={!isValid || addProjectRes.loading || updateProjectRes.loading}
          >
            Next
            {(addProjectRes.loading || updateProjectRes.loading) && (
              <CircularProgress size={16} color="primary" className={classes.circularProgress} />
            )}
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

ProjectEditWidget.propTypes = {
  widgetConfig: WidgetConfigType,
  inputs: PropTypes.shape({
    client: PropTypes.object,
    brand: PropTypes.object,
    project: PropTypes.object,
  }).isRequired,
  outputs: PropTypes.shape({
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
}

ProjectEditWidget.defaultProps = {
  widgetConfig: WidgetConfigDefaultProps,
  outputs: {
    handleSubmit: (e: any) => {},
  },
}

export default ProjectEditWidget
