export const LANGUAGES = [
  { label: 'English', value: 'English' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Mandarin', value: 'Mandarin' },
  { label: 'French', value: 'French' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Russian', value: 'Russian' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Swiss', value: 'Swiss' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
]
