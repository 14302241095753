import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import { useMutation } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import IconMoreVert from '@material-ui/icons/MoreVert'

import SVGFileDownload from '@/assets/imgs/file-download.svg'
import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { HeadCell } from '@/components/pag-table/pag-table-head'
import { CURRENCIES } from '@/shared/constants/currency'
import { DOWNLOAD_PROJECT_FILES, UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { IClient } from '@/shared/models/client'
import { IProject } from '@/shared/models/project'
import { errorHandler } from '@/shared/error-handler'
import { callPagApi } from '@/shared/services/pag-api'
import { Context } from '@/shared/store/reducers/global-reducer'
import { dateToString } from '@/utils/date-utils'
import { downloadFileFromBase64 } from '@/utils/download-file'

const headCells = [
  {
    id: 'brand.name',
    align: 'left',
    disablePadding: true,
    label: 'LOB',
    sortable: false,
    isLink: false,
    render: (value: IProject) => value.brand?.name,
  },
  {
    id: 'budgetLineItem',
    align: 'left',
    disablePadding: true,
    label: 'Budget Line Item',
    sortable: true,
    isLink: false,
    render: (value: IProject, handleClick: (p: IProject) => void) => value.budgetLineItem || '',
  },
  {
    id: 'projectName',
    align: 'left',
    disablePadding: true,
    label: 'Project Name',
    sortable: false,
    isLink: false,
    render: (value: IProject, handleClick: (p: IProject) => void) => value.projectName,
  },
  {
    id: 'metaData.projectTeam',
    align: 'left',
    disablePadding: true,
    label: 'Contacts',
    sortable: false,
    isLink: false,
    render: (value: IProject, handleClick: (p: IProject) => void) => {
      const projectTeam = (value.metaData && value.metaData.projectTeam) || []
      return Boolean(projectTeam[0]) ? (
        <Grid container spacing={1}>
          <Grid item xs>
            <div>
              <b>{projectTeam[0].contactName}</b>
            </div>
            <div>{projectTeam[0].projectTeamRole}</div>
          </Grid>
          {Boolean(projectTeam[1]) && (
            <Grid item xs>
              <div>
                <b>{projectTeam[1].contactName}</b>
              </div>
              <div>{projectTeam[1].projectTeamRole}</div>
            </Grid>
          )}
        </Grid>
      ) : (
        ''
      )
    },
  },
  {
    id: 'frAmount', // budgetTotal
    align: 'right',
    disablePadding: true,
    label: 'Requested Amount',
    sortable: false,
    isLink: false,
    render: (value: IProject, handleClick: (p: IProject) => void) => {
      // const currency = p.currency?.currency || ''
      // const exchangeRate = 1
      // const exchangeRate = exchangeRates[currency] || 1
      return (
        <NumberFormat
          displayType={'text'}
          value={value.frAmount || 0}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix={CURRENCIES[value?.currency?.currency || ''] || '$'}
        />
      )
    },
  },
  {
    id: 'purchaseOrder',
    align: 'left',
    disablePadding: true,
    label: 'Purchase Order',
    isLink: false,
    sortable: true,
    width: '224px',
    renderLabel: (client: IClient) => client?.purchaseOrderName || 'Purchase Order',
    render: (value: IProject, handleClick: (p: IProject) => void) => (
      <div className="pag-flex pag-flex-right">
        {value.purchaseOrders && value.purchaseOrders[0] && (
          <div className="pag-po-item">{value.purchaseOrders[0].poNumber}</div>
        )}
        {value.purchaseOrders && value.purchaseOrders[1] && (
          <div className="pag-po-item">{value.purchaseOrders[1].poNumber}</div>
        )}
        {value.purchaseOrders && value.purchaseOrders.length > 2 && (
          <Button color="primary" size="small" variant="outlined">
            +{value.purchaseOrders.length - 2} More
          </Button>
        )}
        <IconButton size="small" onClick={() => handleClick(value)}>
          <IconMoreVert />
        </IconButton>
      </div>
    ),
  },
  {
    id: 'fundingBeginDate',
    align: 'right',
    disablePadding: true,
    label: 'Funding Begin Date',
    sortable: true,
    isLink: false,
    width: '120px',
    render: (value: IProject, handleClick: (p: IProject) => void) =>
      value.fundingBeginDate ? dateToString(new Date(value.fundingBeginDate)) : '',
  },
  {
    id: 'fundingEndDate',
    align: 'right',
    disablePadding: true,
    label: 'Funding End Date',
    sortable: true,
    isLink: false,
    width: '110px',
    render: (value: IProject, handleClick: (p: IProject) => void) =>
      value.fundingEndDate ? dateToString(new Date(value.fundingEndDate)) : '',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    '& .MuiButton-root': {
      minWidth: 56,
      marginRight: 4,
      padding: 4,
    },
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'middle',
  },
  tdIcon: {
    padding: 2,
    verticalAlign: 'middle',
  },
}))

const BudgetsTableView = (props: any) => {
  const {
    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,
    handleMorePOs,
  } = props
  const classes = useStyles()
  const mountedRef = useMounted(true)
  const sentinel: any = useRef(null)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { exchangeRates, activeClient, activeTenant } = store
  const [orderBy, setOrderBy] = useState('bidNumber')
  const [approvingProjects, setApprovingProjects] = useState<IProject[]>([])
  const [downloadProjectFiles] = useMutation(DOWNLOAD_PROJECT_FILES, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
      // setApprovingProject(prevValue => [...prevValue, p])
    },
    onCompleted: (res) => {
      if (res?.downloadProjectFiles?.success) {
        downloadFileFromBase64(
          res.downloadProjectFiles.base64,
          'application/zip',
          `${res.downloadProjectFiles.filename}.zip`
        )
      } else {
        errorHandler(res.downloadProjectFiles.error)
      }
    },
  })
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
      // setApprovingProject(prevValue => [...prevValue, p])
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (!searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }
  const observer = new IntersectionObserver(onSentinelIntersection)
  const handleApprove = (p: IProject) => {
    let abortController: any
    try {
      setApprovingProjects((prevValue) => [...prevValue, p])
      abortController = new AbortController()

      callPagApi(
        '',
        getTokenSilently,
        updateProject,
        {
          tenantId: activeTenant?.id,
          id: p.id,
          approved: true,
        },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const handleDownloadProject = (p: IProject) => {
    let abortController: any
    try {
      abortController = new AbortController()

      callPagApi(
        '',
        getTokenSilently,
        downloadProjectFiles,
        {
          tenantId: activeTenant?.id,
          id: p.id,
        },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  useEffect(() => {
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [hits, observer, onSentinelIntersection])

  const renderHeadCellValue = (headCell: HeadCell, orderBy: any) => {
    const label = headCell.renderLabel ? headCell.renderLabel(activeClient) : headCell.label
    if (headCell.sortable)
      return (
        <TableSortLabel
          // active={orderBy === headCell.id}
          hideSortIcon={true}
        >
          {label}
        </TableSortLabel>
      )
    return label
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="Projects Results"
          size={'medium'}
          aria-label="Projects Results"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} style={{ width: 62 }}></TableCell>
              {headCells.map((headCell: HeadCell, i) => (
                <TableCell
                  key={headCell.id}
                  className={classes.th}
                  align={headCell.align as any}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{ width: headCell.width || undefined }}
                >
                  {renderHeadCellValue(headCell, orderBy)}
                </TableCell>
              ))}
              <TableCell style={{ width: 30 }}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {hits.map((item: IProject, i: number) => {
              if (approvingProjects.findIndex((ap) => ap === item) >= 0) return null
              return (
                <TableRow key={item.id} tabIndex={-1}>
                  <TableCell className={classes.td}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => handleApprove(item)}
                    >
                      OK
                    </Button>
                  </TableCell>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      className={classes.td}
                      align={headCell.align as any}
                      key={`cell-${i}`}
                    >
                      {headCell.render(item, handleMorePOs)}
                    </TableCell>
                  ))}
                  <TableCell className={classes.tdIcon}>
                    <IconButton size="small" onClick={() => handleDownloadProject(item)}>
                      <img src={SVGFileDownload} alt="file download" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow tabIndex={-1} ref={sentinel}>
              <TableCell className="pag-infinite-sentinel" colSpan={9}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {!nbPages && !searching && (
        <div className="pag-flex" style={{ height: '100%', marginTop: 24 }}>
          <Typography variant="h5">No Projects</Typography>
        </div>
      )}
    </div>
  )
}

export default BudgetsTableView
