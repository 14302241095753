import React, { useState, useCallback, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import { init as filestackInit } from 'filestack-js'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ProjectImage from '../../../routes/projects/components/project-dashboard/project-image-component'
import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { IUploadProjectWithFiles } from '@/shared/models/upload-project-with-files'
import { errorHandler } from '@/shared/error-handler'
import { UPLOAD_PROJECT_WITH_DOCUMENTS } from '@/shared/graphql/mutations/mutationProjects'
import { useAuth0 } from '@/auth/index'
import { callPagApi } from '@/shared/services/pag-api'
import { FILESTACK_KEY, FILESTACK_MAX_FILE_SIZE } from '@/config/filestack.config'
import { DefaultFileIcon, FileIcons } from '@/shared/constants/file-icons'
import OptionalFields from './optional-fields'
import { stringToNumber } from '@/utils/numeric'

const NumberTypeFields: any = {
  budgetRate: 'float',
  budgetTotal: 'float',
  frAmount: 'float'
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 720,
  },
  fieldsWrapper: {
    maxWidth: 320,
  },
  imageWrapper: {
    width: '100%',
    minWidth: 140,
    maxWidth: 140,
    margin: 'auto',
    '& img': {
      width: '100%',
    },
  },
  descriptionWrapper: {
    minWidth: 148,
  },
  buttonWrapper: {
    marginTop: 32,
  },
  button: {
    height: 32,
    minWidth: 120,
  },
  dropZoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(238, 238, 238)',
    borderRadius: 14,
    borderWidth: 1,
    borderStyle: 'dashed',
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
    maxWidth: 320,
    height: 120,
  },
}))

const UploadPortalUploadFilesForm = (props: any) => {
  const { client, project, handleBack, handleStartNewUpload } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { control, getValues } = hookFormMethods
  const mountedRef = useMounted(true)
  const uploadedFilesRef = useRef<{ filename: string; mimetype: string; url: string }[]>([])
  const uploadingFilesRef = useRef({})
  const uploadingProgressRef = useRef({
    uploading: false,
    value: 0,
  })
  const commentsRef = useRef('')
  const [uploadedProject, setUploadedProject] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [projectImage, setProjectImage] = useState<string | null>(null)
  const [showOptionalFields, setShowOptionalFields] = useState(false)

  const [uploadProjectWithDocuments, uploadProjectWithDocumentsRes] = useMutation(
    UPLOAD_PROJECT_WITH_DOCUMENTS,
    {
      onError: (err) => {
        console.log('err =====', err?.networkError, (err.networkError as any)?.statusCode)
        if (err?.networkError) {
          toast.info(`Server is processing now. It'll send you a notification email after done.`)
          setUploadedProject(true)
        } else {
          errorHandler(err)
        }
      },
      onCompleted: (res) => {
        const temp = res?.uploadProjectWithDocuments
        if (temp) {
          toast.success('The documents have been uploaded successfully.')
          setUploadedProject(true)
        }
      },
    }
  )

  const handleDropFiles = useCallback(
    (acceptedFiles: any) => {
      const fileStackClient = filestackInit(FILESTACK_KEY)
      const tags = {
        'Project Number': (project?.projectNumber || '').trim(),
        'Project Name': (project?.projectName || '').trim(),
        Tenant: client?.tenant?.name || '',
        Client: client?.name || '',
        Agency: project?.agency?.name || '',
        Brand: project?.brand?.name || '',
      }
      const promises = []
      const tempProgress: number[] = []
      const numOfFiles = acceptedFiles.length
      uploadingProgressRef.current.uploading = true

      for (let i = 0; i < numOfFiles; i++) {
        if (acceptedFiles[i].size > FILESTACK_MAX_FILE_SIZE) {
          toast.error(
            `You can't upload the file "${acceptedFiles[i].name}" that exceeds the maximum upload size(250MB).`,
            {
              autoClose: 5000,
            }
          )
        } else {
          promises.push(
            new Promise((resolve) => {
              fileStackClient
                .upload(
                  acceptedFiles[i],
                  {
                    onProgress: (evt: any) => {
                      tempProgress[i] = evt.totalPercent / numOfFiles
                      uploadingProgressRef.current = {
                        uploading: true,
                        value: tempProgress.reduce(
                          (prevValue, currentValue) => prevValue + currentValue,
                          0
                        ),
                      }
                      setForceUpdate(new Date().getTime())
                    },
                    tags,
                  },
                  {
                    filename: acceptedFiles[i].name.replace(/[<>:?/|\\"]/g, '-')
                  },
                  uploadingFilesRef.current
                )
                .then((res) => {
                  if (res.status === 'Stored') {
                    // Failed, Stored
                    uploadedFilesRef.current.push({
                      filename: res.filename,
                      mimetype: res.mimetype,
                      url: res.url,
                    })
                    setForceUpdate(new Date().getTime())
                  } else {
                    toast.error(
                      `Sorry, Uploading the file "${res.filename}" has been failed. Please ask Admin about this problem.`,
                      {
                        autoClose: 5000,
                      }
                    )
                  }
                })
                .catch((err) => {
                  toast.error(
                    `Sorry, Uploading the file "${err.filename}" has been failed. Please ask Admin about this problem.`,
                    {
                      autoClose: 5000,
                    }
                  )
                })
                .finally(() => {
                  tempProgress[i] = 100 / numOfFiles
                  uploadingProgressRef.current = {
                    uploading: true,
                    value: tempProgress.reduce(
                      (prevValue, currentValue) => prevValue + currentValue,
                      0
                    ),
                  }
                  setForceUpdate(new Date().getTime())
                  resolve(true)
                })
            })
          )
        }
      }

      Promise.all(promises).then(() => {
        uploadingProgressRef.current = {
          uploading: false,
          value: 0,
        }
        setForceUpdate(new Date().getTime())
      })
    },
    [client, project]
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleDropFiles,
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mountedRef.current) {
      setProjectImage(project?.image || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.image])

  const handleUploadProjectWithFiles = () => {
    if (!client || !project) return
    const abortController = new AbortController()
    try {
      const callApi = async (data: IUploadProjectWithFiles) => {
        try {
          await callPagApi(
            '',
            getTokenSilently,
            uploadProjectWithDocuments,
            data,
            abortController.signal
          )
        } catch (e) {}
      }
      
      const formValues = getValues()
      Object.keys(NumberTypeFields).forEach((item) => {
        formValues[item] = stringToNumber(formValues[item], NumberTypeFields[item])
      })
      if (formValues['tagIds']?.length) {
        formValues['tagIds'] = formValues['tagIds'].map((item: any) => item.id || item)
      }
      commentsRef.current = formValues.comments || ''
      let payload: IUploadProjectWithFiles

      if (project?.id) {
        payload = {
          tenantId: client.tenantId,
          id: project.id,
          brandId: project.brandId,
          documents: uploadedFilesRef.current,
          image: projectImage,
          ...formValues,
        }
      } else {
        payload = {
          ...project,
          tenantId: client.tenantId,
          clientId: client.id,
          documents: uploadedFilesRef.current,
          image: projectImage,
          ...formValues,
        }
      }
      callApi(payload)
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <form className={classes.root} name="uploadPortalForm">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <div className={clsx(classes.imageWrapper, 'pag-project-image-wrapper')}>
                <ProjectImage
                  image={projectImage}
                  project={project}
                  shouldUpdateProject={false}
                  handleCancel={(e: any) => {}}
                  handleSubmit={(e: any) => {
                    setProjectImage(e)
                  }}
                />
              </div>              
            </Grid>
            <Grid item xs>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">{project.projectName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {project.client?.name} &nbsp; &gt; &nbsp;
                    {project.brand?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item>
                      <div className={classes.descriptionWrapper}>
                        <Typography variant="body1">
                          <b>Project Number : </b> {project.projectNumber}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1">
                        <span
                          className="pag-blue"
                          onClick={() => setShowOptionalFields(!showOptionalFields)}
                        >
                          {showOptionalFields ? 'Hide Optional Fields' : 'Optional Fields'}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}></Grid>
            <Grid item xs={6}>
              <div className={classes.fieldsWrapper}>
                {showOptionalFields && (
                  <Grid item xs={12}>
                    <OptionalFields client={client} project={project} control={control} />
                  </Grid>
                )}
                <Grid container spacing={3}>
                  {uploadedProject ? (
                    <Grid item xs={12}>
                      <Typography variant="h5" component="h6" style={{ fontSize: '1.8rem' }}>
                        Summary
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          component="h6"
                          gutterBottom
                          style={{ fontSize: '1.8rem' }}
                        >
                          Upload Your Documents
                        </Typography>
                        <div className={classes.dropZoneWrapper} {...getRootProps()}>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <div>Drop to Attach Files</div>
                          ) : (
                            <div>
                              <div style={{ fontSize: 20 }}>Files</div>
                              <div>
                                Drag and drop or{' '}
                                <span className="pag-blue" onClick={open}>
                                  upload
                                </span>{' '}
                                files
                              </div>
                            </div>
                          )}
                        </div>
                        {uploadingProgressRef.current.uploading && (
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ maxWidth: 364, width: '100%' }}
                          >
                            <Box width="100%" mr={1}>
                              <LinearProgress
                                variant="determinate"
                                value={uploadingProgressRef.current.value}
                              />
                            </Box>
                            <Box minWidth={35}>
                              <Typography variant="body2" color="textSecondary">
                                {`${Math.round(uploadingProgressRef.current.value)}%`}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field, fieldState, formState }) => (
                            <PagTextInput
                              label="Comments"
                              placeholder="Comments"
                              size="small"
                              variant="filled"
                              inputRef={field.ref}
                              error={fieldState.error}
                              value={field.value}
                              multiline
                              minRows={3}
                              maxRows={6}
                              disabled={uploadProjectWithDocumentsRes.loading}
                              onChange={(e: any) => field.onChange(e.target.value || '')}
                              onBlur={field.onBlur}
                            />
                          )}
                          control={control}
                          name="comments"
                          defaultValue=""
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <List disablePadding>
                      {uploadedFilesRef.current.map((f: any, i: number) => (
                        <ListItem key={`uploaded-file-${i}`} dense disableGutters>
                          <ListItemIcon>
                            <FontAwesomeIcon
                              icon={FileIcons[f.mimetype] || FileIcons[f.type] || DefaultFileIcon}
                            />
                          </ListItemIcon>
                          <ListItemText primary={f.filename || f.name} />
                        </ListItem>
                      ))}
                      {uploadedProject && Boolean(commentsRef.current) && (
                        <ListItem dense disableGutters>
                          <ListItemIcon>
                            <FontAwesomeIcon icon={FileIcons['text/plain'] || DefaultFileIcon} />
                          </ListItemIcon>
                          <ListItemText primary="comments.txt" />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.buttonWrapper}>
                      {!uploadedProject && (
                        <>
                          <Button
                            color="primary"
                            size="medium"
                            aria-label="back"
                            variant="contained"
                            className={classes.button}
                            disabled={uploadProjectWithDocumentsRes.loading}
                            onClick={() => handleBack()}
                          >
                            BACK
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      <Button
                        color="primary"
                        size="medium"
                        aria-label="next"
                        variant="contained"
                        className={classes.button}
                        disabled={
                          uploadProjectWithDocumentsRes.loading ||
                          uploadedFilesRef.current.length === 0
                        }
                        onClick={() => {
                          if (uploadedProject) {
                            handleStartNewUpload()
                          } else {
                            handleUploadProjectWithFiles()
                          }
                        }}
                      >
                        {uploadProjectWithDocumentsRes.loading ? (
                          <CircularProgress size={14} color="secondary" />
                        ) : uploadedProject ? (
                          'Start New Upload'
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {/* {Boolean(project?.files?.length) && (
              <Grid item xs={6}>
                <Typography variant="h5" component="h6" style={{ fontSize: '1.8rem' }}>
                  Previously Uploaded
                </Typography>
                <List disablePadding>
                  {(project.files || []).map((f: any, i: number) => (
                    <ListItem key={`uploaded-file-${i}`} dense disableGutters>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={FileIcons[f.mimetype] || DefaultFileIcon} />
                      </ListItemIcon>
                      <ListItemText primary={f.filename} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default UploadPortalUploadFilesForm
