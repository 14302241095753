import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      minWidth: 320,
    },
  },
  dialogTitle: {
    borderBottom: '1px solid',
    padding: 12,
  },
  dialogContent: {
    padding: '24px 12px 12px',
  },
  dialogAction: {
    padding: '4px 12px',
  },
}))

const PagAlertDialog = (props: any) => {
  const { title, content, open, handleClose } = props

  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description" style={{ fontSize: 14 }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PagAlertDialog
