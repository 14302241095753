import React, { useCallback, useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import useMounted from '@/components/hooks/use-mounted'
import { HeadCell } from '@/components/pag-table/pag-table-head'
import { CURRENCIES } from '@/shared/constants/currency'
import { IClient } from '@/shared/models/client'
import { IPurchaseOrder as IPO } from '@/shared/models/purchase-order'
import { Context } from '@/shared/store/reducers/global-reducer'
import { dateToString } from '@/utils/date-utils'

const headCells = [
  {
    id: 'poNumber',
    align: 'left',
    disablePadding: true,
    label: 'PO Number',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
    renderLabel: (client: IClient) => `${client?.purchaseOrderName || 'PO'} Number`,
  },
  {
    id: 'revision',
    align: 'right',
    disablePadding: true,
    label: 'Revision',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    prefix: 'Rev ',
    width: '74px',
  },
  {
    id: 'formattedCreatedAt',
    align: 'right',
    disablePadding: true,
    label: 'Issued Date',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'formattedBeginDate',
    align: 'right',
    disablePadding: true,
    label: 'Begin Date',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'formattedEndDate',
    align: 'right',
    disablePadding: true,
    label: 'End Date',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'amount',
    align: 'right',
    disablePadding: true,
    label: 'Amount',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '120px',
    render: (po: IPO) => (
      <NumberFormat
        displayType={'text'}
        value={po.amount || 0}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={false}
        prefix={po.currency?.currency ? CURRENCIES[po.currency.currency] || '$' : '$'}
      />
    ),
  },
  {
    id: 'totalAmount',
    align: 'right',
    disablePadding: true,
    label: 'Total Budget',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
    width: '120px',
    render: (po: IPO) => (
      <NumberFormat
        displayType={'text'}
        value={po.totalAmount || 0}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={false}
        prefix={po.currency?.currency ? CURRENCIES[po.currency.currency] || '$' : '$'}
      />
    ),
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    minHeight: 62,
    maxHeight: '100%',
    overflowX: 'auto',
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
    '&.disabled': {
      color: 'gray',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      width: 140,
    },
  },
  popoverMenuItem: {
    height: 30,
  },
  loadingWrapper: {
    padding: 12,
    textAlign: 'center',
  },
  buttonsWrapper: {
    padding: '16px 8px',
  },
}))

const PurchaseOrdersTable = (props: any) => {
  const {
    editable,

    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,

    handleDelete,
    handleEdit,
    handleRevise,
  } = props
  const classes = useStyles()
  const { store } = useContext(Context)
  const { activeClient } = store
  const mountedRef = useMounted(true)
  const selectedItem = useRef<IPO | null>(null)
  const sentinel: any = useRef(null)

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('bidNumber')
  const [anchorEl, setAnchorEl] = useState(null)

  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (!searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (!mountedRef.current) return
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [hits])

  const renderHeadCellValue = useCallback((headCell: HeadCell, orderBy: any) => {
    const label = headCell.renderLabel ? headCell.renderLabel(activeClient) : headCell.label
    if (headCell.sortable)
      return (
        <TableSortLabel
          // active={orderBy === headCell.id}
          hideSortIcon={true}
        >
          {label}
        </TableSortLabel>
      )
    return label
  }, [])

  const handleClickMore = (e: any, deliverable: any) => {
    e.preventDefault()
    e.stopPropagation()
    selectedItem.current = deliverable
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="Purchase Orders Result"
          size={'medium'}
          aria-label="Purchase Orders Result"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell: HeadCell, i) => (
                <TableCell
                  key={headCell.id}
                  className={classes.th}
                  align={headCell.align as any}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{
                    width: headCell.width || undefined,
                    maxWidth: headCell.maxWidth || undefined,
                  }}
                >
                  {renderHeadCellValue(headCell, orderBy)}
                </TableCell>
              ))}
              {editable && <TableCell className={classes.th} style={{ width: 30 }}></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {hits?.map((item: any, i: number) => {
              const record = {...(item || {})}
              const beginDate = record.beginDate
              const endDate = record.endDate
              const createdAt = record.createdAt
              if (record['formattedBeginDate'] === undefined) {
                record['formattedBeginDate'] = beginDate ? dateToString(new Date(beginDate)) : null
              }
              if (record['formattedEndDate'] === undefined) {
                record['formattedEndDate'] = endDate ? dateToString(new Date(endDate)) : null
              }
              if (record['formattedCreatedAt'] === undefined) {
                record['formattedCreatedAt'] = createdAt ? dateToString(new Date(createdAt)) : null
              }

              return (
                <TableRow key={i} tabIndex={-1}>
                  {headCells.map((headCell, j) => (
                    <TableCell
                      key={`deliverable_${i}_${j}`}
                      className={clsx(classes.td, headCell.class, {
                        disabled: record['revised'],
                      })}
                      align={headCell.align as any}
                    >
                      {headCell.prefix || ''}
                      {headCell.render ? headCell.render(record) : record[headCell.id] || ''}
                    </TableCell>
                  ))}
                  {editable && (
                    <TableCell className={classes.td}>
                      <IconButton
                        aria-label="more action"
                        size="small"
                        onClick={(e) => handleClickMore(e, record)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
      </div>
      {!searching && !hits?.length && (
        <div className="pag-text-center" style={{ padding: '24px 12px 12px', fontSize: 16 }}>
          No {activeClient?.purchaseOrderName || 'PO'}s
        </div>
      )}

      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!selectedItem.current?.revised && (
          <ListItem
            className={classes.popoverMenuItem}
            button
            onClick={() => {
              handlePopoverClose()
              handleRevise(selectedItem.current)
            }}
          >
            <ListItemText primary="Revise" />
          </ListItem>
        )}
        <ListItem
          className={classes.popoverMenuItem}
          button
          onClick={() => {
            handlePopoverClose()
            handleEdit(selectedItem.current)
          }}
        >
          <ListItemText primary="Edit" />
        </ListItem>
        <ListItem
          className={classes.popoverMenuItem}
          button
          style={{ color: 'red' }}
          onClick={() => {
            handlePopoverClose()
            handleDelete(selectedItem.current)
          }}
        >
          <ListItemText primary="Delete" />
        </ListItem>
      </Popover>
    </div>
  )
}

export default PurchaseOrdersTable
