import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { ProjectItemWidgetContext } from '@/widgets/project-item-widget'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagButton from '@/components/pag-form/pag-button'
import ContactEdit from './contact-edit-component'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { ADD_PROJECT_TEAM_ROLE } from '@/shared/graphql/mutations/mutationProjectTeamRoles'
import { UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { IContact } from '@/shared/models/contact'
import { IProjectTeamRole } from '@/shared/models/project-team-role'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: -184,
    right: 8,
    minWidth: 280,
    maxWidth: 280,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    zIndex: 1001,
    overflow: 'visible',
  },
  form: {
    display: 'block',
  },
  cardHeader: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
}))

export const ProjectTeamMemberEditComponent = (props: any) => {
  const {
    validationSchema,
    projectTeamMembers,
    projectTeamMember,
    project,
    clientId,
    handleChange,
    handleCancel,
  } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { contacts, options, addContact, updateContact, addOption, updateOption } =
    useContext(ProjectItemWidgetContext)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const rootEleRef = useRef(null)
  const uploadValueRef = useRef(null as any)
  const [contactId, setContactId] = useState(projectTeamMember?.data?.contactId || 0)
  const [projectTeamRoleId, setProjectTeamRoleId] = useState(
    projectTeamMember?.data?.projectTeamRoleId || null
  )
  const [projectTeamRoleIdError, setProjectTeamRoleIdError] = useState('')
  const [openContactEdit, setOpenContactEdit] = useState(0)

  const [addProjectTeamRole, addProjectTeamRoleRes] = useMutation(ADD_PROJECT_TEAM_ROLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })
  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    if (!mountedRef.current) return
    const temp = addProjectTeamRoleRes.data?.addProjectTeamRole
    if (temp) {
      addOption(temp)
      setProjectTeamRoleId(temp.id)
    }
  }, [addProjectTeamRoleRes.data])

  useEffect(() => {
    if (updateProjectRes.data && uploadValueRef.current) {
      handleChange(uploadValueRef.current, false)
    }
  }, [updateProjectRes.data])

  const handleAddProjectTeamRole = (value: any) => {
    if (clientId) {
      let abortController: any
      try {
        abortController = new AbortController()
        const payload = {
          tenantId: activeTenant?.id,
          clientId: clientId,
          role: value,
        }

        callPagApi('', getTokenSilently, addProjectTeamRole, payload, abortController.signal)
      } catch (err) {
        console.error('Error in Add Project Team Role', err)
        if (abortController) abortController.abort()
      }
    } else {
      console.log('no client')
    }
  }

  const handleFormSubmit = () => {
    let abortController: any
    try {
      const newValue = {
        contactId,
        projectTeamRoleId,
      }
      const tempProjectTeamMembers = [...(projectTeamMembers || [])]

      if (projectTeamMember && projectTeamMember.index >= 0) {
        tempProjectTeamMembers[projectTeamMember.index] = newValue
      } else {
        tempProjectTeamMembers.push(newValue)
      }

      if (project && project.id) {
        abortController = new AbortController()
        uploadValueRef.current = tempProjectTeamMembers

        callPagApi(
          '',
          getTokenSilently,
          updateProject,
          {
            tenantId: activeTenant?.id,
            id: project.id,
            metaData: {
              ...(project?.metaData || {}),
              projectTeam: tempProjectTeamMembers,
            },
          },
          abortController.signal
        )
      } else {
        handleChange(tempProjectTeamMembers, false)
      }
    } catch (err) {
      console.error('Error in Update Project', err)
      if (abortController) abortController.abort()
    }
  }

  const selectedContact = useMemo(() => {
    if (!contactId) return null
    return contacts.find((c: IContact) => c.id === contactId)
  }, [contacts, contactId])

  const renderContactId = useMemo(() => {
    return (
      <PagAutocomplete
        {...{
          name: 'contactId',
          optionLabelKey: 'name',
          optionValueKey: 'id',
          options: contacts,
          value: contactId,
          isCreatable: false,
          AutocompleteProps: {
            getOptionLabel: (option: IContact) => option.name,
            getOptionSelected: (option: IContact, value: number) => option.id === contactId,
            size: 'small',
            disableClearable: true,
            onChange: (event: object, values: IContact, reason: string) => {
              setContactId(values ? values.id : null)
            },
          },
          InputProps: {
            variant: 'outlined',
            label: 'Select a Contact',
            placeholder: 'Select a Contact',
          },
        }}
      />
    )
  }, [contactId, contacts])

  const renderProjectTeamRoleId = useMemo(() => {
    return (
      <PagAutocomplete
        {...{
          name: 'projectTeamRoleId',
          optionLabelKey: 'role',
          optionValueKey: 'id',
          options,
          error: projectTeamRoleIdError,
          value: projectTeamRoleId,
          loading: addProjectTeamRoleRes.loading,
          isCreatable: true,
          AutocompleteProps: {
            getOptionLabel: (option: IProjectTeamRole) => option.role,
            // getOptionSelected: (option: any, value: any) => option.value === value,
            size: 'small',
            disableClearable: true,
            onChange: (
              event: object,
              values: IProjectTeamRole & { newValue: string },
              reason: string
            ) => {
              if (values.isNew) {
                handleAddProjectTeamRole(values.newValue)
              } else {
                setProjectTeamRoleId(values ? values.id : 0)
              }
            },
          },
          InputProps: {
            variant: 'outlined',
            label: 'Select a Role',
            placeholder: 'Select a Role',
          },
        }}
      />
    )
  }, [projectTeamRoleId, projectTeamRoleIdError, options, addProjectTeamRoleRes.loading])

  const isValid = Boolean(contactId) && Boolean(projectTeamRoleId)

  return (
    <Card ref={rootEleRef} className={clsx(classes.root)} elevation={24} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        title="Edit Team Member"
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderContactId}
            {openContactEdit > 0 && (
              <ContactEdit
                containerRef={rootEleRef}
                contact={openContactEdit === 1 ? selectedContact : null}
                handleChange={(c: IContact) => {
                  if (selectedContact) {
                    updateContact(c)
                  } else {
                    addContact(c)
                    setContactId(c.id)
                  }
                  setOpenContactEdit(0)
                }}
                handleCancel={() => setOpenContactEdit(0)}
              />
            )}
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                {Boolean(contactId) && (
                  <PagButton
                    {...{
                      buttonProps: {
                        variant: 'text',
                        color: 'primary',
                        onClick: () => setOpenContactEdit(1),
                      },
                      alcRender: 'Edit Contact Info',
                    }}
                    style={{ margin: 0, lineHeight: 1 }}
                  />
                )}
                <PagButton
                  {...{
                    buttonProps: {
                      variant: 'text',
                      color: 'primary',
                      onClick: () => setOpenContactEdit(2),
                    },
                    alcRender: 'Add Contact',
                  }}
                  style={{ margin: 0, lineHeight: 1 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {renderProjectTeamRoleId}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Button
          color="primary"
          size="medium"
          aria-label="cancel"
          variant="text"
          className={classes.button}
          disabled={updateProjectRes.loading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          color="primary"
          size="medium"
          aria-label="next"
          variant="contained"
          className={classes.button}
          disabled={!isValid || updateProjectRes.loading}
          onClick={() => handleFormSubmit()}
        >
          Add
          {updateProjectRes.loading && (
            <CircularProgress size={16} color="primary" className={classes.circularProgress} />
          )}
        </Button>
      </CardActions>
    </Card>
  )
}

ProjectTeamMemberEditComponent.defaultProps = {
  handleChange: (e: any, shouldCallApi: boolean) => {},
  handleCancel: () => {},
}

export default ProjectTeamMemberEditComponent
