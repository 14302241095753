import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import PagProjectCard from './project-card'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  gridItem: {
    margin: '24px',
  },
}))

const ProjectsCardView = (props: any) => {
  const {
    detailViewProject,
    handleViewProject,
    disableProjectView,

    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,
  } = props

  const classes = useStyles()
  const sentinel: any = useRef(null)

  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (!searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [hits])

  const content = useMemo(() => {
    return (
      <>
        {nbPages && _get(hits, [0, 'projectName']) ? (
          <>
            <Grid container className={classes.root}>
              {hits.map((project: any, i: any) => (
                <Grid key={project.objectID} item className={classes.gridItem}>
                  <PagProjectCard
                    {...{
                      project,
                      handleViewProject,
                      disabled: detailViewProject && detailViewProject !== project,
                      disableProjectView,
                    }}
                  />
                </Grid>
              ))}
              <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
            </Grid>
          </>
        ) : (
          <>
            {!searching && (
              <div className="pag-flex" style={{ height: '100%' }}>
                <Typography variant="h5">No Projects</Typography>
              </div>
            )}
          </>
        )}
      </>
    )
  }, [nbPages, hits, searching])

  return <>{content}</>
}

// ProjectsCardView.propTypes = {
// 	projects: PropTypes.array.isRequired,
// 	detailViewProject: PropTypes.any.isRequired,
// 	handleViewProject: PropTypes.func.isRequired,
// 	handleViewProjectDetail: PropTypes.func.isRequired,
// };

export default ProjectsCardView
