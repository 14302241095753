import gql from 'graphql-tag'

const ADD_CAMPAIGN = gql`
  mutation addCampaign($tenantId: Int!, $name: String!, $clientId: Int!) {
    addCampaign(tenantId: $tenantId, name: $name, clientId: $clientId) {
      id
      name
      clientId
    }
  }
`
const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($tenantId: Int!, $id: Int!, $name: String, $clientId: Int) {
    updateCampaign(tenantId: $tenantId, id: $id, name: $name, clientId: $clientId)
  }
`

export { ADD_CAMPAIGN, UPDATE_CAMPAIGN }
