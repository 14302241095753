import gql from 'graphql-tag'

const GET_AGENCY_PRODUCERS = gql`
  query agencyProducers($tenantId: Int!, $offset: Int, $limit: Int) {
    agencyProducers(tenantId: $tenantId, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
        clientId
      }
    }
  }
`

export { GET_AGENCY_PRODUCERS }
