import React, { useLayoutEffect, useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useLazyQuery } from '@apollo/react-hooks'
import { matchPath, useLocation } from 'react-router-dom'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import useUrlParams from '@/components/hooks/use-url-params'
import NotPermission from '@/routes/not-permission'
import ProfileMenu from '@/components/app-header/profile-menu'
import PagCircularIndeterminate from '@/components/pag-loading'
import { GET_CLIENT_BY_UPLOAD_PORTAL_SLUG } from '@/shared/graphql/queryGetClients'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'
import { errorHandler } from '@/shared/error-handler'
import { useAuth0 } from '@/auth/index'
import { callPagApi } from '@/shared/services/pag-api'
import UploadPortalSearchProject from './search-project'
import UploadPortalNewProject from './new-project'
import UploadPortalUploadFiles from './upload-files'
import { UPLOAD_PORTAL_ROUTES } from '@/shared/constants/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    height: '100%',
    '& .MuiList-root': {
      width: '100%',
      '& .MuiListItem-button': {
        background: 'black',
        color: 'white',
        fontSize: 14,
        marginBottom: 8,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 20,
      },
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 16,
    paddingTop: '30%',
    textAlign: 'center',
  },
  rightSide: {
    width: '100%',
    height: '100%',
    padding: 16,
    paddingTop: '15%',
    overflow: 'auto',
  },
  imageWrapper: {
    width: '100%',
    minWidth: 140,
    maxWidth: 240,
    margin: 'auto',
    '& img': {
      width: '100%',
    },
  },
  description: {
    fontSize: '1.4rem',
    color: 'gray',
    maxWidth: '65%',
    marginTop: 8,
  },
  button: {
    height: 32,
    minWidth: 120,
  },
  userProfileMenuWrapper: {
    position: 'relative',
    marginTop: 'auto',
    marginBottom: 48,
  },
}))

const UploadPortalContainer = () => {
  const location = useLocation()
  const { slug } = useUrlParams({ slug: false })
  const { dispatch, store } = useContext(Context)
  const { activeClient, loading } = store
  const { getTokenSilently, isAuthenticated, logout, user } = useAuth0()
  const classes = useStyles()
  const mountedRef = useMounted(true)
  const [projectNumber, setProjectNumber] = useState<string>('')
  const [project, setProject] = useState<IProject | null>(null)
  const currentRoute = UPLOAD_PORTAL_ROUTES.find((route) =>
    matchPath(route.path, location.pathname)
  )

  const [getClientByUploadPortalSlug] = useLazyQuery(GET_CLIENT_BY_UPLOAD_PORTAL_SLUG, {
    onError: (err: any) => {
      if (!mountedRef.current) return
      errorHandler(err)
      if (mountedRef.current) {
        dispatch({ type: 'Reset', loading: false })
      }
    },
    onCompleted: (res: any) => {
      if (mountedRef.current) {
        if (res) {
          const client = _get(res, ['clientByUploadPortalSlug', 'client']) || null
          const permissions = _get(res, ['clientByUploadPortalSlug', 'permissions']) || []

          if (client) {
            dispatch({
              type: 'Reset',
              payload: {
                tenants: [client.tenant],
                activeTenant: client.tenant,
                clients: [client],
                activeClient: client,
                userPermissions: permissions,
                loading: false,
              },
            })
          } else {
            dispatch({
              type: 'Reset',
              payload: { loading: false },
            })
          }
        }
      }
    },
    fetchPolicy: 'network-only',
  })

  useLayoutEffect(() => {
    dispatch({
      type: 'Reset',
      payload: { loading: true },
    })
    if (!isAuthenticated) return
    const abortController = new AbortController()
    const { signal } = abortController
    callPagApi(
      '',
      getTokenSilently,
      getClientByUploadPortalSlug,
      {
        uploadPortalSlug: slug,
      },
      signal
    )

    return () => {
      if (abortController) abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, slug])

  const renderRoute = useMemo(() => {
    if (currentRoute?.path === '/p/:slug/new-project') {
      return (
        <UploadPortalNewProject
          projectNumber={projectNumber}
          setProjectNumber={setProjectNumber}
          setProject={setProject}
        />
      )
    }

    if (currentRoute?.path === '/p/:slug/upload-files') {
      return (
        <UploadPortalUploadFiles
          project={project}
          projectNumber={projectNumber}
          setProjectNumber={setProjectNumber}
          setProject={setProject}
        />
      )
    }
    return (
      <UploadPortalSearchProject
        projectNumber={projectNumber}
        setProjectNumber={setProjectNumber}
        setProject={setProject}
      />
    )
  }, [currentRoute, project, projectNumber])

  const uploadPortalImage = activeClient?.uploadPortalImage || activeClient?.defaultProjectImage

  if (loading) {
    return (
      <div className={classes.root}>
        <PagCircularIndeterminate size={24} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {Boolean(activeClient) ? (
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              backgroundColor: activeClient?.uploadPortalSidebarBackgroundColor || '#eeeeee',
              color: activeClient?.uploadPortalSidebarTextColor || '#000000',
            }}
          >
            <div className={classes.leftSide}>
              <div className={clsx(classes.imageWrapper, 'pag-project-image-wrapper')}>
                {uploadPortalImage ? (
                  <img src={uploadPortalImage} alt="uploadPortalImage" />
                ) : (
                  <p> No Image </p>
                )}
              </div>
              <br />
              <div style={{ flex: 1 }}>
                <Typography variant="h4">
                  {activeClient?.uploadPortalTitle || activeClient?.name}
                </Typography>
                <br />
                <Typography variant="body1" className={classes.description}>
                  {activeClient?.uploadPortalDescription}
                </Typography>
              </div>
              <div className={classes.userProfileMenuWrapper}>
                <ProfileMenu
                  user={user}
                  handleLogout={() => {
                    const temp = window.location.pathname.split('/')
                    logout()
                    window.location.href = `/${temp[1]}/${temp[2]}`
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={9}>
            <div className={classes.rightSide}>{renderRoute}</div>
          </Grid>
        </Grid>
      ) : (
        <NotPermission visibleLogo={true} />
      )}
    </div>
  )
}

export default UploadPortalContainer
