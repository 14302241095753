import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react'
import clsx from 'clsx'
import { useLazyQuery } from '@apollo/react-hooks'
import ReactResizeDetector from 'react-resize-detector'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import useUrlParams from '@/components/hooks/use-url-params'
import PagCircularIndeterminate from '@/components/pag-loading'
import ReportView from '../components/report.component'
import { useAuth0 } from '@/auth/index'
import { GET_REPORTS_LIMIT } from '@/config/app.config'
import { errorHandler } from '@/shared/error-handler'
import { GET_REPORTS_WITHOUT_CLIENT } from '@/shared/graphql/queryGetReports'
import { IClient } from '@/shared/models/client'
import { IReport } from '@/shared/models/report'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&.active': {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  gridItem: {
    margin: '24px',
    '&.active': {
      width: '100%',
    },
  },
}))

const ReportsContainer = () => {
  const { clientId } = useUrlParams({ clientId: true })
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store, dispatch } = useContext(Context)
  const { activeTenant, clients, searchPhrase } = store
  const mountedRef = useMounted(true)
  const sentinel = useRef(null)
  const pagination = useRef({
    offset: 0,
    limit: GET_REPORTS_LIMIT,
    hasMore: true,
  })
  const currentSearchPhraseRef = useRef(searchPhrase)
  const containerEle: any = useRef(null)
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  })
  const [activeReport, setActiveReport] = useState(null as any)
  const [reports, setReports] = useState({
    total: 0,
    items: [] as IReport[],
  })

  const [getReports, getReportsRes] = useLazyQuery(GET_REPORTS_WITHOUT_CLIENT, {
    onError: (err) => {
      if (mountedRef.current) errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current || !res.reports) return
      pagination.current = {
        offset:
          pagination.current.offset + Math.min(pagination.current.limit, res.reports.data.length),
        limit: pagination.current.limit,
        hasMore: res.reports.data.length >= pagination.current.limit,
      }
      setReports((prevValue) => ({
        total: res.reports.total || 0,
        items: [...prevValue.items, ...res.reports.data],
      }))
    },
    fetchPolicy: 'network-only',
  })

  const loadMore = () => {
    if (!mountedRef.current || !activeTenant?.id || !clientId || getReportsRes.loading) return
    const abortController = new AbortController()
    if (currentSearchPhraseRef.current !== searchPhrase) {
      currentSearchPhraseRef.current = searchPhrase
      pagination.current.offset = 0
    }

    callPagApi(
      '',
      getTokenSilently,
      getReports,
      {
        tenantId: activeTenant.id,
        clientId: clientId || 0,
        searchPhrase,
        offset: pagination.current.offset,
        limit: pagination.current.limit,
      },
      abortController.signal
    )
  }

  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (!getReportsRes.loading && entry.isIntersecting && pagination.current.hasMore) {
        loadMore()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (!mountedRef.current) return
    const tempClient = (clients || []).find((c: IClient) => c.id === clientId)
    dispatch({
      type: 'SetPartial',
      payload: {
        activeClient: tempClient,
        activeBrand: null,
        activeProject: null,
        activeBid: null,
        bidsViewMode: 'All',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, clients])

  useEffect(() => {
    if (!mountedRef.current) return
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [reports])

  useLayoutEffect(() => {
    pagination.current.offset = 0
    pagination.current.hasMore = true
    setReports({ total: 0, items: [] })
  }, [activeTenant?.id, clientId, searchPhrase])

  const onViewReportDetail = (report: IReport) => {
    setActiveReport(report)
  }

  const onResize = () => {
    setContainerSize({
      width: containerEle.current?.offsetWidth || 0,
      height: containerEle.current?.offsetHeight || 0,
    })
  }

  const renderReportView = (report: IReport, i: number) => {
    const isActive = activeReport && report.id === activeReport.id
    const hidden = activeReport && report.id !== activeReport.id

    return (
      <Grid
        key={`report-${i}`}
        item
        className={clsx(classes.gridItem, {
          'pag-hidden': hidden,
          active: isActive,
        })}
      >
        <ReportView
          {...{
            report,
            isActive,
            containerSize,
            onViewReportDetail,
          }}
        />
      </Grid>
    )
  }

  return (
    <div className="pag-app-content" ref={containerEle}>
      {getReportsRes.loading ? (
        <PagCircularIndeterminate />
      ) : (
        <>
          {reports.total ? (
            <Grid container className={clsx(classes.root, { active: activeReport })}>
              {reports.items.map((report: IReport, i: any) => renderReportView(report, i))}
            </Grid>
          ) : (
            <div className="pag-flex" style={{ height: '100%' }}>
              <Typography variant="h5">No Reports</Typography>
            </div>
          )}
        </>
      )}
      <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
    </div>
  )
}

export default ReportsContainer
