import gql from 'graphql-tag'

const ADD_PROJECT_TEAM_ROLE = gql`
  mutation addProjectTeamRole($tenantId: Int!, $role: String!, $clientId: Int!) {
    addProjectTeamRole(tenantId: $tenantId, role: $role, clientId: $clientId) {
      id
      role
      clientId
    }
  }
`
const UPDATE_PROJECT_TEAM_ROLE = gql`
  mutation updateProjectTeamRole($tenantId: Int!, $id: Int!, $role: String, $clientId: Int) {
    updateProjectTeamRole(tenantId: $tenantId, id: $id, role: $role, clientId: $clientId) {
      id
      role
      clientId
    }
  }
`

export { ADD_PROJECT_TEAM_ROLE, UPDATE_PROJECT_TEAM_ROLE }
