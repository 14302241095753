import React, { useMemo } from 'react'

import ProjectItemSelect from '../components/item-select-component'
import ProjectItemText from '../components/item-text-component'

const ProjectItemNormalWidget = (props: any) => {
  const {
    name,
    labelKey,
    valueKey,
    clientId,
    project,
    type,
    loading,
    isCreatable,
    InputProps,
    handleCancel,
    handleChange,
  } = props

  const renderField = useMemo(() => {
    switch (type) {
      case 'select':
        return (
          <ProjectItemSelect
            name={name}
            labelKey={labelKey}
            valueKey={valueKey}
            loading={loading}
            isCreatable={isCreatable}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
      default:
        return (
          <ProjectItemText
            name={name}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
    }
  }, [loading, InputProps])

  return <div>{renderField}</div>
}

ProjectItemNormalWidget.defaultProps = {
  handleChange: (e: any) => {},
}

export default ProjectItemNormalWidget
