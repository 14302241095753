import gql from 'graphql-tag'

const SORT_BID_ITEMS = gql`
  mutation sortBidItems($tenantId: Int!, $sorts: [SortInput!]!) {
    sortBidItems(tenantId: $tenantId, sorts: $sorts) {
      success
      error
    }
  }
`

export { SORT_BID_ITEMS }
