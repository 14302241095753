import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import NumberFormat from 'react-number-format'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _sumBy from 'lodash/sumBy'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import { CURRENCIES } from '@/shared/constants/currency'
import { useAuth0 } from '@/auth/index'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import {
  ADD_PROJECT_COST_ENTRY_ITEM,
  UPDATE_PROJECT_COST_ENTRY_ITEM,
  DELETE_PROJECT_COST_ENTRY_ITEMS,
} from '@/shared/graphql/mutations/mutationProjectCostEntryItem'
import { callPagApi } from '@/shared/services/pag-api'
import { getWindowDimensions } from '@/utils/window-size'
import { dateToString, dateStringToDate } from '@/utils/date-utils'
import { stringToCurrency, stringToNumber } from '@/utils/numeric'

import { ProjectCostEntryItemType, ProjectCostEntryItemOversageSchema } from '../config'

const DefaultOverageEntryItemValue = {
  entryItemId: 0,
  amount: '',
  notes: '',
  sequence: 0,
  reportingPeriodStart: null,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    marginTop: 12,
    minWidth: 480,
    maxWidth: 480,
    right: 0,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    textAlign: 'left',
    overflow: 'visible',
    zIndex: 1002,

    '& .MuiInputBase-inputMultiline': {
      resize: 'vertical',
    },
  },
  form: {
    display: 'block',
    position: 'relative',
  },
  fieldSet: {
    border: 0,
  },
  cardContent: {
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  overageItem: {
    position: 'relative',
    '& .overage-item-delete-button': {
      display: 'none',
      position: 'absolute',
      right: -16,
      top: 14,
    },
    '&:hover .overage-item-delete-button': {
      display: 'inherit',
    },
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    background: theme.palette.grey[200],
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
}))

const ProjectCostEntryItemOverageEditComponent = (props: any) => {
  const {
    viewPortContainerRef,
    projectCostEntryId,
    projectCostEntryItems,
    currency,
    sequence,
    handleSubmit: onHandleSubmit,
    handleCancel,
  } = props

  const classes = useStyles(props)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const currencyStr = currency?.currency || ''
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ProjectCostEntryItemOversageSchema),
  })

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
  } = hookFormMethods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'entryItems',
    // keyName: 'id', default to 'id', you can change the key name
  })
  const updateDataRef = useRef({
    items: [],
    addItems: [],
    deleteIds: [],
    updateItems: [],
    addedItems: 0,
    updatedItems: 0,
    deletedItems: 0,
  } as any)
  const [defaultValues, setDefaultValues] = useState([])

  const [addProjectCostEntryItem, addProjectCostEntryItemRes] = useMutation(
    ADD_PROJECT_COST_ENTRY_ITEM,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return

        const data = _get(res, ['addProjectCostEntryItem'])
        updateDataRef.current.items.push(data)
        updateDataRef.current.addedItems += 1

        checkSubmit()
      },
    }
  )

  const [updateProjectCostEntryItem, updateProjectCostEntryItemRes] = useMutation(
    UPDATE_PROJECT_COST_ENTRY_ITEM,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return

        const data = _get(res, ['updateProjectCostEntryItem'])
        const index = updateDataRef.current.items.findIndex((item: any) => item.id === data.id)
        if (index >= 0) {
          updateDataRef.current.items[index] = data
        }
        updateDataRef.current.updatedItems += 1

        checkSubmit()
      },
    }
  )

  const [deleteProjectCostEntryItems, deleteProjectCostEntryItemsRes] = useMutation(
    DELETE_PROJECT_COST_ENTRY_ITEMS,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return

        const success = _get(res, ['deleteProjectCostEntryItems', 'success'])
        if (success) {
          updateDataRef.current.items = updateDataRef.current.items.filter(
            (item: any) =>
              updateDataRef.current.deleteIds.findIndex((id: number) => id === item.id) < 0
          )
        } else {
          errorHandler('The overages were not deleted successfully. Please try again later.')
        }
        updateDataRef.current.deletedItems += updateDataRef.current.deleteIds.length
        checkSubmit()
      },
    }
  )

  useEffect(() => {
    async function updateEntryItems() {
      const items: any = _orderBy(projectCostEntryItems || [], ['sequence']).map(
        (item: any, index: number) => ({
          entryItemId: item.id || 0,
          amount: stringToCurrency(`${item.amount}`) || '',
          notes: item.notes || '',
          sequence: item.sequence || 0,
          reportingPeriodStart: dateStringToDate(item?.reportingPeriodStart),
        })
      )
      if (items.length === 0) {
        items.push({ ...DefaultOverageEntryItemValue, sequence: items.length })
        setValue('entryItems', items, { shouldValidate: true, shouldDirty: true })
      } else {
        setValue('entryItems', items, { shouldValidate: true, shouldDirty: true })
        setDefaultValues(items)
        await trigger()
      }
    }

    updateEntryItems()
  }, [])

  const checkSubmit = () => {
    /**
     * if everything is ok
     */
    if (
      updateDataRef.current.addedItems === updateDataRef.current.addItems.length &&
      updateDataRef.current.updatedItems === updateDataRef.current.updateItems.length &&
      updateDataRef.current.deletedItems === updateDataRef.current.deleteIds.length
    ) {
      onHandleSubmit(updateDataRef.current.items)
    }
  }

  const handleUpdate = () => {
    const handleAddItems = () => {
      updateDataRef.current.addItems.forEach((item: any) => {
        let abortController: any

        try {
          abortController = new AbortController()

          callPagApi(
            '',
            getTokenSilently,
            addProjectCostEntryItem,
            {
              tenantId: activeTenant?.id,
              projectCostEntryId: projectCostEntryId,
              type: ProjectCostEntryItemType.OVERAGE,
              amount: stringToNumber(item.amount, 'float'),
              notes: item.notes,
              sequence: parseInt(`${item.sequence || 0}`),
              reportingPeriodStart: dateToString(item.reportingPeriodStart, true, false),
              reportingPeriodEnd: dateToString(item.reportingPeriodStart, false, true),
            },
            abortController.signal
          )
        } catch (err) {
          console.error('Error in Adding Project Cost Entry Items', err)
          if (abortController) abortController.abort()
        }
      })
    }

    const handleUpdateItems = () => {
      updateDataRef.current.updateItems.forEach((item: any) => {
        let abortController: any

        try {
          abortController = new AbortController()

          callPagApi(
            '',
            getTokenSilently,
            updateProjectCostEntryItem,
            {
              tenantId: activeTenant?.id,
              id: parseInt(item.entryItemId),
              projectCostEntryId: projectCostEntryId,
              type: ProjectCostEntryItemType.OVERAGE,
              amount: stringToNumber(item.amount, 'float'),
              notes: item.notes,
              sequence: parseInt(`${item.sequence || 0}`),
              reportingPeriodStart: dateToString(item.reportingPeriodStart, true, false),
              reportingPeriodEnd: dateToString(item.reportingPeriodStart, false, true),
            },
            abortController.signal
          )
        } catch (err) {
          console.error('Error in Updating Project Cost Entry Items', err)
          if (abortController) abortController.abort()
        }
      })
    }

    const handleDeleteItems = () => {
      if (updateDataRef.current.deleteIds.length === 0) return

      let abortController: any

      try {
        abortController = new AbortController()

        callPagApi(
          '',
          getTokenSilently,
          deleteProjectCostEntryItems,
          { tenantId: activeTenant?.id, ids: updateDataRef.current.deleteIds },
          abortController.signal
        )
      } catch (err) {
        console.error('Error in Deleting Project Cost Entry Items', err)
        if (abortController) abortController.abort()
      }
    }

    const data = getValues('entryItems')
    const addItems = data.filter((f: any) => {
      if (Boolean(f.entryItemId)) return false
      const tempAmount = stringToNumber(f.amount, 'float', null)
      return tempAmount !== null
    })

    const updateItems = data.filter((f: any) => {
      const temp = projectCostEntryItems.find((item: any) => `${item.id}` === `${f.entryItemId}`)
      if (!temp) return false

      const tempReportingPeriodStart = dateStringToDate(temp.reportingPeriodStart)
      if (
        temp.notes !== f.notes ||
        (tempReportingPeriodStart && !f.reportingPeriodStart) ||
        (!tempReportingPeriodStart && f.reportingPeriodStart) ||
        (tempReportingPeriodStart &&
          tempReportingPeriodStart.getFullYear() !== f.reportingPeriodStart.getFullYear()) ||
        (tempReportingPeriodStart &&
          tempReportingPeriodStart.getMonth() !== f.reportingPeriodStart.getMonth()) ||
        temp.amount !== stringToNumber(f.amount, 'float', null)
      )
        return true
      return false
    })

    const deleteItems = projectCostEntryItems.filter(
      (item: any) => data.findIndex((f: any) => `${f.entryItemId}` === `${item.id}`) < 0
    )

    updateDataRef.current = {
      items: [...projectCostEntryItems],
      addItems: addItems,
      updateItems: updateItems,
      deleteIds: deleteItems.map((item: any) => item.id),
      addedItems: 0,
      updatedItems: 0,
      deletedItems: 0,
    }

    handleAddItems()
    handleUpdateItems()
    handleDeleteItems()
  }

  const renderForm = useMemo(() => {
    const data = getValues('entryItems')
    const totalOverage = _sumBy(data, (item: any) => stringToNumber(item.amount, 'float', 0))
    const loading =
      addProjectCostEntryItemRes.loading ||
      updateProjectCostEntryItemRes.loading ||
      deleteProjectCostEntryItemsRes.loading

    return (
      <Card
        className={clsx(classes.root)}
        elevation={24}
        variant="outlined"
        onClick={(e) => e.stopPropagation()}
      >
        <form name="ProjectCostEntryItemOverageForm" className={classes.form}>
          <fieldset className={classes.fieldSet} disabled={loading}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                {fields.map((item, index) => {
                  return (
                    <Grid key={item.id} item xs={12} className={classes.overageItem}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <input
                            type="hidden"
                            {...register(`entryItems.${index}.sequence` as const)}
                            value={_get(defaultValues, [index, 'sequence']) || 0}
                          />
                          <input
                            type="hidden"
                            {...register(`entryItems.${index}.entryItemId` as const)}
                            value={_get(defaultValues, [index, 'entryItemId']) || ''}
                          />
                          <Controller
                            render={({ field, fieldState, formState }) => {
                              return (
                                <PagTextInput
                                  label="AMOUNT"
                                  placeholder="Amount"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  value={field.value}
                                  error={fieldState.error}
                                  onChange={field.onChange}
                                  onBlur={(event: any) => {
                                    field.onChange(stringToCurrency(event.target.value) || '')
                                  }}
                                />
                              )
                            }}
                            control={control}
                            name={`entryItems[${index}].amount`}
                            defaultValue={_get(defaultValues, [index, 'amount']) || ''}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Controller
                            render={({ field, fieldState, formState }) => (
                              <PagDatePicker
                                key={`entryItems[${index}].reportingPeriodStart`}
                                margin="none"
                                name={`entryItems[${index}].reportingPeriodStart`}
                                label="Reporting Period"
                                format="MM/yyyy"
                                value={field.value || null}
                                inputVariant="outlined"
                                allowKeyboardControl={true}
                                size="small"
                                fullWidth
                                views={['month', 'year']}
                                onChange={(e: any) => {
                                  field.onChange(e)
                                }}
                                onBlur={field.onBlur}
                              />
                            )}
                            control={control}
                            name={`entryItems[${index}].reportingPeriodStart`}
                            defaultValue={
                              _get(defaultValues, [index, 'reportingPeriodStart']) || null
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            render={({ field, fieldState, formState }) => (
                              <PagTextInput
                                label="NOTES"
                                placeholder="Notes"
                                size="small"
                                variant="outlined"
                                multiline
                                value={field.value}
                                error={fieldState.error}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                              />
                            )}
                            control={control}
                            name={`entryItems[${index}].notes`}
                            defaultValue={_get(defaultValues, [index, 'notes']) || ''}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                      <IconButton
                        className={clsx('overage-item-delete-button')}
                        color="inherit"
                        disableFocusRipple
                        size="small"
                        onClick={(e: any) => {
                          remove(index)
                          setDefaultValues((prevValues) => prevValues.filter((v, i) => i !== index))
                        }}
                      >
                        <Icon style={{ fontSize: 18 }}>delete</Icon>
                      </IconButton>
                    </Grid>
                  )
                })}
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    aria-label="add another"
                    variant="text"
                    className={classes.button}
                    disabled={(fields.length > 0 && !isValid) || loading}
                    onClick={() => {
                      append({
                        entryItemId: 0,
                        amount: '',
                        notes: '',
                        sequence: fields.length,
                        reportingPeriodStart: null,
                      })
                    }}
                  >
                    Add Another
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div>
                TOTAL OVERAGES: &nbsp;
                <NumberFormat
                  displayType={'text'}
                  value={totalOverage || 0}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={CURRENCIES[currencyStr] || '$'}
                />
              </div>

              <Button
                color="primary"
                size="medium"
                type="button"
                aria-label="update"
                variant="contained"
                className={classes.button}
                disabled={loading || (fields.length > 0 && !isValid) || !isDirty}
                onClick={() => {
                  handleUpdate()
                }}
              >
                Update
                {loading && (
                  <CircularProgress
                    size={16}
                    color="primary"
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </CardActions>
          </fieldset>
        </form>
      </Card>
    )
  }, [
    fields,
    errors,
    isValid,
    isDirty,
    addProjectCostEntryItemRes.loading,
    updateProjectCostEntryItemRes.loading,
    deleteProjectCostEntryItemsRes.loading,
  ])

  return <>{renderForm}</>
}

ProjectCostEntryItemOverageEditComponent.propTypes = {
  viewPortContainerRef: PropTypes.any,
  projectCostEntryId: PropTypes.number,
  projectCostEntryItems: PropTypes.array,
  currency: PropTypes.object,

  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

ProjectCostEntryItemOverageEditComponent.defaultProps = {
  projectCostEntryItems: [],
  sequence: 0,
  handleSubmit: (e: any) => {},
  handleCancel: () => {},
}

export default ProjectCostEntryItemOverageEditComponent
