import gql from 'graphql-tag'

const GET_PROJECT_COST_TYPES = gql`
  query projectCostTypes($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    projectCostTypes(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
        description
        customFieldsMetaData
      }
    }
  }
`

export { GET_PROJECT_COST_TYPES }
