import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/react-hooks'
import {
  InstantSearch,
  Configure,
  connectInfiniteHits,
  connectRefinementList,
  connectSearchBox,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'

import _debounce from 'lodash/debounce'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _keys from 'lodash/keys'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import CloseIcon from '@material-ui/icons/Close'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import AdIDTableView from '@/routes/projects/components/deliverables/adid-table-view'
import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagDateRangePicker from '@/components/pag-pickers/pag-date-range-picker'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'
import { IDeliverable } from '@/shared/models/deliverable'
import { errorHandler } from '@/shared/error-handler'
import {
  BULK_WRITE_DELIVERABLES,
  UPDATE_DELIVERABLE,
} from '@/shared/graphql/mutations/mutationDeliverables'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { stringToNumber } from '@/utils/numeric'
import { dateToString } from '@/utils/date-utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    borderBottom: '1px solid',
    padding: 12,
  },
  dialogContent: {
    padding: 8,
    paddingTop: 16,
  },
  dialogAction: {
    padding: 16,
  },
  filtersWrapper: {
    borderBottom: '1px solid',
  },
  tableWrapper: {
    height: 400,
    maxHeight: 400,
    position: 'relative',
  },
  closeButton: {
    fontSize: 18,
    position: 'absolute',
    right: 2,
    top: 2,
    color: 'black',
  },
  dateRangePicker: {
    position: 'absolute',
    zIndex: 10000,
    right: 2,
    top: 36,
  },
}))

const CustomSearchBySpotName = (props: any) => {
  const { currentRefinement, refine } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTriggerChangeSearchField = useCallback(
    _debounce((value: string) => {
      refine(value)
    }, 500),
    []
  )

  return (
    <PagTextInput
      name="searchBySpotName"
      label="Spot Name"
      placeholder="Spot Name"
      size="small"
      variant="outlined"
      defaultValue={currentRefinement}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e: any) => {
        debouncedTriggerChangeSearchField(e.currentTarget.value)
      }}
    />
  )
}

const CustomRefinementList = (props: any) => {
  const {
    items,
    isFromSearch,
    refine,
    searchable,
    searchForItems,
    createURL,

    name,
    label,
    value,
    placeholder,
    handleSelectItem,
  } = props

  const debouncedTriggerChangeSearchField = useCallback(
    _debounce((value: string) => {
      handleSelectItem(undefined)
      refine([value])
    }, 500),
    []
  )

  return (
    <PagAutocomplete
      {...{
        name,
        optionLabelKey: 'label',
        optionValueKey: 'label',
        options: items,
        value: value || null,
        // loading: adIDAgenciesQueryRes.loading,
        isCreatable: false,
        AutocompleteProps: {
          getOptionLabel: (option: any) => option.label,
          getOptionSelected: (option: any, value: any) => option.label === value.label,
          size: 'small',
          disableClearable: false,
          onChange: (event: object, values: any, reason: string) => {
            if (values) {
              // refine(values.value);
              handleSelectItem(values.label)
            } else {
              handleSelectItem(undefined)
            }
          },
          onInputChange: (event: object, value: string, reason: string) => {
            if (reason !== 'input') return
            if (value) {
              debouncedTriggerChangeSearchField(value)
            }
          },
          ListboxProps: {
            onScroll: (event: React.SyntheticEvent) => {
              const listboxNode = event.currentTarget
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              }
            },
          },
        },
        InputProps: {
          variant: 'outlined',
          label,
          placeholder,
        },
      }}
    />
  )
}

// const CustomRefinementListDatePicker = (props: any) => {
//   const {
//     items,
//     isFromSearch,
//     refine,
//     searchForItems,
//     createURL,

//     name,
//     label,
//     value,
//     placeholder,
//     dateRange,
//     handleSelectItem
//   } = props;

//   const [open, setOpen] = React.useState(false);
//   const [dateRange, setDateRange] = React.useState<DateRange>({});

//   let minDate: any = _minBy(items, 'label');
//   let maxDate: any = _maxBy(items, 'label');

//   if (minDate) {
//     minDate = new Date(minDate.label * 1000);
//     minDate.setHours(0);
//     minDate.setMinutes(0);
//     minDate.setSeconds(0);
//   }

//   if (maxDate) {
//     maxDate = new Date(maxDate.label * 1000);
//     maxDate.setHours(23);
//     maxDate.setMinutes(59);
//     maxDate.setSeconds(59);
//   }

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={6}>
//         <PagDatePicker
//           margin="none"
//           name='startDateCreated'
//           label="Start Date Created"
//           format="MM/dd/yyyy"
//           value={_get(dateRange, 'startDate') || null}
//           minDate={minDate || null}
//           maxDate={maxDate || null}
//           inputVariant="outlined"
//           allowKeyboardControl={true}
//           size="small"
//           fullWidth
//           onChange={(e: any) => {
//             if (e) {
//               e.setHours(0);
//               e.setMinutes(0);
//               e.setSeconds(0);
//               handleSelectItem({
//                 startDate: e.getTime(),
//                 endDate: _get(dateRange, 'endDate')
//               });
//             } else {
//               handleSelectItem({
//                 startDate: null,
//                 endDate: _get(dateRange, 'endDate')
//               });
//             }

//           }}
//         />
//       </Grid>
//       <Grid item xs={6}>
//         <PagDatePicker
//           margin="none"
//           name='endDateCreated'
//           label="End Date Created"
//           format="MM/dd/yyyy"
//           value={_get(dateRange, 'endDate') || null}
//           minDate={minDate || null}
//           maxDate={maxDate || null}
//           inputVariant="outlined"
//           allowKeyboardControl={true}
//           size="small"
//           fullWidth
//           onChange={(e: any) => {
//             if (e) {
//               e.setHours(23);
//               e.setMinutes(59);
//               e.setSeconds(59);
//               handleSelectItem({
//                 startDate: _get(dateRange, 'startDate'),
//                 endDate: e.getTime()
//               });
//             } else {
//               handleSelectItem({
//                 startDate: _get(dateRange, 'startDate'),
//                 endDate: null
//               });
//             }
//           }}
//         />
//       </Grid>
//     </Grid>
//   )
// };
const CustomRefinementListDatePicker = (props: any) => {
  const {
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,

    name,
    label,
    value,
    placeholder,
    dateRange,
    handleSelectItem,
  } = props

  const classes = useStyles()
  const [open, setOpen] = useState(false)

  // let minDate: any = _minBy(items, 'label');
  // let maxDate: any = _maxBy(items, 'label');
  let maxDate = new Date()
  maxDate.setHours(23)
  maxDate.setMinutes(59)
  maxDate.setSeconds(59)

  // if (minDate) {
  //   minDate = new Date(parseInt(minDate.label));
  //   minDate.setHours(0);
  //   minDate.setMinutes(0);
  //   minDate.setSeconds(0);
  // }

  // if (maxDate) {
  //   maxDate = new Date(parseInt(maxDate.label));
  //   maxDate.setHours(23);
  //   maxDate.setMinutes(59);
  //   maxDate.setSeconds(59);
  // }

  // if (minDate && maxDate && minDate > maxDate) {
  //   maxDate = minDate;
  // }

  let startDate = _get(value, 'startDate')
  let endDate = _get(value, 'endDate')

  if (startDate && endDate && startDate > endDate) {
    endDate = startDate
  }

  let dispValue = startDate ? dateToString(startDate) : ''
  dispValue += dispValue ? ` - ${endDate ? dateToString(endDate) : ''}` : ''

  return (
    <div style={{ position: 'relative' }}>
      <PagTextInput
        name="dateRangeFilter"
        label={label}
        placeholder={placeholder}
        size="small"
        variant="outlined"
        value={dispValue}
        inputProps={{
          readOnly: true,
        }}
        InputProps={{
          endAdornment: startDate ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="close"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleSelectItem({
                    startDate: null,
                    endDate: null,
                  })
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <div className={classes.dateRangePicker}>
          <PagDateRangePicker
            maxDate={maxDate || new Date()}
            initialDateRange={{
              startDate,
              endDate,
            }}
            handleChangeDateRange={(range: { startDate?: Date | null; endDate?: Date | null }) => {
              setOpen(false)
              handleSelectItem(range)
            }}
            handleCancel={() => setOpen(false)}
          />
        </div>
      )}
    </div>
  )
}

const SearchBySpotName: any = connectSearchBox(CustomSearchBySpotName)
const SearchFilter = connectRefinementList(CustomRefinementList)
const SearchDateFilter = connectRefinementList(CustomRefinementListDatePicker)

const AdIDTableViewHits = connectInfiniteHits(PagAlgoliaConnectConditionalResults(AdIDTableView))

const AdIDSearchDialog = (props: any) => {
  const { open, enableMultipleSelection, deliverable, projectId, handleClose } = props
  const classes = useStyles()
  const { store } = useContext(Context)
  const { algoliaSearchSecuredApiKey, activeTenant } = store
  const { getTokenSilently } = useAuth0()
  const [filters, setFilters] = useState<any>(null)
  const [selectedHits, setSelectedHits] = useState<any[]>([])

  const [clearTimestamp, setClearTimestamp] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [searchState, setSearchState] = useState({ page: 1 } as any)

  // Add Deliverable or Deliverables
  const [bulkWriteDeliverables, bulkWriteDeliverablesRes] = useMutation(BULK_WRITE_DELIVERABLES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      const temp = _get(res, ['bulkWriteDeliverables', 'data'])
      const errors = _get(res, ['bulkWriteDeliverables', 'errors'])
      if (errors && errors.length) {
        for (const error of errors) {
          toast.error(error)
        }
      }
      if (temp) {
        handleClose({ isBulkWrite: true, data: temp })
      }
    },
  })

  const [updateDeliverable, updateDeliverableRes] = useMutation(UPDATE_DELIVERABLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      const temp = _get(res, ['updateDeliverable'])
      if (temp) {
        handleClose({ isUpdate: true, data: temp })
      }
    },
  })

  const searchClient = useMemo(() => {
    if (!algoliaSearchSecuredApiKey) return null
    return algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey)
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if ((!deliverable && selectedHits.length) || deliverable) {
      setSelectedHits([])
    }
    return () => {}
  }, [open, deliverable])

  useEffect(() => {
    if (!searchClient) return
    handleForceRefresh()
    return () => {}
  }, [searchClient])

  useEffect(() => {
    if (refresh) {
      setRefresh(false)
    }
    return () => {}
  }, [refresh])

  const updateFilters = (field: string, value: any) => {
    setFilters({ ...(filters || {}), [field]: value })
    handleForceRefresh()
  }

  const onSearchStateChange = (nextState: any) => {
    setSearchState(nextState)
  }

  const handleForceRefresh = () => {
    setSearchState({
      page: 1,
    })
    setClearTimestamp(new Date().getTime())
    setRefresh(true)
  }

  const handleSave = () => {
    if (selectedHits.length === 0) return
    const abortController = new AbortController()
    try {
      const callApi = async (data: IDeliverable | IDeliverable[]) => {
        try {
          await callPagApi(
            '',
            getTokenSilently,
            _get(data, 'id') ? updateDeliverable : bulkWriteDeliverables,
            { tenantId: activeTenant.id, ...data },
            abortController.signal
          )
        } catch (e) {}
      }

      let payload: any

      if (enableMultipleSelection) {
        const tempDeliverables = selectedHits.map((sh: any) => {
          return {
            agencyName: sh.agency_name,
            created: sh.created,
            jobNo: sh.job_no,
            language: sh.language,
            length: stringToNumber(sh.length),
            linkedTo: 'Ad-ID',
            spotId: sh.adid,
            spotName: sh.ad_title,
            mediaType: sh.media_type,
            medium: _get(sh, ['medium', 0, 'medium']),
            spotType: sh.code_record_type,
            projectId,
          }
        })
        payload = {
          tenantId: activeTenant.id,
          upserts: tempDeliverables,
          deletes: [],
        }
      } else if (deliverable) {
        const sh = selectedHits[0]
        payload = {
          tenantId: activeTenant.id,
          id: deliverable.id,
          deliverable: {
            agencyName: sh.agency_name,
            created: sh.created,
            jobNo: sh.job_no,
            language: sh.language,
            length: stringToNumber(sh.length),
            linkedTo: 'Ad-ID',
            spotId: sh.adid,
            spotName: sh.ad_title,
            mediaType: sh.media_type,
            medium: _get(sh, ['medium', 0, 'medium']),
            spotType: sh.code_record_type,
          },
        }
      } else {
        return
      }

      callApi(payload)
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const renderInstantSearch = useMemo(() => {
    if (!searchClient) return null

    let configFilter = ''
    for (const k of _keys(filters)) {
      if (filters[k]) {
        let filterOption = ''
        if (k === 'created') {
          let startDate = _get(filters[k], 'startDate')
          let endDate = _get(filters[k], 'endDate')
          if (startDate) {
            filterOption = `${k} >= ${new Date(startDate).getTime()}`
          }
          if (endDate) {
            if (startDate && startDate > endDate) {
              endDate = startDate
            }
            if (Boolean(filterOption)) {
              filterOption += ' AND '
            }
            filterOption += `${k} <= ${new Date(endDate).getTime()}`
          }
        } else {
          filterOption = `${k}:"${filters[k]}"`
        }

        if (Boolean(filterOption)) {
          if (Boolean(configFilter)) {
            configFilter += ` AND ${filterOption}`
          } else {
            configFilter = filterOption
          }
        }
      }
    }

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName="Integration_Ad_ID"
        refresh={refresh}
        // key={clearTimestamp}
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
      >
        <Configure filters={configFilter} />
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <b>Filter By:</b>
          </Grid>
          <Grid item xs={12} className={classes.filtersWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <SearchFilter
                  {...{
                    attribute: 'adid',
                    label: 'Spot Identifier',
                    placeholder: 'Spot Identifier',
                    canRefine: true,
                    isFromSearch: true,
                    searchable: true,
                    showMore: true,
                    showMoreLimit: 50,
                    value: filters?.adid || '',
                    handleSelectItem: (e: string) => updateFilters('adid', e),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SearchBySpotName defaultRefinement="" />
              </Grid>
              <Grid item xs={3}>
                <SearchFilter
                  {...{
                    attribute: 'agency_name',
                    label: 'Agency Name',
                    placeholder: 'Agency Name',
                    canRefine: true,
                    isFromSearch: true,
                    searchable: true,
                    showMore: true,
                    showMoreLimit: 50,
                    value: filters?.agency_name || '',
                    handleSelectItem: (e: string) => updateFilters('agency_name', e),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <SearchFilter
                  {...{
                    attribute: 'job_no',
                    label: 'Job Number',
                    placeholder: 'Job Number',
                    canRefine: true,
                    isFromSearch: true,
                    searchable: true,
                    showMore: true,
                    showMoreLimit: 50,
                    value: filters?.job_no || '',
                    handleSelectItem: (e: string) => updateFilters('job_no', e),
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{ position: 'relative' }}>
                <SearchDateFilter
                  {...{
                    attribute: 'created',
                    label: 'Created Date',
                    placeholder: 'Created Date',
                    searchable: true,
                    showMore: true,
                    showMoreLimit: 1000,
                    value: _get(filters, 'created') || '',
                    dateRange: _get(filters, 'created'),
                    handleSelectItem: (e: string) => updateFilters('created', e),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.tableWrapper}>
            <AdIDTableViewHits
              {...{
                selectedHits,
                refresh,
                handleSelectHit: (e: any) => {
                  const tempIndex = _findIndex(selectedHits, (h) => h.adid === e.adid)
                  if (tempIndex >= 0) {
                    if (enableMultipleSelection) {
                      setSelectedHits([
                        ...selectedHits.slice(0, tempIndex),
                        ...selectedHits.slice(tempIndex + 1),
                      ])
                    } else {
                      setSelectedHits([])
                    }
                  } else {
                    if (enableMultipleSelection) {
                      setSelectedHits([...selectedHits, e])
                    } else {
                      setSelectedHits([e])
                    }
                  }
                },
                handleForceRefresh: handleForceRefresh,
              }}
            />
            {/* {
              !filters ? (
                <AdIDTableView
                  {...{
                    selectedHits,
                    hits: [],
                    hasMore: false,
                    searching: false,
                    searchPage: 0,
                    nbPages: 0
                  }}
                />
              ) : (

              )
            } */}
          </Grid>
        </Grid>
      </InstantSearch>
    )
  }, [searchClient, clearTimestamp, refresh, filters, searchState, selectedHits])

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="deliverable-edit-dialog-title"
    >
      <DialogTitle id="deliverable-edit-dialog-title" className={classes.dialogTitle}>
        {enableMultipleSelection ? 'Add New Deliverables' : 'Ad_ID Search'}
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose(null)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{renderInstantSearch}</DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button color="primary" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={selectedHits.length === 0}
          style={{ minWidth: 154, height: 30 }}
          onClick={() => handleSave()}
        >
          {bulkWriteDeliverablesRes.loading || updateDeliverableRes.loading ? (
            <CircularProgress size={14} color="secondary" />
          ) : enableMultipleSelection ? (
            'Create Deliverables'
          ) : (
            'Save to Deliverable'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdIDSearchDialog
