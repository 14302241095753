import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000000,
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999999,
    opacity: 0.3,
    background: '#f4f4f4',
  },
  progress: {
    margin: theme.spacing(2),
  },
}))

const PagCircularIndeterminate = (props: any) => {
  const { size } = props
  const classes = useStyles()

  return (
    <>
      <div className={classes.overlay}></div>
      <div className={classes.root}>
        <CircularProgress className={classes.progress} size={size} />
      </div>
    </>
  )
}

PagCircularIndeterminate.defaultProps = {
  size: 32,
}

export default PagCircularIndeterminate
