import React from 'react'
import clsx from 'clsx'
import _get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '1rem',
  },
}))

const PagButton = (props: any) => {
  const classes = useStyles()
  const { buttonProps, alcRender, style } = props
  const classNames = _get(props, 'classNames') || []

  return (
    <Button className={clsx(classes.button, ...classNames)} {...buttonProps} style={style}>
      {alcRender}
    </Button>
  )
}

export default PagButton
