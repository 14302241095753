import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import useUrlParams from '@/components/hooks/use-url-params'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'
import UploadPortalNewProjectForm from './components/new-project-form'

const UploadPortalNewProject = (props: any) => {
  const { projectNumber, setProjectNumber, setProject } = props
  const { slug } = useUrlParams({ slug: false })
  const navigate = useNavigate()
  const { store } = useContext(Context)
  const { activeClient } = store

  return (
    <UploadPortalNewProjectForm
      client={activeClient}
      projectNumber={projectNumber}
      handleChangeProjectNumber={(e: string) => {
        setProjectNumber(e)
        setProject(null)
        navigate(`/p/${slug}`)
      }}
      handleNext={(e: IProject) => {
        setProject(e)
        navigate(`/p/${slug}/upload-files`)
      }}
      handleBack={() => {
        setProject(null)
        navigate(`/p/${slug}`)
      }}
    />
  )
}

export default UploadPortalNewProject
