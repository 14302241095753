import React, { useContext, useState, useEffect } from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _get from 'lodash/get'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import PagCircularIndeterminate from '@/components/pag-loading'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { CURRENCIES } from '@/shared/constants/currency'
import { GET_BID_ITEMS_BY_CATEGORY } from '@/shared/graphql/queryGetBids'
import { SORT_BID_ITEMS } from '@/shared/graphql/mutations/mutationSortBidItems'
import { callPagApi } from '@/shared/services/pag-api'
import { reorderArray } from '@/utils/array-utils'

const viewFieldsNo = [0, 1, 2, 3, 4]

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
})

const getListStyle = (isDraggingOver: boolean) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
})

const BidItemsByCategory = (props: any) => {
  const { activeBid, allExpanded, category, classes, headCells, provided, token, onEditBidItem } =
    props

  const rowHeight = 80
  const keyFieldName = 'id'
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const [bidItemsByCategory, setBidItemsByCategory] = useState([] as any)
  const [expand, setExpand] = useState(allExpanded)

  // Get BidItems by Category
  const [getBidItemsByCategory, bidItemsByCategoryRes] = useLazyQuery(GET_BID_ITEMS_BY_CATEGORY, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
    fetchPolicy: 'network-only',
  })

  const [sortBidItems, sortBidItemsRes] = useMutation(SORT_BID_ITEMS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    if (!mountedRef.current) return

    setExpand(allExpanded)

    return () => {}
  }, [allExpanded])

  useEffect(() => {
    if (!mountedRef.current || !activeTenant?.id) return

    const abortController = new AbortController()
    const { signal } = abortController
    const bidId = _get(activeBid, ['id'])

    if (category.serviceCategory && expand && bidId)
      callPagApi(
        token,
        getTokenSilently,
        getBidItemsByCategory,
        { tenantId: activeTenant.id, bidId: bidId, serviceCategory: category.serviceCategory },
        signal
      )

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant, activeBid, category, expand])

  useEffect(() => {
    if (!mountedRef.current) return
    const temp = _get(bidItemsByCategoryRes, ['data', 'bidItemsByCategory', 'data']) || []
    setBidItemsByCategory(temp)

    return () => {}
  }, [bidItemsByCategoryRes.data])

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let abortController

    try {
      const temp: any = reorderArray(
        bidItemsByCategory,
        result.source.index,
        result.destination.index
      )
      setBidItemsByCategory(temp)

      abortController = new AbortController()
      const { signal } = abortController
      callPagApi(
        '',
        getTokenSilently,
        sortBidItems,
        {
          tenantId: activeTenant.id,
          sorts: temp.map((t: any, i: number) => ({ id: t.id, sortOrder: i })),
        },
        signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <>
      <TableRow
        tabIndex={-1}
        className={classes.categoryTR}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <TableCell className={classes.categoryTD} colSpan={4}>
          <b>{category.serviceCategory || ''}</b>
        </TableCell>
        <TableCell className={classes.categoryTD} align="right" style={{ width: '12%' }}>
          <b>
            <NumberFormat
              displayType={'text'}
              value={category.bidTotalCost || 0}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={CURRENCIES[_get(activeBid, ['currency', 'currency'])] || '$'}
            />
          </b>
        </TableCell>
        {/* <TableCell className={classes.categoryTD}>
				</TableCell> */}
        <TableCell
          className={classes.categoryTD}
          align="right"
          style={{
            width: headCells[5].width || undefined,
            minWidth: headCells[5].minWidth || undefined,
          }}
        >
          <IconButton color="inherit" disableRipple size="small" onClick={() => setExpand(!expand)}>
            <Icon>{expand ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
          </IconButton>
          <IconButton
            className={clsx(classes.dragIconButton)}
            color="inherit"
            disableRipple
            size="small"
            {...provided.dragHandleProps}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {expand && (
        <TableRow>
          <TableCell className={classes.td} colSpan={6} style={{ padding: 0 }}>
            <Table
              className={classes.table}
              aria-labelledby="Bid Items"
              size={'medium'}
              aria-label="Bid Items"
            >
              {Boolean(bidItemsByCategoryRes) && bidItemsByCategoryRes.loading ? (
                <TableBody>
                  <TableRow tabIndex={-1}>
                    <TableCell>
                      <div style={{ position: 'relative', height: '60px' }}>
                        <PagCircularIndeterminate />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="bidItemDroppable">
                    {(bidItemDnDProvided: any, bidItemDnDSnapshot: any) => (
                      <TableBody
                        innerRef={bidItemDnDProvided.innerRef}
                        {...bidItemDnDProvided.droppableProps}
                      >
                        {bidItemsByCategory.map((bidItem: any, i: any) => (
                          <Draggable
                            key={`bidItem-${bidItem.id}`}
                            draggableId={`bidItem-${bidItem.id}`}
                            index={i}
                          >
                            {(bidItemDnDProvided, bidItemDnDSnapshot) => (
                              <TableRow
                                {...bidItemDnDProvided.draggableProps}
                                {...bidItemDnDProvided.dragHandleProps}
                                ref={bidItemDnDProvided.innerRef}
                                hover
                                tabIndex={-1}
                                style={getItemStyle(
                                  bidItemDnDSnapshot.isDragging,
                                  bidItemDnDProvided.draggableProps.style
                                )}
                              >
                                {/* <TableCell
                                  className={classes.td}
                                  style={{
                                    width: headCells[0].width || undefined,
                                    minWidth: headCells[0].minWidth || undefined,
                                  }}
                                >
                                  E{_get(bidItem, ['serviceItem', 'rateId']) || 0}
                                </TableCell> */}
                                {viewFieldsNo.map((n: any, j: number) => {
                                  const service = bidItem?.serviceItem?.service || ''
                                  const data = _get(bidItem, headCells[n].id) || ''
                                  return (
                                    <TableCell
                                      key={`${i}-${j}`}
                                      className={classes.td}
                                      align={headCells[n].align}
                                      style={{
                                        width: headCells[n].width || undefined,
                                        minWidth: headCells[n].minWidth || undefined,
                                      }}
                                    >
                                      {headCells[n].type === 'currency' ? (
                                        <NumberFormat
                                          displayType={'text'}
                                          value={_get(bidItem, headCells[n].id) || 0}
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          prefix={
                                            CURRENCIES[_get(activeBid, ['currency', 'currency'])] ||
                                            '$'
                                          }
                                        />
                                      ) : (
                                        <>
                                          {headCells[n].id === 'originalService' ? (
                                            <span>
                                              <span>{service}</span>
                                              <span>{service ? ' ' : ''}</span>
                                              {service !== data && (
                                                <span className={classes.originalService}>
                                                  {data}
                                                </span>
                                              )}
                                            </span>
                                          ) : (
                                            data
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  )
                                })}
                                {/* <TableCell className={clsx(classes.td, classes.iconWrite)} align='center'>
                                        <Icon onClick={event => onEditBidItem(event, bidItem[keyFieldName])}>create</Icon>
                                      </TableCell> */}
                                <TableCell
                                  className={classes.td}
                                  align="right"
                                  style={{
                                    width: headCells[5].width || undefined,
                                    minWidth: headCells[5].minWidth || undefined,
                                  }}
                                >
                                  <IconButton
                                    className={clsx(classes.dragIconButton)}
                                    color="inherit"
                                    disableRipple
                                    size="small"
                                  >
                                    <DragIndicatorIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {bidItemDnDProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default BidItemsByCategory
