import React, { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Index, Configure, connectHits } from 'react-instantsearch-dom'
import clsx from 'clsx'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _take from 'lodash/take'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    alignItems: 'flex-start',
  },
  menuTitle: {
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
    paddingRight: 16,
    color: 'gray',
  },
  projectImage: {
    width: 60,
    height: 50,
    minWidth: 60,
    minHeight: 50,
    fontSize: '1rem',
    color: 'white',
    backgroundColor: 'lightgrey',
  },
  content: {
    position: 'relative',
    width: 'calc(100% - 70px)',
    marginLeft: '10px',
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const ProjectsListView = (props: any) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const {
    hits,

    handleClose,
    searchPhrase,
  } = props

  const handleDetailView = useCallback((project: any) => {
    handleClose(null)
    const cId = _get(project, ['clientId']) || _get(project, ['brand', 'clientId'])
    navigate(`/${cId}/${project.brandId}/${project.id}/project`)
  }, [])

  const handleSearchMore = useCallback(() => {
    handleClose(null)
    navigate('/search/projects')
  }, [])

  const content = useMemo(() => {
    const dispDataList = _take(hits, 5)
    const hasHits = _get(hits, 'length') > 0

    return (
      <>
        {hasHits && (
          <div className={classes.menuTitle}>
            <b>PROJECTS</b>
          </div>
        )}

        {_map(dispDataList, (data: any, i: number) => {
          const thumnail =
            _get(data, 'image') ||
            _get(data, ['client', 'defaultProjectImage']) ||
            _get(data, ['client', 'brand', 'defaultProjectImage']) ||
            undefined

          return (
            <ListItem
              key={`projects-search-${i}`}
              button
              className={classes.menuItem}
              onClick={() => handleDetailView(data)}
            >
              {thumnail ? (
                <img className={classes.projectImage} src={thumnail} alt="ProjectImage" />
              ) : (
                <div className={clsx(classes.projectImage, 'pag-flex')}>No Image</div>
              )}
              <div className={classes.content}>
                <div className={classes.title}>
                  <b>{_get(data, 'projectName') || ''}</b>
                </div>
              </div>
            </ListItem>
          )
        })}

        {hasHits && (
          <div className={classes.menuTitle}>
            <span className="pag-blue" onClick={() => handleSearchMore()}>
              Show More Projects
            </span>
          </div>
        )}
      </>
    )
  }, [classes, hits])

  return <>{content}</>
}

const ProjectsListViewHits = connectHits(ProjectsListView)

const ProjectsPopper = (props: any) => {
  const { activeClient, activeBrand, clients, handleClose, searchPhrase } = props

  const content = useMemo(() => {
    return (
      <Index indexId="ProjectsInSearch" indexName="Projects">
        <Configure hitsPerPage={6} />
        <ProjectsListViewHits
          {...{
            activeClient,
            activeBrand,
            clients,
            handleClose,
            searchPhrase,
          }}
        />
      </Index>
    )
  }, [activeBrand, activeClient, clients, searchPhrase])

  return <>{content}</>
}

export default ProjectsPopper
