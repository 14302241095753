import gql from 'graphql-tag'

const GET_PROJECT_TYPES = gql`
  query projectTypes($tenantId: Int!, $offset: Int, $limit: Int) {
    projectTypes(tenantId: $tenantId, offset: $offset, limit: $limit) {
      total
      data {
        id
        projectType
      }
    }
  }
`

export { GET_PROJECT_TYPES }
