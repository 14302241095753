import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _uniq from 'lodash/uniq'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import { CURRENCIES } from '@/shared/constants/currency'
import PagCircularIndeterminate from '@/components/pag-loading'
import BidItemsByCategoryInBids from './bid-items-by-category-in-bids'
import { Context } from '@/shared/store/reducers/global-reducer'
import { hasPermission } from '@/utils/role-permission'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  th: {
    fontSize: '1rem',
    padding: 0,
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
    borderRight: '1px solid lightgray',

    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  trActive: {
    backgroundColor: '#f4f4f4',

    '& td': {
      borderBottom: '1px solid gray',
    },
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
  },
  borderRight: {
    borderRight: '1px solid lightgray',
  },
  borderRightGray: {
    borderRight: '1px solid gray',
  },
  trBorderTopActive: {
    '& td': {
      borderTop: '1px solid gray',
    },
  },
  trBorderBottomActive: {
    '& td': {
      borderBottom: '1px solid gray',
    },
  },
  categoryTR: {
    backgroundColor: '#225099',
  },
  categoryTD: {
    fontSize: '1rem',
    padding: 6,
    color: 'white',
  },
  serviceTD: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  priceVSBenchmark: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  price: {
    width: '8rem',
    textAlign: 'right',
  },
  bidName: {
    padding: '1rem 2rem 0 2rem',
  },
  bidRegion: {
    padding: '0.5rem 2rem 1rem 2rem',
    fontWeight: 400,
  },
  bidTotalCost: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid lightgray',
    padding: '1rem 1rem 1rem 2rem',
  },
  bidServiceGroup: {
    padding: '1rem 0.5rem',
    fontSize: '1.3rem',
  },
  iconArrow: {},
  iconArrowBlack: {
    color: 'black',
  },
  iconWrite: {
    padding: 3,
  },
  noValue: {
    color: 'lightgray',
    lineHeight: 1,
    fontSize: '2rem',
  },
  benchmarkCompare: {
    display: 'flex',
    alignItems: 'center',
  },
  textGray: {
    color: 'gray',
  },
  textRed: {
    color: 'red',
  },
  textGreen: {
    color: '#00CE11',
  },
}))

const BidsCompareView = (props: any) => {
  const { bidDetailsViewMode, selectedBids, loading, bidItemCategoryGroupsInBids, onDetailView } =
    props
  const classes = useStyles()
  const mountedRef = useMounted(true)
  const { store } = useContext(Context)
  const { userPermissions, activeProject, exchangeRates } = store

  const enableBenchmarksBids = useMemo(
    () => hasPermission(userPermissions, 'enablebenchmarks:bids'),
    [userPermissions]
  )
  const projectCurrency = useMemo(() => activeProject?.currency?.currency || '', [activeProject])
  const serviceGroups = useMemo(
    () => _uniq(_map(bidItemCategoryGroupsInBids, 'serviceGroup')) || [],
    [bidItemCategoryGroupsInBids]
  )
  const allExpanded = useMemo(() => bidDetailsViewMode === 'Expand', [bidDetailsViewMode])

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="Bid Compare View"
          size={'medium'}
          aria-label="Bid Compare View"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} colSpan={2}>
                <div className={clsx(classes.bidName)}>Comparing</div>
                <div className={clsx(classes.bidName)}>
                  <Typography variant="h4">{selectedBids.length}</Typography>
                </div>
              </TableCell>

              {selectedBids.map((bid: any, i: any) => {
                const bidCurrency = _get(bid, ['currency', 'currency']) || ''
                const exchangeRate = _get(exchangeRates, [bidCurrency]) || 1
                const temp = bidItemCategoryGroupsInBids.filter((bi: any) => bi.bidId === bid.id)
                const bidTotalCost =
                  _reduce(temp, (sum, i) => sum + (_get(i, ['bidTotalCost']) || 0), 0) *
                  exchangeRate
                const benchmarkBidTotal =
                  _reduce(
                    temp,
                    (sum, i) => sum + (_get(i, ['benchmarkServiceGroupTotal']) || 0),
                    0
                  ) * exchangeRate
                const diff = bidTotalCost - benchmarkBidTotal

                return (
                  <TableCell key={`bid-${i}`} className={classes.th}>
                    <div className={clsx(classes.bidName)}>
                      <b>{_get(bid, ['vendor', 'name']) || ''}</b>
                    </div>
                    <div className={clsx(classes.bidRegion)}>
                      {_get(bid, ['bidCountry', 'country']) || ''}
                    </div>
                    <div className={clsx(classes.bidTotalCost)}>
                      <div className={classes.priceVSBenchmark}>
                        <div>
                          <b>
                            <NumberFormat
                              displayType={'text'}
                              value={bidTotalCost}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={CURRENCIES[projectCurrency] || '$'}
                            />
                          </b>
                        </div>
                        <div
                          className={clsx(
                            classes.benchmarkCompare,
                            diff > 0
                              ? classes.textRed
                              : diff < 0
                              ? classes.textGreen
                              : classes.textGray
                          )}
                        >
                          {enableBenchmarksBids && Boolean(bidTotalCost) ? (
                            <>
                              &nbsp;
                              {
                                benchmarkBidTotal ? (
                                  <>
                                    <Icon
                                      style={{
                                        transform:
                                          diff > 0
                                            ? 'rotate(-90deg)'
                                            : diff < 0
                                            ? 'rotate(90deg)'
                                            : 'none',
                                      }}
                                    >
                                      {diff ? 'play_circle_outlined' : 'check_circle_outlined'}
                                    </Icon>

                                    {diff ? (
                                      <NumberFormat
                                        displayType={'text'}
                                        value={Math.abs(diff)}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={CURRENCIES[bidCurrency] || '$'}
                                      />
                                    ) : null}

                                    <span>
                                      &nbsp;
                                      {!diff ? 'Equal To Benchmark' : ''}
                                    </span>
                                  </>
                                ) : null
                                // <span className={classes.textRed}>
                                //   &nbsp;
                                //   No Benchmark Available
                                // </span>
                              }
                            </>
                          ) : null}
                        </div>
                      </div>
                      <Icon className="pag-blue" onClick={() => onDetailView(bid)}>
                        arrow_forward
                      </Icon>
                    </div>
                  </TableCell>
                )
              })}

              <TableCell className={classes.th}></TableCell>
            </TableRow>
          </TableHead>

          {Boolean(serviceGroups) && serviceGroups.length > 0 ? (
            <TableBody>
              {serviceGroups.map((group: string, i: number) => {
                const serviceCategoriesInGroup = _filter(bidItemCategoryGroupsInBids, {
                  serviceGroup: group,
                })
                const serviceCategories = _uniq(_map(serviceCategoriesInGroup, 'serviceCategory'))

                return (
                  <React.Fragment key={`group-${i}`}>
                    <TableRow>
                      <TableCell className={classes.th} colSpan={3 + selectedBids.length}>
                        <div className={clsx('pag-blue', classes.bidServiceGroup)}>
                          <b>{group}</b>
                        </div>
                      </TableCell>
                    </TableRow>

                    {serviceCategories.map((category, i) => {
                      const serviceCategoryWithBids = _filter(serviceCategoriesInGroup, {
                        serviceCategory: category,
                      })

                      return (
                        <BidItemsByCategoryInBids
                          key={`group-categories-${i}`}
                          allExpanded={allExpanded}
                          category={category}
                          classes={classes}
                          enableBenchmarksBids={enableBenchmarksBids}
                          exchangeRates={exchangeRates}
                          group={group}
                          projectCurrency={projectCurrency}
                          selectedBids={selectedBids}
                          serviceCategoryWithBids={serviceCategoryWithBids}
                        />
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </TableBody>
          ) : (
            <>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3 + selectedBids.length}>
                      <PagCircularIndeterminate />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
      </div>
    </div>
  )
}

export default BidsCompareView
