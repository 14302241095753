import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

const PagNumberFormat = (props: any) => {
  const { inputRef, prefix, thousandSeparator, isNumericString, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={thousandSeparator}
      isNumericString={isNumericString}
      prefix={prefix}
    />
  )
}

PagNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  thousandSeparator: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default PagNumberFormat
