import React, { useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLazyQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _trim from 'lodash/trim'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { GET_PROJECTS } from '@/shared/graphql'
import { IUploadProjectWithFiles } from '@/shared/models/upload-project-with-files'
import { errorHandler } from '@/shared/error-handler'
import { useAuth0 } from '@/auth/index'
import { callPagApi } from '@/shared/services/pag-api'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
    maxWidth: 450,
  },
  buttonWrapper: {
    marginTop: 32,
  },
  button: {
    height: 32,
    minWidth: 120,
  },
}))

const DefaultFormData: IUploadProjectWithFiles = {
  projectNumber: '',
  comments: '',
}

const disallowedCharacters = /[\/\\":*?<>|+]/

const FormStep1Schema = yup.object().shape({
  projectNumber: yup
    .string()
    .test(
      'disallowed-characters',
      'Project Number contains disallowed characters.',
      (value) => !disallowedCharacters.test(value || '')
    )
    .required('Project Number is required.'),
})

const UploadPortalSearchProjectForm = (props: any) => {
  const { client, projectNumber, handleNext } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const hookFormMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: DefaultFormData,
    resolver: yupResolver(FormStep1Schema),
  })
  const { control, getValues, setValue, trigger, handleSubmit } = hookFormMethods
  const mountedRef = useMounted(true)
  const projectNumberValueRef = useRef<string>('')

  const [getProjects, getProjectsRes] = useLazyQuery(GET_PROJECTS, {
    onError: (err: any) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: async (res: any) => {
      if (Boolean(mountedRef.current)) {
        const temp = _get(res, ['projects', 'data']) || []
        handleNext({
          projectNumber: projectNumberValueRef.current,
          project: (temp && temp[0]) || null,
        })
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const temp = getValues('projectNumber')
    if (typeof temp !== 'undefined') {
      setValue('projectNumber', projectNumber || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNumber])

  const handleFormSubmit = async () => {
    const isValid = await trigger()
    if (!isValid || !client) return
    const temp = _trim(getValues('projectNumber') || '')
    if (!temp) return
    projectNumberValueRef.current = temp

    let abortController: any
    try {
      abortController = new AbortController()
      const { signal } = abortController
      callPagApi(
        '',
        getTokenSilently,
        getProjects,
        {
          tenantId: client.tenantId,
          clientId: client.id,
          projectNumber: temp,
          offset: 0,
          limit: 1,
        },
        signal
      )
    } catch (e) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <form
      className={classes.root}
      name="uploadPortalForm"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="h5" component="h6" gutterBottom style={{ fontSize: '1.8rem' }}>
            Enter a Project Number to get started!
          </Typography>
        </ListItem>

        <ListItem disableGutters>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagTextInput
                label="Project Number"
                placeholder="Project Number"
                size="small"
                variant="filled"
                inputRef={field.ref}
                error={fieldState.error}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value || '')}
                onBlur={field.onBlur}
              />
            )}
            control={control}
            name="projectNumber"
            defaultValue={projectNumber}
          />
        </ListItem>

        <ListItem disableGutters className={classes.buttonWrapper}>
          <Button
            color="primary"
            size="medium"
            aria-label="next"
            variant="contained"
            type="submit"
            className={classes.button}
            disabled={getProjectsRes.loading}
          >
            {getProjectsRes.loading ? <CircularProgress size={14} color="secondary" /> : 'Next'}
          </Button>
        </ListItem>
      </List>
    </form>
  )
}

export default UploadPortalSearchProjectForm
