import React, { useReducer } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/react-hooks'

import { AUTH_CONFIG } from './config/auth0.config'
import Auth0Provider from './auth'
import { Initial_State, Global_Reducer, Context } from './shared/store/reducers/global-reducer'
import AppLayout from './layouts'
import AppRoutes from './routes'
import './App.scss'

const apolloClient: ApolloClient<any> = new ApolloClient({
  uri: process.env.REACT_APP_PAG_SERVER_GRAPHQL,
  cache: new InMemoryCache(),
})

const onRedirectCallback = (appState: any) => {
  const targetUrl = appState?.targetUrl || window.location.pathname
  if (targetUrl !== window.location.pathname) {
    window.location.replace(targetUrl)
  }
  window.history.replaceState({}, document.title, targetUrl)
}

const App = (props: any) => {
  const [store, dispatch] = useReducer(Global_Reducer, Initial_State)
  return (
    <Auth0Provider
      domain={AUTH_CONFIG.domain}
      client_id={AUTH_CONFIG.client_id}
      redirect_uri={window.location.origin}
      audience={AUTH_CONFIG.audience}
      returnTo={AUTH_CONFIG.returnTo}
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloProvider client={apolloClient}>
        <Context.Provider value={{ store, dispatch }}>
          <BrowserRouter>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </BrowserRouter>
        </Context.Provider>
      </ApolloProvider>
    </Auth0Provider>
  )
}

export default App
