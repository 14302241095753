import gql from 'graphql-tag'

const GET_PORJECTS_WITHOUT_BIDS = gql`
  query projects(
    $tenantId: Int!
    $clientId: Int
    $brandId: Int
    $projectName: String
    $projectNumber: String
    $offset: Int
    $limit: Int
  ) {
    projects(
      tenantId: $tenantId
      clientId: $clientId
      brandId: $brandId
      projectName: $projectName
      projectNumber: $projectNumber
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        projectName
        projectNumber
        details
        description
        actualizedDate
        approved
        approvedAt
        approvedBy
        budgetLineItem
        budgetTotal
        deliveryDate
        fundingBeginDate
        fundingEndDate
        frAmount
        fundingNotes
        productionStartDate
        preBidDate
        image
        files
        metaData
        sustainability
        importKey
        clientId
        brandId
        agencyId
        agencyProducerId
        bidTypeId
        campaignId
        currencyId
        marketId
        projectCategoryId
        projectRegionId
        projectStatusId
        projectTypeId
        tagIds
        client {
          id
          name
          tenantId
          defaultProjectImage
        }
        brand {
          id
          name
          clientId
          client {
            id
            name
            tenantId
            defaultProjectImage
          }
        }
        agency {
          id
          name
        }
        product {
          id
          name
        }
        projectRegion {
          id
          region
        }
        currency {
          id
          currency
        }
        projectCategory {
          id
          category
        }
        projectStatus {
          id
          status
        }
        projectType {
          id
          projectType
        }
      }
    }
  }
`

const GET_PROJECT_WITHOUT_BIDS = gql`
  query project($tenantId: Int!, $id: Int!) {
    project(tenantId: $tenantId, id: $id) {
      id
      projectName
      projectNumber
      details
      description
      actualizedDate
      approved
      approvedAt
      approvedBy
      budgetLineItem
      budgetRate
      budgetTotal
      deliveryDate
      fundingBeginDate
      fundingEndDate
      frAmount
      fundingNotes
      productionStartDate
      preBidDate
      image
      files
      metaData
      sustainability
      importKey
      clientId
      brandId
      agencyId
      agencyProducerId
      bidTypeId
      campaignId
      currencyId
      marketId
      projectRegionId
      projectCategoryId
      projectStatusId
      projectTypeId
      tagIds
      client {
        id
        name
        tenantId
        defaultProjectImage
      }
      brand {
        id
        name
        client {
          id
          name
          tenantId
          defaultProjectImage
        }
      }
      agency {
        id
        name
      }
      campaign {
        id
        name
      }
      currency {
        id
        currency
      }
      market {
        id
        name
      }
      projectCategory {
        id
        category
      }
      projectRegion {
        id
        region
      }
      projectStatus {
        id
        status
      }
      projectType {
        id
        projectType
      }
    }
  }
`

const GET_PROJECT_FOR_VIEWS = gql`
  query project($tenantId: Int!, $id: Int!, $expandProjectTeams: Boolean) {
    project(tenantId: $tenantId, id: $id, expandProjectTeams: $expandProjectTeams) {
      id
      projectName
      projectNumber
      budgetLineItem
      budgetTotal
      fundingBeginDate
      fundingEndDate
      fundingNotes
      metaData
      clientId
      currencyId
      brand {
        id
        name
        clientId
      }
      currency {
        id
        currency
      }
      purchaseOrders {
        id
        poNumber
      }
    }
  }
`

const GET_PROJECTS_FOR_VIEWS = gql`
  query projects(
    $tenantId: Int!
    $clientId: Int
    $projectName: String
    $approved: Boolean
    $expandProjectTeams: Boolean
    $offset: Int
    $limit: Int
  ) {
    projects(
      tenantId: $tenantId
      clientId: $clientId
      projectName: $projectName
      approved: $approved
      expandProjectTeams: $expandProjectTeams
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        projectName
        projectNumber
        budgetLineItem
        budgetTotal
        fundingBeginDate
        fundingEndDate
        frAmount
        fundingNotes
        metaData
        clientId
        currencyId
        brand {
          id
          name
          clientId
        }
        currency {
          id
          currency
        }
        purchaseOrders {
          id
          poNumber
        }
      }
    }
  }
`

const GET_PROJECTS = gql`
  query projects(
    $tenantId: Int!
    $clientId: Int
    $brandId: Int
    $projectName: String
    $projectNumber: String
    $offset: Int
    $limit: Int
  ) {
    projects(
      tenantId: $tenantId
      clientId: $clientId
      brandId: $brandId
      projectName: $projectName
      projectNumber: $projectNumber
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        projectName
        projectNumber
        details
        description
        actualizedDate
        budgetLineItem
        budgetRate
        budgetTotal
        deliveryDate
        fundingBeginDate
        fundingEndDate
        frAmount
        fundingNotes
        productionStartDate
        preBidDate
        image
        files
        metaData
        sustainability
        importKey
        clientId
        brandId
        agencyId
        agencyProducerId
        bidTypeId
        campaignId
        currencyId
        marketId
        projectCategoryId
        projectRegionId
        projectStatusId
        projectTypeId
        tagIds
        client {
          id
          name
          tenantId
          defaultProjectImage
          tenant {
            id
            name
          }
        }
        brand {
          id
          name
          clientId
          client {
            id
            name
            tenantId
            defaultProjectImage
            tenant {
              id
              name
            }
          }
        }
        agency {
          id
          name
        }
        projectRegion {
          id
          region
        }
        currency {
          id
          currency
        }
        projectCategory {
          id
          category
        }
        projectStatus {
          id
          status
        }
        projectType {
          id
          projectType
        }
      }
    }
  }
`

export {
  GET_PROJECTS,
  GET_PORJECTS_WITHOUT_BIDS,
  GET_PROJECT_WITHOUT_BIDS,
  GET_PROJECTS_FOR_VIEWS,
  GET_PROJECT_FOR_VIEWS,
}
