import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ReceipitIcon from '@material-ui/icons/Receipt'
import LaunchIcon from '@material-ui/icons/Launch'
import StarIcon from '@material-ui/icons/Star'

import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IClient } from '@/shared/models/client'
import { IBrand } from '@/shared/models/brand'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: '0',
  },

  listItem: {
    height: 45,
    '&.hasChildren': {
      color: '#225099',
      backgroundColor: '#f4f4f4',
      '& svg': {
        fill: '#225099',
      },
      '& span': {
        fontWeight: `600!important`,
      },
    },
    '&.active': {
      color: 'white',
      backgroundColor: '#225099',
      '&:hover, &:focus': {
        color: 'white',
        backgroundColor: '#225099',
      },
      '& svg': {
        fill: 'white',
      },
    },
    '& svg': {
      width: 22,
      height: 22,
    },
  },

  viewReports: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    padding: '15px',
    cursor: 'pointer',
  },

  subListItem: {
    height: 40,
    '&.active': {
      color: 'white',
      backgroundColor: '#225099',
      '&:hover, &:focus': {
        color: 'white',
        backgroundColor: '#225099',
      },
    },
  },

  listItemIcon: {
    minWidth: 32,
  },
}))

const AppSideMenubar = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()
  const { store } = useContext(Context)
  const { clients } = store

  const renderClient = (client: any, expanded: boolean) => {
    return (
      <div key={client.id}>
        <ListItem
          button
          className={clsx(
            classes.listItem,
            { hasChildren: true },
            { active: location.pathname === `/${client.id}/list` }
          )}
          onClick={() => {
            navigate(`/${client.id}/list`)
          }}
        >
          <ListItemText primary={client.name} />
        </ListItem>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {client.brands.map((brand: IBrand, j: number) => (
            <List key={`brand-${brand.id}`} component="div" disablePadding>
              <ListItem
                button
                className={clsx(classes.subListItem, {
                  active: (location.pathname || '').indexOf(`/${client.id}/${brand.id}/`) === 0,
                })}
                onClick={() => {
                  navigate(`/${client.id}/${brand.id}/list`)
                }}
              >
                <ListItemText primary={brand.name} />
              </ListItem>
              <Divider />
            </List>
          ))}
        </Collapse>
      </div>
    )
  }

  const client = clients && clients[0]
  const clientId = client?.id
  const calendarWidget = client?.calendarWidget

  return (
    <List component="nav" aria-labelledby="app-side-menu" className={classes.root}>
      <ListItem
        button
        className={clsx(classes.listItem, { active: location.pathname === '/' })}
        onClick={() => {
          navigate(`/`)
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem
        button
        className={classes.listItem}
        onClick={() =>
          window.open(
            'https://app.forestadmin.com/PostAdsGroup%20Admin/Production/Operations/dashboard/107504'
          )
        }
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ReceipitIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItem>

      {Boolean(clientId) && (
        <ListItem
          button
          className={clsx(classes.listItem, {
            active: location.pathname === `/${clientId}/reports`,
          })}
          onClick={() => {
            navigate(`/${clientId}/reports`)
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <LaunchIcon />
          </ListItemIcon>
          <ListItemText primary="Reporting" />
        </ListItem>
      )}

      {Boolean(clientId) && (
        <ListItem
          button
          className={clsx(classes.listItem, {
            active: location.pathname === `/${clientId}/views`,
          })}
          onClick={() => {
            navigate(`/${clientId}/views`)
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Views" />
        </ListItem>
      )}

      {Boolean(calendarWidget) && (
        <ListItem
          button
          className={clsx(classes.listItem, {
            active: location.pathname === `/${clientId}/calendar`,
          })}
          onClick={() => {
            navigate(`/${clientId}/calendar`)
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CalendarTodayIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItem>
      )}

      <ListItem className={classes.listItem} style={{ height: '27px' }}></ListItem>

      {isAuthenticated &&
        clients.map((client: IClient, i: number) =>
          client.brands ? (
            renderClient(client, true)
          ) : (
            // renderClient(client, expands.indexOf(client.id) >= 0)
            <ListItem key={client.id} button className={classes.listItem}>
              <ListItemText primary={client.name} />
            </ListItem>
          )
        )}
    </List>
  )
}

export default AppSideMenubar
