import gql from 'graphql-tag'

const ADD_TAG = gql`
  mutation addTag(
    $tenantId: Int!
    $name: String!
    $description: String
    $visibleUploadPortal: Boolean
  ) {
    addTag(
      tenantId: $tenantId
      name: $name
      description: $description
      visibleUploadPortal: $visibleUploadPortal
    ) {
      id
      name
      description
      visibleUploadPortal
    }
  }
`
const UPDATE_TAG = gql`
  mutation updateTag(
    $tenantId: Int!
    $id: Int!
    $name: String
    $description: String
    $visibleUploadPortal: Boolean
  ) {
    updateProject(
      tenantId: $tenantId
      id: $id
      name: $name
      description: $description
      visibleUploadPortal: $visibleUploadPortal
    )
  }
`

const DELETE_TAG = gql`
  mutation deleteTag($tenantId: Int!, $id: Int!) {
    deleteTag(tenantId: $tenantId, id: $id) {
      success
      data
      error
    }
  }
`

export { ADD_TAG, UPDATE_TAG, DELETE_TAG }
