import { toast } from 'react-toastify'
import _startsWith from 'lodash/startsWith'

export const errorHandler = (err: any) => {
  if (!err) return

  const { graphQLErrors, networkError, message } = err

  if (graphQLErrors && graphQLErrors.length > 0) {
    if (
      _startsWith(graphQLErrors[0].message, 'Invalid permission') ||
      _startsWith(graphQLErrors[0].message, 'Validation error') ||
      _startsWith(graphQLErrors[0].message, 'Non existent Contact') ||
      _startsWith(graphQLErrors[0].message, 'There is already a deliverable with the same spotId')
    ) {
      toast.error(graphQLErrors[0].message)
    } else {
      toast.error('Something went wrong. Refresh your browser and try again.')
    }
  } else if (networkError) {
    if (!(networkError instanceof DOMException)) {
      toast.error('Server Error. Refresh your browser and try again.')
    }
  } else if (err && typeof err === 'string') {
    toast.error(err)
  }
}
