import React, { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { HeadCell } from '@/components/pag-table/pag-table-head'
import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { IDeliverable } from '@/shared/models/deliverable'
import { dateToString } from '@/utils/date-utils'

const headCells = [
  {
    id: 'spotId',
    align: 'left',
    disablePadding: true,
    label: 'Spot Identifier',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
    width: '124px',
  },
  {
    id: 'dateCreated',
    align: 'left',
    disablePadding: true,
    label: 'Date Created',
    sortable: true,
    isLink: false,
    width: '84px',
  },
  {
    id: 'jobNo',
    align: 'left',
    disablePadding: true,
    label: 'Job Number',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '96px',
  },
  {
    id: 'spotName',
    align: 'left',
    disablePadding: true,
    label: 'Spot Name',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    maxWidth: '30%',
    width: '30%',
  },
  {
    id: 'length',
    align: 'left',
    disablePadding: true,
    label: 'Length',
    sortable: true,
    isLink: false,
    suffix: ' Seconds',
    width: '96px',
  },
  {
    id: 'spotType.name',
    align: 'left',
    disablePadding: true,
    label: 'Spot Type',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'mediaType.name',
    align: 'left',
    disablePadding: true,
    label: 'Media Type',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'medium.name',
    align: 'left',
    disablePadding: true,
    label: 'Medium',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'language',
    align: 'left',
    disablePadding: true,
    label: 'Language',
    sortable: true,
    isLink: false,
    width: '84px',
  },
  {
    id: 'agencyName',
    align: 'left',
    disablePadding: true,
    label: 'Agency',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'linkedTo',
    align: 'left',
    disablePadding: true,
    label: 'Linked To',
    sortable: true,
    isLink: false,
    width: '104px',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    minHeight: 62,
    maxHeight: '100%',
    overflowX: 'auto',
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
  },
  popover: {
    '& .MuiPopover-paper': {
      width: 140,
    },
  },
  popoverMenuItem: {
    height: 30,
  },
  loadingWrapper: {
    padding: 12,
    textAlign: 'center',
  },
  buttonsWrapper: {
    padding: '16px 8px',
  },
}))

const RenderHeadCellValue = (headCell: HeadCell, orderBy: any) => {
  if (headCell.sortable)
    return (
      <TableSortLabel
        // active={orderBy === headCell.id}
        hideSortIcon={true}
      >
        {headCell.label}
      </TableSortLabel>
    )
  return headCell.label
}

const DeliverablesTableView = (props: any) => {
  const {
    editable,

    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,

    handleEdit,
    handleDelete,
  } = props
  const navigate = useNavigate()
  const { getTokenSilently } = useAuth0()
  const mountedRef = useMounted(true)
  const selectedItem = useRef(null)
  const sentinel: any = useRef(null)
  const classes = useStyles()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('bidNumber')
  const [anchorEl, setAnchorEl] = useState(null)

  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (!searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (!mountedRef.current) return
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [hits])

  const handleClickMore = (e: any, deliverable: any) => {
    e.preventDefault()
    e.stopPropagation()
    selectedItem.current = deliverable
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleViewDeliverable = useCallback((deliverable: IDeliverable, tabName: string) => {
    const cId =
      _get(deliverable, ['project', 'clientId']) ||
      _get(deliverable, ['project', 'brand', 'clientId'])
    const bId =
      _get(deliverable, ['project', 'brandId']) || _get(deliverable, ['project', 'brand', 'id'])
    const pId = _get(deliverable, ['project', 'projectId'])
    navigate(`/${cId}/${bId}/${pId}/${tabName}`)
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="Deliverables Result"
          size={'medium'}
          aria-label="Deliverables Result"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell: HeadCell, i) => (
                <TableCell
                  key={headCell.id}
                  className={classes.th}
                  align={headCell.align as any}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{
                    width: headCell.width || undefined,
                    maxWidth: headCell.maxWidth || undefined,
                  }}
                >
                  {RenderHeadCellValue(headCell, orderBy)}
                </TableCell>
              ))}
              {editable && <TableCell className={classes.th} style={{ width: 30 }}></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {_map(hits, (item: any, i: number) => {
              const record = {...(item || {})}
              if (record.dateCreated === undefined) {
                record.dateCreated = record.created ? dateToString(new Date(parseInt(record.created))) : null
              }
              return (
                <TableRow key={i} tabIndex={-1}>
                  {headCells.map((headCell, j) => (
                    <TableCell
                      key={`deliverable_${i}_${j}`}
                      className={clsx(classes.td, headCell.class)}
                    >
                      {_get(record, headCell.id) || ''} {headCell.suffix}
                    </TableCell>
                  ))}
                  {editable && (
                    <TableCell className={classes.td}>
                      <IconButton
                        aria-label="more action"
                        size="small"
                        onClick={(e) => handleClickMore(e, record)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
      </div>
      {!searching && !_get(hits, 'length') && (
        <div className="pag-text-center" style={{ padding: '24px 12px 12px', fontSize: 16 }}>
          No Deliverables
        </div>
      )}

      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListItem
          className={classes.popoverMenuItem}
          button
          onClick={() => {
            handlePopoverClose()
            handleEdit(selectedItem.current)
          }}
        >
          <ListItemText primary="Edit" />
        </ListItem>
        <ListItem
          className={classes.popoverMenuItem}
          button
          style={{ color: 'red' }}
          onClick={() => {
            handlePopoverClose()
            handleDelete(selectedItem.current)
          }}
        >
          <ListItemText primary="Delete" />
        </ListItem>
      </Popover>
    </div>
  )
}

export default DeliverablesTableView
