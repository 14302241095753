import {
  ProjectCostEntrySchema,
  ProjectCostEntryItemSchema,
  ProjectCostEntryItemOrignalSchema,
  ProjectCostEntryItemOversageSchema,
} from './validations'

const Permission = 'write:Projects'

enum ProjectCostEntryItemType {
  BALLPARK = 'BALLPARK',
  ORIGINAL = 'ORIGINAL',
  ADDREVISION = 'ADDREVISION',
  REVISION = 'REVISION',
  AWARDED = 'AWARDED',
  OVERAGE = 'OVERAGE',
  SAVINGS = 'SAVINGS',
  ACTUAL = 'ACTUAL',
}

export const ProjectCostItemTypesRelatedToBid = [
  ProjectCostEntryItemType.ORIGINAL,
  ProjectCostEntryItemType.REVISION,
  ProjectCostEntryItemType.AWARDED,
]

export {
  Permission,
  ProjectCostEntryItemType,
  ProjectCostEntrySchema,
  ProjectCostEntryItemSchema,
  ProjectCostEntryItemOrignalSchema,
  ProjectCostEntryItemOversageSchema,
}
