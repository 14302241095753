import React, { useEffect, useMemo, useContext, useRef } from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { useNavigate } from 'react-router-dom'
import _find from 'lodash/find'

import useUrlParams from '@/components/hooks/use-url-params'
import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import ProjectsBreadCrumb from '@/routes/projects/components/projects/projects-breadcrumb'
import ProjectsSearchContainer from '@/routes/projects/containers/search-containers/projects-search-container'
import ProjectsCardView from '@/routes/projects/components/projects/projects-card-view'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'

const ProjectsCardViewHits = connectInfiniteHits(
  PagAlgoliaConnectConditionalResults(ProjectsCardView)
)

const ProjectsContainer = () => {
  const { clientId, brandId } = useUrlParams({
    clientId: true,
    brandId: true,
  })
  const navigate = useNavigate()
  const { store, dispatch } = useContext(Context)
  const containerEle: any = useRef(null)
  const { activeClient, activeBrand, projectsViewMode } = store

  useEffect(() => {
    const brand =
      !brandId || !activeClient ? null : _find(activeClient.brands || '', (b) => b.id === brandId)
    dispatch({
      type: 'SetPartial',
      payload: { activeBrand: brand, activeProject: null, activeBid: null },
    })
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClient, brandId])

  const handleViewProject = (project: IProject, tabName: string) => {
    const cId = project?.clientId || project?.brand?.clientId
    navigate(`/${cId}/${project.brandId}/${project.id}/${tabName}`)
  }

  const renderProjectCrumb = useMemo(() => {
    return (
      <ProjectsBreadCrumb
        {...{
          projectsViewMode,
          activeClient,
          activeBrand,
        }}
      />
    )
  }, [projectsViewMode, activeClient, activeBrand])

  const renderProjectsSearch = useMemo(() => {
    const filters = brandId ? `brandId:${brandId}` : clientId ? `clientId:${clientId}` : ''

    return (
      <div className="pag-app-content" ref={containerEle}>
        <ProjectsSearchContainer
          {...{
            filterable: true,
            filters,
            clientId,
            brandId,
          }}
        >
          <ProjectsCardViewHits
            {...{
              handleViewProject,
            }}
          />
        </ProjectsSearchContainer>
      </div>
    )
  }, [clientId, brandId])

  return (
    <>
      {renderProjectCrumb}
      {renderProjectsSearch}
    </>
  )
}

export default ProjectsContainer
