import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

const StyledMenuItem = withStyles(theme => ({
  root: {
    paddingLeft: 8,
    paddingRight: 8,

    '& .MuiListItemText-primary': {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 18,
    },

    '&.active': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 18,
      },
      '& .MuiListItemText-primary': {
        paddingLeft: 0,
      },
    },
  },
}))(MenuItem)

export default StyledMenuItem

// import React from "react";
// import styled from "styled-components";
// import MenuItem from '@material-ui/core/MenuItem';

// export default styled(
//   (props: any) =>
//     <MenuItem
//       {...props} />
//   )`
//   ${({ theme }) => `
//     root: {
//       padding-left: 8,
//       padding-right: 8,

//       '& .MuiListItemText-primary': {
//         margin-top: 0,
//         margin-bottom: 0,
//         padding-left: 18px,
//       },

//       '&.active': {
//         background-color: ${theme.palette.primary.main},
//         '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//           color: ${theme.palette.common.white},
//         },
//         '& .MuiListItemIcon-root': {
//           min-width: 18px,
//         },
//         '& .MuiListItemText-primary': {
//           padding-left: 0,
//         },
//       },
//     },
//   `}
// `;
