import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _get from 'lodash/get'
import _findIndex from 'lodash/findIndex'
import _map from 'lodash/map'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'

import useMounted from '@/components/hooks/use-mounted'
import { HeadCell } from '@/components/pag-table/pag-table-head'
import { dateToString } from '@/utils/date-utils'

const headCells = [
  {
    id: 'adid',
    align: 'left',
    disablePadding: true,
    label: 'Spot Identifier',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '114px',
  },
  {
    id: 'dateCreated',
    align: 'left',
    disablePadding: true,
    label: 'Date Created',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '84px',
  },
  {
    id: 'job_no',
    align: 'left',
    disablePadding: true,
    label: 'Job No',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '114px',
  },
  {
    id: 'ad_title',
    align: 'left',
    disablePadding: true,
    label: 'Spot Name',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
  },
  {
    id: 'length',
    align: 'left',
    disablePadding: true,
    label: 'Length',
    sortable: true,
    isLink: false,
    width: '96px',
  },
  {
    id: 'code_record_type',
    align: 'left',
    disablePadding: true,
    label: 'Spot Type',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '85px',
  },
  {
    id: 'media_type',
    align: 'left',
    disablePadding: true,
    label: 'Media Type',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
    width: '85px',
  },
  {
    id: 'medium.0.medium',
    align: 'left',
    disablePadding: true,
    label: 'Medium',
    sortable: false,
    isLink: false,
    class: 'pag-text-wrap',
    width: '85px',
  },
  {
    id: 'language',
    align: 'left',
    disablePadding: true,
    label: 'Language',
    sortable: true,
    isLink: false,
    width: '74px',
  },
  {
    id: 'agency_name',
    align: 'left',
    disablePadding: true,
    label: 'Agency',
    sortable: true,
    isLink: false,
    class: 'pag-text-wrap',
    width: '84px',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    minHeight: 62,
    maxHeight: '100%',
    overflowX: 'auto',
  },
  th: {
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
  },
  tdCheckbox: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 6,
    paddingTop: 6,
    verticalAlign: 'bottom',
    minWidth: 24,
    width: 24,
  },
  popover: {
    '& .MuiPopover-paper': {
      width: 140,
    },
  },
  popoverMenuItem: {
    height: 30,
  },
  loadingWrapper: {
    padding: 12,
    textAlign: 'center',
  },
  buttonsWrapper: {
    padding: '16px 8px',
  },
}))

const RenderHeadCellValue = (headCell: HeadCell, orderBy: any) => {
  if (headCell.sortable)
    return (
      <TableSortLabel
        // active={orderBy === headCell.id}
        hideSortIcon={true}
      >
        {headCell.label}
      </TableSortLabel>
    )
  return headCell.label
}

const AdIDTableView = (props: any) => {
  const {
    selectedHits,
    hits,
    hasMore,
    hasPrevious,

    refine,
    refineNext,
    refinePrevious,

    searching,
    searchPage,
    nbPages,

    refresh,
    handleForceRefresh,
    handleSelectHit,
  } = props

  const mountedRef = useMounted(true)
  const tableWrapperEleRef = useRef(null as any)
  const sentinel: any = useRef(null)
  const classes = useStyles()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('bidNumber')

  const onSentinelIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      const page = searchPage || 0
      if (Boolean(refine) && !searching && page < nbPages && entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  const observer = new IntersectionObserver(onSentinelIntersection)

  useEffect(() => {
    if (refresh && tableWrapperEleRef.current) {
      tableWrapperEleRef.current.scrollTop = 0
    }
    return () => {}
  }, [refresh])

  useEffect(() => {
    if (sentinel && sentinel.current) {
      observer.observe(sentinel.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [hits])

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper} ref={tableWrapperEleRef}>
        <Table
          className={classes.table}
          aria-labelledby="Ad_ID Search Result"
          size={'medium'}
          aria-label="Ad_ID Search Result"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tdCheckbox}></TableCell>
              {headCells.map((headCell: HeadCell, i) => (
                <TableCell
                  key={headCell.id}
                  className={classes.th}
                  align={headCell.align as any}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{ width: headCell.width || undefined }}
                >
                  {RenderHeadCellValue(headCell, orderBy)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {_map(hits, (item: any, i: number) => {
              const record = {...(item || {})}
              if (record.dateCreated === undefined) {
                record.dateCreated = record.created ? dateToString(new Date(parseInt(record.created))) : null
              }

              return (
                <TableRow key={i} tabIndex={-1}>
                  <TableCell className={classes.tdCheckbox}>
                    <Checkbox
                      className="pag-checkbox"
                      checked={_findIndex(selectedHits, (h: any) => h.adid === record.adid) >= 0}
                      inputProps={{ 'aria-labelledby': record.adid }}
                      onClick={(e) => handleSelectHit(record)}
                    />
                  </TableCell>
                  {headCells.map((headCell, j) => (
                    <TableCell
                      key={`deliverable_${i}_${j}`}
                      className={clsx(classes.td, headCell.class)}
                    >
                      {_get(record, headCell.id) || ''}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <div tabIndex={-1} ref={sentinel} className="pag-infinite-sentinel"></div>
      </div>
      {!searching && !_get(hits, 'length') && (
        <div className="pag-text-center" style={{ padding: '24px 12px 12px', fontSize: 16 }}>
          {Boolean(refine) ? 'No Ad_ID records' : 'Start your search'}
        </div>
      )}
    </div>
  )
}

export default AdIDTableView
