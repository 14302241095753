import React, { useContext, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'

import { ProjectItemWidgetContext } from '@/widgets/project-item-widget'
import { dateToString } from '@/utils/date-utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  projectTeamMember: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  iconButton: {
    width: 10,
  },
}))

const ProjectItemShootsComponent = (props: any) => {
  const {
    name,
    InputProps,
    showAddButton,
    handleCancel,
    handleChange,
    handleEdit,
    handleAdd,
  } = props

  const classes = useStyles()
  const { cities, countries, shootTypes } = useContext(ProjectItemWidgetContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const currentItem = useRef(null as any)

  const getCountry = (countryId: number) => {
    if (!countryId) return null
    const temp = (countries || []).find((o: any) => o.id === countryId)
    return temp?.country || null
  }

  const getCity = (cityId: number) => {
    if (!cityId) return null
    const temp = (cities || []).find((o: any) => o.id === cityId)
    return temp?.name || null
  }

  const getShootType = (shootTypeId: number) => {
    if (!shootTypeId) return null
    const temp = (shootTypes || []).find((o: any) => o.value === shootTypeId)
    return temp?.name || null
  }

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleRemoveShoot = (index: number) => {
    handleCloseMenu()
    handleChange(InputProps.value.filter((p: any, i: number) => i !== index))
  }

  return (
    <Grid container spacing={2}>
      {Boolean(InputProps?.value?.length) ? (
        <>
          {InputProps.value.map((shoot: any, i: number) => (
            <Grid
              key={`shoots-${i}`}
              item
              xs={12}
              className={classes.projectTeamMember}
              onMouseEnter={() => {
                currentItem.current = { data: shoot, index: i }
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3} className={'pag-text-ellipsis'}>
                  {dateToString(shoot.shootDate) || ''}
                </Grid>

                <Grid item xs={8} className={'pag-text-ellipsis'}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Location {getCity(shoot.cityId)}, {getCountry(shoot.countryId)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="caption">
                            Pre-Light Hours {shoot.preLightHrs}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="caption">Shoot Days {shoot.shootHrs}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="inherit"
                    disableFocusRipple
                    size="small"
                    className={classes.iconButton}
                    onClick={handleOpenMenu}
                  >
                    <MoreVertIcon style={{ fontSize: 14 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu()
                handleEdit(currentItem.current)
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={(e: any) => {
                if (currentItem.current && currentItem.current.index >= 0)
                  handleRemoveShoot(currentItem.current.index)
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography color="inherit" variant="subtitle1" align="center">
            <b>You haven't added</b>
            <br />
            <b>shoots yet.</b>
          </Typography>
          {!showAddButton && (
            <Typography color="inherit" variant="subtitle2" align="center">
              Click the + sign to add your first shoot.
            </Typography>
          )}
        </Grid>
      )}
      {showAddButton && (
        <Grid item xs={12} className="pag-text-center">
          <Button color="primary" variant="outlined" onClick={handleAdd}>
            Add
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

ProjectItemShootsComponent.defaultProps = {
  handleChange: (e: any) => {},
  handleCancel: (e: any) => {},
}

export default ProjectItemShootsComponent
