import gql from 'graphql-tag'

const GET_CURRENCIES = gql`
  query currencies($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    currencies(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        currency
      }
    }
  }
`

export { GET_CURRENCIES }
