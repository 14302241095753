import React, { createContext, useContext, useMemo, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagCircularIndeterminate from '@/components/pag-loading'
import BudgetsBreadCrumb from '../components/budgets-breadcrumb'
import BudgetsTableView from '../components/budgets-table-view'
import POsDialog from '../components/purchase-orders-dialog'
import { IProject } from '@/shared/models/project'
import { GET_PROJECTS_FOR_VIEWS, GET_PROJECT_FOR_VIEWS } from '@/shared/graphql/queryGetProjects'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'

export const BudgetsContainerContext = createContext<{
  setResultsNum: (num: number) => void
  handleMorePOs: (p: IProject) => void
}>({
  setResultsNum: (num: number) => {},
  handleMorePOs: (p) => {},
})

const BudgetsContainer = () => {
  const mountedRef = useMounted(true)
  const pagination = useRef({
    nbPages: 1,
    offset: 0,
    limit: 20,
    hasMore: true,
  })
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, activeClient } = store
  const [projects, setProjects] = useState<{ total: number; data: IProject[] }>({
    total: 1,
    data: [],
  })
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null)

  const [projectsQuery, projectsQueryRes] = useLazyQuery(GET_PROJECTS_FOR_VIEWS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = res.projects || { data: [], total: 0 }

      setProjects({
        total: temp.total,
        data: [...projects.data, ...temp.data],
      })

      if (temp.data.length) {
        pagination.current = {
          nbPages: Math.ceil(temp.total / pagination.current.limit),
          offset: pagination.current.offset + Math.min(pagination.current.limit, temp.data.length),
          limit: pagination.current.limit,
          hasMore: temp.data.length >= pagination.current.limit,
        }
      } else {
        pagination.current = {
          ...pagination.current,
          nbPages: pagination.current.offset === 0 ? 0 : pagination.current.nbPages,
          hasMore: false,
        }
      }
    },
    fetchPolicy: 'network-only',
  })

  const [projectQuery, projectQueryRes] = useLazyQuery(GET_PROJECT_FOR_VIEWS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      if (res.project) {
        const tempIndex = projects.data.findIndex((p) => p.id === res.project.id)
        if (tempIndex >= 0) {
          setProjects({
            total: projects.total,
            data: [
              ...projects.data.splice(0, tempIndex),
              res.project,
              ...projects.data.splice(tempIndex + 1),
            ],
          })
        }
      }
    },
    fetchPolicy: 'network-only',
  })

  const loadMore = () => {
    if (!mountedRef.current || !activeTenant?.id || projectsQueryRes.loading) return
    const abortController = new AbortController()
    const callApi = async () => {
      try {
        await callPagApi(
          '',
          getTokenSilently,
          projectsQuery,
          {
            tenantId: activeTenant.id,
            clientId: activeClient.id,
            approved: false,
            expandProjectTeams: true,
            offset: pagination.current.offset,
            limit: pagination.current.limit,
          },
          abortController.signal
        )
      } catch (e) {}
    }

    callApi()
  }

  const handleClosePOsDialog = () => {
    if (!mountedRef.current || !selectedProject || !activeTenant?.id) return
    const abortController = new AbortController()
    const callApi = async () => {
      try {
        await callPagApi(
          '',
          getTokenSilently,
          projectQuery,
          {
            tenantId: activeTenant.id,
            id: selectedProject.id,
            expandProjectTeams: true,
          },
          abortController.signal
        )
      } catch (e) {}
    }

    callApi()
    setSelectedProject(null)
  }

  const renderBreadCrumb = useMemo(() => {
    return <BudgetsBreadCrumb resultNum={projects.total} />
  }, [projects])

  return (
    <div>
      {renderBreadCrumb}
      <BudgetsTableView
        hits={projects.data}
        searching={projectsQueryRes.loading}
        nbPages={pagination.current.nbPages}
        hasMore={pagination.current.hasMore}
        refineNext={loadMore}
        handleMorePOs={(p: IProject) => setSelectedProject(p)}
      />
      {projectsQueryRes.loading && <PagCircularIndeterminate size={24} />}
      <POsDialog
        open={Boolean(selectedProject)}
        project={selectedProject}
        handleClose={() => handleClosePOsDialog()}
      />
    </div>
  )
}

export default BudgetsContainer
