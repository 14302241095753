import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 120,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

export default StyledMenu

// import styled from "styled-components";
// export default styled(
//   (props) =>
//     <Menu
//       elevation={0}
//       getContentAnchorEl={null}
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'center',
//       }}
//       transformOrigin={{
//         vertical: 'top',
//         horizontal: 'center',
//       }}
//       {...props} />
//   )`
//   ${({ theme }) => `
//     paper: {
//       border: 1px solid #d3d4d5,
//       min-width: 120px,
//     },
//     list: {
//       padding-top: 0,
//       padding-bottom: 0,
//     }
//   `}
// `;
