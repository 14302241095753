import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Theme } from '@material-ui/core'

import useMounted from '@/components/hooks/use-mounted'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagCircularIndeterminate from '@/components/pag-loading'
import { useAuth0 } from '@/auth/index'
import { errorHandler } from '@/shared/error-handler'
import { callPagApi } from '@/shared/services/pag-api'
import { Context } from '@/shared/store/reducers/global-reducer'
import { GET_PROJECT_COST_TYPES } from '@/shared/graphql/queryGetProjectCostTypes'
import { GET_CURRENCIES } from '@/shared/graphql/queryGetCurrencies'
import { GET_VENDORS } from '@/shared/graphql/queryGetVendors'
import {
  ADD_PROJECT_COST_ENTRY,
  UPDATE_PROJECT_COST_ENTRY,
} from '@/shared/graphql/mutations/mutationProjectCostEntry'
import { ICurrency } from '@/shared/models/currency'
import { IProjectCostType } from '@/shared/models/project-cost-type'
import { IVendor } from '@/shared/models/vendor'
import { ProjectCostEntrySchema } from '../config/validations'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    borderWidth: 'thin',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderColor: '#dbdbdb',
    color: '#575757',
    minHeight: 28,
    height: 28,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200],
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  td: {
    fontSize: '1rem',
    position: 'relative',
    padding: 2,
    borderBottom: 'none',
    lineHeight: 2,
  },

  projectCostType: {
    width: '30%',
  },
  description: {
    width: '45%',
  },
  vendor: {
    width: '25%',
  },

  projectCostEntryAdd: {
    textAlign: 'center',
    paddingTop: 4,
  },

  editContainer: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    maxWidth: 320,
    minWidth: 320,
    overflow: 'visible',
    zIndex: 1001,
  },
  form: {
    display: 'block',
  },
  cardContent: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
}))

const ProjectCostEntryEditComponent = (props: any) => {
  const { projectCostId, projectCostEntry, handleSubmit: onHandleSubmit, handleCancel } = props
  const classes = useStyles(props)
  const mountedRef = useMounted(true)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      projectCostTypeId: projectCostEntry?.projectCostTypeId || null,
      description: projectCostEntry?.description || '',
      vendorId: projectCostEntry?.vendorId || null,
      currencyId: projectCostEntry?.currencyId || null,
    },
    resolver: yupResolver(ProjectCostEntrySchema),
  })

  const {
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = hookFormMethods
  const [projectCostTypeOptions, setProjectCostTypeOptions] = useState<IProjectCostType[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<ICurrency[]>([])
  const [vendorOptions, setVendorOptions] = useState<IVendor[]>([])

  const [getProjectCostTypes, getProjectCostTypesRes] = useLazyQuery(GET_PROJECT_COST_TYPES, {
    onError: (err) => {
      if (mountedRef.current) {
        setProjectCostTypeOptions([])
      }
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setProjectCostTypeOptions(_orderBy(res.projectCostTypes?.data || [], ['name']))
    },
    fetchPolicy: 'network-only',
  })

  const [getVendors, getVendorsRes] = useLazyQuery(GET_VENDORS, {
    onError: (err) => {
      if (mountedRef.current) {
        setVendorOptions([])
      }
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setVendorOptions(_orderBy(res.vendors?.data || [], ['name']))
    },
    fetchPolicy: 'network-only',
  })

  const [getCurrencies, getCurrenciesRes] = useLazyQuery(GET_CURRENCIES, {
    onError: (err) => {
      if (mountedRef.current) {
        setCurrencyOptions([])
      }
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setCurrencyOptions(_orderBy(res.currencies?.data || [], ['currency']))
    },
    fetchPolicy: 'network-only',
  })

  const [addProjectCostEntry, addProjectCostEntryRes] = useMutation(ADD_PROJECT_COST_ENTRY, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = _get(res, ['addProjectCostEntry'])
      if (temp) {
        onHandleSubmit(temp)
      }
    },
  })

  const [updateProjectCostEntry, updateProjectCostEntryRes] = useMutation(
    UPDATE_PROJECT_COST_ENTRY,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        const temp = _get(res, ['updateProjectCostEntry'])
        if (temp) {
          onHandleSubmit(temp)
        }
      },
    }
  )

  useEffect(() => {
    if (!activeTenant?.id) return
    let abortController: any
    try {
      abortController = new AbortController()
      const { signal } = abortController
      callPagApi('', getTokenSilently, getVendors, { tenantId: activeTenant.id }, signal)
      callPagApi('', getTokenSilently, getProjectCostTypes, { tenantId: activeTenant.id }, signal)
      callPagApi('', getTokenSilently, getCurrencies, { tenantId: activeTenant.id }, signal)
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant])

  useEffect(() => {
    if (!projectCostEntry?.currencyId) {
      const temp = _find(currencyOptions, (c: ICurrency) => c.currency === 'USD')
      if (temp) {
        setValue('currencyId', temp.id)
      }
    } else {
      setValue('currencyId', projectCostEntry.currencyId)
    }
    return () => {}
  }, [projectCostEntry, currencyOptions])

  const onSubmit = (data: any) => {
    let abortController: any
    try {
      abortController = new AbortController()
      const { signal } = abortController

      const payload: any = {
        tenantId: activeTenant?.id,
        projectCostId: projectCostId,
        projectCostTypeId: data.projectCostTypeId,
        vendorId: Number.isInteger(data.vendorId) ? data.vendorId || null : null,
        currencyId: Number.isInteger(data.currencyId) ? data.currencyId || null : null,
        description: data.description || null,
      }

      if (projectCostEntry && projectCostEntry.id) {
        payload['id'] = projectCostEntry.id
        callPagApi('', getTokenSilently, updateProjectCostEntry, payload, signal)
      } else {
        callPagApi('', getTokenSilently, addProjectCostEntry, payload, signal)
      }
    } catch (err) {
      console.error('Error in Add Project', err)
      if (abortController) abortController.abort()
    }
  }

  return (
    <Card
      className={clsx(classes.editContainer)}
      elevation={24}
      variant="outlined"
      onClick={(e) => e.stopPropagation()}
    >
      <form name="ProjectCostWidgetForm" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <CardContent className={classes.cardContent}>
          {(getVendorsRes.loading ||
            getProjectCostTypesRes.loading ||
            getCurrenciesRes.loading) && <PagCircularIndeterminate size={24} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'projectCostTypeId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: projectCostTypeOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IProjectCostType) => option.name,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: IProjectCostType, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'TYPE',
                        placeholder: 'TYPE',
                      },
                    }}
                  />
                )}
                control={control}
                // onChange={([ event ]) => { console.log(event); return event}}
                // rules={{ required: true }}
                name="projectCostTypeId"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState }) => (
                  <PagTextInput
                    label="Description"
                    placeholder="Description"
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`description`}
                defaultValue={_get(projectCostEntry, ['description'])}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'vendorId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: vendorOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IVendor) => option.name,
                        size: 'small',
                        disableClearable: false,
                        onChange: (event: object, values: IVendor, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'VENDOR',
                        placeholder: 'VENDOR',
                      },
                    }}
                  />
                )}
                control={control}
                // onChange={([ event ]) => { return event}}
                // rules={{ required: true }}
                name="vendorId"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'currencyId',
                      optionLabelKey: 'currency',
                      optionValueKey: 'id',
                      options: currencyOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: ICurrency) => option.currency,
                        getOptionSelected: (option: ICurrency, value: number) =>
                          option.id === value,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: ICurrency, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'currency',
                        placeholder: 'currency',
                      },
                    }}
                  />
                )}
                control={control}
                // onChange={([ event ]) => { return event}}
                // rules={{ required: true }}
                name="currencyId"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.cardAction}>
          <Button
            size="medium"
            aria-label="cancel"
            variant="contained"
            className={classes.button}
            disabled={addProjectCostEntryRes.loading || updateProjectCostEntryRes.loading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            size="medium"
            type="submit"
            aria-label="update"
            variant="contained"
            className={classes.button}
            disabled={
              !isValid || addProjectCostEntryRes.loading || updateProjectCostEntryRes.loading
            }
          >
            Update
            {(addProjectCostEntryRes.loading || updateProjectCostEntryRes.loading) && (
              <CircularProgress size={16} color="primary" className={classes.circularProgress} />
            )}
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

const ProjectCostEntryComponent = (props: any) => {
  const {
    projectCostId,
    projectCostEntry,

    handleSubmit,
    handleCancel,
  } = props

  const classes = useStyles(props)
  const [editingStatus, setEditingStatus] = useState(false)

  return (
    <ClickAwayListener
      onClickAway={(e: any) => {
        const isDialog = _findIndex(e.path, (ele: any) => {
          return (
            ele.className &&
            typeof ele.className === 'string' &&
            (ele.className.indexOf('MuiDialog') >= 0 ||
              ele.className.indexOf('MuiAutocomplete-popper') >= 0)
          )
        })

        if (isDialog < 0) setEditingStatus(false)
      }}
    >
      <Paper
        className={clsx(classes.root)}
        elevation={0}
        square
        onClick={() => setEditingStatus(!editingStatus)}
      >
        <Table className={classes.table}>
          <TableBody>
            {projectCostEntry ? (
              <TableRow>
                <TableCell className={clsx(classes.td, classes.projectCostType)}>
                  <div className={clsx('pag-text-ellipsis')}>
                    {_get(projectCostEntry, ['projectCostType', 'name']) || ''}
                  </div>
                </TableCell>
                <TableCell className={clsx(classes.td, classes.description)}>
                  <div className={clsx('pag-text-ellipsis')}>
                    {_get(projectCostEntry, ['description']) || ''}
                  </div>
                </TableCell>
                <TableCell className={clsx(classes.td, classes.vendor)}>
                  <div className={clsx('pag-text-ellipsis')}>
                    {_get(projectCostEntry, ['vendor', 'name']) || ''}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell
                  className={clsx(classes.td)}
                  // className={clsx(classes.projectCostEntryAdd)}
                  colSpan={0}
                  align="center"
                >
                  <Icon color="primary">add</Icon>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {editingStatus && (
          <ProjectCostEntryEditComponent
            projectCostId={projectCostId}
            projectCostEntry={projectCostEntry}
            handleSubmit={(p: any) => {
              setEditingStatus(false)
              handleSubmit(p)
            }}
            handleCancel={() => {
              setEditingStatus(false)
            }}
          />
        )}
      </Paper>
    </ClickAwayListener>
  )
}

ProjectCostEntryComponent.propTypes = {
  projectCostId: PropTypes.number,
  projectCostEntry: PropTypes.object,
  currency: PropTypes.object,
  editable: PropTypes.bool,

  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

ProjectCostEntryComponent.defaultProps = {
  projectCostEntry: null,

  handleSubmit: (e: any) => {},
  handleCancel: () => {},
}

export default ProjectCostEntryComponent
