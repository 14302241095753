import React, { useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import _orderBy from 'lodash/orderBy'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'

import useMounted from '@/components/hooks/use-mounted'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import PagNumberFormatInput from '@/components/pag-form/pag-number-format-Input'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { errorHandler } from '@/shared/error-handler'
import { GET_CURRENCIES } from '@/shared/graphql/queryGetCurrencies'
import {
  ADD_PURCHASE_ORDER as ADD_PO,
  UPDATE_PURCHASE_ORDER as UPDATE_PO,
} from '@/shared/graphql/mutations/mutationPurchaseOrders'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { ICurrency } from '@/shared/models/currency'
import { IPurchaseOrder as IPO } from '@/shared/models/purchase-order'
import { dateToString, dateStringToDate } from '@/utils/date-utils'
import { stringToNumber } from '@/utils/numeric'

const FormSchema = yup.object().shape({
  poNumber: yup.string().required('It is required.'),
  beginDate: yup.string().required('Begin Date is required.'),
  endDate: yup.string().required('End Date is required.'),
})

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    borderBottom: '1px solid',
    padding: 12,
  },
  dialogContent: {
    padding: '24px 16px 16px',
  },
  dialogAction: {
    padding: 16,
  },
  closeButton: {
    fontSize: 18,
    position: 'absolute',
    right: 2,
    top: 2,
    color: 'black',
  },
}))

const POEditDialog: React.FC<{
  open: boolean
  po: IPO | null
  projectId: number
  status: 'edit' | 'new' | 'revise'
  title: string
  handleClose: (data: { isNew?: boolean; isUpdate?: boolean; data?: IPO } | null) => void
  handleDelete: (po: IPO) => void
}> = ({ open, po, projectId, status, title, handleClose, handleDelete }) => {
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, activeProject, activeClient } = store
  const mountedRef = useMounted(true)
  const [currencyOptions, setCurrencyOptions] = useState<ICurrency[]>([])
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema),
  })

  const {
    control,
    formState: { errors, isValid },
    reset,
    handleSubmit,
  } = hookFormMethods

  const [getCurrencies, getCurrenciesRes] = useLazyQuery(GET_CURRENCIES, {
    onError: (err) => {
      if (mountedRef.current) {
        setCurrencyOptions([])
      }
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setCurrencyOptions(_orderBy(res.currencies?.data || [], ['currency']))
    },
    fetchPolicy: 'network-only',
  })
  const [addPO, addPORes] = useMutation(ADD_PO, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (res.addPurchaseOrder) {
        handleClose({ isNew: true, data: res.addPurchaseOrder })
      }
    },
  })
  const [updatePO, updatePORes] = useMutation(UPDATE_PO, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (res.updatePurchaseOrder) {
        handleClose({ isUpdate: true, data: res.updatePurchaseOrder })
      }
    },
  })

  useEffect(() => {
    if (!open) return
    const usd = currencyOptions.find((c) => c.currency === 'USD')
    if (status === 'new') {
      reset({
        poNumber: '',
        beginDate: activeProject?.fundingBeginDate || null,
        endDate: activeProject?.fundingEndDate || null,
        amount: activeProject?.frAmount || '',
        currencyId: usd?.id,
      })
    } else {
      reset({
        poNumber: po?.poNumber || '',
        beginDate: status === 'revise' ? null : dateStringToDate(po?.beginDate),
        endDate: status === 'revise' ? null : dateStringToDate(po?.endDate),
        amount: status === 'revise' ? '' : po?.amount || '',
        currencyId: po?.currencyId || usd?.id,
      })
    }

    return () => {}
  }, [open, status, activeProject, currencyOptions, reset, po?.poNumber, po?.beginDate, po?.endDate, po?.amount, po?.currencyId])

  useEffect(() => {
    if (!activeTenant?.id) return
    let abortController: any
    try {
      abortController = new AbortController()
      const { signal } = abortController
      callPagApi('', getTokenSilently, getCurrencies, { tenantId: activeTenant.id }, signal)
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant])

  const handleSubmitForm = (formData: any) => {
    if (!activeTenant?.id) return
    const abortController = new AbortController()
    try {
      const callApi = async (data: any) => {
        try {
          await callPagApi(
            '',
            getTokenSilently,
            payload.id && status === 'edit' ? updatePO : addPO,
            data,
            abortController.signal
          )
        } catch (e) {}
      }

      const payload: any = {
        tenantId: activeTenant.id,
        id: po?.id,
        data: {
          poNumber: formData.poNumber,
          beginDate: dateToString(formData.beginDate),
          endDate: dateToString(formData.endDate),
          amount: stringToNumber(formData.amount, 'float'),
          projectId,
        },
      }

      callApi(payload)
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const poName = activeClient?.purchaseOrderName || 'Purchase Order'

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="po-edit-dialog-title"
    >
      <DialogTitle id="po-edit-dialog-title" className={classes.dialogTitle}>
        {title}
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose(null)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form
          name="PurchaseOrderForm"
          onSubmit={handleSubmit(handleSubmitForm)}
          // onMouseEnter={(e) => e.stopPropagation()}
          // onMouseLeave={(e) => handleMouseLeave(e)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagTextInput
                    label={`${poName} Number`}
                    placeholder={`${poName} Number`}
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    InputProps={{
                      readOnly: status === 'revise',
                    }}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name="poNumber"
                defaultValue={po?.poNumber || ''}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagTextInput
                    label="Amount"
                    placeholder="Amount"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      name: 'amount',
                      inputComponent: PagNumberFormatInput,
                      inputProps: {
                        thousandSeparator: true,
                        isNumericString: true,
                        decimalScale: 2,
                      },
                    }}
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name="amount"
                defaultValue={po?.amount || ''}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'currencyId',
                      optionLabelKey: 'currency',
                      optionValueKey: 'id',
                      options: currencyOptions,
                      error: fieldState.error,
                      value: field.value || null,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: ICurrency) => option.currency,
                        getOptionSelected: (option: ICurrency, value: number) =>
                          option.id === value,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: ICurrency, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'currency',
                        placeholder: 'currency',
                      },
                    }}
                  />
                )}
                control={control}
                name="currencyId"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagDatePicker
                    key="PO-beginDate"
                    margin="none"
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    value={field.value}
                    inputVariant="outlined"
                    allowKeyboardControl={true}
                    size="small"
                    // fullWidth
                    onChange={(e: any) => {
                      field.onChange(e)
                    }}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name="beginDate"
                defaultValue={po?.beginDate || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagDatePicker
                    key="PO-endDate"
                    margin="none"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={field.value}
                    inputVariant="outlined"
                    allowKeyboardControl={true}
                    size="small"
                    // fullWidth
                    onChange={(e: any) => {
                      field.onChange(e)
                    }}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name="endDate"
                defaultValue={po?.endDate || ''}
              />
            </Grid>
            <Grid item xs={12} className="pag-text-right">
              {Boolean(po) && status === 'edit' && (
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  style={{ float: 'left', marginRight: 12 }}
                  onClick={() => handleDelete(po as IPO)}
                >
                  Delete
                </Button>
              )}
              <Button variant="text" color="inherit" onClick={() => handleClose(null)}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="pag-button"
                disabled={!isValid || addPORes.loading || updatePORes.loading}
              >
                {addPORes.loading || updatePORes.loading ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  'Save'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default POEditDialog
