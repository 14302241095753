import gql from 'graphql-tag'

const GET_CLIENTS_WITH_BRANDS = gql`
  query clientsWithBrands(
    $tenantId: Int!
    $slug: String
    $uploadPortalSlug: String
    $offset: Int
    $limit: Int
  ) {
    clientsWithBrands(
      tenantId: $tenantId
      slug: $slug
      uploadPortalSlug: $uploadPortalSlug
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        defaultProjectImage
        purchaseOrderName
        slug
        calendarWidget
        uploadPortalImage
        uploadPortalLoginImage
        uploadPortalTitle
        uploadPortalDescription
        uploadPortalSlug
        uploadPortalFields
        uploadPortalSidebarBackgroundColor
        uploadPortalSidebarTextColor
        brands {
          id
          name
          clientId
        }
      }
    }
  }
`

const GET_CLIENTS = gql`
  query clients(
    $tenantId: Int!
    $slug: String
    $uploadPortalSlug: String
    $offset: Int
    $limit: Int
  ) {
    clients(
      tenantId: $tenantId
      slug: $slug
      uploadPortalSlug: $uploadPortalSlug
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        calendarWidget
        defaultProjectImage
        purchaseOrderName
        slug
        tenantId
        uploadPortalImage
        uploadPortalLoginImage
        uploadPortalTitle
        uploadPortalDescription
        uploadPortalSlug
        uploadPortalFields
        uploadPortalSidebarBackgroundColor
        uploadPortalSidebarTextColor
      }
    }
  }
`

const GET_CLIENT_BY_UPLOAD_PORTAL_SLUG = gql`
  query clientByUploadPortalSlug($uploadPortalSlug: String) {
    clientByUploadPortalSlug(uploadPortalSlug: $uploadPortalSlug) {
      client {
        id
        name
        calendarWidget
        defaultProjectImage
        purchaseOrderName
        slug
        tenantId
        tenant {
          id
          name
        }
        uploadPortalImage
        uploadPortalLoginImage
        uploadPortalTitle
        uploadPortalDescription
        uploadPortalSlug
        uploadPortalFields
        uploadPortalSidebarBackgroundColor
        uploadPortalSidebarTextColor
      }
      roles
      permissions
    }
  }
`

const GET_CLIENT = gql`
  query client($tenantId: Int!, $id: String!) {
    client(tenantId: $tenantId, id: $id) {
      id
      name
      calendarWidget
      defaultProjectImage
      purchaseOrderName
      slug
      uploadPortalImage
      uploadPortalLoginImage
      uploadPortalTitle
      uploadPortalDescription
      uploadPortalSlug
      uploadPortalFields
      uploadPortalSidebarBackgroundColor
      uploadPortalSidebarTextColor
    }
  }
`

export { GET_CLIENTS_WITH_BRANDS, GET_CLIENTS, GET_CLIENT_BY_UPLOAD_PORTAL_SLUG, GET_CLIENT }
