import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import useUrlParams from '@/components/hooks/use-url-params'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'
import UploadPortalSearchProjectForm from './components/search-project-form'

const UploadPortalSearchProject = (props: any) => {
  const { projectNumber, setProjectNumber, setProject } = props
  const { slug } = useUrlParams({ slug: false })
  const navigate = useNavigate()
  const { store } = useContext(Context)
  const { activeClient } = store

  return (
    <UploadPortalSearchProjectForm
      client={activeClient}
      projectNumber={projectNumber}
      handleNext={(e: { projectNumber: string; project: IProject | null }) => {
        setProjectNumber(e.projectNumber)
        setProject(e.project)
        if (e.project) {
          navigate(`/p/${slug}/upload-files`)
        } else {
          navigate(`/p/${slug}/new-project`)
        }
      }}
    />
  )
}

export default UploadPortalSearchProject
