import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _range from 'lodash/range'
import _toUpper from 'lodash/toUpper'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Theme } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import DeleteIcon from '@material-ui/icons/Delete'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagAlertDialog from '@/components/pag-alert'
import PagCircularIndeterminate from '@/components/pag-loading'
import ProjectCostEntry from './components/project-cost-entry-component'
import ProjectCostEntryItem from './components/project-cost-entry-item-component'
import { errorHandler } from '@/shared/error-handler'
import { GET_PROJECT_COST_BY_PROJECT } from '@/shared/graphql/queryGetProjectCostByProject'
import { DELETE_PROJECT_COST_ENTRY } from '@/shared/graphql/mutations/mutationProjectCostEntry'
import { SORT_PROJECT_COST_ENTRIES } from '@/shared/graphql/mutations/mutationSortProjectCostEntries'
import { CURRENCIES } from '@/shared/constants/currency'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { reorderArray } from '@/utils/array-utils'
import { dateToString } from '@/utils/date-utils'
import { stringToNumber } from '@/utils/numeric'
import { WidgetConfigType, WidgetConfigDefaultProps } from '../config'
import { ProjectCostEntryItemType } from './config'

const ProjectCostEntryItemTypes = [
  { type: ProjectCostEntryItemType.BALLPARK, sequence: 0 },
  { type: ProjectCostEntryItemType.ORIGINAL, sequence: 0 },
  { type: ProjectCostEntryItemType.ADDREVISION, sequence: 0 },
  { type: ProjectCostEntryItemType.AWARDED, sequence: 0 },
  { type: ProjectCostEntryItemType.OVERAGE, sequence: 0 },
  { type: ProjectCostEntryItemType.SAVINGS, sequence: 0 },
  { type: ProjectCostEntryItemType.ACTUAL, sequence: 0 },
]

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  divider: {
    height: 4,
    backgroundColor: theme.palette.text.primary,
  },

  projectCostEntryItem: {
    position: 'relative',
    // color: '#575757',
    // color: '#b2b2b2',
  },

  tableContainer: {
    overflowX: 'unset',
  },

  table: {
    tableLayout: 'fixed',
  },

  tr: {
    '& .project-cost-entry-delete-button': {
      display: 'none',
    },
    '&:hover .project-cost-entry-delete-button': {
      display: 'inline-flex',
    },
  },
  th: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 2,
    position: 'relative',
    padding: 4,
  },
  td: {
    fontSize: '1rem',
    position: 'relative',
    lineHeight: 2,
    padding: 2,
    borderBottom: 'none',
  },
  projectCostEntryItemCell: {
    minWidth: 20,
    maxWidth: 80,

    '&.add-revision': {
      padding: '0!important',
      maxWidth: 1,
      minWidth: 1,
      width: 1,
      textAlign: 'center',
      zIndex: 1,

      '& .add-revision-icon-button': {
        cursor: 'pointer',
        height: '100%',
        maxWidth: 20,
        display: 'none',
      },

      '&::before': {
        content: `""`,
        position: 'absolute',
        top: 0,
        left: -6,
        height: '100%',
        width: 6,
      },
      '&::after': {
        content: `""`,
        position: 'absolute',
        top: 0,
        rigth: -6,
        height: '100%',
        width: 6,
      },
      '&:hover': {
        width: 20,
        maxWidth: 20,
        minWidth: 20,
        backgroundColor: theme.palette.grey[300],
        '& .add-revision-icon-button': {
          display: 'inline-flex',
        },
        '& .MuiButtonBase-root': {
          borderRadius: 0,
        },
      },
    },
  },

  cellValue: {
    textAlign: 'right',
  },
  totalValue: {
    borderWidth: 'thin',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderColor: '#dbdbdb',
    fontWeight: 500,
    padding: 2,
    height: 28,
    minHeight: 28,
    textAlign: 'right',
  },

  editCell: {
    minWidth: 46,
    maxWidth: 46,
    width: 46,
  },
}))

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    display: 'flex',
    // background: "rgb(235,235,235)",
  }),
})

const ProjectCostEntryRow = (props: any) => {
  const {
    projectCostEntriesWrapperEleRef,
    project,
    projectCost,
    projectCostEntryItemTypes,
    entryCellWidth,
    cellWidth,
    projectCostEntry,
    index,
    provided,
    snapshot,
    setOpenDeleteConfirm,
    handleSubmit,
    handleCancel,
  } = props

  const classes = useStyles(props)
  const projectCostEntryId = projectCostEntry?.id
  const currency = projectCostEntry?.currency
  let customFieldsMetaData = projectCostEntry?.projectCostType?.customFieldsMetaData
  if (typeof customFieldsMetaData == 'string') {
    customFieldsMetaData = JSON.parse(customFieldsMetaData)
  }

  return (
    <TableRow
      {...provided.draggableProps}
      ref={provided.innerRef}
      hover
      tabIndex={-1}
      className={classes.tr}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <TableCell className={clsx(classes.td)} style={{ width: entryCellWidth }}>
        <ProjectCostEntry
          projectCostId={projectCost?.id}
          projectCostEntry={projectCostEntry}
          currency={projectCostEntry?.currency}
          editable={Boolean(projectCostEntryId)}
          handleSubmit={(p: any) =>
            handleSubmit('projectCostEntry', null, p, projectCostEntryId, -1)
          }
          handleCancel={() => {}}
        />
      </TableCell>

      {projectCostEntryItemTypes.map((CEIT: any, i: number) => {
        const entryItems = _filter(
          _get(projectCostEntry, ['projectCostEntryItems']) || [],
          (entryItem) => entryItem.type === CEIT.type
        )
        const entryItem = _get(
          _filter(entryItems, (entryItem) => entryItem.sequence === CEIT.sequence),
          [0]
        )
        const isAddRevision = CEIT.type === ProjectCostEntryItemType.ADDREVISION

        if (isAddRevision) return null

        return (
          <TableCell
            key={`projectCostEntryItem-${i}`}
            className={clsx(classes.td, classes.projectCostEntryItemCell)}
            style={{ width: `${cellWidth}%`, minWidth: `${cellWidth}%` }}
          >
            <ProjectCostEntryItem
              viewPortContainerRef={projectCostEntriesWrapperEleRef}
              clientId={project?.clientId}
              projectId={project?.id}
              projectCostEntryId={projectCostEntryId}
              customFieldsMetaData={customFieldsMetaData}
              projectCostEntryItem={entryItem}
              projectCostEntryItems={entryItems}
              projectCostEntryItemType={CEIT.type}
              sequence={CEIT.sequence}
              currency={currency}
              handleSubmit={(p: any) =>
                handleSubmit(
                  'projectCostEntryItem',
                  CEIT.type,
                  p,
                  projectCostEntryId,
                  entryItem?.id || -1
                )
              }
              handleCancel={handleCancel}
            />
          </TableCell>
        )
      })}
      <TableCell className={clsx(classes.td, classes.editCell)}>
        <IconButton color="inherit" disableRipple size="small" {...provided.dragHandleProps}>
          <DragIndicatorIcon fontSize="small" />
        </IconButton>
        <IconButton
          className={clsx('project-cost-entry-delete-button')}
          color="inherit"
          disableRipple
          size="small"
          onClick={() => setOpenDeleteConfirm(projectCostEntryId)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const ProjectCostWidget = (props: any) => {
  const {
    widgetConfig: { order, area, permissions, style },
    config,
    inputs: { project, exchangeRates, reportingPeriodStart, reportingPeriodEnd },
    outputs: { handleCancel, handleSubmit: onHandleSubmit },
  } = props
  const classes = useStyles(props)
  const mountedRef = useMounted(true)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store

  const [projectCost, setProjectCost] = useState(null as any)
  const [projectCostEntryItemTypes, setProjectCostEntryItemTypes] =
    useState(ProjectCostEntryItemTypes)
  const [deleteProjectCostEntryIds, setDeleteProjectCostEntryIds] = useState([] as any)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(0)

  const projectCostEntriesWrapperEleRef = useRef(null)
  const [getProjectCostByProject, getProjectCostByProjectRes] = useLazyQuery(
    GET_PROJECT_COST_BY_PROJECT,
    {
      onError: (err) => {
        if (mountedRef.current) {
          setProjectCost(null)
        }
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        const temp = res?.projectCostByProject || null
        const projectCostEntries = temp?.projectCostEntries || []
        const rMaxNum = Math.max(
          0,
          ...projectCostEntries.map((entry: any) =>
            Math.max(
              0,
              ..._filter(
                entry?.projectCostEntryItems || [],
                (ei) => ei.type === ProjectCostEntryItemType.REVISION
              ).map((revision) => revision.sequence + 1)
            )
          )
        )

        setProjectCostEntryItemTypes([
          { type: ProjectCostEntryItemType.BALLPARK, sequence: 0 },
          { type: ProjectCostEntryItemType.ORIGINAL, sequence: 0 },
          ..._range(rMaxNum).map((i) => ({
            type: ProjectCostEntryItemType.REVISION,
            sequence: i,
          })),
          { type: ProjectCostEntryItemType.ADDREVISION, sequence: 0 },
          { type: ProjectCostEntryItemType.AWARDED, sequence: 0 },
          { type: ProjectCostEntryItemType.OVERAGE, sequence: 0 },
          { type: ProjectCostEntryItemType.SAVINGS, sequence: 0 },
          { type: ProjectCostEntryItemType.ACTUAL, sequence: 0 },
        ])
        setProjectCost(temp)
      },
      fetchPolicy: 'network-only',
    }
  )

  const [sortProjectCostEntries, sortProjectCostEntriesRes] = useMutation(
    SORT_PROJECT_COST_ENTRIES,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        if (_get(res, ['sortProjectCostEntries", "success'])) {
        }
      },
    }
  )

  const [deleteProjectCostEntry, deleteProjectCostEntryRes] = useMutation(
    DELETE_PROJECT_COST_ENTRY,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {},
    }
  )

  useEffect(() => {
    if (!mountedRef.current || !activeTenant?.id || !project?.id) return
    let abortController: any
    try {
      abortController = new AbortController()
      const { signal } = abortController

      const params: any = {
        tenantId: activeTenant.id,
        projectId: project.id || 0,
      }

      if (Boolean(reportingPeriodStart)) {
        params['projectCostEntryItemsFilter'] = {
          reportingPeriodStart: dateToString(reportingPeriodStart, true),
        }
      }

      if (Boolean(reportingPeriodEnd)) {
        if (!params['projectCostEntryItemsFilter']) params['projectCostEntryItemsFilter'] = {}

        params['projectCostEntryItemsFilter']['reportingPeriodEnd'] = dateToString(
          reportingPeriodEnd,
          false,
          true
        )
      }

      callPagApi('', getTokenSilently, getProjectCostByProject, params, signal)
    } catch (err) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant, project?.id, reportingPeriodStart, reportingPeriodEnd])

  useEffect(() => {
    if (!mountedRef.current || !deleteProjectCostEntryRes.data) return
    if (_get(deleteProjectCostEntryRes.data, ['deleteProjectCostEntry', 'success'])) {
      const projectCostEntries = projectCost?.projectCostEntries || []
      setProjectCost({
        ...projectCost,
        projectCostEntries: projectCostEntries.filter(
          (pce: any) => deleteProjectCostEntryIds.indexOf(pce.id) < 0
        ),
      })
    }

    return () => {}
  }, [deleteProjectCostEntryRes.data])

  const onDragEnd = (result: any) => {
    if (!result.destination || !activeTenant?.id) {
      return
    }

    let abortController
    try {
      const temp = reorderArray(
        projectCost?.projectCostEntries || [],
        result.source.index,
        result.destination.index
      )

      setProjectCost({ ...projectCost, projectCostEntries: temp })

      abortController = new AbortController()
      const { signal } = abortController
      callPagApi(
        '',
        getTokenSilently,
        sortProjectCostEntries,
        {
          tenantId: activeTenant?.id,
          sorts: temp.map((t: any, i: number) => ({ id: t.id, sortOrder: i })),
        },
        signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const handleAddRevision = (n: number) => {
    const temp = [...projectCostEntryItemTypes]
    temp.splice(n, 0, {
      type: ProjectCostEntryItemType.REVISION,
      sequence:
        temp[n - 1].type === ProjectCostEntryItemType.REVISION ? temp[n - 1].sequence + 1 : 0,
    })
    setProjectCostEntryItemTypes(temp)
  }

  const handleDeleteProjectCostEntry = (projectCostEntryId: number) => {
    let abortController: any

    try {
      if (deleteProjectCostEntryIds.indexOf(projectCostEntryId) >= 0) {
        return
      }

      abortController = new AbortController()
      const { signal } = abortController
      setDeleteProjectCostEntryIds([...deleteProjectCostEntryIds, projectCostEntryId])
      callPagApi(
        '',
        getTokenSilently,
        deleteProjectCostEntry,
        { tenantId: activeTenant?.id, id: projectCostEntryId },
        signal
      )
    } catch (err) {
      console.log(err)
      if (abortController) abortController.abort()
    }
  }

  const handleSubmit = (
    type: string,
    projectCostEntryItemType: any,
    value: any,
    projectCostEntryId: number,
    projectCostEntryItemId: number
  ) => {
    const projectCostEntries = [...(projectCost.projectCostEntries || [])]
    if (type === 'projectCostEntry') {
      if (projectCostEntryId) {
        const pceIndex = projectCostEntries.findIndex((p: any) => p.id === projectCostEntryId)
        if (pceIndex >= 0) {
          projectCostEntries[pceIndex] = value
        }
      } else {
        projectCostEntries.push(value)
      }
      setProjectCost({
        ...projectCost,
        projectCostEntries: projectCostEntries,
      })
    } else if (projectCostEntryId) {
      const pceIndex = projectCostEntries.findIndex((p: any) => p.id === projectCostEntryId)
      if (pceIndex >= 0) {
        let pceItems = [...(projectCostEntries[pceIndex].projectCostEntryItems || [])]
        if (projectCostEntryItemType === ProjectCostEntryItemType.OVERAGE) {
          pceItems = pceItems.filter(
            (item: any) => item.type !== ProjectCostEntryItemType.OVERAGE
          )
          pceItems.push(...value)
        } else {
          if (projectCostEntryItemId >= 0) {
            const pceItemIndex = pceItems.findIndex(
              (ei: any) => ei.id === projectCostEntryItemId
            )

            if (pceItemIndex >= 0) {
              if (Boolean(value)) {
                pceItems[pceItemIndex] = value
              } else {
                pceItems.splice(pceItemIndex, 1)
              }
            } else if (value) {
              pceItems.push(value)
            }
          } else if (Boolean(value)) {
            pceItems.push(value)
          }
        }
        projectCostEntries[pceIndex] = {
          ...projectCostEntries[pceIndex],
          projectCostEntryItems: pceItems
        }
        setProjectCost({
          ...projectCost,
          projectCostEntries: projectCostEntries,
        })
      }
    }
  }

  const renderProjectCostContent = useMemo(() => {
    const dispProjectCostEntries = projectCost?.projectCostEntries || []
    const projectCostEntryTypesNum = projectCostEntryItemTypes?.length || 0
    const cellWidth = 50 / (projectCostEntryTypesNum - 1)
    // const entryCellWidth = `calc(50% - 50px)`;
    const entryCellWidth = `50%`
    const projectCurrency = project?.currency?.currency
    let totals: any = null

    if (dispProjectCostEntries.length > 0) {
      totals = {}

      dispProjectCostEntries.forEach((item: any) => {
        const projectCostEntryCurrency = item?.currency?.currency || 'USD'
        const exchangeRate = exchangeRates?.rates?.[`${projectCostEntryCurrency}`] || 1
        const projectCostEntryItems = item?.projectCostEntryItems || []

        projectCostEntryItems.forEach((entryItem: any) => {
          if (!totals[entryItem.type]) totals[entryItem.type] = {}

          if (entryItem.type === ProjectCostEntryItemType.REVISION)
            totals[entryItem.type][entryItem.sequence || 0] =
              (totals[entryItem.type][entryItem.sequence || 0] || 0) +
              stringToNumber(entryItem?.amount, 'float', 0) * exchangeRate
          else
            totals[entryItem.type][0] =
              (totals[entryItem.type][0] || 0) +
              stringToNumber(entryItem?.amount, 'float', 0) * exchangeRate
        })
      })
    }

    return (
      <TableContainer ref={projectCostEntriesWrapperEleRef} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="Project Cost Entries">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided: any, snapshot: any) => (
                <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.th)}
                      style={{ padding: 2, width: entryCellWidth }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={clsx(classes.th)}
                              style={{
                                width: '30%',
                                padding: 2,
                                borderBottom: 'none',
                              }}
                            >
                              <div className={clsx('pag-text-ellipsis')}>TYPE</div>
                            </TableCell>
                            <TableCell
                              className={clsx(classes.th)}
                              style={{
                                width: '45%',
                                padding: 2,
                                borderBottom: 'none',
                              }}
                            >
                              <div className={clsx('pag-text-ellipsis')}>DESCRIPTION</div>
                            </TableCell>
                            <TableCell
                              className={clsx(classes.th)}
                              style={{
                                width: '25%',
                                padding: 2,
                                borderBottom: 'none',
                              }}
                            >
                              <div className={clsx('pag-text-ellipsis')}>VENDOR</div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    {projectCostEntryItemTypes.map((CEIT: any, i: number) => {
                      const isAddRevision = CEIT.type === ProjectCostEntryItemType.ADDREVISION
                      return (
                        <TableCell
                          key={`projectCostEntryItem-head-${i}`}
                          rowSpan={isAddRevision ? 0 : undefined}
                          className={clsx(
                            classes.th,
                            classes.projectCostEntryItemCell,
                            isAddRevision ? 'add-revision' : undefined
                          )}
                          style={{
                            width: isAddRevision ? undefined : `${cellWidth}%`,
                          }}
                        >
                          {isAddRevision ? (
                            <IconButton
                              className={clsx('add-revision-icon-button')}
                              color="primary"
                              disableFocusRipple
                              size="small"
                              onClick={() => handleAddRevision(i)}
                            >
                              <Icon style={{ fontSize: 18 }}>add</Icon>
                            </IconButton>
                          ) : (
                            <div className={clsx(classes.cellValue, 'pag-text-ellipsis')}>
                              {_toUpper(CEIT.type) || ''}
                            </div>
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell className={clsx(classes.td, classes.editCell)}></TableCell>
                  </TableRow>

                  {dispProjectCostEntries.map((projectCostEntry: any, index: number) => (
                    <Draggable
                      key={`cost-entry-${projectCostEntry.id}`}
                      draggableId={`cost-entry-${projectCostEntry.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ProjectCostEntryRow
                          {...{
                            projectCostEntriesWrapperEleRef,
                            project,
                            projectCost,
                            projectCostEntryItemTypes,
                            projectCostEntry,
                            entryCellWidth,
                            cellWidth,
                            index,
                            provided,
                            snapshot,
                            handleSubmit,
                            handleCancel,
                            setOpenDeleteConfirm,
                          }}
                        />
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}

                  {/* new project cost entry */}

                  <TableRow hover tabIndex={-1} className={classes.tr}>
                    <TableCell
                      className={clsx(classes.td)}
                      style={{ textAlign: 'center', width: entryCellWidth }}
                    >
                      <ProjectCostEntry
                        projectCostId={projectCost?.id}
                        editable={false}
                        handleSubmit={(p: any) => handleSubmit('projectCostEntry', null, p, 0, -1)}
                        handleCancel={() => {}}
                      />
                    </TableCell>

                    {projectCostEntryItemTypes.map((CEIT: any, i: number) => {
                      const isAddRevision = CEIT.type === ProjectCostEntryItemType.ADDREVISION

                      if (isAddRevision) return null

                      return (
                        <TableCell
                          key={`projectCostEntryItem-new-${i}`}
                          className={clsx(classes.td, classes.projectCostEntryItemCell)}
                          style={{ width: `${cellWidth}%` }}
                        >
                          <div className={clsx(classes.totalValue, 'pag-text-ellipsis')}>
                            <Icon className="icon-remove">remove</Icon>
                          </div>
                        </TableCell>
                      )
                    })}
                    <TableCell className={clsx(classes.td, classes.editCell)}></TableCell>
                  </TableRow>

                  {/* Totals */}

                  {Boolean(totals) && (
                    <TableRow>
                      <TableCell className={clsx(classes.td)}>
                        <div
                          className={clsx(classes.totalValue, 'pag-text-ellipsis')}
                          style={{ textAlign: 'center' }}
                        >
                          Total
                        </div>
                      </TableCell>
                      {projectCostEntryItemTypes.map((CEIT: any, i: number) => {
                        const isAddRevision = CEIT.type === ProjectCostEntryItemType.ADDREVISION
                        if (isAddRevision) return null
                        return (
                          <TableCell
                            key={`projectCostEntryItem-total-${i}`}
                            className={clsx(classes.td, classes.projectCostEntryItemCell)}
                            style={{ width: `${cellWidth}%` }}
                          >
                            <div className={clsx(classes.totalValue, 'pag-text-ellipsis')}>
                              {!_get(totals, [CEIT.type, CEIT.sequence]) ? (
                                <Icon className="icon-remove">remove</Icon>
                              ) : (
                                <NumberFormat
                                  displayType={'text'}
                                  value={_get(totals, [CEIT.type, CEIT.sequence]) || 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={CURRENCIES[projectCurrency] || '$'}
                                />
                              )}
                            </div>
                          </TableCell>
                        )
                      })}
                      <TableCell className={clsx(classes.td)}></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    )
  }, [projectCost, projectCostEntryItemTypes, exchangeRates])

  return (
    <Paper className={clsx(classes.root)} elevation={0}>
      {(getProjectCostByProjectRes.loading || deleteProjectCostEntryRes.loading) && (
        <PagCircularIndeterminate size={24} />
      )}

      {renderProjectCostContent}

      {Boolean(openDeleteConfirm) && (
        <PagAlertDialog
          title="Delete Project Cost Entry"
          content="Are you sure to delete the Project Cost Entry?"
          open={true}
          handleClose={(e: boolean) => {
            if (e) {
              handleDeleteProjectCostEntry(openDeleteConfirm)
            }
            setOpenDeleteConfirm(0)
          }}
        />
      )}
    </Paper>
  )
}

ProjectCostWidget.propTypes = {
  widgetConfig: WidgetConfigType,
  config: PropTypes.shape({}),
  inputs: PropTypes.shape({
    project: PropTypes.object.isRequired,
    exchangeRates: PropTypes.any,
    reportingPeriodStart: PropTypes.any,
    reportingPeriodEnd: PropTypes.any,
  }).isRequired,
  outputs: PropTypes.shape({
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
}

ProjectCostWidget.defaultProps = {
  widgetConfig: WidgetConfigDefaultProps,
  inputs: {
    project: {},
  },
  outputs: {
    handleSubmit: (e: any) => {},
  },
}

export default ProjectCostWidget
