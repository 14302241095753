import React, { useContext, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Portal from '@material-ui/core/Portal'

import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import PagTextInput from '@/components/pag-form/pag-text-input'

import { useAuth0 } from '@/auth/index'
import { ProjectItemWidgetContext } from '@/widgets/project-item-widget'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { ICity } from '@/shared/models/city'
import { IBidCountry } from '@/shared/models/bid-country'
import { IShootType } from '@/shared/models/shoot-type'
import { dateToString, dateStringToDate } from '@/utils/date-utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    minWidth: 340,
    maxWidth: 340,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    zIndex: 1004,
    overflow: 'visible',
  },
  form: {
    display: 'block',
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
}))

const FormSchema = yup.object().shape({
  shootDate: yup.date().required('Shoot Date is required.'),
  shootTypeId: yup.number().required('Shoot Type is required.'),
})

const ProjectItemShootEditComponent = (props: any) => {
  const { validationSchema, clientId, project, shoot, containerRef, handleCancel, handleChange } =
    props
  const classes = useStyles(props)
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { cities, countries, shootTypes } = useContext(ProjectItemWidgetContext)
  const { activeTenant } = store
  const uploadValueRef = useRef(null as any)
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema),
  })
  const {
    control,
    reset,
    formState: { isValid },
    getValues,
    trigger,
  } = hookFormMethods

  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    reset({
      shootDate: dateStringToDate(shoot?.data?.shootDate),
      shootTypeId: shoot?.data?.shootTypeId || null,
      cityId: shoot?.data?.cityId || null,
      countryId: shoot?.data?.countryId || null,
      preLightHrs: shoot?.data?.preLightHrs || '',
      shootHrs: shoot?.data?.shootHrs || '',
    })
  }, [reset, shoot])

  useEffect(() => {
    if (Boolean(updateProjectRes.data) && Boolean(uploadValueRef.current)) {
      handleChange(uploadValueRef.current, false)
    }

    return () => {}
  }, [updateProjectRes.data])

  const containerRect = useMemo(() => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    return containerRect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoot])

  const handleFormSubmit = async () => {
    let abortController: any
    try {
      const isValid = await trigger()
      if (!isValid) return
      const data = getValues()
      const tempShoots = [...(project?.metaData?.shoots || [])]
      const newValue = {
        ...data,
        shootDate: dateToString(data.shootDate),
      }

      if (shoot && shoot.index >= 0) {
        tempShoots[shoot.index] = newValue
      } else {
        tempShoots.push(newValue)
      }
      if (project?.id) {
        abortController = new AbortController()
        uploadValueRef.current = tempShoots

        callPagApi(
          '',
          getTokenSilently,
          updateProject,
          {
            tenantId: activeTenant?.id,
            id: project.id,
            metaData: {
              ...(project.metaData || {}),
              shoots: tempShoots,
            },
          },
          abortController.signal
        )
      } else {
        handleChange(tempShoots, false)
      }
    } catch (err) {
      console.error('Error in Update Project', err)
      if (abortController) abortController.abort()
    }
  }

  return (
    <Portal>
      <Paper
        className={clsx(classes.root)}
        elevation={0}
        style={{ left: containerRect?.left, top: containerRect?.bottom - 270 }}
      >
        <form name="shootEditForm">
          <Card elevation={24} variant="outlined">
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagDatePicker
                        margin="none"
                        name="shootDate"
                        label="SHOOT DATE"
                        format="MM/dd/yyyy"
                        value={field.value}
                        inputVariant="outlined"
                        allowKeyboardControl={true}
                        size="small"
                        fullWidth
                        onChange={(e: any) => {
                          field.onChange(e)
                          // debouncedTriggerChange('shootDate', e)
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    )}
                    control={control}
                    name="shootDate"
                    defaultValue={shoot?.data?.shootDate || null}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagAutocomplete
                        {...{
                          name: 'shootTypeId',
                          optionLabelKey: 'name',
                          optionValueKey: 'id',
                          options: shootTypes,
                          error: null,
                          value: field.value,
                          // loading: addProjectTeamRoleRes.loading,
                          isCreatable: false,
                          AutocompleteProps: {
                            getOptionLabel: (option: IShootType) => option.name,
                            // getOptionSelected: (option: any, value: any) => option.value === value,
                            size: 'small',
                            disableClearable: true,
                            onChange: (event: object, values: any, reason: string) => {
                              field.onChange(values ? values.id : null)
                              // debouncedTriggerChange('shootTypeId', values ? values.id : null)
                            },
                          },
                          InputProps: {
                            variant: 'outlined',
                            label: 'SHOOT TYPE',
                            placeholder: 'Select a Shoot Type',
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                      />
                    )}
                    control={control}
                    name="shootTypeId"
                    defaultValue={shoot?.data?.shootTypeId}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagAutocomplete
                        {...{
                          name: 'cityId',
                          optionLabelKey: 'name',
                          optionValueKey: 'id',
                          options: cities,
                          error: null,
                          value: field.value,
                          // loading: addProjectTeamRoleRes.loading,
                          isCreatable: false,
                          AutocompleteProps: {
                            getOptionLabel: (option: ICity) => option.name,
                            // getOptionSelected: (option: any, value: any) => option.value === value,
                            size: 'small',
                            disableClearable: true,
                            onChange: (event: object, values: ICity, reason: string) => {
                              field.onChange(values ? values.id : null)
                              // debouncedTriggerChange('cityId', values ? values.id : null)
                            },
                          },
                          InputProps: {
                            variant: 'outlined',
                            label: 'CITY',
                            placeholder: 'Select a City',
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                      />
                    )}
                    control={control}
                    name="cityId"
                    defaultValue={shoot?.data?.cityId}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagAutocomplete
                        {...{
                          name: 'countryId',
                          optionLabelKey: 'country',
                          optionValueKey: 'id',
                          options: countries,
                          error: null,
                          value: field.value,
                          // loading: addProjectTeamRoleRes.loading,
                          isCreatable: false,
                          AutocompleteProps: {
                            getOptionLabel: (option: IBidCountry) => option.country,
                            // getOptionSelected: (option: any, value: any) => option.value === value,
                            size: 'small',
                            disableClearable: true,
                            onChange: (event: object, values: IBidCountry, reason: string) => {
                              field.onChange(values ? values.id : null)
                              // debouncedTriggerChange('countryId', values ? values.id : null)
                            },
                          },
                          InputProps: {
                            variant: 'outlined',
                            label: 'COUNTRY',
                            placeholder: 'Select a Country',
                            InputLabelProps: {
                              shrink: true,
                            },
                          },
                        }}
                      />
                    )}
                    control={control}
                    name="countryId"
                    defaultValue={shoot?.data?.countryId}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagTextInput
                        name="preLightHrs"
                        label="PRE-LIGHT HOURS"
                        placeholder="Enter pre-light hours"
                        size="small"
                        variant="outlined"
                        disabled={updateProjectRes.loading}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={null}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e.target.value)
                          // debouncedTriggerChange('preLightHrs', e.target.value)
                        }}
                      />
                    )}
                    control={control}
                    name="preLightHrs"
                    defaultValue={shoot?.data?.preLightHrs || ''}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagTextInput
                        name="shootHrs"
                        label="SHOOT DAYS"
                        placeholder="Enter Shoot Days"
                        size="small"
                        variant="outlined"
                        disabled={updateProjectRes.loading}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={null}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e.target.value)
                          // debouncedTriggerChange('shootHrs', e.target.value)
                        }}
                      />
                    )}
                    control={control}
                    name="shootHrs"
                    defaultValue={shoot?.data?.shootHrs || ''}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <Button
                color="primary"
                size="medium"
                aria-label="cancel"
                variant="text"
                className={classes.button}
                disabled={updateProjectRes.loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                size="medium"
                aria-label="next"
                variant="contained"
                className={classes.button}
                disabled={!isValid || updateProjectRes.loading}
                onClick={handleFormSubmit}
              >
                Save
                {updateProjectRes.loading && (
                  <CircularProgress
                    size={16}
                    color="primary"
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Paper>
    </Portal>
  )
}

ProjectItemShootEditComponent.defaultProps = {
  handleChange: (e: any) => {},
  handleCancel: (e: any) => {},
}

export default ProjectItemShootEditComponent
