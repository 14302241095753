import { useMemo } from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import _get from 'lodash/get'
import moment from 'moment'

import { CURRENCIES } from '@/shared/constants/currency'
import { truncate } from '@/shared/libs/str-lib'
import './project-card.scss'

const ProjectCardComponent = ({ project, handleViewProject, disabled }: any) => {
  const detailsRender = useMemo(() => {
    return truncate(project.details, 178)
  }, [project.details])

  const projectImage =
    _get(project, ['image']) ||
    _get(project, ['client', 'defaultProjectImage']) ||
    _get(project, ['client', 'brand', 'defaultProjectImage']) ||
    undefined

  return (
    <div
      className={clsx('pag-project-card', disabled ? 'disabled' : null)}
      onClick={() => !disabled && handleViewProject(project, 'project')}
    >
      <div className="pag-project-image-wrapper pag-flex">
        {projectImage ? (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img className="pag-project-image" src={projectImage} alt="project image" />
        ) : (
          <p> No Image </p>
        )}
      </div>

      {/* <FlagIcon className={clsx('pag-flag-icon', {'favorite': project.favorite})}/> */}

      <div className="pag-project-card-content">
        <p className="pag-project-name">{_get(project, ['projectName']) || ''}</p>

        <div className="pag-project-brand">
          <div className="pag-project-brand-content">
            <span className="pag-project-brand-name">
              {_get(project, ['client', 'name']) || ''}
            </span>
            &nbsp;|&nbsp;
            <span>{_get(project, ['brand', 'name']) || ''}</span>
          </div>
          <div className="pag-client-region pag-text-ellipsis">
            <i>{_get(project, ['projectRegion', 'region']) || ''}</i>
          </div>
        </div>

        <div className="pag-project-brand">
          <div className="pag-project-brand-content pag-project-number">
            {project.projectNumber}
          </div>
          <div className="pag-client-region">
            {project.deliveryDate ? moment(project.deliveryDate).format('L') : null}
          </div>
        </div>

        <p className="pag-project-description">{detailsRender}</p>

        <div className="pag-project-status-budget">
          <div className="pag-project-status-budget-labels">
            <span>Status:</span>
            <span>Budget:</span>
          </div>
          <div className="pag-project-status-budget-values">
            <b className="pag-project-status-budget-value">
              {_get(project, ['projectStatus', 'status']) || ''}
            </b>
            <b className="pag-project-status-budget-value">
              <NumberFormat
                displayType={'text'}
                value={project?.budgetTotal || project?.budgetRate || 0}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={false}
                prefix={CURRENCIES[_get(project, ['currency', 'currency'])] || '$'}
              />
            </b>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCardComponent
