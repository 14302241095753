// import gql from 'graphql-tag';

// const GET_REPORTS_WITHOUT_CLIENT = gql`
//   query reports($clientId: Int, $name: String, $offset: Int, $limit: Int) {
//     reports(clientId: $clientId, name: $name, offset: $offset, limit: $limit) {
// 			total
// 			data {
// 				id
//         name
//         tableauViewUrl
//         width
//         hideTabs
//         hideToolbar
//         clientId
// 			}
// 		}
//   }
// `;

// const GET_REPORTS_WITH_CLIENT = gql`
//   query reports($clientId: Int, $name: String, $offset: Int, $limit: Int) {
//     reports(clientId: $clientId, name: $name, offset: $offset, limit: $limit) {
// 			total
// 			data {
// 				id
//         name
//         tableauViewUrl
//         width
//         hideTabs
//         hideToolbar
//         clientId,
//         client {
//           id
//           name
//           slug
//         }
// 			}
// 		}
//   }
// `;

// export {
//   GET_REPORTS_WITHOUT_CLIENT,
// 	GET_REPORTS_WITH_CLIENT,
// }

import gql from 'graphql-tag'

const GET_REPORTS_WITHOUT_CLIENT = gql`
  query reports($tenantId: Int!, $clientId: Int!, $name: String, $offset: Int, $limit: Int) {
    reports(tenantId: $tenantId, clientId: $clientId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        tableauWorkbookPath
        tableauViewPath
        tableauViewName
        tableauViewContentUrl
        tableauViewResourceId
        tableauWorkbookResourceId
        hideTabs
        hideToolbar
        sortOrder
        clientId
        previewImage
      }
    }
  }
`

const GET_REPORTS_WITH_CLIENT = gql`
  query reports($tenantId: Int!, $clientId: Int!, $name: String, $offset: Int, $limit: Int) {
    reports(tenantId: $tenantId, clientId: $clientId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        tableauWorkbookPath
        tableauViewPath
        tableauViewName
        tableauViewContentUrl
        tableauViewResourceId
        tableauWorkbookResourceId
        hideTabs
        hideToolbar
        sortOrder
        clientId
        previewImage
        client {
          id
          name
        }
      }
    }
  }
`

export { GET_REPORTS_WITHOUT_CLIENT, GET_REPORTS_WITH_CLIENT }
