import gql from 'graphql-tag'

const GET_MEDIA_TYPES = gql`
  query mediaTypes($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    mediaTypes(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_MEDIA_TYPES }
