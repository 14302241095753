import b64ToBlob from 'b64-to-blob'
import fileSaver from 'file-saver'

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
}

export const downloadFileFromBase64 = (
  fileAsBase64: string,
  fileType: string,
  fileName: string
) => {
  const blob = b64ToBlob(fileAsBase64, fileType)
  fileSaver.saveAs(blob, fileName)
}
