import React, { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'

import useMounted from '@/components/hooks/use-mounted'
import PagAlertDialog from '@/components/pag-alert'
import PagCircularIndeterminate from '@/components/pag-loading'
import {
  DeliverableEditDialog,
  DeliverablesTableView,
  AdIDSearchDialog,
} from '@/routes/projects/components/deliverables'
import { GET_DELIVERABLES } from '@/shared/graphql/queryGetDeliverables'
import { GET_MEDIA_TYPES } from '@/shared/graphql/queryGetMediaTypes'
import { GET_MEDIUMS } from '@/shared/graphql/queryGetMediums'
import { GET_SPOT_TYPES } from '@/shared/graphql/queryGetSpotTypes'
import { DELETE_DELIVERABLE } from '@/shared/graphql/mutations/mutationDeliverables'
import { IDeliverable } from '@/shared/models/deliverable'
import { IBulkWriteResponse } from '@/shared/models/bulkWriteResponse'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonsWrapper: {
    padding: '16px 0',
  },
}))

const DeliverablesContainer = (props: any) => {
  const { projectId, editable } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const pagination = useRef({
    nbPages: 1,
    offset: 0,
    limit: 20,
    hasMore: true,
  })

  const [deliverables, setDeliverables] = useState<IDeliverable[]>([])
  const [mediaTypes, setMediaTypes] = useState([])
  const [mediums, setMediums] = useState([])
  const [spotTypes, setSpotTypes] = useState([])
  const [editDialogOption, setEditDialogOption] = useState<{
    openDeliverableDialog: boolean
    openAdIDSearchDialog: boolean
    title: string
    deliverable: IDeliverable | null
  }>({
    openDeliverableDialog: false,
    openAdIDSearchDialog: false,
    title: 'Add New Deliverables',
    deliverable: null,
  })
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<IDeliverable | null>(null)

  const [deliverablesQuery, deliverablesQueryRes] = useLazyQuery(GET_DELIVERABLES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = res.deliverables || { data: [], total: 0 }

      if (temp.data.length) {
        pagination.current = {
          nbPages: Math.ceil(temp.total / pagination.current.limit),
          offset: pagination.current.offset + Math.min(pagination.current.limit, temp.data.length),
          limit: pagination.current.limit,
          hasMore: temp.data.length >= pagination.current.limit,
        }
        setDeliverables((prevValues) => [...prevValues, ...temp.data])
      }
    },
    fetchPolicy: 'network-only',
  })

  const [mediaTypesQuery, mediaTypesQueryRes] = useLazyQuery(GET_MEDIA_TYPES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setMediaTypes(_get(res, ['mediaTypes', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })

  const [mediumsQuery, mediumsQueryRes] = useLazyQuery(GET_MEDIUMS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setMediums(_get(res, ['mediums', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })

  const [spotTypesQuery, spotTypesQueryRes] = useLazyQuery(GET_SPOT_TYPES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = setSpotTypes(_get(res, ['spotTypes', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })

  const [deleteDeliverable, deleteDeliverableRes] = useMutation(DELETE_DELIVERABLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (mountedRef.current && _get(res, ['deleteDeliverable', 'success'])) {
        const deletedId = _get(res, ['deleteDeliverable', 'data'])
        setDeliverables(deliverables.filter((d) => d.id !== deletedId))
      }
    },
  })

  useEffect(() => {
    if (!activeTenant?.id) return
    const abortController = new AbortController()
    const { signal } = abortController

    callPagApi('', getTokenSilently, mediaTypesQuery, { tenantId: activeTenant.id }, signal)
    callPagApi('', getTokenSilently, mediumsQuery, { tenantId: activeTenant.id }, signal)
    callPagApi('', getTokenSilently, spotTypesQuery, { tenantId: activeTenant.id }, signal)

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant])

  const loadMore = () => {
    if (!mountedRef.current || !activeTenant?.id || !projectId || deliverablesQueryRes.loading)
      return
    const abortController = new AbortController()
    const callApi = async () => {
      try {
        await callPagApi(
          '',
          getTokenSilently,
          deliverablesQuery,
          {
            tenantId: activeTenant.id,
            projectId: projectId,
            offset: pagination.current.offset,
            limit: pagination.current.limit,
          },
          abortController.signal
        )
      } catch (e) {}
    }

    callApi()
  }

  const handleEdit = (item: IDeliverable) => {
    setEditDialogOption({
      openDeliverableDialog: true,
      openAdIDSearchDialog: false,
      title: 'Edit Deliverable',
      deliverable: item,
    })
  }

  const handleDelete = (item: IDeliverable | null) => {
    if (!item || !activeTenant?.id) return
    const abortController = new AbortController()

    try {
      const callApi = async (data: { tenantId: number; id: number }) => {
        try {
          await callPagApi('', getTokenSilently, deleteDeliverable, data, abortController.signal)
        } catch (e) {}
      }

      setEditDialogOption({
        openDeliverableDialog: false,
        openAdIDSearchDialog: false,
        title: 'Add New Deliverables',
        deliverable: null,
      })
      callApi({ tenantId: activeTenant?.id, id: item.id as number })
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const renderDeliverableEditDialog = useMemo(() => {
    if (!editable) return null
    return (
      <DeliverableEditDialog
        open={editDialogOption.openDeliverableDialog}
        deliverable={editDialogOption.deliverable}
        projectId={projectId}
        mediaTypes={mediaTypes}
        mediums={mediums}
        spotTypes={spotTypes}
        handleOpenAdIdSearchDialog={(e: boolean) => {
          setEditDialogOption({
            ...editDialogOption,
            openAdIDSearchDialog: e,
          })
        }}
        handleDelete={(e: IDeliverable) => {
          setOpenDeleteConfirm(e)
        }}
        handleClose={(e: IDeliverable | null) => {
          setEditDialogOption({
            openDeliverableDialog: false,
            openAdIDSearchDialog: false,
            title: 'Add New Deliverables',
            deliverable: null,
          })
          if (e) {
            const tempIndex = _findIndex(deliverables, (d) => d.id === e.id)
            if (tempIndex >= 0) {
              setDeliverables([
                ...deliverables.slice(0, tempIndex),
                e,
                ...deliverables.slice(tempIndex + 1),
              ])
            } else {
              setDeliverables([e, ...deliverables])
              pagination.current.offset += 1
            }
          }
        }}
      />
    )
  }, [editDialogOption, projectId, editable, mediaTypes, mediums, spotTypes])

  const renderAdIDSearchDialog = useMemo(() => {
    if (!editable || !editDialogOption.openAdIDSearchDialog) return null
    return (
      <AdIDSearchDialog
        open={editDialogOption.openAdIDSearchDialog}
        enableMultipleSelection={
          editDialogOption.openAdIDSearchDialog && !editDialogOption.deliverable
        }
        deliverable={_get(editDialogOption, ['deliverable'])}
        projectId={projectId}
        handleClose={(
          e: {
            isBulkWrite?: boolean
            isUpdate?: boolean
            data: IBulkWriteResponse<IDeliverable> | IDeliverable
          } | null
        ) => {
          if (e) {
            if (e.isBulkWrite) {
              const data = e.data as IBulkWriteResponse<IDeliverable>
              let temp = [...deliverables]
              for (const item of data.updates || []) {
                const tempIndex = _findIndex(deliverables, (d) => d.id === item.id)
                if (tempIndex >= 0) {
                  temp[tempIndex] = item
                } else {
                  temp = [item, ...temp]
                  pagination.current.offset += 1
                }
              }
              if (data.inserts?.length) {
                temp = [...data.inserts, ...temp]
                pagination.current.offset += data.inserts.length
              }
              setDeliverables(temp)
            } else if (e.isUpdate) {
              const tempIndex = _findIndex(
                deliverables,
                (d) => d.id === (e.data as IDeliverable).id
              )
              if (tempIndex >= 0) {
                setDeliverables([
                  ...deliverables.slice(0, tempIndex),
                  e.data as IDeliverable,
                  ...deliverables.slice(tempIndex + 1),
                ])
              }
            }

            setEditDialogOption({
              openDeliverableDialog: false,
              openAdIDSearchDialog: false,
              title: 'Add New Deliverables',
              deliverable: null,
            })
          } else {
            setEditDialogOption({
              ...editDialogOption,
              openAdIDSearchDialog: false,
            })
          }
        }}
      />
    )
  }, [editDialogOption, projectId, editable])

  const renderDeliverablesTableView = useMemo(() => {
    return (
      <DeliverablesTableView
        hits={deliverables}
        searching={deliverablesQueryRes.loading}
        nbPages={pagination.current.nbPages}
        editable={editable}
        hasMore={pagination.current.hasMore}
        refineNext={loadMore}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
    )
  }, [deliverables, deliverablesQueryRes.loading, editable])

  return (
    <div className={classes.root}>
      {renderDeliverablesTableView}
      {editable && (
        <div className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={() => {
              setEditDialogOption({
                openDeliverableDialog: true,
                openAdIDSearchDialog: false,
                title: 'Add New Deliverable',
                deliverable: null,
              })
            }}
          >
            Add New
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={() => {
              setEditDialogOption({
                openDeliverableDialog: false,
                openAdIDSearchDialog: true,
                title: 'Add New Deliverables',
                deliverable: null,
              })
            }}
          >
            Add New from adID
          </Button>
        </div>
      )}
      {editDialogOption.openDeliverableDialog && renderDeliverableEditDialog}
      {renderAdIDSearchDialog}
      {openDeleteConfirm && (
        <PagAlertDialog
          title="Delete a Deliverable"
          content="Are you sure to delete the deliverable?"
          open={true}
          handleClose={(e: boolean) => {
            if (e) {
              handleDelete(openDeleteConfirm)
            }
            setOpenDeleteConfirm(null)
          }}
        />
      )}
      {(deliverablesQueryRes.loading || deleteDeliverableRes.loading) && (
        <PagCircularIndeterminate size={24} />
      )}
    </div>
  )
}

export default DeliverablesContainer
