import gql from 'graphql-tag'

const GET_EXCHANGE_RATES = gql`
  query exchangeRates($base: String) {
    exchangeRates(base: $base) {
      base
      date
      rates
    }
  }
`

export { GET_EXCHANGE_RATES }
