import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import * as FileSaver from 'file-saver'
import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import PagButton from '@/components/pag-form/pag-button'
import { CURRENCIES } from '@/shared/constants/currency'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  projectOutline: {
    display: 'flex',
  },
  projectOutline1: {
    display: 'flex',
    flex: 2,
    minWidth: 180,
  },
  projectOutline2: {
    flex: 1,
    maxWidth: 380,
  },
  projectField: {
    padding: '0 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
  projectImage: {
    width: 100,
    height: 80,
    minWidth: 100,
    minHeight: 80,
    fontSize: 14,
    color: 'white',
    backgroundColor: 'lightgrey',
  },
  projectDetails: {
    minWidth: 150,
    padding: '0 12px',
  },
  bidTotal: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#437434',
    height: '100%',
    width: '100%',
    padding: '0 8px',
  },
  searchResultFor: {
    minWidth: 200,
    maxWidth: 200,
    marginRight: 50,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
}))

const ProjectsBidsSearchBreadCrumb = (props: any) => {
  const {
    activeBid,
    tabName,
    searchView,
    expanded,
    disableCompareBids,
    bidsViewMode,
    bidDetailsViewMode,

    goBack,
    onCompareBids,
    onChangeBidsViewMode,
    onChangeBidDetailsViewMode,
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const onDownloadPDF = () => {
    const fileName = _get(activeBid, ['pdf'])
    FileSaver.saveAs(`${fileName}`, `${fileName}.pdf`)
  }

  const renderCompareButton = useMemo(() => {
    return (
      <PagButton
        {...{
          buttonProps: {
            variant: bidsViewMode === 'All' ? 'text' : 'contained',
            color: 'primary',
            onClick: onCompareBids,
            disabled: disableCompareBids,
          },
          alcRender: bidsViewMode === 'All' ? 'Compare Multiple Bids' : 'Compare',
        }}
        style={{ minWidth: '124px' }}
      ></PagButton>
    )
  }, [bidsViewMode, disableCompareBids, onCompareBids])

  const renderSwitch = useMemo(() => {
    return (
      <Switch
        checked={bidsViewMode !== 'All'}
        onChange={onChangeBidsViewMode}
        color="primary"
        inputProps={{ 'aria-label': 'Bids View Mode' }}
      />
    )
  }, [bidsViewMode, onChangeBidsViewMode])

  const tempTabName = _capitalize(tabName)

  return (
    <div className={clsx('pag-bread-crumb')}>
      <div className="pag-link pag-go-back">
        <Icon onClick={goBack}>arrow_back</Icon>
        <span onClick={goBack}>&nbsp;&nbsp;&nbsp;Back</span>
      </div>

      <div className="pag-crumb-content">
        <div className={clsx('pag-flex')}>
          <div className={clsx(classes.searchResultFor)}>
            <b>Search results for:&nbsp;&nbsp;</b>
            <span>{searchView.searchPhrase}</span>
          </div>
        </div>

        <div className="pag-tab-container pag-m-auto">
          <div
            className={clsx('pag-tab', { active: tempTabName === 'All' })}
            onClick={() => {
              navigate('/search/all')
            }}
          >
            <b>All</b>
          </div>
          <div
            className={clsx('pag-tab', { active: tempTabName === 'Projects' })}
            onClick={() => {
              navigate('/search/projects')
            }}
          >
            <b>Projects</b>
          </div>
          <div
            className={clsx('pag-tab', { active: tempTabName === 'Bids' })}
            onClick={() => {
              navigate('/search/bids')
            }}
          >
            <b>Bids</b>
          </div>
          <div
            className={clsx('pag-tab', { active: tempTabName === 'Deliverables' })}
            onClick={() => {
              navigate('/search/deliverables')
            }}
          >
            <b>Deliverables</b>
          </div>
        </div>

        <div
          className={clsx(classes.projectOutline2, classes.projectOutline)}
          style={{ height: '100%' }}
        >
          {tempTabName === 'Bids' && (
            <>
              {Boolean(activeBid) ? (
                <>
                  {/* <div className={clsx(classes.projectOutline2, 'pag-flex')} style={{justifyContent: 'flex-start'}}>
                        {_get(activeBid, ['supplierName'])}
                      </div> */}
                  <div className={clsx(classes.projectOutline1)} style={{ height: '100%' }}>
                    <div className={clsx('pag-flex')} style={{ height: '100%', width: '100%' }}>
                      <div className={clsx(classes.bidTotal)}>
                        <span>Bid Total</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b style={{ fontSize: '16px' }}>
                          <NumberFormat
                            displayType={'text'}
                            value={_get(activeBid, ['bidTotalCost']) || 0}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={CURRENCIES[_get(activeBid, ['currency', 'currency'])] || '$'}
                          />
                        </b>
                        {Boolean(_get(activeBid, ['pdf'])) && (
                          <Icon
                            style={{
                              fontSize: '22px',
                              marginLeft: 'auto',
                              marginRight: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={onDownloadPDF}
                          >
                            picture_as_pdf
                          </Icon>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={clsx(classes.projectOutline1, 'pag-flex')}>
                  {renderCompareButton}
                  {renderSwitch}
                </div>
              )}

              {bidsViewMode === 'Compared' ? (
                <div className="pag-flex" style={{ marginRight: 0 }}>
                  <PagButton
                    {...{
                      buttonProps: {
                        variant: 'text',
                        color: 'primary',
                        onClick: onChangeBidDetailsViewMode,
                      },
                      alcRender:
                        bidDetailsViewMode === 'Expand'
                          ? 'Collapse All Bid Details'
                          : 'Expand All Bid Details',
                    }}
                    style={{ minWidth: 160 }}
                  ></PagButton>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ProjectsBidsSearchBreadCrumb.propTypes = {
  searchView: PropTypes.object.isRequired,
  tabName: PropTypes.string.isRequired,
}

export default ProjectsBidsSearchBreadCrumb
