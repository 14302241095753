import React, { useMemo, useContext } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { connectInfiniteHits } from 'react-instantsearch-dom'

import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'

import useUrlParams from '@/components/hooks/use-url-params'
import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import SearchBreadCrumb from '@/routes/projects/components/search/search-breadcrumb'
import AllSearchContainer from '@/routes/projects/containers/search-containers/all-search-container'
import ProjectsSearchContainer from '@/routes/projects/containers/search-containers/projects-search-container'
import BidsSearchContainer from '@/routes/projects/containers/search-containers/bids-search-container'
import DeliverablesSearchContainer from '@/routes/projects/containers/search-containers/deliverables-search-container'
import ProjectsCardView from '@/routes/projects/components/projects/projects-card-view'
import { Context } from '@/shared/store/reducers/global-reducer'
import { IProject } from '@/shared/models/project'

const ProjectsCardViewHits = connectInfiniteHits(
  PagAlgoliaConnectConditionalResults(ProjectsCardView)
)

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))

const SearchContainer = () => {
  const { tabName } = useUrlParams({ tabName: false })
  const navigate = useNavigate()
  const { store, dispatch } = useContext(Context)
  const {
    activeBid,
    searchView,
    bidsViewMode, // Compare Bids
    bidDetailsViewMode,
    selectedBids,
  } = store

  const classes = useStyles()

  const onCompareBids = () => {
    dispatch({ type: 'ChangeBidsViewMode', bidsViewMode: 'Compared' })
  }

  const goBack = () => {
    navigate('/')
  }

  const handleViewProject = (project: IProject, tabName: string) => {
    const cId = project?.clientId || project?.brand?.clientId
    navigate(`/${cId}/${project.brandId}/${project.id}/${tabName}`)
  }

  const renderCrumb = useMemo(() => {
    return (
      <SearchBreadCrumb
        {...{
          activeBid,
          bidsViewMode,
          bidDetailsViewMode,
          disableCompareBids: bidsViewMode !== 'Compare' || (_get(selectedBids, 'length') || 0) < 2,

          searchView,
          tabName,

          goBack,
          onCompareBids,
          onChangeBidsViewMode: () =>
            dispatch({
              type: 'ChangeBidsViewMode',
              bidsViewMode: bidsViewMode === 'All' ? 'Compare' : 'All',
            }),
          onChangeBidDetailsViewMode: () =>
            dispatch({
              type: 'ChangeBidDetailsViewMode',
              bidDetailsViewMode: bidDetailsViewMode === 'Expand' ? 'Collapse' : 'Expand',
            }),
        }}
      />
    )
  }, [activeBid, bidsViewMode, bidDetailsViewMode, selectedBids, tabName, searchView])

  const renderProjectsSearch = useMemo(() => {
    return (
      <ProjectsSearchContainer searchPhrase={searchView.searchPhrase}>
        <ProjectsCardViewHits
          {...{
            handleViewProject,
          }}
        />
      </ProjectsSearchContainer>
    )
  }, [searchView.searchPhrase])

  const renderBidsSearch = useMemo(() => {
    return <BidsSearchContainer searchPhrase={searchView.searchPhrase} />
  }, [searchView.searchPhrase])

  const renderDeliverablesSearch = useMemo(() => {
    return <DeliverablesSearchContainer editable={true} searchPhrase={searchView.searchPhrase} />
  }, [searchView.searchPhrase])

  const renderAllSearch = useMemo(() => {
    return <AllSearchContainer searchPhrase={searchView.searchPhrase} />
  }, [searchView.searchPhrase])

  const tempTabName = _capitalize(tabName)
  return (
    <>
      {renderCrumb}

      <div
        className={clsx(classes.root, 'pag-app-content', {
          'pag-no-visibility': tempTabName !== 'All',
        })}
      >
        {renderAllSearch}
      </div>

      <div
        className={clsx(classes.root, 'pag-app-content', {
          'pag-no-visibility': tempTabName !== 'Projects',
        })}
      >
        {renderProjectsSearch}
      </div>

      <div
        className={clsx(classes.root, 'pag-app-content', {
          'pag-no-visibility': tempTabName !== 'Bids',
        })}
      >
        {renderBidsSearch}
      </div>

      <div
        className={clsx(classes.root, 'pag-app-content', {
          'pag-no-visibility': tempTabName !== 'Deliverables',
        })}
      >
        {renderDeliverablesSearch}
      </div>
    </>
  )
}

export default SearchContainer
