import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import Paper from '@material-ui/core/Paper'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ProjectItemNormal from './containers/normal'
import ProjectItemSidebar from './containers/sidebar'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { errorHandler } from '@/shared/error-handler'
import { ADD_AGENCY_PRODUCER } from '@/shared/graphql/mutations/mutationAgencyProducers'
import { ADD_CAMPAIGN } from '@/shared/graphql/mutations/mutationCampaigns'
import { ADD_MARKET } from '@/shared/graphql/mutations/mutationMarkets'
import { ADD_TAG } from '@/shared/graphql/mutations/mutationTags'
import { UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { GET_AGENCIES } from '@/shared/graphql/queryGetAgencies'
import { GET_AGENCY_PRODUCERS } from '@/shared/graphql/queryGetAgencyProducers'
import { GET_BID_COUNTRIES } from '@/shared/graphql/queryGetBidCountries'
import { GET_BID_TYPES } from '@/shared/graphql/queryGetBidTypes'
import { GET_CAMPAIGNS } from '@/shared/graphql/queryGetCampaigns'
import { GET_CONTACTS } from '@/shared/graphql/queryGetContacts'
import { GET_CITIES } from '@/shared/graphql/queryGetCities'
import { GET_CURRENCIES } from '@/shared/graphql/queryGetCurrencies'
import { GET_SHOOT_TYPES } from '@/shared/graphql/queryGetShootTypes'
import { GET_MARKETS } from '@/shared/graphql/queryGetMarkets'
import { GET_PROJECT_CATEGORIES } from '@/shared/graphql/queryGetProjectCategories'
import { GET_PROJECT_REGIONS } from '@/shared/graphql/queryGetProjectRegions'
import { GET_PROJECT_STATUSES } from '@/shared/graphql/queryGetProjectStatus'
import { GET_PROJECT_TEAM_ROLES } from '@/shared/graphql/queryGetProjectTeamRoles'
import { GET_PROJECT_TYPES } from '@/shared/graphql/queryGetProjectTypes'
import { GET_TAGS } from '@/shared/graphql/queryGetTags'

import { callPagApi } from '@/shared/services/pag-api'
import { Context } from '@/shared/store/reducers/global-reducer'
import { stringToNumber } from '@/utils/numeric'
import { WidgetConfigType, WidgetConfigDefaultProps } from '../config'
import { IBidCountry } from '@/shared/models/bid-country'
import { IContact } from '@/shared/models/contact'
import { ICity } from '@/shared/models/city'
import { ICurrency } from '@/shared/models/currency'
import { IShootType } from '@/shared/models/shoot-type'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 80,
    width: '100%',
  },
}))

export const ProjectItemWidgetContext = createContext<{
  cities: ICity[]
  contacts: IContact[]
  countries: IBidCountry[]
  options: any[]
  shootTypes: IShootType[]
  addContact: (e: IContact) => void
  updateContact: (e: IContact) => void
  addOption: (e: any) => void
  updateOption: (e: any) => void
}>({
  cities: [],
  contacts: [],
  countries: [],
  options: [],
  shootTypes: [],
  addContact: (e: IContact) => {},
  updateContact: (e: IContact) => {},
  addOption: (e: any) => {},
  updateOption: (e: any) => {},
})

const ProjectItemWidget = (props: any) => {
  const {
    widgetConfig: { order, area, permissions, visible },
    config,
    inputs: {
      tenantId,
      clientId,
      project,
      label,
      name,
      labelKey,
      valueKey,
      type,
      dataType,
      isCreatable,
      showAddButton,
      InputProps,
    },
    outputs: { handleCancel, handleSubmit },
    shouldUpdateProject = true
  } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  let abortController: any
  const multiple = _get(InputProps, ['AutocompleteProps', 'multiple'])
  const mountedRef = useMounted(true)
  const newValueRef = useRef(null as any)
  const [options, setOptions] = useState([] as any)
  const [contacts, setContacts] = useState<IContact[]>([])
  const [countries, setCountries] = useState<IBidCountry[]>([])
  const [cities, setCities] = useState<ICity[]>([])
  const [shootTypes, setShootTypes] = useState<IShootType[]>([])

  const [getAgencies] = useLazyQuery(GET_AGENCIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.agencies?.data || [], ['name']))
    },
  })

  const [getAgencyProducers] = useLazyQuery(GET_AGENCY_PRODUCERS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.agencyProducers?.data || [], ['name']))
    },
  })

  const [getCampaigns] = useLazyQuery(GET_CAMPAIGNS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.campaigns?.data || [], ['name']))
    },
  })

  const [getContacts] = useLazyQuery(GET_CONTACTS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setContacts(_orderBy(res.contacts?.data || [], ['name']))
    },
  })

  const [getCurrencies] = useLazyQuery(GET_CURRENCIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.currencies?.data || [], ['currency']))
    },
  })

  const [getMarkets] = useLazyQuery(GET_MARKETS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.markets?.data || [], ['name']))
    },
  })

  const [getProjectCategories] = useLazyQuery(GET_PROJECT_CATEGORIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.projectCategories?.data || [], ['region']))
    },
  })

  const [getProjectStatuses] = useLazyQuery(GET_PROJECT_STATUSES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.projectStatuses?.data || [], ['status']))
    },
  })

  const [getProjectTypes] = useLazyQuery(GET_PROJECT_TYPES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.projectTypes?.data || [], ['projectType']))
    },
  })

  const [getProjectRegions] = useLazyQuery(GET_PROJECT_REGIONS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.projectRegions?.data || [], ['region']))
    },
  })

  const [getProjectTeamRoles] = useLazyQuery(GET_PROJECT_TEAM_ROLES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setOptions([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.projectTeamRoles?.data || [], ['role']))
    },
    fetchPolicy: 'network-only',
  })

  const [getBidTypes] = useLazyQuery(GET_BID_TYPES, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.bidTypes?.data || [], ['bidType']))
    },
  })

  const [getBidCountries] = useLazyQuery(GET_BID_COUNTRIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setCountries([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setCountries(_orderBy(res.bidCountries?.data || [], ['country']))
    },
    fetchPolicy: 'network-only',
  })

  const [getCities] = useLazyQuery(GET_CITIES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setCities([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setCities(_orderBy(res.cities?.data || [], ['name']))
    },
    fetchPolicy: 'network-only',
  })

  const [getShootTypes] = useLazyQuery(GET_SHOOT_TYPES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setShootTypes([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setShootTypes(_orderBy(res.shootTypes?.data || [], ['name']))
    },
    fetchPolicy: 'network-only',
  })

  const [getTags] = useLazyQuery(GET_TAGS, {
    onError: (err) => {
      if (!mountedRef.current) return
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setOptions(_orderBy(res.tags?.data || [], ['name']))
    },
  })

  const [addAgencyProducer, addAgencyProducerRes] = useMutation(ADD_AGENCY_PRODUCER, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const [addCampaign, addCampaignRes] = useMutation(ADD_CAMPAIGN, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const [addMarket, addMarketRes] = useMutation(ADD_MARKET, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const [addTag, addTagRes] = useMutation(ADD_TAG, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    return () => {
      if (abortController) abortController.abort()
    }
  }, [])

  useEffect(() => {
    if (mountedRef.current && updateProjectRes.data) {
      let temp = _get(updateProjectRes.data, ['updateProject'])
      if (temp) {
        if (name === 'currencyId') {
          temp = options.find((o: ICurrency) => o.id === newValueRef.current)
          if (temp) {
            project.currency = {
              id: newValueRef.current,
              currency: temp.currency,
            }
          }
        }
        handleSubmit(newValueRef.current)
      }
    }
    return () => {}
  }, [updateProjectRes.data])

  useEffect(() => {
    if (!mountedRef.current || (!activeTenant?.id && !tenantId)) return
    const cId = clientId || project?.clientId || project?.brand?.clientId
    abortController = new AbortController()

    try {
      switch (name) {
        case 'agencyId':
          callPagApi(
            '',
            getTokenSilently,
            getAgencies,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'agencyProducerId':
          callPagApi(
            '',
            getTokenSilently,
            getAgencyProducers,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'campaignId':
          callPagApi(
            '',
            getTokenSilently,
            getCampaigns,
            {
              tenantId: activeTenant.id,
              clientId: cId,
            },
            abortController.signal
          )
          break
        case 'currencyId':
          callPagApi(
            '',
            getTokenSilently,
            getCurrencies,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'marketId':
          callPagApi(
            '',
            getTokenSilently,
            getMarkets,
            {
              tenantId: activeTenant.id,
              clientId: cId,
            },
            abortController.signal
          )
          break
        case 'projectCategoryId':
          callPagApi(
            '',
            getTokenSilently,
            getProjectCategories,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'projectRegionId':
          callPagApi(
            '',
            getTokenSilently,
            getProjectRegions,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'projectStatusId':
          callPagApi(
            '',
            getTokenSilently,
            getProjectStatuses,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'projectTypeId':
          callPagApi(
            '',
            getTokenSilently,
            getProjectTypes,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'bidTypeId':
          callPagApi(
            '',
            getTokenSilently,
            getBidTypes,
            { tenantId: activeTenant.id },
            abortController.signal
          )
          break
        case 'metaData.projectTeam':
        case 'projectTeam':
          callPagApi(
            '',
            getTokenSilently,
            getContacts,
            {
              tenantId: activeTenant?.id || tenantId,
              clientId: cId,
            },
            abortController.signal
          )

          callPagApi(
            '',
            getTokenSilently,
            getProjectTeamRoles,
            {
              tenantId: activeTenant?.id || tenantId,
              clientId: cId,
            },
            abortController.signal
          )
          break
        case 'metaData.shoots':
        case 'shoots':
          callPagApi(
            '',
            getTokenSilently,
            getBidCountries,
            { tenantId: activeTenant?.id || tenantId },
            abortController.signal
          )

          callPagApi(
            '',
            getTokenSilently,
            getCities,
            { tenantId: activeTenant?.id || tenantId },
            abortController.signal
          )

          callPagApi(
            '',
            getTokenSilently,
            getShootTypes,
            { tenantId: activeTenant?.id || tenantId },
            abortController.signal
          )
          break
        case 'tagIds':
          callPagApi(
            '',
            getTokenSilently,
            getTags,
            {
              tenantId: activeTenant?.id || tenantId,
              visibleUploadPortal: labelKey === 'description' ? true : undefined,
            },
            abortController.signal
          )
          break
      }
    } catch (e) {
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant, tenantId, name])

  useEffect(() => {
    if (!mountedRef.current || !addAgencyProducerRes.data?.addAgencyProducer) return
    setOptions([...options, addAgencyProducerRes.data?.addAgencyProducer])
    handleUpdateProject(addAgencyProducerRes.data.addAgencyProducer.id)
  }, [addAgencyProducerRes.data])

  useEffect(() => {
    if (!mountedRef.current || !addCampaignRes.data?.addCampaign) return
    setOptions([...options, addCampaignRes.data.addCampaign])
    handleUpdateProject(addCampaignRes.data.addCampaign.id)
  }, [addCampaignRes.data])

  useEffect(() => {
    if (!mountedRef.current || !addMarketRes.data?.addMarket) return
    setOptions([...options, addMarketRes.data.addMarket])
    handleUpdateProject(addMarketRes.data.addMarket.id)
  }, [addMarketRes.data])

  useEffect(() => {
    if (!mountedRef.current || !addTagRes.data?.addTag) return
    setOptions([...options, addTagRes.data.addTag])
    handleUpdateProject([...(InputProps.value || []), addTagRes.data.addTag.id])
  }, [addTagRes.data])

  const handleAddCampaign = (value: any) => {
    const cId = clientId || project?.clientId || project?.brand?.clientId
    if (cId) {
      try {
        abortController = new AbortController()
        const payload = {
          tenantId: activeTenant?.id,
          clientId: cId,
          name: value,
        }

        callPagApi('', getTokenSilently, addCampaign, payload, abortController.signal)
      } catch (err) {
        console.error('Error in Add Campaign', err)
        if (abortController) abortController.abort()
      }
    } else {
      console.log('no client')
    }
  }

  const handleAddAgencyProducer = (value: any) => {
    const cId = clientId || project?.clientId || project?.brand?.clientId

    if (cId) {
      try {
        abortController = new AbortController()
        const payload = {
          tenantId: activeTenant?.id,
          clientId: cId,
          name: value,
        }

        callPagApi('', getTokenSilently, addAgencyProducer, payload, abortController.signal)
      } catch (err) {
        console.error('Error in Add Agecny Producer', err)
        if (abortController) abortController.abort()
      }
    } else {
      console.log('no client')
    }
  }

  const handleAddMarket = (value: any) => {
    const cId = clientId || project?.clientId || project?.brand?.clientId

    if (cId) {
      try {
        abortController = new AbortController()
        const payload = {
          tenantId: activeTenant?.id,
          clientId: cId,
          name: value,
        }

        callPagApi('', getTokenSilently, addMarket, payload, abortController.signal)
      } catch (err) {
        console.error('Error in Add Market', err)
        if (abortController) abortController.abort()
      }
    } else {
      console.log('no client')
    }
  }

  const handleAddTag = (name: any) => {
    try {
      abortController = new AbortController()
      const payload = {
        tenantId: activeTenant?.id,
        name,
      }

      callPagApi('', getTokenSilently, addTag, payload, abortController.signal)
    } catch (err) {
      console.error('Error in Add Campaign', err)
      if (abortController) abortController.abort()
    }
  }

  const handleUpdateProject = (value: any) => {
    try {
      abortController = new AbortController()
      let tempValue = value?.value || value
      if (dataType === 'float' || dataType === 'int') {
        tempValue = stringToNumber(tempValue, dataType)
      }

      newValueRef.current = tempValue
      const payload: any = {
        tenantId: activeTenant?.id,
        id: project.id,
      }

      if (name.indexOf('metaData.') === 0) {
        payload['metaData'] = {
          ...(_get(project, 'metaData') || {}),
          [name.substring(9)]: value,
        }
      } else {
        payload[name] = tempValue
      }

      callPagApi('', getTokenSilently, updateProject, payload, abortController.signal)
    } catch (err) {
      console.error('Error in Update Project', err)
      if (abortController) abortController.abort()
    }
  }

  const handleChange = (value: any, shouldCallApi: boolean = true) => {
    if (!project || !shouldCallApi || !shouldUpdateProject) {
      handleSubmit(value)
      return
    }

    if (`${value}` === `${InputProps.defaultValue}`) {
      return
    }

    switch (name) {
      case 'agencyProducerId':
        if (value.isNew) {
          handleAddAgencyProducer(value.newValue)
        } else {
          handleUpdateProject(value.id)
        }
        break
      case 'campaignId':
        if (value.isNew) {
          handleAddCampaign(value.newValue)
        } else {
          handleUpdateProject(value.id)
        }
        break
      case 'marketId':
        if (value.isNew) {
          handleAddMarket(value.newValue)
        } else {
          handleUpdateProject(value.id)
        }
        break
      case 'tagIds':
        if (multiple) {
          const temp1 = (value || []).filter((v: any) => !v.isNew)
          const temp2 = (value || []).find((v: any) => Boolean(v.isNew)) // new tag
          if (temp2) {
            handleAddTag(temp2.newValue)
          } else {
            handleUpdateProject(temp1.map((t: any) => t.id))
          }
        } else {
          if (value.isNew) {
            handleAddTag(value.newValue)
          } else {
            handleUpdateProject(value.id)
          }
        }
        break
      default:
        handleUpdateProject(value?.id || value)
    }
  }

  const renderItemWidget = useMemo(() => {
    switch (area) {
      case 'Sidebar':
        return (
          <ProjectItemSidebar
            {...{
              label,
              name,
              labelKey,
              valueKey,
              clientId,
              project,
              type,
              isCreatable,
              showAddButton,
              InputProps,
              loading:
                updateProjectRes.loading ||
                addAgencyProducerRes.loading ||
                addCampaignRes.loading ||
                addTagRes.loading,
              handleCancel,
              handleChange,
            }}
          />
        )
      default:
        return (
          <ProjectItemNormal
            {...{
              name,
              labelKey,
              valueKey,
              clientId,
              project,
              type,
              isCreatable,
              InputProps,
              loading: updateProjectRes.loading,
              handleCancel,
              handleChange,
            }}
          />
        )
    }
  }, [
    project,
    updateProjectRes.loading,
    addAgencyProducerRes.loading,
    addCampaignRes.loading,
    addTagRes.loading,
    InputProps,
  ])

  return (
    <Paper id={`project-item-${name}`} elevation={0} className={classes.root}>
      <ProjectItemWidgetContext.Provider
        value={{
          cities,
          contacts,
          countries,
          options,
          shootTypes,
          addContact: (e: IContact) => {
            setContacts([...contacts, e])
          },
          updateContact: (e: IContact) => {
            const tempIndex = contacts.findIndex((c) => c.id === e.id)
            if (tempIndex) {
              setContacts([...contacts.slice(0, tempIndex), e, ...contacts.slice(tempIndex + 1)])
            }
          },
          addOption: (e: any) => {
            setOptions([...options, e])
          },
          updateOption: (e: any) => {
            const tempIndex = options.findIndex((o: any) => o.id === e.id)
            if (tempIndex) {
              setOptions([...options.slice(0, tempIndex), e, ...options.slice(tempIndex + 1)])
            }
          },
        }}
      >
        {renderItemWidget}
      </ProjectItemWidgetContext.Provider>
    </Paper>
  )
}

ProjectItemWidget.propTypes = {
  widgetConfig: WidgetConfigType,
  config: PropTypes.shape({}).isRequired,
  inputs: PropTypes.shape({
    tenantId: PropTypes.number,
    clientId: PropTypes.number,
    project: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    labelKey: PropTypes.string, // for only auto-complete
    valueKey: PropTypes.string, // for only auto-complete
    type: PropTypes.string,
    dataType: PropTypes.oneOf(['int', 'float', 'date', 'string', 'object']),
    isCreatable: PropTypes.bool,
    showAddButton: PropTypes.bool,
    InputProps: PropTypes.object,
  }).isRequired,
  outputs: PropTypes.shape({
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  shouldUpdateProject: PropTypes.bool
}

ProjectItemWidget.defaultProps = {
  widgetConfig: WidgetConfigDefaultProps,
  outputs: {
    handleCancel: (e: any) => {},
    handleSubmit: (e: any) => {},
  },
}

export default ProjectItemWidget
