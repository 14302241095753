import gql from 'graphql-tag'

const ADD_PROJECT = gql`
  mutation addProject(
    $tenantId: Int!
    $projectName: String!
    $projectNumber: String!
    $description: String
    $details: String
    $actualizedDate: Date
    $budgetRate: Float
    $budgetTotal: Float
    $deliveryDate: Date
    $productionStartDate: Date
    $preBidDate: Date
    $sustainability: Boolean
    $metaData: JSON
    $image: String
    $files: [JSON]
    $agencyId: Int!
    $agencyProducerId: Int
    $bidTypeId: Int
    $brandId: Int!
    $campaignId: Int
    $currencyId: Int
    $marketId: Int
    $projectRegionId: Int
    $projectCategoryId: Int
    $projectStatusId: Int
    $projectTypeId: Int
    $tagIds: [Int]
  ) {
    addProject(
      tenantId: $tenantId
      projectName: $projectName
      projectNumber: $projectNumber
      details: $details
      description: $description
      actualizedDate: $actualizedDate
      budgetRate: $budgetRate
      budgetTotal: $budgetTotal
      deliveryDate: $deliveryDate
      productionStartDate: $productionStartDate
      preBidDate: $preBidDate
      sustainability: $sustainability
      metaData: $metaData
      image: $image
      files: $files
      agencyId: $agencyId
      agencyProducerId: $agencyProducerId
      bidTypeId: $bidTypeId
      brandId: $brandId
      campaignId: $campaignId
      currencyId: $currencyId
      marketId: $marketId
      projectRegionId: $projectRegionId
      projectCategoryId: $projectCategoryId
      projectStatusId: $projectStatusId
      projectTypeId: $projectTypeId
      tagIds: $tagIds
    ) {
      id
      projectName
      projectNumber
      description
      details
      actualizedDate
      approved
      approvedAt
      budgetRate
      budgetTotal
      deliveryDate
      fundingBeginDate
      fundingEndDate
      frAmount
      fundingNotes
      productionStartDate
      preBidDate
      sustainability
      metaData
      image
      files
      agencyId
      agencyProducerId
      approvedBy
      bidTypeId
      brandId
      campaignId
      clientId
      currencyId
      marketId
      projectCategoryId
      projectRegionId      
      projectStatusId
      projectTypeId
      tagIds
      currency {
        id
        currency
      }
    }
  }
`
const UPDATE_PROJECT = gql`
  mutation updateProject(
    $tenantId: Int!
    $id: Int!
    $projectName: String
    $projectNumber: String
    $details: String
    $description: String
    $actualizedDate: Date
    $approved: Boolean
    $budgetLineItem: String
    $budgetRate: Float
    $budgetTotal: Float
    $deliveryDate: Date
    $fundingBeginDate: Date
    $fundingEndDate: Date
    $fundingNotes: String
    $frAmount: Float
    $productionStartDate: Date
    $preBidDate: Date
    $metaData: JSON
    $sustainability: Boolean
    $image: String
    $files: [JSON]
    $approvedBy: Int
    $agencyId: Int
    $agencyProducerId: Int
    $bidTypeId: Int
    $brandId: Int
    $campaignId: Int
    $clientId: Int
    $currencyId: Int
    $marketId: Int
    $projectCategoryId: Int
    $projectStatusId: Int
    $projectRegionId: Int
    $projectTypeId: Int
    $tagIds: [Int]
  ) {
    updateProject(
      tenantId: $tenantId
      id: $id
      projectName: $projectName
      projectNumber: $projectNumber
      details: $details
      description: $description
      actualizedDate: $actualizedDate
      approved: $approved
      budgetLineItem: $budgetLineItem
      budgetRate: $budgetRate
      budgetTotal: $budgetTotal
      deliveryDate: $deliveryDate
      fundingBeginDate: $fundingBeginDate
      fundingEndDate: $fundingEndDate
      frAmount: $frAmount
      fundingNotes: $fundingNotes
      productionStartDate: $productionStartDate
      preBidDate: $preBidDate
      metaData: $metaData
      sustainability: $sustainability
      image: $image
      files: $files
      approvedBy: $approvedBy
      agencyId: $agencyId
      agencyProducerId: $agencyProducerId
      bidTypeId: $bidTypeId
      brandId: $brandId
      campaignId: $campaignId
      clientId: $clientId
      currencyId: $currencyId
      marketId: $marketId
      projectCategoryId: $projectCategoryId
      projectRegionId: $projectRegionId
      projectStatusId: $projectStatusId
      projectTypeId: $projectTypeId
      tagIds: $tagIds
    ) {
      id
      projectName
      projectNumber
      description
      details
      actualizedDate
      approved
      approvedAt
      budgetLineItem
      budgetRate
      budgetTotal
      deliveryDate
      fundingBeginDate
      fundingEndDate
      frAmount
      fundingNotes
      productionStartDate
      preBidDate
      sustainability
      metaData
      image
      files
      agencyId
      agencyProducerId
      approvedBy
      bidTypeId
      brandId
      campaignId
      clientId
      currencyId
      marketId
      projectCategoryId
      projectRegionId      
      projectStatusId
      projectTypeId
      tagIds
      currency {
        id
        currency
      }
    }
  }
`

const UPLOAD_PROJECT_WITH_DOCUMENTS = gql`
  mutation uploadProjectWithDocuments(
    $tenantId: Int!
    $id: Int
    $projectName: String
    $projectNumber: String
    $details: String
    $description: String
    $actualizedDate: Date
    $budgetLineItem: String
    $budgetRate: Float
    $budgetTotal: Float
    $deliveryDate: Date
    $fundingBeginDate: Date
    $fundingEndDate: Date
    $fundingNotes: String
    $frAmount: Float
    $image: String
    $metaData: JSON
    $productionStartDate: Date
    $preBidDate: Date    
    $sustainability: Boolean
    $agencyId: Int
    $agencyProducerId: Int
    $bidTypeId: Int
    $brandId: Int
    $campaignId: Int
    $clientId: Int
    $currencyId: Int
    $marketId: Int
    $projectCategoryId: Int
    $projectStatusId: Int
    $projectRegionId: Int
    $projectTypeId: Int
    $tagIds: [Int]
    $documents: [JSON]
    $comments: String
  ) {
    uploadProjectWithDocuments(
      tenantId: $tenantId
      id: $id
      projectName: $projectName
      projectNumber: $projectNumber
      details: $details
      description: $description
      actualizedDate: $actualizedDate
      budgetLineItem: $budgetLineItem
      budgetRate: $budgetRate
      budgetTotal: $budgetTotal
      deliveryDate: $deliveryDate
      fundingBeginDate: $fundingBeginDate
      fundingEndDate: $fundingEndDate      
      fundingNotes: $fundingNotes
      frAmount: $frAmount
      image: $image
      metaData: $metaData
      productionStartDate: $productionStartDate
      preBidDate: $preBidDate      
      sustainability: $sustainability
      agencyId: $agencyId
      agencyProducerId: $agencyProducerId
      bidTypeId: $bidTypeId
      brandId: $brandId
      campaignId: $campaignId
      clientId: $clientId
      currencyId: $currencyId
      marketId: $marketId
      projectCategoryId: $projectCategoryId
      projectRegionId: $projectRegionId
      projectStatusId: $projectStatusId
      projectTypeId: $projectTypeId
      tagIds: $tagIds
      documents: $documents
      comments: $comments
    ) {
      id
      projectName
      projectNumber
      description
      details
      actualizedDate
      approved
      approvedAt
      budgetLineItem
      budgetRate
      budgetTotal
      deliveryDate
      files
      fundingBeginDate
      fundingEndDate
      fundingNotes
      frAmount
      image
      metaData
      productionStartDate
      preBidDate
      sustainability
      agencyId
      agencyProducerId
      approvedBy
      bidTypeId
      brandId
      campaignId
      clientId
      currencyId
      marketId
      projectCategoryId
      projectRegionId      
      projectStatusId
      projectTypeId
      tagIds
      agency {
        id
        name
      }
      brand {
        id
        name
        client {
          id
          name
          tenant {
            id
            name
          }
        }
      }
      client {
        id
        name
        tenant {
          id
          name
        }
      }
      currency {
        id
        currency
      }
    }
  }
`

const DOWNLOAD_PROJECT = gql`
  mutation downloadProject($tenantId: Int!, $id: Int!) {
    downloadProject(tenantId: $tenantId, id: $id) {
      success
      filename
      url
      error
    }
  }
`

const DOWNLOAD_PROJECT_FILES = gql`
  mutation downloadProjectFiles($tenantId: Int!, $id: Int!) {
    downloadProjectFiles(tenantId: $tenantId, id: $id) {
      success
      filename
      base64
      error
    }
  }
`

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload) {
    uploadFile(file: $file) {
      filename
    }
  }
`

export {
  ADD_PROJECT,
  DOWNLOAD_PROJECT,
  DOWNLOAD_PROJECT_FILES,
  UPDATE_PROJECT,
  UPLOAD_PROJECT_WITH_DOCUMENTS,
  UPLOAD_FILE,
}
