import gql from 'graphql-tag'

const SORT_SERVICE_CATEGORY = gql`
  mutation sortServiceCategory(
    $tenantId: Int!
    $sourceServiceCategory: String!
    $destinationServiceCategory: String!
  ) {
    sortServiceCategory(
      tenantId: $tenantId
      sourceServiceCategory: $sourceServiceCategory
      destinationServiceCategory: $destinationServiceCategory
    ) {
      success
      error
    }
  }
`

export { SORT_SERVICE_CATEGORY }
