import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ProjectsContainer, ProjectDashboard, SearchContainer } from './projects'
import { ReportsContainer } from './reports'
import UploadPortalContainer from './upload-portal'
import CalendarContainer from './calendar'
import { BudgetsContainer } from './budgets'
import NotFound from './not-found'
import PrivacyPolicy from './onboard/privacy-policy'
import UsageTerms from './onboard/usage-terms'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsContainer />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/usage-terms" element={<UsageTerms />} />
      <Route path="search/:tabName" element={<SearchContainer />} />
      <Route path="p/:slug">
        <Route index element={<UploadPortalContainer />} />
        <Route path="new-project" element={<UploadPortalContainer />} />
        <Route path="upload-files" element={<UploadPortalContainer />} />
      </Route>
      <Route path=":clientId">
        <Route path="calendar" element={<CalendarContainer />} />
        <Route path="list" element={<ProjectsContainer />} />
        <Route path="reports" element={<ReportsContainer />} />
        <Route path="views" element={<BudgetsContainer />} />
        <Route path=":brandId">
          <Route path="list" element={<ProjectsContainer />} />
          <Route path=":projectId">
            <Route index element={<ProjectDashboard />} />
            <Route path="bids/:bidId" element={<ProjectDashboard />} />
            <Route path=":tabName" element={<ProjectDashboard />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound visibleLogo={false} />} />
    </Routes>
  )
}

export default AppRoutes
