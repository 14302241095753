import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import NumberFormat from 'react-number-format'

import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ProjectCostEntryItemEdit from './project-cost-entry-item-edit-component'
import ProjectCostEntryItemOverageEdit from './project-cost-entry-item-overage-edit-component'

import { CURRENCIES } from '@/shared/constants/currency'
import { ProjectCostEntryItemType } from '../config'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 28,
    height: 28,

    borderWidth: 'thin',
    borderBottomStyle: 'solid',
    borderRightStyle: 'solid',
    borderColor: '#dbdbdb',
    color: '#575757',

    textAlign: 'right',
    padding: 2,

    '&.editable:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  projectCostEntryItemAdd: {
    '&.editable': {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
        cursor: 'pointer',
        textAlign: 'center',
        '& .icon-add': {
          display: 'initial',
        },
        '& .icon-remove': {
          display: 'none',
        },
      },
      '& .icon-add': {
        display: 'none',
      },
      '& .icon-remove': {
        display: 'initial',
      },
    },
  },
  form: {
    display: 'block',
  },
  cardContent: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))

const ProjectCostEntryItemComponent = (props: any) => {
  const {
    viewPortContainerRef,
    projectCostEntryItemType,
    clientId,
    projectId,
    projectCostEntryId,
    customFieldsMetaData,
    projectCostEntryItem,
    projectCostEntryItems,
    sequence,
    currency,
    editable,
    handleSubmit,
    handleCancel,
  } = props

  const classes = useStyles(props)
  const [editingStatus, setEditingStatus] = useState(false)
  const [amount, setAmount] = useState(null as any)

  useEffect(() => {
    if (projectCostEntryItemType === ProjectCostEntryItemType.OVERAGE) {
      if (!projectCostEntryItems.length) {
        setAmount(null)
      } else {
        setAmount(_sumBy(projectCostEntryItems, 'amount') || 0)
      }
    } else {
      if (!projectCostEntryItem) {
        setAmount(null)
      } else {
        setAmount(_get(projectCostEntryItem, ['amount']))
      }
    }

    return () => {}
  }, [projectCostEntryItem, projectCostEntryItems])

  return (
    <ClickAwayListener
      onClickAway={(e: any) => {
        const isDialog = _findIndex(e.path, (ele: any) => {
          return (
            ele.className &&
            typeof ele.className === 'string' &&
            (ele.className.indexOf('MuiDialog') >= 0 ||
              ele.className.indexOf('MuiAutocomplete-popper') >= 0)
          )
        })

        if (isDialog < 0) setEditingStatus(false)
      }}
    >
      <Paper
        className={clsx(
          classes.root,
          'pag-text-ellipsis',
          projectCostEntryId && editable ? 'editable' : undefined,
          amount === null ? classes.projectCostEntryItemAdd : undefined
        )}
        elevation={0}
        square
        onClick={() => {
          if (editable && projectCostEntryId) {
            setEditingStatus(!editingStatus)
          }
        }}
      >
        {amount !== null ? (
          <NumberFormat
            displayType={'text'}
            value={amount}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={CURRENCIES[_get(currency, ['currency']) || ''] || '$'}
          />
        ) : (
          <>
            <Icon className="icon-remove">remove</Icon>
            {Boolean(projectCostEntryId) && editable && (
              <Icon className={'icon-add'} color="primary">
                add
              </Icon>
            )}
          </>
        )}

        {Boolean(projectCostEntryId) && editingStatus && (
          <>
            {projectCostEntryItemType === ProjectCostEntryItemType.OVERAGE ? (
              <ProjectCostEntryItemOverageEdit
                viewPortContainerRef={viewPortContainerRef}
                projectCostEntryId={projectCostEntryId}
                projectCostEntryItems={projectCostEntryItems}
                sequence={sequence}
                currency={currency}
                handleSubmit={(p: any) => {
                  setEditingStatus(false)
                  handleSubmit(p)
                }}
                handleCancel={handleCancel}
              />
            ) : (
              <ProjectCostEntryItemEdit
                viewPortContainerRef={viewPortContainerRef}
                clientId={clientId}
                projectId={projectId}
                projectCostEntryId={projectCostEntryId}
                customFieldsMetaData={customFieldsMetaData}
                projectCostEntryItem={projectCostEntryItem}
                projectCostEntryItemType={projectCostEntryItemType}
                sequence={sequence}
                currency={currency}
                handleSubmit={(p: any) => {
                  setEditingStatus(false)
                  handleSubmit(p)
                }}
                handleCancel={handleCancel}
              />
            )}
          </>
        )}
      </Paper>
    </ClickAwayListener>
  )
}

ProjectCostEntryItemComponent.propTypes = {
  viewPortContainerRef: PropTypes.any,
  clientId: PropTypes.number,
  projectId: PropTypes.number,
  projectCostEntryId: PropTypes.number,
  customFieldsMetaData: PropTypes.object,
  projectCostEntryItem: PropTypes.object,
  projectCostEntryItems: PropTypes.array,
  sequence: PropTypes.number,
  projectCostEntryItemType: PropTypes.any,
  currency: PropTypes.object,
  editable: PropTypes.bool,

  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

ProjectCostEntryItemComponent.defaultProps = {
  projectCostEntryItem: null,
  projectCostEntryItems: [],
  type: '',
  sequence: 0,
  editable: true,
  handleSubmit: (e: any) => {},
  handleCancel: () => {},
}

export default ProjectCostEntryItemComponent
