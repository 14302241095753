import gql from 'graphql-tag'

const GET_COST_DRIVERS_OPTIMIZERS = gql`
  query costDriversOptimizers(
    $tenantId: Int!
    $name: String
    $type: CostDriverOptimizerType
    $offset: Int
    $limit: Int
  ) {
    costDriversOptimizers(
      tenantId: $tenantId
      name: $name
      type: $type
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        clientId
        type
      }
    }
  }
`

export { GET_COST_DRIVERS_OPTIMIZERS }
