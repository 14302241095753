import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import _debounce from 'lodash/debounce'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import PagNumberFormatInput from '@/components/pag-form/pag-number-format-Input'

import { Context } from '@/shared/store/reducers/global-reducer'
import { UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'
import { CURRENCIES } from '@/shared/constants/currency'
import { stringToNumber } from '@/utils/numeric'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
  },
}))

const DefaultFormData = {
  fundingBeginDate: null,
  fundingEndDate: null,
  frAmount: '',
  fundingNotes: '',
}

const FundingNotesForm = (props: any) => {
  const { projectId } = props
  const classes = useStyles()
  const mountedRef = useMounted(true)
  const { getTokenSilently } = useAuth0()
  const { store, dispatch } = useContext(Context)
  const { activeTenant, activeProject } = store
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DefaultFormData,
  })
  const {
    control,
    getValues,
    reset,
    formState: { isValid },
    handleSubmit,
  } = hookFormMethods

  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      dispatch({ type: 'SetActiveProject', payload: { project: res.updateProject || null } })
    },
  })

  useEffect(() => {
    if (!mountedRef.current) return
    reset(
      {
        fundingBeginDate: activeProject?.fundingBeginDate,
        fundingEndDate: activeProject?.fundingEndDate,
        frAmount: activeProject?.frAmount ? `${activeProject.frAmount}` : '',
        fundingNotes: activeProject?.fundingNotes || '',
      },
      { keepDefaultValues: false, keepIsValid: false }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTriggerChange = useCallback(
    _debounce((fieldName: string, e: any) => {
      handleFormSubmit()
    }, 800),
    []
  )

  const handleFormSubmit = async () => {
    let abortController: any
    try {
      abortController = new AbortController()
      const values = getValues()
      callPagApi(
        '',
        getTokenSilently,
        updateProject,
        {
          tenantId: activeTenant?.id,
          id: projectId,
          ...values,
          frAmount: stringToNumber(values.frAmount, 'float'),
        },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <form
      className={classes.root}
      name="fundingNotesForm"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Funding Notes</Typography>
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagDatePicker
                margin="none"
                label="Begin Date"
                format="MM/dd/yyyy"
                value={field.value}
                size="small"
                inputVariant="outlined"
                fullWidth={true}
                emptyLabel=""
                onChange={(e: any) => {
                  field.onChange(e)
                  debouncedTriggerChange('fundingBeginDate', e)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            control={control}
            name="fundingBeginDate"
            defaultValue={activeProject?.fundingBeginDate || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagDatePicker
                margin="none"
                label="End Date"
                format="MM/dd/yyyy"
                value={field.value}
                size="small"
                inputVariant="outlined"
                fullWidth={true}
                emptyLabel=""
                onChange={(e: any) => {
                  field.onChange(e)
                  debouncedTriggerChange('fundingEndDate', e)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            control={control}
            name="fundingEndDate"
            defaultValue={activeProject?.fundingEndDate || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagTextInput
                label="Requested Amount"
                placeholder="Requested Amount"
                size="small"
                variant="outlined"
                InputProps={{
                  name: 'frAmount',
                  inputComponent: PagNumberFormatInput,
                  inputProps: {
                    allowNegative: true,
                    allowEmptyFormatting: true,
                    thousandSeparator: true,
                    isNumericString: true,
                    decimalScale: 2,
                    // fixedDecimalScale: true,
                    prefix: CURRENCIES[activeProject?.currency?.currency || ''] || '$',
                  },
                }}
                error={fieldState.error}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e.target.value)
                  debouncedTriggerChange('frAmount', e.target.value)
                }}
                onBlur={field.onBlur}
              />
            )}
            control={control}
            name="frAmount"
            defaultValue={activeProject?.frAmount || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagTextInput
                label="Notes"
                placeholder="Notes"
                size="small"
                variant="outlined"
                error={fieldState.error}
                value={field.value}
                multiline
                minRows={4}
                onChange={(e: any) => {
                  field.onChange(e.target.value || '')
                  debouncedTriggerChange('fundingNotes', e.target.value)
                }}
                onBlur={field.onBlur}
              />
            )}
            control={control}
            name="fundingNotes"
            defaultValue={activeProject?.fundingNotes || ''}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default FundingNotesForm
