import gql from 'graphql-tag'

const GET_PURCHASE_ORDERS = gql`
  query purchaseOrders($tenantId: Int!, $projectId: Int!, $offset: Int, $limit: Int) {
    purchaseOrders(tenantId: $tenantId, projectId: $projectId, offset: $offset, limit: $limit) {
      total
      data {
        id
        poNumber
        beginDate
        endDate
        revision
        amount
        totalAmount
        revised
        projectId
        currencyId
        currency {
          id
          currency
        }
        createdAt
      }
    }
  }
`

export { GET_PURCHASE_ORDERS }
