import moment from 'moment'

export const dateToString = (date: any, monthStartDate?: boolean, monthEndDate?: boolean) => {
  if (date === 'Invalid Date') {
    return null
  } else if (date && date.getMonth) {
    if (monthStartDate) {
      return `${date.getMonth() + 1}/01/${date.getFullYear()}`
    } else if (monthEndDate) {
      const temp = new Date(date.getFullYear(), date.getMonth() + 2, 0)
      return `${date.getMonth() + 1}/${temp.getDate()}/${date.getFullYear()}`
    } else {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }
  } else if (typeof date === 'string') {
    return date
  }
  return null
}

export const dateStringToDate = (
  dateStr: any
) => {
  if (!dateStr) return null
  if (dateStr instanceof Date) return dateStr
  if (typeof dateStr !== 'string') return null
  let year, month, date
  let temp = dateStr.split('T')
  temp = temp[0].split(' ')
  temp = temp[0].split('-')

  if (temp.length === 3) {
    year = temp[0]
    month = temp[1]
    date = temp[2]
  } else {
    temp = temp[0].split('/')
    year = temp[2]
    month = temp[0]
    date = temp[1]
  }

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(date))
}

export const utcDateStringToLocalDate = (dateStr: string) => {
  if (!dateStr) return null
  return new Date(moment.utc(`${dateStr}`).valueOf())
}

export const utcDateStringToLocalDateString = (dateStr: string, format: string) => {
  if (!dateStr) return null
  return moment.utc(`${dateStr}`).format(format)
}
