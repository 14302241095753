import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  ${({ theme }) => `
    .MuiIconButton-root {
      padding: 2px;
      padding-right: 6px;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: 14px;
    }
  `}
`

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    '&.overview': {
      '&:hover .MuiInputBase-root:not(.Mui-focused)': {
        backgroundColor: theme.palette.grey[200],
      },
      '& .MuiOutlinedInput-root': {
        '&:not(.Mui-focused) fieldset': {
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
        },
      },
      '& .MuiInput-underline': {
        '&:not(.Mui-focused):before': {
          borderWidth: 0,
        },
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  helperText: {
    padding: '0 10px',
  },
  clearButton: {
    position: 'absolute',
    right: 24,
    bottom: 1,

    '&.outlined': {
      bottom: 5,
    },
  },
}))

const PagDatePicker = (props: any) => {
  const { className, error, ...rest } = props
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl className={clsx(classes.formControl, className)} error={error ? true : false}>
        <StyledKeyboardDatePicker {...rest} />
        {Boolean(props.value) && (
          <IconButton
            className={clsx(classes.clearButton, props.inputVariant || undefined)}
            size="small"
            onClick={() => {
              props.onChange(null)
            }}
          >
            <Icon style={{ fontSize: 18 }}>clear</Icon>
          </IconButton>
        )}

        {error ? (
          <FormHelperText className={classes.helperText}>
            <b>{error.message}</b>
          </FormHelperText>
        ) : null}
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

PagDatePicker.defaultProps = {
  error: null,
}

export default PagDatePicker
