import { createContext } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'

const Initial_State = {
  userPermissions: [],
  algoliaSearchSecuredApiKey: '', // Algolia Search Secured API Key
  tenants: [], // list of tenants with clients for user profile
  clients: [], // list of clients with brand for side menu
  activeTenant: null,
  activeClient: null,
  activeBrand: null,
  activeProject: null,
  activeBid: null,
  exchangeRates: null, // exchange rates based on the currency of an active project
  projectsOffset: 0,
  searchView: { searchPhrase: '' },
  projectsViewMode: 'Card', // Card | Table
  bidsViewMode: 'All', // All | Compare | Compared
  bidDetailsViewMode: 'Collapse', // Expand | Collapse
  selectedBids: [], // Selected Bids for comparing ones
  loading: false,
}

const Global_Reducer = (state: any, action: any) => {
  let temp: any

  switch (action.type) {
    case 'Reset':
      return {
        ...Initial_State,
        ...action.payload,
      }
    case 'SetPartial':
      if (!action.payload) {
        return Initial_State
      }

      temp = {
        ...state,
        ...action.payload,
      }

      if (action.payload.activeProject) {
        const tempClient = action.payload.client || action.payload.brand?.client
        const tempBrand = action.payload.brand
        const activeClientId = state.activeClient?.id
        const activeBrandId = state.activeBrand?.id

        if (tempClient && tempClient.id !== activeClientId) {
          temp['activeClient'] = _find(state.clients, (c: any) => c.id === tempClient.id)
        }

        if (tempBrand && tempBrand.id !== activeBrandId) {
          temp['activeBrand'] = tempBrand
        }
      }

      return temp
    case 'SetUserPermissions':
      return {
        ...state,
        userPermissions: action.payload,
      }
    case 'SetAlgoliaSearchSecuredApiKey':
      return {
        ...state,
        algoliaSearchSecuredApiKey: action.payload.key,
      }
    case 'SetTenants':
      return {
        ...Initial_State,
        algoliaSearchSecuredApiKey: state.algoliaSearchSecuredApiKey,
        tenants: action.payload.tenants,
        activeTenant: action.payload.activeTenant,
      }

    case 'SetClients':
      const activeClient = _get(action.payload.clients, [0]) || null
      return {
        ...state,
        clients: action.payload.clients,
        activeClient: activeClient,
      }

    case 'SetActiveProject':
      return {
        ...state,
        activeProject:
          action.payload.project === undefined ? state.activeProject : action.payload.project,
        activeBid: action.payload.bid === undefined ? state.activeBid : action.payload.bid,
      }

    case 'SetExchangeRates':
      return {
        ...state,
        exchangeRates: action.payload.exchangeRates,
      }

    case 'SetProjectsOffset':
      return { ...state, projectsOffset: action.offset }

    case 'ChangeSearchPhrase':
      return { ...state, searchView: { searchPhrase: action.searchPhrase } }

    case 'ChangeBidsViewMode':
      return { ...state, bidsViewMode: action.bidsViewMode }

    case 'ChangeBidDetailsViewMode':
      return { ...state, bidDetailsViewMode: action.bidDetailsViewMode }

    case 'ChangeSelectedBids':
      return { ...state, selectedBids: action.selectedBids }

    default:
      return state
  }
}

const Context = createContext(true as any)

export { Initial_State, Global_Reducer, Context }
