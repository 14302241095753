import React, { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import _capitalize from 'lodash/capitalize'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import { INTEGRATION_CATEGORIES } from '@/shared/constants/integration'
import IntegrationApp from '@/routes/projects/components/integration/integration-app'

const useStyles = makeStyles((theme) => ({
  description: {
    '&.MuiTypography-subtitle1': {
      fontSize: 14,
    },
  },
}))

const IntegrationContainer = (props: any) => {
  const { tabName } = props
  const mountedRef = useMounted(true)
  const classes = useStyles()
  const [integrationCategory, setIntegrationCategory] = useState(null as any)

  useEffect(() => {
    const tempTabName = _capitalize(tabName)
    const temp = INTEGRATION_CATEGORIES.find((i) => i.name === tempTabName)
    setIntegrationCategory(temp || null)
    return () => {}
  }, [tabName])

  const renderIntegrationCategories = useMemo(() => {
    if (!integrationCategory) return null

    return (
      <>
        <Typography variant="h2" align="center" gutterBottom>
          {integrationCategory.category}
        </Typography>
        {integrationCategory['sub_descriptions'].map((subDesc: any, i: number) => {
          return (
            <Typography
              color="inherit"
              variant={subDesc.variant}
              key={`sub-desc-${i}`}
              align="center"
              className={classes.description}
              gutterBottom
            >
              {subDesc.text}
            </Typography>
          )
        })}
        <br />
        <br />
        <Grid container justifyContent="center" spacing={2}>
          {integrationCategory['apps'].map((integrationApp: any, i: number) => {
            return (
              <Grid item xs={6} sm={4} md={3} key={`integration-app-${i}`}>
                <IntegrationApp
                  name={integrationApp.name}
                  description={integrationApp.description}
                  image_url={integrationApp.image_url}
                />
              </Grid>
            )
          })}
        </Grid>
      </>
    )
  }, [integrationCategory])

  return (
    <Paper elevation={0}>
      <br />
      <br />
      {renderIntegrationCategories}
    </Paper>
  )
}

export default IntegrationContainer
