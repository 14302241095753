export const INTEGRATION_CATEGORIES = [
  {
    category: 'Pre-Pro',
    name: 'Pre-Pro',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `Automatically Ingest & Export Project Costs, Bids & Invoices to Prodigy`,
      },
      {
        variant: 'subtitle1',
        text: `Project Overview, Project Team, Briefs, Shoot Location & Schedules`,
      },
    ],
    apps: [
      {
        name: 'Studio-Binder',
        description:
          'A management platform for production companies, agencies & freelance creatives with features for shooting schedules, calendars, & more.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/studio-binder.png',
      },
      {
        name: 'Asana',
        description:
          'Asana is a workflow management tool that allows for easy and affordable collaboration. Unlike project management tools that do a specific job, Asana can be used more flexibly depending on your needs. It’s web/mobile based, has a robust list of integrations, and is organized via dashboards to show tasks, projects, and conversations.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/asana.png',
      },
      {
        name: 'Monday',
        description:
          'monday.com helps teams plan and execute projects that deliver results on time, whether they are in the office, home, or on-the-go.. With powerful productivity features such as time tracking, automated notifications, dependencies, timeline views and integrations, your team can achieve better and faster results for every project milestone.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/monday.png',
      },
      {
        name: 'Celtx',
        description:
          'Celtx is a media pre-production software, designed for creating and organizing media projects like screen plays, films, videos, stage plays, documentaries, machinima, games, and podcasts.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/celtx.png',
      },
    ],
  },
  {
    category: 'Quotes',
    name: 'Quotes',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `Automatically Ingest & Export Project Costs, Bids & Invoices to Prodigy`,
      },
      {
        variant: 'subtitle1',
        text: `Project Overview & Detail, Suppliers, Submitted AICP/AICE’s, Awarded AICP’s/AICE’s`,
      },
    ],
    apps: [
      {
        name: 'Octerra',
        description:
          'Octerra is the first marketing procurement solution built for brands, agencies and vendors. It won’t change the way you do what you do. It will just help you do it better.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/octerra.png',
      },
    ],
  },
  {
    category: 'Project',
    name: 'Project',
    url: null,
    hidden: false,
    sub_descriptions: [],
    apps: [],
  },
  {
    category: 'Bids',
    name: 'Bids',
    url: null,
    hidden: false,
    sub_descriptions: [],
    apps: [],
  },
  {
    category: 'Deliverables',
    name: 'Deliverables',
    url: null,
    hidden: false,
    sub_descriptions: [],
    apps: [],
  },
  {
    category: 'Budget',
    name: 'Budget',
    url: null,
    hidden: false,
    sub_descriptions: [],
    apps: [],
  },
  {
    category: 'Invoices',
    name: 'Invoices',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `View & Track Spot Information by Project`,
      },
      {
        variant: 'subtitle1',
        text: `Digital, Asset Management, Ad-Id Association to Creative Project, Ad Distribution Invoicing, Talent Payment Tracking`,
      },
    ],
    apps: [
      // { 'name': 'Adstream', 'description': `Adstream's powerful advertising platform helps brands and agencies manage the creation, optimization, storage and delivery of their content.`, 'image_url': 'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adstream.png' },
      // { 'name': 'Extreme Reach', 'description': 'Extreme Reach is a tech platform for video ad campaign workflow that delivers ads to every screen and device on time and in perfect quality.', 'image_url': 'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/extreme-reach.png' },
      // { 'name': 'DMS', 'description': 'We are a full-service creative digital agency. Ourpeople, technology and insights help global media and entertainment, sports and lifestyle brandscreate, manage, localise, distribute and place their content, and evaluate its media reach.', 'image_url': 'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/dms.png' },
      {
        name: 'Ad-Id',
        description:
          'Ad-ID Ensures Each Asset Is Uniquely Identified, Improving The Workflow Between Advertiser, Agency, Vendor, And Publisher, Saving Everyone Time And Money.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adid.png',
      },
    ],
  },
  {
    category: 'Deliverables',
    name: 'Deliverables',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `View & Track Spot Information by Project`,
      },
      {
        variant: 'subtitle1',
        text: `Digital, Asset Management, Ad-Id Association to Creative Project, Ad Distribution Invoicing, Talent Payment Tracking`,
      },
    ],
    apps: [
      {
        name: 'Adstream',
        description: `Adstream's powerful advertising platform helps brands and agencies manage the creation, optimization, storage and delivery of their content.`,
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adstream.png',
      },
      {
        name: 'Extreme Reach',
        description:
          'Extreme Reach is a tech platform for video ad campaign workflow that delivers ads to every screen and device on time and in perfect quality.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/extreme-reach.png',
      },
      {
        name: 'DMS',
        description:
          'We are a full-service creative digital agency. Ourpeople, technology and insights help global media and entertainment, sports and lifestyle brandscreate, manage, localise, distribute and place their content, and evaluate its media reach.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/dms.png',
      },
      {
        name: 'Ad-Id',
        description:
          'Ad-ID Ensures Each Asset Is Uniquely Identified, Improving The Workflow Between Advertiser, Agency, Vendor, And Publisher, Saving Everyone Time And Money.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adid.png',
      },
    ],
  },
  {
    category: 'Workflow',
    name: 'Workflow',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `Automatically Ingest & Export Project Costs, Bids & Invoices to Prodigy`,
      },
      {
        variant: 'subtitle1',
        text: `Budget’s, Purchase Order’s, Agency Estimates, Actuals, Bids & Invoices`,
      },
    ],
    apps: [
      {
        name: 'Microsoft 365',
        description:
          'Microsoft 365 is the productivity cloud that brings together best-in-class Office apps with powerful cloud services, device management, and advanced security.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/microsoft-365.png',
      },
      {
        name: 'G Suite',
        description:
          'An integrated suite of secure, cloud-native collaboration and productivity apps powered by Google AI. Includes Gmail, Docs, Drive, Calendar, Meet and more.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/g-suite.png',
      },
      {
        name: 'SAP',
        description:
          'SAP is an ERP (Enterprise Resource Planning) software used for effective business operations.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/sap.png',
      },
      {
        name: 'Zapier',
        description:
          'Zapier is the glue that connects thousands of web apps. Zaps are workflows that connect your apps, so they can work together. Zaps start with a trigger—an event in one of your apps that kicks off your workflow. Zaps automate tasks in the background, so you can focus on more important work.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/zapier.png',
      },
    ],
  },
  {
    category: 'Reporting',
    name: 'Reporting',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `Integrate to Your Favorite Data Visualization Tool for Projects, Vendor and/or Strategic Reporting`,
      },
    ],
    apps: [
      {
        name: 'Tableau',
        description:
          'Tableau can help anyone see and understand their data. Connect to almost any database, drag and drop to create visualizations, and share with a click.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/tableau.png',
      },
      {
        name: 'Power BI',
        description:
          'Power BI is a collection of software services, apps, and connectors that work together to turn your unrelated sources of data into coherent, visually immersive, and interactive insights. Your data may be an Excel spreadsheet, or a collection of cloud-based and on-premises hybrid data warehouses.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/powerbi.png',
      },
      {
        name: 'QlikView',
        description:
          'QlikView is a BI data discovery product for creating guided analytics applications and dashboards tailor-made for business challenges. The software enables users to uncover data insights and relationships across various sources.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/qlik.png',
      },
      {
        name: 'SAP Lumira',
        description:
          'SAP Lumira. Build impactful business intelligence (BI) visualization to assess risk, improve efficiencies, highlight unique insights, and identify opportunities.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/sap.png',
      },
    ],
  },
  {
    category: 'Performance',
    name: 'Performance',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `View & Track SpotPerformancebyProject`,
      },
      {
        variant: 'subtitle1',
        text: `SSO to your favorite data analytics system through each Prodigy Project, Spots and/or Campaign`,
      },
    ],
    apps: [
      {
        name: 'vidmob',
        description:
          'The Agile Creative Studio platform transforms your marketing process and delivers dramatic ROI improvement with measurably better creative.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/vidmob.png',
      },
      {
        name: 'Adobe',
        description:
          'Adobe Audience Manager. A data management platform that helps you build unique audience profiles so you can identify your most valuable segments and use them across any digital channel. Understand your audiences - get a complete view of your audience by combining all of your data sources in one place.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adobe.png',
      },
      {
        name: 'adstream',
        description: `Track and optimise every step of your campaign, from brief to final mile delivery and beyond with Adstream's smart report builder.`,
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/adstream.png',
      },
      {
        name: 'nielsen',
        description:
          'Our marketing cloud gives you access to a universe of Nielsen audience data. Using built-in analytics and Nielsen Artificial Intelligence (AI), our cloud is constantly evaluating the success of your marketing and making adjustments in real-time. The result? Every step of your marketing process gets smarter and more effective.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/nielsen.png',
      },
    ],
  },
  {
    category: 'Collaboration',
    name: 'Collaboration',
    url: 'workflow',
    hidden: true,
    sub_descriptions: [
      {
        variant: 'h5',
        text: `Integrate to Your Favorite Messaging Tool into Prodigy for Project & Reporting Collaboration`,
      },
      {
        variant: 'subtitle1',
        text: `Messaging, Document Sharing, Report Sharing, Video Conferencing`,
      },
    ],
    apps: [
      {
        name: 'G Suite',
        description:
          'An integrated suite of secure, cloud-native collaboration and productivity apps powered by Google AI. Includes Gmail, Docs, Drive, Calendar, Meet and more.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/g-suite.png',
      },
      {
        name: 'Microsoft Teams',
        description:
          'Microsoft Teams is a unified communication and collaboration platform that combines persistent workplace chat, video meetings, file storage (including collaboration on files), and application integration.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/teams.png',
      },
      {
        name: 'Slack',
        description:
          'Slack brings team communication and collaboration into one place so you can get more work done, whether you belong to a large enterprise or a small business. Check off your to-do list and move your projects forward by bringing the right people, conversations, tools, and information you need together.',
        image_url:
          'https://post-ads-group-staging.s3.us-east-2.amazonaws.com/integration_logos/slack.png',
      },
    ],
  },
]
