import gql from 'graphql-tag'

const GET_PROJECT_COST_ENTRIES = gql`
  query projectCostEntries($tenantId: Int!, $projectCostId: Int!) {
    projectCostEntries(tenantId: $tenantId, projectCostId: $projectCostId) {
      total
      data {
        id
        projectCostId
        projectCostTypeId
        vendorId
        currencyId
        description
      }
    }
  }
`

export { GET_PROJECT_COST_ENTRIES }
