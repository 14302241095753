import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CheckIcon from '@material-ui/icons/Check'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import { Context } from '@/shared/store/reducers/global-reducer'
import StyledMenu from './components/styled-menu'
import StyledMenuItem from './components/styled-menu-item'

const useStyles = makeStyles((theme) => ({
  userImageFab: {
    position: 'relative',
    width: 44,
    height: 44,
    overflow: 'hidden',
  },
  userProfileImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}))

const ProfileMenu = (props: any) => {
  const { user, handleLogout } = props
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { store, dispatch } = useContext(Context)
  const { tenants, activeTenant } = store
  const isNotUploadPortal = location.pathname.indexOf('/p/') < 0

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClickUserProfileMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserProfileMenu = () => {
    setAnchorEl(null)
  }

  const handleClickTenant = (tenant: any) => {
    if (_get(activeTenant, ['id']) !== _get(tenant, ['id'])) {
      dispatch({ type: 'SetPartial', payload: { activeTenant: tenant } })
      navigate(`/${tenant?.client?.id}/list`)
    }
    setAnchorEl(null)
  }

  return (
    <>
      <Fab aria-label="user" className={classes.userImageFab} onClick={handleClickUserProfileMenu}>
        <img src={user.picture} className={classes.userProfileImage} alt="userProfileImage" />
      </Fab>

      <StyledMenu
        id="user-profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseUserProfileMenu}
      >
        {isNotUploadPortal &&
          tenants.map((tenant: any, i: number) => {
            const isActive = tenant === activeTenant

            return (
              <StyledMenuItem
                key={`tenant-${i}`}
                className={clsx({ active: isActive })}
                onClick={() => handleClickTenant(tenant)}
              >
                {isActive ? (
                  <ListItemIcon>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                ) : null}
                <ListItemText primary={`${tenant.name}`} />
              </StyledMenuItem>
            )
          })}

        {isNotUploadPortal && tenants?.length > 0 && <Divider />}

        <StyledMenuItem onClick={handleLogout}>
          <ListItemText primary="Log out" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}

export default ProfileMenu
