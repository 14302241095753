import React, { useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMounted from '@/components/hooks/use-mounted'
import ProjectItemWidget from '@/widgets/project-item-widget'
import { truncate } from '@/shared/libs/str-lib'
import { Context } from '@/shared/store/reducers/global-reducer'
import ProjectImage from './project-image-component'

yup.object().shape({
  name: yup.string().required(),
  age: yup.number().required().positive().integer(),
  email: yup.string().email(),
  website: yup.string().url(),
  createdOn: yup.date().default(function () {
    return new Date()
  }),
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))

const ProjectOverviewComponent = (props: any) => {
  const mountedRef = useMounted(true)
  const classes = useStyles()
  const { store, dispatch } = useContext(Context)
  const { activeProject } = store

  const detailsRender = useMemo(() => {
    return truncate(activeProject?.details || '', 182)
  }, [activeProject])

  const renderProjectImage = useMemo(() => {
    if (!mountedRef.current) return null
    return (
      <ProjectImage
        project={activeProject}
        handleCancel={(e: any) => {}}
        handleSubmit={(v: any) => {
          handleSubmit('image', v)
        }}
      />
    )
  }, [activeProject])

  const handleSubmit = (fieldName: string, value: any) => {
    const tempProject = _cloneDeep(activeProject)
    _set(tempProject, fieldName, value)
    dispatch({
      type: 'SetActiveProject',
      payload: { project: tempProject, bid: null },
    })
  }

  return (
    <Grid container justifyContent="flex-start" className={classes.root} wrap="nowrap" spacing={2}>
      <Grid item>{renderProjectImage}</Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <b>{activeProject?.client?.name || ''}</b>
                  &nbsp;&nbsp;
                  {activeProject?.brand?.name || ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'projectName',
                    label: '',
                    type: 'text',
                    InputProps: {
                      key: activeProject?.projectName || '',
                      className: 'overview nonePaddingLeft',
                      defaultValue: activeProject?.projectName || '',
                      label: '',
                      size: 'small',
                      variant: 'outlined',
                      InputLabelProps: {
                        shrink: false,
                      },
                      inputProps: {
                        style: { fontSize: 20 },
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: () => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('projectName', v)
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 8 }}>
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'projectStatusId',
                    label: 'STATUS',
                    type: 'select',
                    InputProps: {
                      className: 'overview status',
                      label: 'STATUS',
                      size: 'small',
                      value: activeProject?.projectStatusId || null,
                      variant: 'outlined',
                      placeholder: 'Select a Status',
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: (e: any) => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('projectStatusId', v)
                    },
                  }}
                />
              </Grid>

              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'projectNumber',
                    label: 'PROJECT NUMBER',
                    type: 'text',
                    InputProps: {
                      key: activeProject?.projectNumber || '',
                      className: 'overview',
                      defaultValue: activeProject?.projectNumber || '',
                      label: 'PROJECT NUMBER',
                      size: 'small',
                      variant: 'outlined',
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: (e: any) => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('projectNumber', v)
                    },
                  }}
                />
              </Grid>

              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'agencyId',
                    label: 'AGENCY',
                    type: 'select',
                    InputProps: {
                      className: 'overview',
                      label: 'AGENCY',
                      size: 'small',
                      value: activeProject?.agencyId || null,
                      variant: 'outlined',
                      placeholder: 'Select a Agency',
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: (e: any) => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('agencyId', v)
                    },
                  }}
                />
              </Grid>

              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'projectCategoryId',
                    label: 'CATEGORY',
                    type: 'select',
                    InputProps: {
                      className: 'overview',
                      label: 'CATEGORY',
                      size: 'small',
                      value: activeProject?.projectCategoryId || null,
                      variant: 'outlined',
                      placeholder: 'Select a Category',
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: (e: any) => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('projectCategoryId', v)
                    },
                  }}
                />
              </Grid>

              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'projectRegionId',
                    label: 'REGION',
                    type: 'select',
                    InputProps: {
                      className: 'overview',
                      label: 'REGION',
                      size: 'small',
                      value: activeProject?.projectRegionId || null,
                      variant: 'outlined',
                      placeholder: 'Select a Region',
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                  outputs={{
                    handleCancel: (e: any) => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('projectRegionId', v)
                    },
                  }}
                />
              </Grid>

              <Grid item xs>
                <ProjectItemWidget
                  widgetConfig={{
                    order: 0,
                    area: 'Normal',
                    permissions: [],
                    visible: true,
                    style: {},
                  }}
                  config={{}}
                  inputs={{
                    project: activeProject,
                    name: 'marketId',
                    type: 'select',
                    isCreatable: true,
                    InputProps: {
                      className: 'overview',
                      label: 'MARKET',
                      size: 'small',
                      value: activeProject?.marketId || null,
                      variant: 'outlined',
                      placeholder: 'Enter a market',
                      InputLabelProps: {
                        shrink: true,
                      },
                      AutocompleteProps: {},
                    },
                  }}
                  outputs={{
                    handleCancel: () => {},
                    handleSubmit: (v: any) => {
                      handleSubmit('marketId', v)
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ProjectOverviewComponent.propTypes = {
  project: PropTypes.object,
}

export default ProjectOverviewComponent
