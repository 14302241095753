import gql from 'graphql-tag'

const ADD_AGENCY_PRODUCER = gql`
  mutation addAgencyProducer($tenantId: Int!, $name: String!, $clientId: Int!) {
    addAgencyProducer(tenantId: $tenantId, name: $name, clientId: $clientId) {
      id
      name
      clientId
    }
  }
`
const UPDATE_AGENCY_PRODUCER = gql`
  mutation updateAgencyProducer($tenantId: Int!, $id: Int!, $name: String, $clientId: Int) {
    updateAgencyProducer(tenantId: $tenantId, id: $id, name: $name, clientId: $clientId) {
      id
      name
    }
  }
`

export { ADD_AGENCY_PRODUCER, UPDATE_AGENCY_PRODUCER }
