import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
}))

const PagCheckbox = (props: any) => {
  const { className, error, ...rest } = props

  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.formControl, className)}>
      <FormControlLabel control={<Checkbox color="primary" />} {...rest} />
    </FormControl>
  )
}

PagCheckbox.defaultProps = {
  error: null,
}

export default PagCheckbox
