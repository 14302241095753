import gql from 'graphql-tag'

const GET_ALGOLIA_SEARCH_SECURED_API_KEY = gql`
  query algoliaSearchSecuredApiKey($tenantId: Int!) {
    algoliaSearchSecuredApiKey(tenantId: $tenantId) {
      key
    }
  }
`

export { GET_ALGOLIA_SEARCH_SECURED_API_KEY }
