import gql from 'graphql-tag'

const GET_TENANTS = gql`
  {
    tenants {
      total
      data {
        id
        name
        client {
          id
          name
          defaultProjectImage
          slug
          uploadPortalImage
          uploadPortalLoginImage
          uploadPortalTitle
          uploadPortalDescription
          uploadPortalSlug
        }
      }
    }
  }
`

export { GET_TENANTS }
