import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Portal from '@material-ui/core/Portal'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { ADD_CONTACT, UPDATE_CONTACT } from '@/shared/graphql/mutations/mutationContacts'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    minWidth: 280,
    maxWidth: 280,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    zIndex: 1004,
    overflow: 'visible',
  },
  form: {
    display: 'block',
  },
  cardHeader: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 100,
  },
  circularProgress: {
    position: 'absolute',
  },
}))

const DefaultFormData = {
  name: '',
  email: '',
}

const FormSchema = yup.object().shape({
  name: yup.string().required('Contact name is required.'),
  email: yup.string().email('Email is not valid.').required('Email is required.'),
})

export const ContactEditComponent = (props: any) => {
  const { containerRef, contact, handleChange, handleCancel } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, activeClient } = store
  const mountedRef = useMounted(true)
  const hookFormMethods = useForm({
    defaultValues: DefaultFormData,
    resolver: yupResolver(FormSchema),
  })
  const { control, reset, handleSubmit } = hookFormMethods

  const [addContact, addContactRes] = useMutation(ADD_CONTACT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (res.addContact) {
        handleChange(res.addContact)
      }
    },
  })
  const [updateContact, updateContactRes] = useMutation(UPDATE_CONTACT, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (res.updateContact) {
        handleChange(res.updateContact)
      }
    },
  })

  useEffect(() => {
    reset({
      name: contact?.name || '',
      email: contact?.email || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  const containerRect = useMemo(() => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    return containerRect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  const handleFormSubmit = (data: typeof DefaultFormData) => {
    let abortController: any
    try {
      abortController = new AbortController()
      const payload: any = {
        tenantId: activeTenant?.id,
        email: data.email,
        name: data.name,
      }
      if (contact?.id) {
        payload.id = contact.id
      } else {
        payload.clientId = activeClient.id
      }

      callPagApi(
        '',
        getTokenSilently,
        contact?.id ? updateContact : addContact,
        payload,
        abortController.signal
      )
    } catch (err) {
      console.error('Error', err)
      if (abortController) abortController.abort()
    }
  }

  return (
    <Portal>
      <Paper
        className={clsx(classes.root)}
        elevation={0}
        style={{ left: containerRect?.left, top: containerRect?.top }}
      >
        <form name="ContactForm" onSubmit={handleSubmit(handleFormSubmit)}>
          <Card elevation={24} variant="outlined">
            <CardHeader
              className={classes.cardHeader}
              title="Contact Information"
              titleTypographyProps={{
                variant: 'h6',
              }}
            />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagTextInput
                        label="Contact name"
                        placeholder="Enter contact name"
                        size="small"
                        variant="outlined"
                        error={fieldState.error}
                        value={field.value}
                        onChange={(e: any) => field.onChange(e.target.value || '')}
                        onBlur={field.onBlur}
                      />
                    )}
                    control={control}
                    name="name"
                    defaultValue={contact?.name || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState, formState }) => (
                      <PagTextInput
                        label="Contact email"
                        placeholder="Enter contact email"
                        size="small"
                        variant="outlined"
                        error={fieldState.error}
                        value={field.value}
                        onChange={(e: any) => field.onChange(e.target.value || '')}
                        onBlur={field.onBlur}
                      />
                    )}
                    control={control}
                    name="email"
                    defaultValue={contact?.email || ''}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <Button
                color="primary"
                size="medium"
                aria-label="cancel"
                variant="text"
                className={classes.button}
                disabled={addContactRes.loading || updateContactRes.loading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                size="medium"
                type="submit"
                aria-label="next"
                variant="contained"
                className={classes.button}
                disabled={addContactRes.loading || updateContactRes.loading}
              >
                {contact ? 'Update' : 'Add'}
                {(addContactRes.loading || updateContactRes.loading) && (
                  <CircularProgress
                    size={16}
                    color="primary"
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Paper>
    </Portal>
  )
}

ContactEditComponent.defaultProps = {
  handleChange: (e: any, shouldCallApi: boolean) => {},
  handleCancel: () => {},
}

export default ContactEditComponent
