import React from 'react'
import {
  faFile,
  faFilePdf,
  faFileExcel,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileImage,
  faFileAudio,
  faFileCode,
} from '@fortawesome/free-regular-svg-icons'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

export const DefaultFileIcon = faFile
export const FileIcons: any = {
  'text/csv': faFileCsv,
  'application/msword': faFileWord,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': faFileWord,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': faFileExcel,
  'application/pdf': faFilePdf,
  'text/plain': faFile,
  'application/rtf': faFile,
  'application/vnd.ms-excel': faFileExcel,
  'application/json': faFileCode,
  'application/zip': faFile,
  'application/xml': faFileCode,
  'application/x-httpd-php': faFileCode,
  'application/vnd.ms-powerpoint': faFilePowerpoint,
  'audio/mpeg': faFileAudio,
  'audio/ogg': faFileAudio,
  'audio/wav': faFileAudio,
  'audio/webm': faFileAudio,
  'image/png': faFileImage,
  'image/bmp': faFileImage,
  'image/jpeg': faFileImage,
  'text/html': faFileCode,
  'text/javascript': faFileCode,
  'video/mpeg': faFileVideo,
  'video/x-msvideo': faFileVideo,
  'video/ogg': faFileVideo,
  'video/webm': faFileVideo,
}
