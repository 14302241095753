import React, { useContext, useMemo, useState, useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useLazyQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import useMounted from '@/components/hooks/use-mounted'
import useProtected from '@/components/hooks/use-protected'
import AppHeader from '@/components/app-header'
import AppSideMenubar from '@/components/app-side-menu'
import PagCircularIndeterminate from '@/components/pag-loading'
import NotPermission from '@/routes/not-permission'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'
import { GET_ALGOLIA_SEARCH_SECURED_API_KEY } from '@/shared/graphql/queryGetAlgoliaSearchSecuredApiKey'
import { GET_TENANTS } from '@/shared/graphql/queryGetTenants'
import { GET_CLIENTS_WITH_BRANDS } from '@/shared/graphql/queryGetClients'
import { GET_USER_PERMISSIONS } from '@/shared/graphql/queryGetUserPermissions'

const drawerWidth = 160
const appBarHeight = 80
const drawerSpace = 32

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    minHeight: appBarHeight,
    borderBottom: '1px solid lightgray',
    boxShadow: 'none',
    background: 'white',
    width: `100%`,
    left: 0,

    [theme.breakpoints.up('md')]: {
      // left: drawerSpace,
      // width: `calc(100% - ${2 * drawerSpace}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    background: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.74)',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    minHeight: appBarHeight,
    [theme.breakpoints.up('sm')]: {
      minHeight: appBarHeight,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    top: appBarHeight,
    left: 0,
    borderTop: '1px solid lightgray',
    marginTop: 0,
    maxHeight: `calc(100% - ${appBarHeight}px)`,

    [theme.breakpoints.up('md')]: {
      // left: drawerSpace,
    },
  },
  main: {
    position: 'relative',
    marginTop: 0,
    height: `100%`,
    width: `100%`,
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
    },
    overflowX: 'hidden',
    '&.hasHeader': {
      marginTop: appBarHeight,
      height: `calc(100% - ${appBarHeight}px)`,
    },
  },
}))

type Props = {
  children?: React.ReactNode
}

const MainLayout = ({ children }: Props) => {
  const mountedRef = useMounted(true)
  const location = useLocation()
  const { hasPermission, isEnabledRoute } = useProtected()
  const { isAuthenticated, getTokenSilently, loading: auth0Loading } = useAuth0()
  const { store, dispatch } = useContext(Context)
  const { activeTenant, activeClient, tenants, redirectUrl } = store
  const mdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const [mobileOpen, setMobileOpen] = useState(false)

  const [getUserPermissions, getUserPermissionsRes] = useLazyQuery(GET_USER_PERMISSIONS, {
    onError: (err) => {
      errorHandler(err)
      if (mountedRef.current) {
        dispatch({ type: 'SetUserPermissions', payload: [] })
      }
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = res.userPermissions || []
      dispatch({ type: 'SetUserPermissions', payload: temp })
    },
    fetchPolicy: 'network-only',
  })

  const [getTenants, getTenantsRes] = useLazyQuery(GET_TENANTS, {
    onError: (err) => {
      console.log('err ===', err)
      errorHandler(err)
      if (mountedRef.current) {
        dispatch({ type: 'SetTenants', payload: { tenants: [], activeTenant: null } })
      }
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const tempTenants = res.tenants?.data || []
      const temp = location.pathname.split('/')
      const clientId = temp[1] ? parseInt(temp[1]) : null
      const tempActiveTenant = clientId
        ? tempTenants.find((t: any) => _get(t, ['client', 'id']) === clientId) || null
        : _get(tempTenants, [0]) || null
      dispatch({
        type: 'SetTenants',
        payload: { tenants: tempTenants, activeTenant: tempActiveTenant },
      })
    },
    fetchPolicy: 'network-only',
  })

  const [getAlgoliaSearchSecuredApiKey, getAlgoliaSearchSecuredApiKeyRes] = useLazyQuery(
    GET_ALGOLIA_SEARCH_SECURED_API_KEY,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {},
      fetchPolicy: 'network-only',
    }
  )

  const [getClientsWithBrands, getClientsWithBrandsRes] = useLazyQuery(GET_CLIENTS_WITH_BRANDS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
    fetchPolicy: 'network-only',
  })

  const classes = useStyles()
  const theme = useTheme()

  useLayoutEffect(() => {
    if (!mountedRef.current || !isAuthenticated) return
    const abortController = new AbortController()
    callPagApi('', getTokenSilently, getTenants, null, abortController.signal)

    return () => {
      if (abortController) abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (!mountedRef.current || !isAuthenticated || !activeTenant?.id) return
    const abortController = new AbortController()
    dispatch({
      type: 'SetPartial',
      payload: {
        algoliaSearchSecuredApiKey: '',
        clients: [],
        activeClient: null,
        userPermissions: [],
      },
    })
    callPagApi(
      '',
      getTokenSilently,
      getUserPermissions,
      { tenantId: activeTenant.id },
      abortController.signal
    )
    callPagApi(
      '',
      getTokenSilently,
      getAlgoliaSearchSecuredApiKey,
      { tenantId: activeTenant.id },
      abortController.signal
    )
    callPagApi(
      '',
      getTokenSilently,
      getClientsWithBrands,
      { tenantId: activeTenant.id },
      abortController.signal
    )

    return () => {
      if (abortController) abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, activeTenant])

  useEffect(() => {
    if (!getAlgoliaSearchSecuredApiKeyRes.called || getAlgoliaSearchSecuredApiKeyRes.loading) return

    let algoliaSearchSecuredApiKey = ''
    if (getAlgoliaSearchSecuredApiKeyRes.data) {
      algoliaSearchSecuredApiKey =
        _get(getAlgoliaSearchSecuredApiKeyRes.data, ['algoliaSearchSecuredApiKey', 'key']) || ''
    }

    dispatch({
      type: 'SetAlgoliaSearchSecuredApiKey',
      payload: { key: algoliaSearchSecuredApiKey },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAlgoliaSearchSecuredApiKeyRes])

  useEffect(() => {
    if (!getClientsWithBrandsRes.called || getClientsWithBrandsRes.loading) return

    let clients = []
    let tempActiveClient = null

    if (getClientsWithBrandsRes.data) {
      clients = _get(getClientsWithBrandsRes.data, ['clientsWithBrands', 'data']) || []
      tempActiveClient = _get(clients, [0]) || null
    }

    dispatch({
      type: 'SetPartial',
      payload: { clients, activeClient: tempActiveClient },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientsWithBrandsRes])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const loading = useMemo(() => {
    return (
      !getTenantsRes.called ||
      getTenantsRes.loading ||
      !getUserPermissionsRes.called ||
      getUserPermissionsRes.loading ||
      !getClientsWithBrandsRes.called ||
      getClientsWithBrandsRes.loading ||
      !getAlgoliaSearchSecuredApiKeyRes.called ||
      getAlgoliaSearchSecuredApiKeyRes.loading ||
      !tenants.length ||
      !activeClient
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTenantsRes,
    getUserPermissionsRes,
    getClientsWithBrandsRes,
    getAlgoliaSearchSecuredApiKeyRes,
    tenants,
    activeClient,
  ])

  const renderAppSideMenubar = useMemo(
    () => (
      <div>
        <AppSideMenubar />
      </div>
    ),
    []
  )

  if (auth0Loading) {
    return (
      <div className={classes.root}>
        <PagCircularIndeterminate size={24} />
      </div>
    )
  }

  if (!loading && !hasPermission) {
    return <NotPermission visibleLogo={true} />
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <AppHeader />
        </Toolbar>
      </AppBar>
      {hasPermission && (
        <nav className={classes.drawer} aria-label="sidemenu">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {!mdUp && (
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {renderAppSideMenubar}
            </Drawer>
          )}
          {mdUp && (
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {renderAppSideMenubar}
            </Drawer>
          )}
        </nav>
      )}
      <main className={clsx(classes.main, 'hasHeader')}>{loading ? null : children}</main>
      {loading && <PagCircularIndeterminate />}
    </>
  )
}

export default MainLayout
