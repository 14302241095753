import gql from 'graphql-tag'

const SORT_PROJECT_COST_ENTRIES = gql`
  mutation sortProjectCostEntries($tenantId: Int!, $sorts: [SortInput!]!) {
    sortProjectCostEntries(tenantId: $tenantId, sorts: $sorts) {
      success
      error
    }
  }
`

export { SORT_PROJECT_COST_ENTRIES }
