import React from 'react'
import clsx from 'clsx'

import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

export const StyledTextField = styled(TextField)`
  ${({ theme }) => `
    .overview & {
      &:hover .MuiInputBase-root:not(.Mui-focused) {
        background-color: ${theme.palette.grey[200]}
      }

      & .MuiOutlinedInput-root {
        &:not(.Mui-focused) fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-width: 0;
          }
        }
      }
      & .MuiInput-underline {
        &:not(.Mui-focused):before {
          border-width: 0;
        }
      }
    }
    .nonePaddingLeft & {
      .MuiInputBase-root:not(.Mui-focused) {
        .MuiInputBase-input {
          padding-left: 0
        }
      }
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(12px, -6px) scale(0.75);
    }
    .MuiOutlinedInput-input {
      padding: 18.5px 12px;
    }
    .MuiOutlinedInput-inputMarginDense {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
    .MuiOutlinedInput-inputMultiline {
      padding: 0;
    }
    .MuiOutlinedInput-adornedStart {
      padding-left: 12px;
      margin-right: 0;
    }
    .MuiOutlinedInput-adornedStart .MuiInputAdornment-positionStart {
      margin-right: 0;
    }

    .MuiOutlinedInput-adornedStart .MuiOutlinedInput-inputMarginDense {
      padding-left: 2px;
    }
  `}
`

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  helperText: {
    padding: '0 10px',
  },
}))

const PagTextInput = React.forwardRef(({ className, error, ...rest }: any, ref) => {
  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.formControl, className)} error={error ? true : false}>
      <StyledTextField
        {...rest}
        onChange={(e: any) => {
          if (rest.onChange && `${rest.value || ''}` !== e.target.value) {
            rest.onChange(e)
          }
        }}
      />
      {error ? (
        <FormHelperText className={classes.helperText}>
          <b>{error.message}</b>
        </FormHelperText>
      ) : null}
    </FormControl>
  )
})

PagTextInput.defaultProps = {
  error: null,
}

export default PagTextInput
