import React, { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Index,
  Configure,
  connectHits,
  connectStateResults,
  connectInfiniteHits,
} from 'react-instantsearch-dom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _take from 'lodash/take'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
    paddingRight: 16,
    color: 'gray',
  },
  content: {
    position: 'relative',
    width: '100%',
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const DeliverableListView = (props: any) => {
  const { hits, handleClose, searchPhrase } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const handleDetailView = useCallback(
    (data: any) => {
      handleClose(null)
      const cId =
        _get(data, ['project', 'client', 'id']) || _get(data, ['project', 'brand', 'client', 'id'])
      const bId = _get(data, ['project', 'brand', 'id'])
      const projectId = _get(data, ['projectId'])
      if (projectId) {
        navigate(`/${cId}/${bId}/${projectId}/deliverables`)
      }
    },
    [handleClose, navigate]
  )

  const handleSearchMore = useCallback(() => {
    handleClose(null)
    navigate('/search/deliverables')
  }, [handleClose, navigate])

  const content = useMemo(() => {
    const dispDataList = _take(hits, 5)
    const hasHits = _get(hits, 'length') > 0

    return (
      <>
        {hasHits && (
          <div className={classes.menuTitle}>
            <b>DELIVERABLES</b>
          </div>
        )}

        {_map(dispDataList, (data: any, i: number) => (
          <ListItem key={`bids-search-${i}`} button onClick={() => handleDetailView(data)}>
            <div className={classes.content}>
              <div className={classes.title}>
                <b>{_get(data, ['spotName']) || ''}</b>
              </div>
            </div>
          </ListItem>
        ))}

        {hasHits && (
          <div className={classes.menuTitle}>
            <span className="pag-blue" onClick={() => handleSearchMore()}>
              Show More Deliverables
            </span>
          </div>
        )}
      </>
    )
  }, [hits])

  return <>{content}</>
}

const DeliverableListViewHits = connectHits(DeliverableListView)
// const BidsListViewHits = connectStateResults(connectHits(BidsListView));
// const BidsListViewHits = connectInfiniteHits(PagAlgoliaConnectConditionalResults(BidsListView));

const DeliverablesPopper = (props: any) => {
  const { activeClient, activeBrand, clients, handleClose, searchPhrase } = props

  const content = useMemo(() => {
    return (
      <Index indexId="DeliverablesInSearch" indexName="Deliverables">
        <Configure hitsPerPage={5} />
        <DeliverableListViewHits
          {...{
            handleClose,
            searchPhrase,
          }}
        />
      </Index>
    )
  }, [activeBrand, activeClient, clients, handleClose, searchPhrase])

  return <>{content}</>
}

export default DeliverablesPopper
