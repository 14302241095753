import React, { useState, useLayoutEffect, useContext, createContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import axios from 'axios'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.origin)

export const Auth0Context = createContext(false as any)
export const useAuth0 = () => useContext(Auth0Context)

const Auth0Provider = ({
  children,
  returnTo,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null as any)
  const [auth0Client, setAuth0Client] = useState(null as any)
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useLayoutEffect(() => {
    const initAuth0 = async () => {
      try {
        if (window.location.pathname.indexOf('/p/') >= 0) {
          const temp = window.location.pathname.split('/p/')
          if (temp[1]) {
            const result = await axios.get(
              `${process.env.REACT_APP_PAG_SERVER}/api/upload-portal-login-image?uploadPortalSlug=${temp[1]}`
            )
            initOptions.uploadPortalImage = result.data?.uploadPortalLoginImage
          }
        }

        const auth0FromHook = await createAuth0Client(initOptions)
        setAuth0Client(auth0FromHook)

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback()
          onRedirectCallback(appState)
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated()
        if (isAuthenticated) {
          const user = await auth0FromHook.getUser({
            scope: 'openid profile email',
            audience: initOptions.audience,
          })
          setUser(user)
        }

        setIsAuthenticated(isAuthenticated)
        setLoading(false)
      } catch (e) {
        setIsAuthenticated(false)
        setLoading(false)
        console.error('auth error', e)
      }
    }

    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)

    try {
      await auth0Client.loginWithPopup(params)
      const user = await auth0Client.getUser()
      setUser(user)
      setIsAuthenticated(true)
    } catch (error) {
      console.error('error', error)
      setUser(null)
      setIsAuthenticated(false)
    } finally {
      setPopupOpen(false)
    }
  }

  const handleRedirectCallback = async () => {
    try {
      setLoading(true)
      await auth0Client.handleRedirectCallback()
      const user = await auth0Client.getUser()
      setLoading(false)
      setIsAuthenticated(true)
      setUser(user)
    } catch (e) {
      setLoading(false)
      setUser(null)
      setIsAuthenticated(false)
      auth0Client.loginWithRedirect()
      console.log('callback error', e)
    }
  }

  const handleLogout = (p: any) => {
    axios.post(`${process.env.REACT_APP_PAG_SERVER}/api/logout`, { sub: user.sub })
    auth0Client?.logout({ returnTo, ...(p || {}) })
  }

  if (!auth0Client) return null

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (p: any) => auth0Client.getIdTokenClaims(p || {}),
        loginWithRedirect: (p: any) => auth0Client.loginWithRedirect(p || {}),
        getTokenSilently: (p: any) => auth0Client.getTokenSilently(p || {}),
        getTokenWithPopup: (p: any) => auth0Client.getTokenWithPopup(p || {}),
        logout: (p: any) => handleLogout(p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
