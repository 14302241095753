import React, { useState, useEffect } from 'react'
import { Control } from 'react-hook-form'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

import NestFormField, { Field } from './nest-form-field'
import { IClient } from '@/shared/models/client'
import { IProject } from '@/shared/models/project'

const OptionalFields = (props: { client: IClient; project?: IProject; control: Control<any> }) => {
  const { client, project, control } = props
  const [fields, setFields] = useState<{
    projectFields: { fields: Field[]; show?: boolean }
    metaFields: { fields: Field[]; show?: boolean }
  }>({ projectFields: { fields: [] }, metaFields: { fields: [] } })

  useEffect(() => {
    const optionalUploadFields = client?.uploadPortalFields?.optional || []
    setFields({
      projectFields: {
        fields: optionalUploadFields.filter(f => f.field.indexOf('metaData.') < 0),
      },
      metaFields: {
        fields: optionalUploadFields.filter(f => f.field.indexOf('metaData.') >= 0),
      },
    })
  }, [client])

  return (
    <List disablePadding>
      {fields.projectFields.fields.length > 0 && (
        <ListItem
          button
          onClick={() => {
            setFields(prevValue => ({
              ...prevValue,
              projectFields: {
                fields: prevValue.projectFields.fields,
                show: !prevValue.projectFields.show,
              },
            }))
          }}
        >
          <ListItemText primary="Additional Project Details" disableTypography />
          {fields.projectFields.show ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItem>
      )}
      <Collapse in={fields.projectFields.show} timeout="auto" unmountOnExit>
        {fields.projectFields.fields.map((f: Field, index: number) => (
          <ListItem disableGutters key={`upload-portal-optional-field-${index}`}>
            <NestFormField
              clientId={client?.id}
              project={project}
              control={control}
              formField={f}
            />
          </ListItem>
        ))}
      </Collapse>
      {fields.metaFields.fields.map((f: Field, index: number) => (
        <ListItem disableGutters key={`upload-portal-meta-field-${index}`}>
          <List disablePadding>
            <ListItem
              button
              onClick={() => {
                setFields(prevValue => ({
                  ...prevValue,
                  metaFields: {
                    fields: prevValue.metaFields.fields.map((f, i) =>
                      i !== index ? f : { ...f, show: !f.show }
                    ),
                    show: !prevValue.metaFields.show,
                  },
                }))
              }}
            >
              <ListItemText primary={f.label} disableTypography />
              {f.show ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItem>
            <Collapse in={f.show} timeout="auto" unmountOnExit>
              <ListItem disableGutters key={`upload-portal-optional-field-${index}`}>
                <NestFormField
                  tenantId={client?.tenantId}
                  clientId={client?.id}
                  project={project}
                  control={control}
                  formField={f}
                />
              </ListItem>
            </Collapse>
          </List>
        </ListItem>
      ))}
    </List>
  )
}

export default OptionalFields
