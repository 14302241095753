import React from 'react'
import PropTypes from 'prop-types'

import StarIcon from '@material-ui/icons/Star'

const BudgetsBreadCrumb: React.FC<{ resultNum: number }> = ({ resultNum }) => {
  return (
    <div
      className="pag-bread-crumb pag-flex"
      style={{ fontSize: 16, paddingLeft: 6, justifyContent: 'flex-start' }}
    >
      <StarIcon fontSize="large" />
      &nbsp;
      <b>Budgeting</b>&nbsp;&nbsp;
      <span>{resultNum === 1 ? `1 Result` : `${resultNum || 0} Results`}</span>
    </div>
  )
}

export default BudgetsBreadCrumb
