export const stringToNumber = (value: string | number, targetType: 'int' | 'float' = 'int', defaultValue: any = undefined) => {
  if (value === undefined || value === null) return defaultValue

  let temp: any = `${value}`.replace(/,/g, '')
  temp = temp.match(/[-+]?(\d+)(\.\d+)?/g)

  if (!temp) return null

  temp = targetType === 'float' ? parseFloat(temp) : parseInt(temp)

  return isNaN(temp) ? null : temp
}

export const stringToCurrency = (value: any) => {
  if (!value) return null
  let temp: any = `${value}`.replace(/,/g, '')
  temp = temp.match(/[-+]?(\d+)(\.\d+)?/g)

  if (!temp) return null

  temp = parseFloat(temp)

  return isNaN(temp) ? null : temp.toFixed(2)
}
