import React from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { makeStyles } from '@material-ui/core/styles'
import { MAIN_ROUTES, UPLOAD_PORTAL_ROUTES } from '@/shared/constants/routes'
import MainLayout from './main-layout'
import UploadPortalLayout from './upload-portal-layout'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
}))

type Props = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: Props) => {
  const classes = useStyles()
  const location = useLocation()

  const isUploadPortalRoute = UPLOAD_PORTAL_ROUTES.find((route) =>
    matchPath(route.path, location.pathname)
  )

  if (isUploadPortalRoute) {
    return (
      <div className={classes.root}>
        <UploadPortalLayout>{children}</UploadPortalLayout>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          position={toast.POSITION.TOP_RIGHT}
        />
      </div>
    )
  }

  const isMainRoute = MAIN_ROUTES.find((route) => matchPath(route.path, location.pathname))

  if (isMainRoute) {
    return (
      <div className={classes.root}>
        <MainLayout>{children}</MainLayout>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          position={toast.POSITION.TOP_RIGHT}
        />
      </div>
    )
  }

  return <div className={classes.root}>{children}</div>
}

export default AppLayout
