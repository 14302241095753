const yup = require('yup')

const disallowedCharacters = /[\/\\":*?<>|+]/;

export const ProjectItemWidgetSchema = {
  projectName: yup.object().shape({
    projectName: yup.string().required('Project Title is required.'),
  }),
  projectNumber: yup.object().shape({
    projectNumberId: yup
      .string()
      .test(
        'disallowed-characters',
        'Project Number contains disallowed characters.',
        (value) => !disallowedCharacters.test(value || '')
      )
      .required('Project Number is required.'),
  }),
  projectCategoryId: yup.object().shape({
    projectCategoryId: yup
      .number()
      .required('Project Category is required.')
      .typeError('Project Category is required.'),
  }),
  projectRegionId: yup.object().shape({
    projectRegionId: yup
      .number()
      .required('Project Region is required.')
      .typeError('Project Region is required.'),
  }),
  projectStatusId: yup.object().shape({
    projectStatusId: yup
      .number()
      .required('Project Status is required.')
      .typeError('Project Status is required.'),
  }),
  projectBudgetRate: yup.object().shape({
    projectBudgetRate: yup.number().typeError('Budget Rate should be number.'),
  }),
  projectTeam: yup.object().shape({
    contactId: yup.number().required('Contact is required.').typeError('Contact is required.'),
    projectTeamRoleId: yup
      .number()
      .required('Project Team Role is required.')
      .typeError('Project Team Role is required.'),
  }),
  // 'spots': yup.object().shape({
  //   spotName: yup.string().required('Spot Name is required.'),
  //   length: yup.number('Length should be number.'),
  //   count: yup.number('Count should be number.'),
  // }),
  shoot: yup.object().shape({
    shootTypeId: yup
      .number()
      .required('Shoot Type is required.')
      .typeError('Shoot Type is required.'),
  }),
}
