import { Controller, Control } from 'react-hook-form'
import _get from 'lodash/get'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import ProjectItemWidget from '@/widgets/project-item-widget'
import { IProject } from '@/shared/models/project'

const DateTypeFields = ['actualizedDate', 'deliveryDate', 'fundingBeginDate', 'fundingEndDate', 'productionStartDate', 'preBidDate']

export type Field = {
  field: string
  label: string
  value?: string | number
  options?: { id: number; name: string }[]
  show?: boolean
}

const NestFormField = (props: {
  control: Control<any>
  formField: Field
  disabled?: boolean
  tenantId?: number
  clientId: number
  project?: IProject
}) => {
  const { control, formField, disabled, tenantId, clientId, project } = props

  if (formField.field === 'tagIds') {
    return (
      <Controller
        render={({ field, fieldState, formState }) => {
          return (
            <ProjectItemWidget
              widgetConfig={{
                order: 0,
                permissions: [],
                visible: true,
                style: {},
              }}
              config={{}}
              inputs={{
                name: formField.field,
                label: formField.label,
                labelKey: 'description',
                type: 'select',
                isCreatable: true,
                tenantId,
                clientId,
                project,
                InputProps: {
                  className: 'overview',
                  label: formField.label,
                  size: 'small',
                  value: field.value,
                  variant: 'filled',
                  placeholder: 'Click to add tags',
                  InputLabelProps: {
                    shrink: true,
                  },
                  AutocompleteProps: {
                    multiple: true,
                  },
                },
              }}
              outputs={{
                handleCancel: () => {},
                handleSubmit: (v: any) => {
                  field.onChange(v)
                },
              }}
              shouldUpdateProject={false}
            />
          )
        }}
        control={control}
        name={formField.field as any}
        defaultValue={_get(project, formField.field) || []}
      />
    )
  }

  if (formField.field.indexOf('metaData.') >= 0) {
    return (
      <Controller
        render={({ field, fieldState, formState }) => {
          return (
            <ProjectItemWidget
              widgetConfig={{
                order: 0,
                area: 'Sidebar',
                permissions: [],
                visible: true,
                style: {},
              }}
              config={{}}
              inputs={{
                name: formField.field,
                type: 'metaData',
                tenantId,
                clientId,
                project,
                showAddButton: true,
                InputProps: {
                  className: 'overview',
                  size: 'small',
                  value: field.value,
                },
              }}
              outputs={{
                handleCancel: () => {},
                handleSubmit: (v: any) => {
                  field.onChange(v)
                },
              }}
              shouldUpdateProject={false}
            />
          )
        }}
        control={control}
        name={formField.field as any}
        defaultValue={_get(project, formField.field) || null}
      />
    )
  }

  if (DateTypeFields.includes(formField.field)) {
    return (
      <Controller
        render={({ field, fieldState, formState }) => (
          <PagDatePicker
            {...{
              margin: 'none',
              label: formField.label,
              format: 'MM/dd/yyyy',
              value: field.value,
              size: 'small',
              inputVariant: 'filled',
              fullWidth: true,
              emptyLabel: '',
              onChange: (e: any) => {
                field.onChange(e)
              },
              KeyboardButtonProps: {
                'aria-label': 'change date',
              },
            }}
          />
        )}
        control={control}
        name={formField.field as any}
        defaultValue={_get(project, formField.field) || null}
      />
    )
  }

  if (formField.options) {
    return (
      <Controller
        render={({ field, fieldState, formState }) => (
          <PagAutocomplete
            {...{
              name: formField.field,
              optionLabelKey: 'name',
              optionValueKey: 'id',
              options: formField.options || [],
              error: fieldState.error,
              value: field.value || null,
              isCreatable: false,
              AutocompleteProps: {
                getOptionLabel: (option: any) => option.name,
                // getOptionSelected: (option: any, value: any) => option.value === value,
                size: 'small',
                disableClearable: false,
                onChange: (event: object, values: any, reason: string) => {
                  field.onChange(values ? values.id : null)
                },
                onBlur: field.onBlur,
              },
              InputProps: {
                variant: 'filled',
                label: formField.label,
                placeholder: formField.label,
              },
            }}
          />
        )}
        control={control}
        name={formField.field as any}
        defaultValue={_get(project, formField.field) || null}
      />
    )
  }

  return (
    <Controller
      render={({ field, fieldState, formState }) => (
        <PagTextInput
          label={formField.label}
          placeholder={formField.label}
          size="small"
          variant="filled"
          error={fieldState.error}
          disabled={disabled || false}
          value={field.value}
          onChange={(e: any) => field.onChange(e.target.value)}
          onBlur={field.onBlur}
        />
      )}
      control={control}
      name={formField.field as any}
      defaultValue={_get(project, formField.field) || ''}
    />
  )
}

export default NestFormField
