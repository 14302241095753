import gql from 'graphql-tag'

const ADD_CONTACT = gql`
  mutation addContact($tenantId: Int!, $email: String, $name: String!, $clientId: Int!) {
    addContact(tenantId: $tenantId, email: $email, name: $name, clientId: $clientId) {
      id
      name
      email
      clientId
    }
  }
`
const UPDATE_CONTACT = gql`
  mutation updateContact($tenantId: Int!, $id: Int!, $email: String, $name: String) {
    updateContact(tenantId: $tenantId, id: $id, email: $email, name: $name) {
      id
      name
      email
      clientId
    }
  }
`

export { ADD_CONTACT, UPDATE_CONTACT }
