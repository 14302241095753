import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { InstantSearch, Index, Configure, connectInfiniteHits } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import _get from 'lodash/get'

import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import AllSearchView from '@/routes/projects/components/search/all-search-view'
import { IProject } from '@/shared/models/project'
import { Context } from '@/shared/store/reducers/global-reducer'
import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'

const AllSearchViewHits = connectInfiniteHits(PagAlgoliaConnectConditionalResults(AllSearchView))

const AllSearchContainer = (props: any) => {
  const {
    clientId,
    brandId,

    filterable,
    filters,
    searchPhrase,
  } = props
  const navigate = useNavigate()
  const { store, dispatch } = useContext(Context)
  const [searchState, setSearchState] = useState({ page: 1 } as any)
  const [searchClient, setSearchClient] = React.useState(null as any)

  const { algoliaSearchSecuredApiKey } = store

  useEffect(() => {
    if (algoliaSearchSecuredApiKey) {
      setSearchClient(algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey))
    }
    // setSearchClient(algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY));
    return () => {}
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if (!searchClient) {
      return
    }

    searchClient.clearCache()
    setSearchState({
      page: 1,
    })

    return () => {}
  }, [searchClient, clientId, brandId])

  const handleViewProject = useCallback((project: IProject, tabName: string) => {
    const cId = project.clientId || project.client?.id || project.brand?.clientId
    const bId = project.brand?.id || project.brandId
    navigate(`/${cId}/${bId}/${project.id}/${tabName}`)
  }, [])

  const onSearchStateChange = (nextState: any) => {
    setSearchState(nextState)
  }

  if (!searchClient) return null

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="Deliverables"
      stalledSearchDelay={500}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
    >
      <Index indexName="Projects">
        {filterable ? (
          <Configure filters={filters} query={''} />
        ) : (
          <Configure query={searchPhrase} />
        )}
        <AllSearchViewHits />
      </Index>

      <Index indexName="Bids">
        {filterable ? (
          <Configure filters={filters} query={''} />
        ) : (
          <Configure query={searchPhrase} />
        )}
        <AllSearchViewHits />
      </Index>

      <Index indexName="Deliverables">
        {filterable ? (
          <Configure filters={filters} query={''} />
        ) : (
          <Configure query={searchPhrase} />
        )}
        <AllSearchViewHits />
      </Index>

      {/* {filterable ? (
            <Configure filters={filters} query={''} />
          ) : (
            <Configure query={searchPhrase} />
          )} */}
      {/* <AllSearchViewHits/> */}
      {/* <Index indexName="Projects"/> */}
      {/* <Index indexName="Bids" /> */}
      {/* <Index indexName="Deliverables"/> */}
    </InstantSearch>
  )
}

export default AllSearchContainer
