const yup = require('yup')

const disallowedCharacters = /[\/\\":*?<>|+]/;

const NewProjectSchema = yup.object().shape({
  clientName: yup.string().required('Client is required.'),
  brandId: yup.number().required('Brand is required.').typeError('Brand is required.'),
  agencyId: yup.number().required('Agency is required.').typeError('Agency is required.'),
  projectName: yup.string().required('Project Name is required.'),
  projectNumber: yup
    .string()
    .test(
      'disallowed-characters',
      'Project Number contains disallowed characters.',
      (value) => !disallowedCharacters.test(value || '')
    )
    .required('Project Number is required.'),
})

export default NewProjectSchema
