import gql from 'graphql-tag'

const ADD_PURCHASE_ORDER = gql`
  mutation addPurchaseOrder($tenantId: Int!, $data: PurchaseOrderInput!) {
    addPurchaseOrder(tenantId: $tenantId, data: $data) {
      id
      poNumber
      beginDate
      endDate
      amount
      revision
      totalAmount
      revised
      projectId
      currencyId
    }
  }
`

const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder($tenantId: Int!, $id: Int!, $data: PurchaseOrderInput!) {
    updatePurchaseOrder(tenantId: $tenantId, id: $id, data: $data) {
      id
      poNumber
      beginDate
      endDate
      amount
      revision
      totalAmount
      revised
      projectId
      currencyId
    }
  }
`

const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($tenantId: Int!, $id: Int!) {
    deletePurchaseOrder(tenantId: $tenantId, id: $id) {
      success
      data
      error
    }
  }
`

export { ADD_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER, DELETE_PURCHASE_ORDER }
