import React, { useState, useEffect, useMemo, useContext } from 'react'
import { InstantSearch, Configure, connectInfiniteHits } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'

import useMounted from '@/components/hooks/use-mounted'
import PagAlertDialog from '@/components/pag-alert'
import PagCircularIndeterminate from '@/components/pag-loading'
import {
  DeliverableEditDialog,
  DeliverablesTableView,
  AdIDSearchDialog,
} from '@/routes/projects/components/deliverables'
import PagAlgoliaConnectConditionalResults from '@/components/pag-algolia-connect-condition-results'
import { GET_MEDIA_TYPES } from '@/shared/graphql/queryGetMediaTypes'
import { GET_MEDIUMS } from '@/shared/graphql/queryGetMediums'
import { GET_SPOT_TYPES } from '@/shared/graphql/queryGetSpotTypes'
import { DELETE_DELIVERABLE } from '@/shared/graphql/mutations/mutationDeliverables'
import { IDeliverable } from '@/shared/models/deliverable'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'

import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonsWrapper: {
    padding: '16px 0',
  },
}))

const DeliverablesTableViewHits = connectInfiniteHits(
  PagAlgoliaConnectConditionalResults(DeliverablesTableView)
)

const DeliverablesSearchContainer = (props: any) => {
  const {
    clientId,
    brandId,
    projectId,
    editable,

    filterable,
    filters,
    searchPhrase,
  } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, algoliaSearchSecuredApiKey } = store
  const mountedRef = useMounted(true)
  const [searchStateSettings, setSearchStateSettings] = useState({
    searchState: { page: 1 } as any,
    refresh: false,
  })

  const [mediaTypes, setMediaTypes] = useState([])
  const [mediums, setMediums] = useState([])
  const [spotTypes, setSpotTypes] = useState([])
  const [editDialogOption, setEditDialogOption] = useState<{
    openDeliverableDialog: boolean
    openAdIDSearchDialog: boolean
    title: string
    deliverable: IDeliverable | null
  }>({
    openDeliverableDialog: false,
    openAdIDSearchDialog: false,
    title: 'Add New Deliverables',
    deliverable: null,
  })
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<IDeliverable | null>(null)

  // Get MediaTypes
  const [mediaTypesQuery, mediaTypesQueryRes] = useLazyQuery(GET_MEDIA_TYPES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setMediaTypes(_get(res, ['mediaTypes', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })
  // Get Mediums
  const [mediumsQuery, mediumsQueryRes] = useLazyQuery(GET_MEDIUMS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setMediums(_get(res, ['mediums', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })
  // Get SpotTypes
  const [spotTypesQuery, spotTypesQueryRes] = useLazyQuery(GET_SPOT_TYPES, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      setSpotTypes(_get(res, ['spotTypes', 'data'], []))
    },
    fetchPolicy: 'network-only',
  })

  // Delete Deliverable
  const [deleteDeliverable, deleteDeliverableRes] = useMutation(DELETE_DELIVERABLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  useEffect(() => {
    if (activeTenant) return
    const abortController = new AbortController()
    const { signal } = abortController

    callPagApi('', getTokenSilently, mediaTypesQuery, { tenantId: activeTenant.id }, signal)

    callPagApi('', getTokenSilently, mediumsQuery, { tenantId: activeTenant.id }, signal)

    callPagApi('', getTokenSilently, spotTypesQuery, { tenantId: activeTenant.id }, signal)

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant])

  const searchClient = useMemo(() => {
    return algoliaSearchSecuredApiKey
      ? algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey)
      : null
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if (!searchClient) {
      return
    }
    handleForceRefresh()
    return () => {}
  }, [searchClient, clientId, brandId, projectId])

  useEffect(() => {
    if (!searchClient || !deleteDeliverableRes.data) {
      return
    }
    handleForceRefresh()
    return () => {}
  }, [deleteDeliverableRes.data])

  useEffect(() => {
    if (searchStateSettings.refresh) {
      setSearchStateSettings({
        ...searchStateSettings,
        refresh: false,
      })
    }
    return () => {}
  }, [searchStateSettings.refresh])

  const onSearchStateChange = (nextState: any) => {
    setSearchStateSettings({
      ...searchStateSettings,
      searchState: nextState,
      refresh: true,
    })
  }

  const handleForceRefresh = () => {
    setSearchStateSettings({
      ...searchStateSettings,
      searchState: {
        ...searchStateSettings.searchState,
        page: 1,
      },
      refresh: true,
    })
  }

  const handleEdit = (item: IDeliverable) => {
    setEditDialogOption({
      openDeliverableDialog: true,
      openAdIDSearchDialog: false,
      title: 'Edit Deliverable',
      deliverable: item,
    })
  }

  const handleDelete = (item: IDeliverable | null) => {
    if (!item || !activeTenant?.id) return
    const abortController = new AbortController()
    try {
      const callApi = async (data: { tenantId: number; id: number }) => {
        try {
          const temp = await callPagApi(
            '',
            getTokenSilently,
            deleteDeliverable,
            data,
            abortController.signal
          )
        } catch (e) {}
      }

      setEditDialogOption({
        openDeliverableDialog: false,
        openAdIDSearchDialog: false,
        title: 'Add New Deliverables',
        deliverable: null,
      })
      callApi({ tenantId: activeTenant?.id, id: item.id as number })
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const renderDeliverableEditDialog = useMemo(() => {
    if (!editable) return null
    return (
      <DeliverableEditDialog
        open={editDialogOption.openDeliverableDialog}
        deliverable={editDialogOption.deliverable}
        projectId={projectId}
        mediaTypes={mediaTypes}
        mediums={mediums}
        spotTypes={spotTypes}
        handleOpenAdIdSearchDialog={(e: boolean) => {
          setEditDialogOption({
            ...editDialogOption,
            openAdIDSearchDialog: e,
          })
        }}
        handleDelete={(e: IDeliverable) => {
          setOpenDeleteConfirm(e)
        }}
        handleClose={(e: IDeliverable | null) => {
          setEditDialogOption({
            openDeliverableDialog: false,
            openAdIDSearchDialog: false,
            title: 'Add New Deliverables',
            deliverable: null,
          })

          if (e) {
            handleForceRefresh()
          }
        }}
      />
    )
  }, [editDialogOption, projectId, editable, mediaTypes, mediums, spotTypes])

  const renderAdIDSearchDialog = useMemo(() => {
    if (!editable || !editDialogOption.openAdIDSearchDialog) return null
    return (
      <AdIDSearchDialog
        open={editDialogOption.openAdIDSearchDialog}
        enableMultipleSelection={
          editDialogOption.openAdIDSearchDialog && !editDialogOption.deliverable
        }
        deliverable={_get(editDialogOption, ['deliverable'])}
        projectId={projectId}
        handleClose={(e: IDeliverable | null) => {
          if (e) {
            setEditDialogOption({
              openDeliverableDialog: false,
              openAdIDSearchDialog: false,
              title: 'Add New Deliverables',
              deliverable: null,
            })
            handleForceRefresh()
          } else {
            setEditDialogOption({
              ...editDialogOption,
              openAdIDSearchDialog: false,
            })
          }
        }}
      />
    )
  }, [editDialogOption, projectId, editable])

  const renderInstantSearch = useMemo(() => {
    if (!searchClient) return null
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName="Deliverables"
        refresh={searchStateSettings.refresh}
        stalledSearchDelay={500}
        searchState={searchStateSettings.searchState}
        onSearchStateChange={onSearchStateChange}
      >
        {filterable ? (
          <Configure filters={filters} query={''} />
        ) : (
          <Configure query={searchPhrase} />
        )}
        <DeliverablesTableViewHits
          {...{
            editable,
            handleEdit,
            handleDelete,
          }}
        />
      </InstantSearch>
    )
  }, [searchClient, searchStateSettings, searchPhrase, editable, filterable, filters])

  return (
    <div className={classes.root}>
      {renderInstantSearch}
      {editable && (
        <div className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={() => {
              setEditDialogOption({
                openDeliverableDialog: true,
                openAdIDSearchDialog: false,
                title: 'Add New Deliverable',
                deliverable: null,
              })
            }}
          >
            Add New
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={() => {
              setEditDialogOption({
                openDeliverableDialog: false,
                openAdIDSearchDialog: true,
                title: 'Add New Deliverables',
                deliverable: null,
              })
            }}
          >
            Add New from adID
          </Button>
        </div>
      )}
      {renderDeliverableEditDialog}
      {renderAdIDSearchDialog}
      {openDeleteConfirm && (
        <PagAlertDialog
          title="Delete a Deliverable"
          content="Are you sure to delete the deliverable?"
          open={true}
          handleClose={(e: boolean) => {
            if (e) {
              handleDelete(openDeleteConfirm)
            }
            setOpenDeleteConfirm(null)
          }}
        />
      )}
      {deleteDeliverableRes.loading && <PagCircularIndeterminate size={24} />}
    </div>
  )
}

export default DeliverablesSearchContainer
