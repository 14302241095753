import gql from 'graphql-tag'

const BULK_WRITE_DELIVERABLES = gql`
  mutation bulkWriteDeliverables($tenantId: Int!, $upserts: [DeliverableInput], $deletes: [Int]) {
    bulkWriteDeliverables(tenantId: $tenantId, upserts: $upserts, deletes: $deletes) {
      success
      data {
        inserts {
          id
          agencyName
          created
          jobNo
          language
          length
          linkedTo
          spotName
          spotId
          mediaType {
            id
            name
          }
          medium {
            id
            name
          }
          spotType {
            id
            name
          }
        }
        updates {
          id
          agencyName
          created
          jobNo
          language
          length
          linkedTo
          spotName
          spotId
          projectId
          mediaType {
            id
            name
          }
          medium {
            id
            name
          }
          spotType {
            id
            name
          }
        }
        deletes
      }
      errors
    }
  }
`
const ADD_DELIVERABLE = gql`
  mutation addDeliverable($tenantId: Int!, $data: DeliverableInput!) {
    addDeliverable(tenantId: $tenantId, data: $data) {
      id
      agencyName
      created
      jobNo
      language
      length
      linkedTo
      spotName
      spotId
      mediaType {
        id
        name
      }
      medium {
        id
        name
      }
      projectId
      spotType {
        id
        name
      }
    }
  }
`

const UPDATE_DELIVERABLE = gql`
  mutation updateDeliverable($tenantId: Int!, $id: Int!, $data: DeliverableInput!) {
    updateDeliverable(tenantId: $tenantId, id: $id, data: $data) {
      id
      agencyName
      created
      jobNo
      language
      length
      linkedTo
      spotName
      spotId
      projectId
      mediaType {
        id
        name
      }
      medium {
        id
        name
      }
      spotType {
        id
        name
      }
    }
  }
`

const DELETE_DELIVERABLE = gql`
  mutation deleteDeliverable($tenantId: Int!, $id: Int!) {
    deleteDeliverable(tenantId: $tenantId, id: $id) {
      success
      data
      error
    }
  }
`

export { BULK_WRITE_DELIVERABLES, ADD_DELIVERABLE, UPDATE_DELIVERABLE, DELETE_DELIVERABLE }
