export const CURRENCIES: any = {
  AUD: 'A$',
  BGN: 'Лв.',
  BRL: 'R$',
  CAD: 'Can$',
  CHF: 'CHf',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'Kr.',
  EUR: '€',
  GBP: '£',
  HKD: 'HK$',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JPY: '¥',
  KRW: '₩',
  MXN: 'Mex$',
  MYR: 'RM',
  NOK: 'kr',
  NZD: '$',
  PHP: '₱',
  PLN: 'zł',
  RON: 'lei',
  RUB: '₽',
  SEK: 'kr',
  SGD: 'S$',
  THB: '฿',
  TRY: '₺',
  USD: '$',
  'US Dollar': '$',
  ZAR: 'R',
}
