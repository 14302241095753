import React, { useContext, useLayoutEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'

import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagNumberFormatInput from '@/components/pag-form/pag-number-format-Input'
import PagTextInput from '@/components/pag-form/pag-text-input'
import { LANGUAGES } from '@/shared/constants/language'
import { errorHandler } from '@/shared/error-handler'
import {
  ADD_DELIVERABLE,
  UPDATE_DELIVERABLE,
} from '@/shared/graphql/mutations/mutationDeliverables'
import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { IAutocompletOption } from '@/shared/models/autocomplete'
import { IMediaType } from '@/shared/models/media-type'
import { IMedium } from '@/shared/models/medium'
import { ISpotType } from '@/shared/models/spot-type'
import { stringToNumber } from '@/utils/numeric'

const FormSchema = yup.object().shape({
  spotName: yup.string().required('Spot Name is required.'),
  spotId: yup.string().required('Spot Identifier is required.'),
})

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    borderBottom: '1px solid',
    padding: 12,
  },
  dialogContent: {
    padding: '24px 16px 16px',
  },
  dialogAction: {
    padding: 16,
  },
  closeButton: {
    fontSize: 18,
    position: 'absolute',
    right: 2,
    top: 2,
    color: 'black',
  },
}))

const DeliverableEditDialog = (props: any) => {
  const {
    open,
    deliverable,
    projectId,
    mediaTypes,
    mediums,
    spotTypes,
    handleClose,
    handleDelete,
    handleOpenAdIdSearchDialog,
  } = props

  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const [addDeliverable, addDeliverableRes] = useMutation(ADD_DELIVERABLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      const temp = res?.addDeliverable
      if (temp) {
        handleClose(temp)
      }
    },
  })
  const [updateDeliverable, updateDeliverableRes] = useMutation(UPDATE_DELIVERABLE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      const temp = res?.updateDeliverable
      if (temp) {
        handleClose(temp)
      }
    },
  })

  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema),
  })

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = hookFormMethods

  useLayoutEffect(() => {
    if (deliverable) {
      const temp = LANGUAGES.find((l) => l.value === deliverable.language)
      if (!temp) {
        LANGUAGES.push({ label: deliverable.language, value: deliverable.language })
      }
    }
  }, [deliverable])

  const handleSubmitForm = (formData: any) => {
    if (!activeTenant?.id) return
    const abortController = new AbortController()
    try {
      const callApi = async (data: any) => {
        try {
          await callPagApi(
            '',
            getTokenSilently,
            payload?.id ? updateDeliverable : addDeliverable,
            data,
            abortController.signal
          )
        } catch (e) {}
      }

      const payload: any = {
        tenantId: activeTenant.id,
        id: deliverable?.id,
        data: {
          language: formData.language,
          length: stringToNumber(formData.length),
          spotName: formData.spotName,
          spotId: formData.spotId,
          mediaTypeId: formData.mediaTypeId,
          mediumId: formData.mediumId,
          spotTypeId: formData.spotTypeId,
          projectId,
        },
      }

      callApi(payload)
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="deliverable-edit-dialog-title"
    >
      <DialogTitle id="deliverable-edit-dialog-title" className={classes.dialogTitle}>
        {Boolean(deliverable) ? 'Edit Deliverable' : 'Add New Deliverable'}
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose(null)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form
          name="DeliverableForm"
          onSubmit={handleSubmit(handleSubmitForm)}
          // onMouseEnter={(e) => e.stopPropagation()}
          // onMouseLeave={(e) => handleMouseLeave(e)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagTextInput
                    label="Spot Identifier"
                    placeholder="Spot Identifier"
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`spotId`}
                defaultValue={deliverable?.spotId || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagTextInput
                    label="Spot Name"
                    placeholder="Spot Name"
                    size="small"
                    variant="outlined"
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`spotName`}
                defaultValue={deliverable?.spotName || ''}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: spotTypes,
                      error: fieldState.error,
                      value: field.value,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: ISpotType) => option.name,
                        getOptionSelected: (option: ISpotType, value: ISpotType | null) =>
                          option.id === value?.id,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: ISpotType, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Spot Type',
                        placeholder: 'Spot Type',
                      },
                    }}
                  />
                )}
                control={control}
                name={`spotTypeId`}
                defaultValue={deliverable?.spotType?.id || null}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'mediaTypeId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: mediaTypes,
                      error: fieldState.error,
                      value: field.value,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IMediaType) => option.name,
                        getOptionSelected: (option: IMediaType, value: IMediaType | null) =>
                          option.id === value?.id,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: IMediaType, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Media Type',
                        placeholder: 'Media Type',
                      },
                    }}
                  />
                )}
                control={control}
                name={`mediaTypeId`}
                defaultValue={deliverable?.mediaType?.id || null}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'mediumId',
                      optionLabelKey: 'name',
                      optionValueKey: 'id',
                      options: mediums,
                      error: fieldState.error,
                      value: field.value,
                      isCreatable: false,
                      AutocompleteProps: {
                        getOptionLabel: (option: IMedium) => option.name,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: IMedium, reason: string) => {
                          field.onChange(values ? values.id : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Medium',
                        placeholder: 'Medium',
                      },
                    }}
                  />
                )}
                control={control}
                name={`mediumId`}
                defaultValue={deliverable?.medium?.id || null}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState, formState }) => (
                  <PagAutocomplete
                    {...{
                      name: 'language',
                      optionLabelKey: 'label',
                      optionValueKey: 'value',
                      options: LANGUAGES,
                      error: fieldState.error,
                      value: field.value,
                      isCreatable: true,
                      AutocompleteProps: {
                        getOptionLabel: (option: IAutocompletOption) => option.label,
                        getOptionSelected: (
                          option: IAutocompletOption,
                          value: IAutocompletOption | null
                        ) => option.value === value?.value,
                        size: 'small',
                        disableClearable: true,
                        onChange: (event: object, values: any, reason: string) => {
                          if (values.isNew) {
                            LANGUAGES.push({ label: values.value, value: values.newValue })
                          }
                          field.onChange(values ? values.value : null)
                        },
                        onBlur: field.onBlur,
                      },
                      InputProps: {
                        variant: 'outlined',
                        label: 'Language',
                        placeholder: 'Language',
                      },
                    }}
                  />
                )}
                control={control}
                name={`language`}
                defaultValue={deliverable?.language || null}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                render={({ field, fieldState }) => (
                  <PagTextInput
                    label="Length"
                    placeholder="Length"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      name: 'length',
                      inputComponent: PagNumberFormatInput,
                      inputProps: {
                        thousandSeparator: true,
                        isNumericString: true,
                        decimalScale: 0,
                      },
                    }}
                    error={fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
                control={control}
                name={`length`}
                defaultValue={deliverable?.length || ''}
              />
            </Grid>
            <Grid item xs={4}>
              {deliverable?.linkTo || ''}
            </Grid>

            <Grid item xs={12} className="pag-text-right">
              {Boolean(deliverable) && (
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  style={{ float: 'left', marginRight: 12 }}
                  onClick={() => handleDelete(deliverable)}
                >
                  Delete
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                className="pag-button"
                style={{ float: 'left' }}
                onClick={() => handleOpenAdIdSearchDialog(true)}
              >
                Find Ad-ID
              </Button>
              <Button variant="text" color="inherit" onClick={() => handleClose(null)}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="pag-button"
                disabled={!isValid || addDeliverableRes.loading || updateDeliverableRes.loading}
              >
                {addDeliverableRes.loading || updateDeliverableRes.loading ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  'Save'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DeliverableEditDialog
