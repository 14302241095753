import React, { useRef } from 'react'
import { PickerOverlay } from 'filestack-react'

import _get from 'lodash/get'

import useMounted from '@/components/hooks/use-mounted'
import { FILESTACK_KEY } from '@/config/filestack.config'

export const ProjectItemFileEditComponent = (props: any) => {
  const { name, file, handleCancel, handleChange } = props

  const mountedRef = useMounted(true)
  const uploadingRef = useRef(false)

  const handleChangeFile = (e: any) => {
    const uploadedFile = _get(e, ['filesUploaded', 0]) || {}
    handleChange({
      source: _get(uploadedFile, ['source']) || '',
      handle: _get(uploadedFile, ['handle']) || '',
      filename: _get(uploadedFile, ['filename']) || '',
      key: _get(uploadedFile, ['key']) || '',
      url: _get(uploadedFile, ['url']) || '',
      mimetype: _get(uploadedFile, ['mimetype']) || '',
    })
  }

  return (
    <PickerOverlay
      apikey={FILESTACK_KEY}
      pickerOptions={{
        accept: ['audio/*', 'video/*', 'image/*', 'text/*', 'application/*', 'image/jpeg', '.pdf'],
        exposeOriginalFile: true,
        onFileUploadStarted: () => {
          uploadingRef.current = true
        },
        onFileUploadFinished: () => {},
        onFileUploadFailed: () => {
          uploadingRef.current = false
        },
        onClose: () => {
          if (!uploadingRef.current) {
            handleCancel()
          }
        },
      }}
      onUploadDone={(res: any) => {
        uploadingRef.current = false
        handleChangeFile(res)
      }}
    />
  )
}

export default ProjectItemFileEditComponent
