import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { PickerOverlay } from 'filestack-react'
import _get from 'lodash/get'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'

import { FILESTACK_KEY } from '@/config/filestack.config'
import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { UPDATE_PROJECT } from '@/shared/graphql/mutations/mutationProjects'
import { callPagApi } from '@/shared/services/pag-api'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
      '& .image-over-layer': {
        display: 'inherit',
      },
    },
    '& .image-over-layer': {
      display: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundColor: theme.palette.grey[200],
      opacity: '0.4',
    },
  },
  projectImage: {
    width: 164,
    height: 114,
    objectFit: 'contain',
    objectPosition: 'top'
  },

  projectImageEdit: {
    position: 'absolute',
    marginTop: 12,
    textAlign: 'center',
    width: 316,
    zIndex: 1001,
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  projectImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200],
    height: 198,
  },
  projectImageEditImage: {
    width: 284,
    height: 198,
  },
}))

export const ProjectImageEdit = (props: any) => {
  const { projectId, shouldUpdateProject = true, handleCancel, handleChangeProjectImage } = props
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const uploadingRef = useRef(false)
  const uploadingImageRef = useRef(null)
  const [updateProject, updateProjectRes] = useMutation(UPDATE_PROJECT, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      if (res) handleChangeProjectImage(uploadingImageRef.current)
    },
  })

  const handleChangeFile = (e: any) => {
    let abortController: any
    try {
      const url = _get(e, ['filesUploaded', 0, 'url']) || ''
      if (!projectId || !shouldUpdateProject) return handleChangeProjectImage(url)

      uploadingImageRef.current = url
      abortController = new AbortController()

      callPagApi(
        '',
        getTokenSilently,
        updateProject,
        {
          tenantId: activeTenant?.id,
          id: projectId,
          image: url,
        },
        abortController.signal
      )
    } catch (err) {
      uploadingImageRef.current = null
      if (abortController) abortController.abort()
    }
  }

  if (updateProjectRes.loading || updateProjectRes.called) return null

  return (
    <PickerOverlay
      apikey={FILESTACK_KEY}
      componentDisplayMode={{
        type: 'immediate',
      }}
      pickerOptions={{
        accept: ['image/*', 'image/jpeg'],
        onFileUploadStarted: () => {
          uploadingRef.current = true
        },
        onFileUploadFinished: () => {},
        onFileUploadFailed: () => {
          uploadingRef.current = false
        },
        onFileUploadCancel: () => {
          if (!uploadingRef.current) {
            handleCancel()
          }
        },
        onClose: () => {
          if (!uploadingRef.current) {
            handleCancel()
          }
        },
      }}
      onUploadDone={(res: any) => {
        handleChangeFile(res)
      }}
      onError={(res: any) => {
        console.log('errr', res)
      }}
    />
  )
}

const ProjectImage = (props: any) => {
  const { image, project, shouldUpdateProject = true, handleSubmit } = props
  const classes = useStyles()
  const [editingStatus, setEditingStatus] = useState(false)

  const handleChangeProjectImage = (url: string) => {
    setEditingStatus(false)
    handleSubmit(url)
  }

  const projectImage =
    image ||
    project?.image ||
    project?.client?.defaultProjectImage ||
    project?.client?.brand?.defaultProjectImage

  return (
    <Paper className={clsx(classes.root)} elevation={0}>
      <div onClick={() => setEditingStatus(true)}>
        {projectImage ? (
          <img className={classes.projectImage} src={projectImage} alt="project thumbnail" />
        ) : (
          <div className={clsx(classes.projectImage, 'pag-flex')}>
            <Icon color="primary" style={{ fontSize: 32 }}>
              add
            </Icon>
          </div>
        )}
        <div className={clsx('image-over-layer')}></div>
      </div>
      {editingStatus && (
        <ProjectImageEdit
          projectId={project?.id}
          shouldUpdateProject={shouldUpdateProject}
          handleChangeProjectImage={handleChangeProjectImage}
          handleCancel={() => {
            setEditingStatus(false)
          }}
        />
      )}
    </Paper>
  )
}

ProjectImage.propTypes = {
  image: PropTypes.string,
  project: PropTypes.object,
  shouldUpdateProject: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

ProjectImage.defaultProps = {
  handleCancel: (e: any) => {},
  handleSubmit: (e: any) => {},
}

export default ProjectImage
