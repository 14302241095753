import React, { useEffect, useRef } from 'react'

const useMounted = (defaultValue: boolean) => {
  const mountedRef = useRef(defaultValue)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return mountedRef
}

export default useMounted
