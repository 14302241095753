import gql from 'graphql-tag'

const GET_PROJECT_REGIONS = gql`
  query projectRegions($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    projectRegions(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        region
      }
    }
  }
`

export { GET_PROJECT_REGIONS }
