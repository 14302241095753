import gql from 'graphql-tag'

const GET_BID_STATUS = gql`
  query projectStatus($tenantId: Int!, $offset: Int, $limit: Int) {
    projectBidStatus(tenantId: $tenantId, offset: $offset, limit: $limit) {
      total
      data {
        id
        status
      }
    }
  }
`

export { GET_BID_STATUS }
