import React, { useContext, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import { ProjectItemWidgetContext } from '../index'
import ProjectItemDate from '../components/item-date-component'
import ProjectItemFiles from '../components/item-files-component'
import ProjectItemSelect from '../components/item-select-component'
import ProjectItemText from '../components/item-text-component'
import ProjectItemShoots from '../components/item-shoots-component'
import ProjectItemProjectTeam from '../components/item-project-team-component'
import ProjectDriversOptimizers from '../components/item-cost-driver-optimizers-component'

import FileEdit from '../components/item-file-edit-component'
import ProjectTeamMemberEdit from '../components/project-team-member-edit-component'
import ShootEdit from '../components/item-shoot-edit-component'

import { AddIconItemNames, Permission, ProjectItemWidgetSchema } from '../config'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  itemHeader: {
    position: 'relative',
  },
  itemTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  itemContent: {
    position: 'relative',
  },
  addIconButton: {
    position: 'absolute',
    padding: 4,
    top: 8,
    right: 8,
  },
  divider: {
    height: 4,
    backgroundColor: theme.palette.text.primary,
  },
  padding18: {
    paddingLeft: '18px!important',
    paddingRight: '18px!important',
  },
}))

const ProjectItemSidebarWidget = (props: any) => {
  const {
    label,
    name,
    labelKey,
    valueKey,
    clientId,
    project,
    type,
    isCreatable,
    loading,
    showAddButton,
    InputProps,
    handleCancel,
    handleChange,
  } = props

  const classes = useStyles(props)
  const editContainerRef = useRef(null)
  const { options } = useContext(ProjectItemWidgetContext)
  const [openEditor, setOpenEditor] = useState(false)
  const [editItem, setEditItem] = useState(null as any)

  const hasAddIconItem = AddIconItemNames.indexOf(name) >= 0
  const handleAddIconButton = () => {
    setOpenEditor(!openEditor)
  }

  const renderField = useMemo(() => {
    const cId = clientId || project?.clientId || project?.brand?.clientId
    switch (type) {
      case 'select':
        return (
          <ProjectItemSelect
            name={name}
            labelKey={labelKey}
            valueKey={valueKey}
            loading={loading}
            isCreatable={isCreatable}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
      case 'files':
        return (
          <ProjectItemFiles
            name={name}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
      case 'date':
        return (
          <ProjectItemDate
            name={name}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
      case 'metaData':
        // if (name === 'spots') {
        //   return (
        //     <ProjectItemSpots
        //       {
        //         ...{
        //           name,
        //           InputProps,
        //           loading,
        //           validationSchema: ProjectItemWidgetSchema['spots'] as any,
        //           handleCancel,
        //           handleChange,
        //         }
        //       }
        //     />
        //   );
        // } else
        if (name === 'projectTeam' || name === 'metaData.projectTeam') {
          return (
            <ProjectItemProjectTeam
              {...{
                name,
                InputProps,
                projectTeamMemberRoles: options,
                showAddButton,
                handleCancel,
                handleChange,
                handleEdit: (item: any) => {
                  setEditItem(item)
                  setOpenEditor(true)
                },
                handleAdd: handleAddIconButton,
              }}
            />
          )
        } else if (name === 'costDriversOptimizers' || name === 'metaData.costDriversOptimizers') {
          return (
            <ProjectDriversOptimizers
              {...{
                InputProps,
                clientId: cId,
                loading,
                showAddButton,
                handleCancel,
                handleChange,
              }}
            />
          )
        } else if (name === 'shoots' || name === 'metaData.shoots') {
          return (
            <ProjectItemShoots
              {...{
                name,
                InputProps,
                loading,
                showAddButton,
                handleCancel,
                handleChange,
                handleEdit: (item: any) => {
                  setEditItem(item)
                  setOpenEditor(true)
                },
                handleAdd: handleAddIconButton,
              }}
            />
          )
        }
        return null
      default:
        return (
          <ProjectItemText
            name={name}
            InputProps={InputProps}
            handleCancel={handleCancel}
            handleChange={handleChange}
          />
        )
    }
  }, [
    label,
    name,
    clientId,
    project,
    type,
    isCreatable,
    loading,
    showAddButton,
    InputProps,
    handleCancel,
    handleChange,
  ])

  const renderEdit = useMemo(() => {
    if (!openEditor) return null
    const cId = clientId || project?.clientId || project?.brand?.clientId
    switch (name) {
      case 'files':
        return (
          <FileEdit
            handleChange={(e: string) => {
              setOpenEditor(false)
              handleChange([...InputProps.value, e])
            }}
            handleCancel={() => {
              setOpenEditor(false)
              setEditItem(null)
            }}
          />
        )
      case 'metaData.projectTeam':
      case 'projectTeam':
        return (
          <ProjectTeamMemberEdit
            clientId={cId}
            project={project}
            projectTeamMembers={InputProps.value}
            projectTeamMember={editItem}
            projectTeamMemberRoles={options}
            validationSchema={ProjectItemWidgetSchema['projectTeam'] as any}
            handleChange={(e: string, shouldCallApi: boolean) => {
              setOpenEditor(false)
              setEditItem(null)
              handleChange(e, shouldCallApi)
            }}
            handleCancel={() => {
              setOpenEditor(false)
              setEditItem(null)
            }}
          />
        )
      case 'metaData.shoots':
      case 'shoots':
        return (
          <ShootEdit
            clientId={cId}
            project={project}
            shoot={editItem}
            validationSchema={ProjectItemWidgetSchema['shoot'] as any}
            containerRef={editContainerRef}
            handleChange={(e: string, shouldCallApi: boolean) => {
              setOpenEditor(false)
              setEditItem(null)
              handleChange(e, shouldCallApi)
            }}
            handleCancel={() => {
              setOpenEditor(false)
              setEditItem(null)
            }}
          />
        )
    }

    return null
  }, [editItem, InputProps, project, openEditor])

  return (
    <div className={classes.root} ref={editContainerRef}>
      <Grid container spacing={2}>
        {Boolean(label) && (
          <Grid item xs={12} className={classes.itemHeader}>
            <Typography variant="h5" gutterBottom className={classes.itemTitle}>
              <b>{label}</b>
            </Typography>
            {hasAddIconItem && (
              <IconButton
                color="inherit"
                aria-label="add item content"
                edge="start"
                onClick={() => handleAddIconButton()}
                className={classes.addIconButton}
              >
                <AddIcon />
              </IconButton>
            )}
            <Divider className={classes.divider} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={clsx(classes.itemContent, hasAddIconItem ? classes.padding18 : undefined)}
        >
          {renderField}
          {renderEdit}
        </Grid>
      </Grid>
    </div>
  )
}

ProjectItemSidebarWidget.defaultProps = {
  handleChange: (e: any, shouldCallApi: boolean = true) => {},
}

export default ProjectItemSidebarWidget
