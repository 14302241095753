const yup = require('yup')

const ProjectCostEntrySchema = yup.object().shape({
  projectCostTypeId: yup
    .number()
    .typeError('Project Cost Type is required.')
    .required('Project Cost Type is required.'),
})

const ProjectCostEntryItemSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Amount is required.')
    .required('Amount is required.'),
  notes: yup.string(),
})

const ProjectCostEntryItemOrignalSchema = yup.object().shape({
  bidId: yup
    .number()
    .typeError('Bid is required.')
    .required('Bid is required.'),
  amount: yup.number().typeError('Amount should be number.'),
  notes: yup.string(),
})

const ProjectCostEntryItemOversageSchema = yup.object().shape({
  entryItems: yup
    .array()
    .of(
      yup.object().shape({
        amount: yup
          .number()
          .typeError('Amount is required.')
          .required('Amount is required.'),
        notes: yup.string(),
      })
    )
    .required(),
  // .compact((v: any) => !v.checked),
})

export {
  ProjectCostEntrySchema,
  ProjectCostEntryItemSchema,
  ProjectCostEntryItemOrignalSchema,
  ProjectCostEntryItemOversageSchema,
}
