import React, { useContext, useState, useMemo, useRef } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'

import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import PagAlertDialog from '@/components/pag-alert'
import PagCircularIndeterminate from '@/components/pag-loading'
import { POEditDialog, POsTable } from '@/routes/projects/components/purchase-orders'
import FundingNotesForm from '@/routes/budgets/components/funding-notes-form'
import { GET_PURCHASE_ORDERS } from '@/shared/graphql/queryGetPurchaseOrders'
import { DELETE_PURCHASE_ORDER } from '@/shared/graphql/mutations/mutationPurchaseOrders'
import { IPurchaseOrder as IPO } from '@/shared/models/purchase-order'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { errorHandler } from '@/shared/error-handler'

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonsWrapper: {
    padding: '16px 0',
  },
}))

const BudgetContainer = (props: any) => {
  const { projectId, editable, isDialog } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant, activeClient } = store
  const mountedRef = useMounted(true)
  const pagination = useRef({
    nbPages: 1,
    offset: 0,
    limit: 20,
    hasMore: true,
  })

  const [POs, setPOs] = useState<IPO[]>([])
  const [editDialogOption, setEditDialogOption] = useState<{
    openPODialog: boolean
    title: string
    po: IPO | null
    status: 'new' | 'edit' | 'revise'
  }>({
    openPODialog: false,
    title: 'Add New Purchase Order',
    po: null,
    status: 'new',
  })
  const [deletingItem, setDeletingItem] = useState<IPO | null>(null)

  const [POsQuery, POsQueryRes] = useLazyQuery(GET_PURCHASE_ORDERS, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return
      const temp = res.purchaseOrders || { data: [], total: 0 }
      if (temp.data.length) {
        pagination.current = {
          nbPages: Math.ceil(temp.total / pagination.current.limit),
          offset: pagination.current.offset + Math.min(pagination.current.limit, temp.data.length),
          limit: pagination.current.limit,
          hasMore: temp.data.length >= pagination.current.limit,
        }
        setPOs([...POs, ...temp.data])
      } else {
        pagination.current = {
          ...pagination.current,
          nbPages: pagination.current.offset === 0 ? 0 : pagination.current.nbPages,
          hasMore: false,
        }
      }
    },
    fetchPolicy: 'network-only',
  })

  const [deletePO, deletePORes] = useMutation(DELETE_PURCHASE_ORDER, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {
      if (mountedRef.current && res.deletePurchaseOrder?.success) {
        pagination.current.offset = 0
        setPOs([])
        loadMore()
      }
    },
  })

  const loadMore = () => {
    if (!mountedRef.current || !activeTenant?.id || !projectId || POsQueryRes.loading) return
    const abortController = new AbortController()
    const callApi = async () => {
      try {
        await callPagApi(
          '',
          getTokenSilently,
          POsQuery,
          {
            tenantId: activeTenant.id,
            projectId: projectId,
            offset: pagination.current.offset,
            limit: pagination.current.limit,
          },
          abortController.signal
        )
      } catch (e) {}
    }

    callApi()
  }

  const handleDelete = (item: IPO | null) => {
    if (!item || !activeTenant?.id) return
    const abortController = new AbortController()

    try {
      const callApi = async (data: { tenantId: number; id: number }) => {
        try {
          await callPagApi('', getTokenSilently, deletePO, data, abortController.signal)
        } catch (e) {}
      }
      setEditDialogOption({
        openPODialog: false,
        title: `Add New ${activeClient?.purchaseOrderName || 'Purchase Order'}`,
        po: null,
        status: 'new',
      })
      callApi({ tenantId: activeTenant?.id, id: item.id as number })
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const handleEdit = (item: IPO) => {
    setEditDialogOption({
      openPODialog: true,
      title: `Edit ${activeClient?.purchaseOrderName || 'Purchase Order'}`,
      po: item,
      status: 'edit',
    })
  }

  const handleRevise = (item: IPO) => {
    setEditDialogOption({
      openPODialog: true,
      title: `Revise ${activeClient?.purchaseOrderName || 'Purchase Order'}`,
      po: item,
      status: 'revise',
    })
  }

  const renderPOsTable = useMemo(() => {
    return (
      <POsTable
        hits={POs}
        searching={POsQueryRes.loading}
        nbPages={pagination.current.nbPages}
        editable={editable}
        hasMore={pagination.current.hasMore}
        refineNext={loadMore}
        handleDelete={(e: IPO) => setDeletingItem(e)}
        handleEdit={handleEdit}
        handleRevise={handleRevise}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [POs, POsQueryRes.loading, editable])

  return (
    <div className={classes.root}>
      {renderPOsTable}
      {editable && (
        <>
          <div className={classes.buttonsWrapper}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={() => {
                setEditDialogOption({
                  openPODialog: true,
                  title: `Add New ${activeClient?.purchaseOrderName || 'Purchase Order'}`,
                  po: null,
                  status: 'new',
                })
              }}
            >
              Add New
            </Button>
          </div>
          {!isDialog && (
            <div>
              <br />
              <FundingNotesForm projectId={projectId} />
            </div>
          )}
        </>
      )}
      <POEditDialog
        open={editDialogOption.openPODialog}
        po={editDialogOption.po}
        title={editDialogOption.title}
        status={editDialogOption.status}
        projectId={projectId}
        handleDelete={(e: IPO) => {
          setDeletingItem(e)
        }}
        handleClose={(e) => {
          setEditDialogOption((prevValue) => ({
            ...prevValue,
            openPODialog: false,
          }))

          if (e) {
            pagination.current.offset = 0
            setPOs([])
            loadMore()
            // if (e.isNew) {
            //   setPOs([e.data as PO, ...POs])
            //   pagination.current.offset += 1
            // } else if (e.isUpdate) {
            //   const poId = (e.data as PO).id
            //   const tempIndex = POs.findIndex(d => d.id === poId)
            //   if (tempIndex >= 0) {
            //     setPOs([...POs.slice(0, tempIndex), e.data as PO, ...POs.slice(tempIndex + 1)])
            //   }
            // }
          }
        }}
      />
      <PagAlertDialog
        title={`Delete a ${activeClient?.purchaseOrderName || 'Purchase Order'}`}
        content={`Are you sure to delete the ${
          activeClient?.purchaseOrderName || 'Purchase Order'
        }?`}
        open={Boolean(deletingItem)}
        handleClose={(e: boolean) => {
          if (e) {
            handleDelete(deletingItem)
          }
          setDeletingItem(null)
        }}
      />
      {(POsQueryRes.loading || deletePORes.loading) && <PagCircularIndeterminate size={24} />}
    </div>
  )
}

export default BudgetContainer
