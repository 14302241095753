import React, { useContext } from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'
import { useLazyQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import * as FileSaver from 'file-saver'
import { makeStyles } from '@material-ui/core/styles'

import Switch from '@material-ui/core/Switch'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'

import PagButton from '@/components/pag-form/pag-button'
import { useAuth0 } from '@/auth/index'
import useMounted from '@/components/hooks/use-mounted'
import { errorHandler } from '@/shared/error-handler'
import { GET_FILE } from '@/shared/graphql/queryGetFile'
import { CURRENCIES } from '@/shared/constants/currency'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    marginLeft: 'auto',
    maxWidth: 400,
  },
  projectOutline: {
    display: 'flex',
  },
  projectOutline1: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
    minWidth: 180,
    minHeight: 45,
  },
  projectOutline2: {
    flex: 1,
    minHeight: 45,
  },
  bidTotal: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#437434',
    height: '100%',
    width: '100%',
    padding: '0 8px',
  },
  bidPdf: {
    fontSize: '22px',
    marginLeft: 'auto',
    marginRight: '10px',
  },
}))

const BidsBreadCrumb = (props: any) => {
  const classes = useStyles()
  const { store, dispatch } = useContext(Context)
  const { activeBid, bidsViewMode, bidDetailsViewMode, selectedBids } = store
  const { getTokenSilently } = useAuth0()
  const mountedRef = useMounted(true)

  // Get BidItem Category Groups in Bids
  const [getFileData, getFileDataRes] = useLazyQuery(GET_FILE, {
    onError: (err) => {
      errorHandler(err)
      if (!mountedRef.current) return
    },
    onCompleted: (res) => {
      if (!mountedRef.current) return

      const fileData = _get(res, ['getFile', 'data'])

      if (fileData) {
        const fileName = _get(activeBid, ['bidNumber'])
        FileSaver.saveAs(fileData, `${fileName}.pdf`)
      }
    },
    fetchPolicy: 'network-only',
  })

  const onDownloadPDF = async () => {
    let abortController: any

    try {
      abortController = new AbortController()
      const path = _get(activeBid, ['pdf'])
      await callPagApi('', getTokenSilently, getFileData, { path }, abortController.signal)
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const handleChangeBidsViewMode = () => {
    dispatch({
      type: 'ChangeBidsViewMode',
      bidsViewMode: bidsViewMode === 'All' ? 'Compare' : 'All',
    })
  }

  const handleCompare = () => {
    dispatch({ type: 'ChangeBidsViewMode', bidsViewMode: 'Compared' })
  }

  const handleChangeBidDetailsViewMode = () => {
    dispatch({
      type: 'ChangeBidDetailsViewMode',
      bidDetailsViewMode: bidDetailsViewMode === 'Expand' ? 'Collapse' : 'Expand',
    })
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.projectOutline2, classes.projectOutline)}
        style={{ height: '100%' }}
      >
        {Boolean(activeBid) ? (
          <>
            {/* <div className={clsx(classes.projectOutline2, 'pag-flex')} style={{justifyContent: 'flex-start'}}>
                  {_get(activeBid, ['supplierName'])}
                </div> */}
            <div className={clsx(classes.projectOutline1)} style={{ height: '100%' }}>
              <div className={clsx('pag-flex')} style={{ height: '100%', width: '100%' }}>
                <div className={clsx(classes.bidTotal)}>
                  <span>Bid Total</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b style={{ fontSize: '16px' }}>
                    <NumberFormat
                      displayType={'text'}
                      value={_get(activeBid, ['bidTotalCost']) || 0}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={CURRENCIES[_get(activeBid, ['currency', 'currency'])] || '$'}
                    />
                  </b>
                  {_get(activeBid, ['pdf']) && (!getFileDataRes || !getFileDataRes.loading) && (
                    <Icon
                      className={classes.bidPdf}
                      style={{ cursor: 'pointer' }}
                      onClick={onDownloadPDF}
                    >
                      picture_as_pdf
                    </Icon>
                  )}
                  {getFileDataRes && getFileDataRes.loading ? (
                    <CircularProgress
                      className={classes.bidPdf}
                      style={{
                        width: '20px',
                        height: '20px',
                        color: 'white',
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={clsx(classes.projectOutline1, 'pag-flex')}>
            <PagButton
              {...{
                buttonProps: {
                  variant: bidsViewMode === 'All' ? 'text' : 'contained',
                  color: 'primary',
                  onClick: handleCompare,
                  disabled: bidsViewMode !== 'Compare' || (selectedBids && selectedBids.length < 2),
                },
                alcRender: bidsViewMode === 'All' ? 'Compare Multiple Bids' : 'Compare',
              }}
              style={{ minWidth: 124, margin: 0 }}
            ></PagButton>
            <Switch
              checked={bidsViewMode !== 'All'}
              onChange={handleChangeBidsViewMode}
              color="primary"
              inputProps={{ 'aria-label': 'Bids View Mode' }}
            />
          </div>
        )}

        {bidsViewMode === 'Compared' ? (
          <div className="pag-flex" style={{ marginRight: 0 }}>
            <PagButton
              {...{
                buttonProps: {
                  variant: 'text',
                  color: 'primary',
                  onClick: handleChangeBidDetailsViewMode,
                },
                alcRender:
                  bidDetailsViewMode === 'Expand'
                    ? 'Collapse All Bid Details'
                    : 'Expand All Bid Details',
              }}
              style={{ minWidth: 160, margin: 0 }}
            ></PagButton>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BidsBreadCrumb
