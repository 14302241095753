import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import PagTextInput from '@/components/pag-form/pag-text-input'
import NestFormField from './nest-form-field'
import OptionalFields from './optional-fields'
import { IUploadProjectWithFiles } from '@/shared/models/upload-project-with-files'
import { IProject } from '@/shared/models/project'

type Field = {
  field: string
  label: string
  value: string
  options?: { id: number; name: string }[]
  show?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
    maxWidth: 450,
  },
  buttonWrapper: {
    marginTop: 32,
  },
  button: {
    height: 32,
    minWidth: 120,
  },
}))

const DefaultFormData: IUploadProjectWithFiles = {
  projectNumber: '',
  comments: '',
}

const disallowedCharacters = /[\/\\":*?<>|+]/;

const FormStep2Schema = yup.object().shape({
  projectNumber: yup
    .string()
    .test(
      'disallowed-characters',
      'Project Number contains disallowed characters.',
      (value) => !disallowedCharacters.test(value || '')
    )
    .required('Project Number is required.'),
  projectName: yup.string().required('Project Name is required.'),
  brandId: yup
    .number()
    .min(1, 'Brand is required.')
    .required('Brand is required.')
    .typeError('Brand is required.'),
  agencyId: yup
    .number()
    .min(1, 'Agency is required.')
    .required('Agency is required.')
    .typeError('Brand is required.'),
})

const UploadPortalNewProjectForm = (props: any) => {
  const { client, projectNumber, handleChangeProjectNumber, handleNext, handleBack } = props
  const classes = useStyles()
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DefaultFormData,
    resolver: yupResolver(FormStep2Schema),
  })
  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
    handleSubmit,
  } = hookFormMethods

  const [fields, setFields] = useState<Field[]>([])
  const [showOptionalFields, setShowOptionalFields] = useState(false)

  useEffect(() => {
    setFields(
      (client?.uploadPortalFields?.required || []).filter((f: Field) => f.field !== 'projectNumber')
    )
  }, [client])

  useEffect(() => {
    const temp = getValues('projectNumber')
    if (typeof temp !== 'undefined') {
      setValue('projectNumber', projectNumber || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNumber])

  const handleFormSubmit = async () => {
    const isValid = await trigger()
    if (!isValid) return
    const formValues = getValues()
    const tempBrandOptions = fields.find((f) => f.field === 'brandId')
    const tempBrand = tempBrandOptions?.options?.find((b) => b.id === formValues.brandId)
    const tempAgencyOptions = fields.find((f) => f.field === 'agencyId')
    const tempAgency = tempAgencyOptions?.options?.find((a) => a.id === formValues.agencyId)

    const data = {
      ...formValues,
      client: {
        id: client?.id,
        name: client?.name,
      },
      brand: tempBrand,
      agency: tempAgency,
    }

    if (data.tagIds?.length) {
      data.tagIds = data.tagIds.map((item) => item.id || item)
    }

    handleNext(data as IProject)
  }

  return (
    <form
      className={classes.root}
      name="uploadPortalForm"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="h5" component="h6" gutterBottom style={{ fontSize: '1.8rem' }}>
            We couldn't find an existing Project, Create a new Project to continue.
          </Typography>
        </ListItem>
        <ListItem disableGutters>
          <Controller
            render={({ field, fieldState, formState }) => (
              <PagTextInput
                label="Project Number"
                placeholder="Project Number"
                size="small"
                variant="filled"
                inputRef={field.ref}
                error={fieldState.error}
                value={field.value}
                onChange={(e: any) => {
                  handleChangeProjectNumber(e.target.value || '')
                }}
                onBlur={field.onBlur}
              />
            )}
            control={control}
            name="projectNumber"
            defaultValue={projectNumber}
          />
        </ListItem>
        {fields.map((f: Field, index: number) => (
          <ListItem disableGutters key={`upload-portal-required-field-${index}`}>
            <NestFormField
              tenantId={client?.tenantId}
              clientId={client?.id}
              control={control}
              formField={f}
            />
          </ListItem>
        ))}

        <ListItem disableGutters style={{ justifyContent: 'flex-end' }}>
          <span className="pag-blue" onClick={() => setShowOptionalFields(!showOptionalFields)}>
            {showOptionalFields ? 'Hide Optional Fields' : 'Optional Fields'}
          </span>
        </ListItem>
        {showOptionalFields && (
          <ListItem disableGutters>
            <OptionalFields client={client} control={control} />
          </ListItem>
        )}
        <ListItem disableGutters className={classes.buttonWrapper}>
          <Button
            color="primary"
            size="medium"
            aria-label="back"
            variant="contained"
            className={classes.button}
            onClick={() => handleBack()}
          >
            BACK
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            size="medium"
            aria-label="next"
            variant="contained"
            type="submit"
            className={classes.button}
            disabled={!isValid}
          >
            Next
          </Button>
        </ListItem>
      </List>
    </form>
  )
}

export default UploadPortalNewProjectForm
