import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import useMounted from '@/components/hooks/use-mounted'
import PagTableHead, { HeadCell } from '@/components/pag-table/pag-table-head'
import BidItemsByCategory from './bid-items-by-category'
import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { errorHandler } from '@/shared/error-handler'
import { callPagApi } from '@/shared/services/pag-api'
import { SORT_SERVICE_CATEGORY } from '@/shared/graphql/mutations/mutationServiceItems'
import { reorderArray } from '@/utils/array-utils'

const headCells = [
  // {
  //   id: 'serviceItem.rateId',
  //   align: 'left',
  //   disablePadding: true,
  //   label: 'Category',
  //   sortable: false,
  //   isLink: false,
  //   width: '6%',
  //   minWidth: '6%',
  // },
  {
    id: 'originalService',
    align: 'left',
    disablePadding: true,
    label: 'Description',
    sortable: true,
    isLink: false,
  },
  {
    id: 'serviceUnit',
    align: 'left',
    disablePadding: true,
    label: 'Unit',
    sortable: true,
    isLink: false,
    width: '5%',
    minWidth: '5%',
  },
  {
    id: 'serviceQty',
    align: 'right',
    disablePadding: true,
    label: 'Qty',
    sortable: true,
    isLink: false,
    width: '5%',
    minWidth: '5%',
  },
  {
    id: 'serviceRate',
    align: 'right',
    disablePadding: true,
    label: 'Rate',
    sortable: true,
    isLink: false,
    width: '10%',
    minWidth: '10%',
    type: 'currency',
  },
  {
    id: 'serviceCost',
    align: 'right',
    disablePadding: true,
    label: 'Bid Cost',
    sortable: true,
    isLink: false,
    width: '12%',
    minWidth: '12%',
    type: 'currency',
  },
  // { id: 'Edit', align: 'center', disablePadding: true, label: 'Edit', sortable: false, isLink: false, width: '8%' },
  {
    id: 'Collapse',
    align: 'right',
    disablePadding: true,
    label: 'All Bid Details',
    sortable: false,
    isLink: true,
    width: '140px',
    minWidth: '140px',
  },
]

const RenderHeadCellValue = (
  headCell: HeadCell,
  orderBy: any,
  allExpanded: boolean,
  onLinkClick: any
) => {
  if (headCell.sortable)
    return (
      <TableSortLabel
        // active={orderBy === headCell.id}
        hideSortIcon={true}
      >
        {headCell.label}
      </TableSortLabel>
    )
  if (headCell.isLink)
    return (
      <span
        className={clsx('pag-link', 'pag-blue')}
        onClick={onLinkClick}
        style={{ justifyContent: 'flex-end' }}
      >
        {`${allExpanded ? 'Collapse' : 'Expand'} ${headCell.label}`}
      </span>
    )
  return headCell.label
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 450,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  th: {
    borderBottomWidth: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 6,
  },
  td: {
    fontSize: '1rem',
    padding: 6,
    verticalAlign: 'bottom',
    maxWidth: 0,
  },
  categoryTR: {
    backgroundColor: '#225099',
  },
  categoryTD: {
    fontSize: '1rem',
    padding: 6,
    color: 'white',
  },
  iconArrow: {},
  iconWrite: {
    padding: 3,
  },
  originalService: {
    color: 'gray',
    fontStyle: 'italic',
  },
  dragIconButton: {
    cursor: 'grab',
    marginLeft: 6,
  },
}))

const BidDetailsView = (props: any) => {
  const { activeBid, bidItemCategoryGroups, handleChangeBidItemCategoryGroups } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store

  const [allExpanded, setAllExpanded] = useState(true)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')

  const [sortServiceCategory, sortServiceCategoryRes] = useMutation(SORT_SERVICE_CATEGORY, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination || !activeTenant?.id) {
      return
    }

    let abortController

    try {
      const sourceCategory = bidItemCategoryGroups[result.source.index]
      const destinationCategory = bidItemCategoryGroups[result.destination.index]

      const temp: any = reorderArray(
        bidItemCategoryGroups,
        result.source.index,
        result.destination.index
      )

      handleChangeBidItemCategoryGroups(temp)

      abortController = new AbortController()
      const { signal } = abortController
      callPagApi(
        '',
        getTokenSilently,
        sortServiceCategory,
        {
          tenantId: activeTenant.id,
          sourceServiceCategory: sourceCategory.serviceCategory,
          destinationServiceCategory: destinationCategory.serviceCategory,
        },
        signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="Bid Details View"
          size={'medium'}
          aria-label="Bid Details View"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell: HeadCell, i) => (
                <TableCell
                  key={headCell.id}
                  className={classes.th}
                  align={headCell.align as any}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  style={{
                    width: headCell.width || undefined,
                    minWidth: headCell.minWidth || undefined,
                  }}
                >
                  {RenderHeadCellValue(headCell, orderBy, allExpanded, () =>
                    setAllExpanded(!allExpanded)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {bidItemCategoryGroups && bidItemCategoryGroups.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <TableBody innerRef={provided.innerRef} {...provided.droppableProps}>
                    {bidItemCategoryGroups.map((category: any, i: number) => (
                      <Draggable key={`category-${i}`} draggableId={`category-${i}`} index={i}>
                        {(provided, snapshot) => (
                          <BidItemsByCategory
                            key={`bid-category-${i}`}
                            activeBid={activeBid}
                            allExpanded={allExpanded}
                            category={category}
                            classes={classes}
                            headCells={headCells}
                            provided={provided}
                            onEditBidItem={() => {}}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </Table>
      </div>
    </div>
  )
}

export default BidDetailsView
