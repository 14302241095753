import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import BudgetsContainer from '@/routes/projects/containers/budget-container'
import { IProject } from '@/shared/models/project'

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    borderBottom: '1px solid',
    padding: 12,
    paddingBottom: 4,
  },
  dialogContent: {
    padding: '6px 12px',
  },
  closeButton: {
    fontSize: 18,
    position: 'absolute',
    right: 2,
    top: 2,
    color: 'black',
  },
  projectName: {
    marginBottom: 8,
    '& .MuiTypography-subtitle1': {
      fontSize: 14,
    },
  },
  POsWrapper: {
    overflow: 'auto',
  },
}))

const POsDialog: React.FC<{
  open: boolean
  project: IProject | null
  handleClose: () => void
}> = ({ open, project, handleClose }) => {
  const classes = useStyles()

  const renderPOs = useMemo(() => {
    if (!project) return null
    return <BudgetsContainer projectId={project.id} editable={true} isDialog={true} />
  }, [project])

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="purchase-orders-dialog-title"
    >
      <DialogTitle id="purchase-orders-dialog-title" className={classes.dialogTitle}>
        Budgets
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose()}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.projectName}>
          <Typography variant="subtitle1">
            <b>{project?.projectName}</b>
          </Typography>
          <Typography variant="body2">
            {project?.client?.name} &gt; {project?.brand?.name}
          </Typography>
        </div>
        <div className={classes.POsWrapper}>{renderPOs}</div>
      </DialogContent>
    </Dialog>
  )
}

export default POsDialog
