import gql from 'graphql-tag'

const GET_AGENCIES = gql`
  query agencies($tenantId: Int!, $agencyName: String, $offset: Int, $limit: Int) {
    agencies(tenantId: $tenantId, agencyName: $agencyName, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_AGENCIES }
