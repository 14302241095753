import React, { useContext, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import _capitalize from 'lodash/capitalize'
import _find from 'lodash/find'
import _toLower from 'lodash/toLower'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Context } from '@/shared/store/reducers/global-reducer'
import { INTEGRATION_CATEGORIES } from '@/shared/constants/integration'
import BidsBreadCrumb from './bids-breadcrumb-content-component'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 12,
  },
  navContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  minHeight45: {
    minHeight: 45,
  },
}))

const ProjectDashboardBreadCrumb = (props: any) => {
  const { clientId, brandId, projectId, tabName } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const { store } = useContext(Context)
  const { clients, activeClient, activeBrand, activeProject, activeBid } = store

  const minWidth682 = useMediaQuery('(min-width: 682px)')
  const minWidth768 = useMediaQuery('(min-width: 768px)')
  const minWidth886 = useMediaQuery('(min-width: 886px)')
  const minWidth948 = useMediaQuery('(min-width: 948px)')
  const minWidth992 = useMediaQuery('(min-width: 992px)')
  const minWidth1020 = useMediaQuery('(min-width: 1020px)')
  const minWidth1080 = useMediaQuery('(min-width: 1080px)')
  const minWidth1112 = useMediaQuery('(min-width: 1112px)')
  const minWidth1236 = useMediaQuery('(min-width: 1236px)')
  const minWidth1324 = useMediaQuery('(min-width: 1324px)')
  const minWidth1379 = useMediaQuery('(min-width: 1379px)')
  const minWidth1432 = useMediaQuery('(min-width: 1432px)')
  const minWidth1440 = useMediaQuery('(min-width: 1440px)')
  const minWidth1620 = useMediaQuery('(min-width: 1620px)')
  const minWidth1720 = useMediaQuery('(min-width: 1720px)')
  const minWidth1820 = useMediaQuery('(min-width: 1820px)')

  const [anchorIntegrationMenuEl, setAnchorIntegrationMenuEl] = useState(null as any)

  const client = useMemo(
    () => _find(clients, (c: any) => c.id === clientId) || null,
    [clients, clientId]
  )
  const brand = useMemo(
    () => _find(client?.brands || [], (b: any) => b.id === brandId) || null,
    [client, brandId]
  )

  const goBack = () => {
    if (Boolean(activeBrand)) {
      navigate(`/${clientId}/${brandId}/list`)
    } else if (Boolean(activeClient)) {
      navigate(`/${clientId}/list`)
    } else {
      navigate(`/`)
    }
  }

  const handleClickMoreTab = (e: any) => {
    setAnchorIntegrationMenuEl(e.currentTarget)
  }

  const handleClickTab = (tabName: string | null) => {
    setAnchorIntegrationMenuEl(null)
    if (tabName) {
      navigate(`/${clientId}/${brandId}/${projectId}/${_toLower(tabName)}`)
    }
  }

  const renderBreadcrumb = useMemo(() => {
    const tempTabName = _capitalize(tabName)
    const isBidsViewMode = tempTabName === 'Bids'
    const integrationCategories = INTEGRATION_CATEGORIES.filter((i) => !i.hidden)

    return (
      <Grid container spacing={0}>
        <Grid item xs={6} sm={7} md={5}>
          <div className={clsx(classes.navContent)}>
            <div className="pag-link pag-go-back">
              <Icon onClick={goBack}>arrow_back</Icon>
              <span onClick={goBack}>&nbsp;&nbsp;&nbsp;Back</span>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {Boolean(client) && (
              <>
                <span
                  className="pag-link pag-block pag-blue pag-bold pag-text-ellipsis"
                  onClick={() => navigate(`/${client.id}/list`)}
                >
                  {client.name || ''}
                </span>
                {Boolean(brand) && (
                  <>
                    <Icon>chevron_right</Icon>
                    <span
                      className="pag-link pag-block pag-blue pag-bold pag-text-ellipsis"
                      onClick={() => navigate(`/${client.id}/${brand.id}/list`)}
                    >
                      {brand.name || ''}
                    </span>
                  </>
                )}
              </>
            )}
            <Icon>chevron_right</Icon>
            {Boolean(activeBid) || isBidsViewMode ? (
              <span
                className="pag-link pag-block pag-blue pag-bold pag-text-ellipsis"
                onClick={() => {
                  navigate(`/${clientId}/${brandId}/${projectId}/project`)
                }}
              >
                {activeProject?.projectName || ''}
              </span>
            ) : (
              <div className="pag-text-ellipsis">{activeProject?.projectName || ''}</div>
            )}
            {isBidsViewMode && (
              <>
                <Icon>chevron_right</Icon>
                {Boolean(activeBid) ? (
                  <span
                    className="pag-link pag-block pag-blue pag-bold pag-text-ellipsis"
                    onClick={() => {
                      navigate(`/${clientId}/${brandId}/${projectId}/bids`)
                    }}
                  >
                    Bids
                  </span>
                ) : (
                  <div>Bids</div>
                )}
              </>
            )}
            {Boolean(activeBid) && (
              <>
                <Icon>chevron_right</Icon>
                <div className="pag-text-ellipsis">{activeBid.bidNumber || ''}</div>
              </>
            )}
          </div>
        </Grid>

        {Boolean(activeProject) && (
          <Grid item xs={6} sm={5} md={7}>
            <Grid container wrap="nowrap" spacing={0} className={clsx(classes.minHeight45)}>
              {!activeBid && (
                <Grid item xs>
                  {integrationCategories.length < 5 ? (
                    <div className="pag-tab-container">
                      {integrationCategories.map((integrationCategory, i) => {
                        return (
                          <div
                            key={integrationCategory.category}
                            className={clsx('pag-tab', {
                              active: tempTabName === integrationCategory.name,
                            })}
                            onClick={() => handleClickTab(integrationCategory.name)}
                          >
                            <div>{integrationCategory.category}</div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className="pag-tab-container">
                      {integrationCategories.map((integrationCategory, i) => {
                        let isHidden = false

                        if (i === 0) {
                          isHidden = !minWidth768 && isBidsViewMode
                        } else if (i === 1) {
                          isHidden = !minWidth948 && isBidsViewMode
                        } else if (i === 2) {
                          isHidden = !minWidth682 || (!minWidth1020 && isBidsViewMode)
                        } else if (i === 3) {
                          isHidden = !minWidth768 || (!minWidth1112 && isBidsViewMode)
                        } else if (i === 4) {
                          isHidden = !minWidth886 || (!minWidth1236 && isBidsViewMode)
                        } else if (i === 5) {
                          isHidden = !minWidth992 || (!minWidth1324 && isBidsViewMode)
                        } else if (i === 6) {
                          isHidden =
                            (!minWidth1112 && !isBidsViewMode) || (!minWidth1432 && isBidsViewMode)
                        } else if (i === 7) {
                          isHidden =
                            (!minWidth1236 && !isBidsViewMode) || (!minWidth1620 && isBidsViewMode)
                        } else if (i == 8) {
                          isHidden =
                            (!minWidth1379 && !isBidsViewMode) || (!minWidth1720 && isBidsViewMode)
                        } else if (i > 8) {
                          isHidden =
                            (!minWidth1440 && !isBidsViewMode) || (!minWidth1820 && isBidsViewMode)
                        }

                        return (
                          <div
                            key={integrationCategory.category}
                            className={clsx(
                              'pag-tab',
                              { active: tempTabName === integrationCategory.name },
                              { 'pag-hidden': isHidden }
                            )}
                            onClick={() => handleClickTab(integrationCategory.name)}
                          >
                            <div>{integrationCategory.category}</div>
                          </div>
                        )
                      })}

                      <div
                        className={clsx('pag-tab', {
                          'pag-hidden':
                            (minWidth1440 && !isBidsViewMode) || (minWidth1820 && isBidsViewMode),
                        })}
                        aria-controls="integration-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleClickMoreTab(e)}
                      >
                        More...
                      </div>

                      <Menu
                        id="integration-menu"
                        anchorEl={anchorIntegrationMenuEl}
                        keepMounted
                        open={Boolean(anchorIntegrationMenuEl)}
                        onClose={() => handleClickTab(null)}
                      >
                        {integrationCategories.map((integrationCategory, i) => {
                          let isHidden = true

                          if (i === 0) {
                            isHidden = minWidth768 && isBidsViewMode
                          } else if (i === 1) {
                            isHidden = minWidth948 && isBidsViewMode
                          } else if (i === 2) {
                            isHidden =
                              (minWidth682 && !isBidsViewMode) || (minWidth1020 && isBidsViewMode)
                          } else if (i === 3) {
                            isHidden =
                              (minWidth768 && !isBidsViewMode) || (minWidth1112 && isBidsViewMode)
                          } else if (i === 4) {
                            isHidden =
                              (minWidth886 && !isBidsViewMode) || (minWidth1236 && isBidsViewMode)
                          } else if (i === 5) {
                            isHidden =
                              (minWidth992 && !isBidsViewMode) || (minWidth1324 && isBidsViewMode)
                          } else if (i === 6) {
                            isHidden =
                              (minWidth1112 && !isBidsViewMode) || (minWidth1432 && isBidsViewMode)
                          } else if (i === 7) {
                            isHidden =
                              (minWidth1236 && !isBidsViewMode) || (minWidth1620 && isBidsViewMode)
                          } else if (i == 8) {
                            isHidden =
                              (minWidth1379 && !isBidsViewMode) || (minWidth1720 && isBidsViewMode)
                          } else if (i > 8) {
                            isHidden =
                              (minWidth1440 && !isBidsViewMode) || (minWidth1820 && isBidsViewMode)
                          }

                          return (
                            <MenuItem
                              key={integrationCategory.category}
                              className={clsx({ 'pag-hidden': isHidden })}
                              onClick={() => handleClickTab(integrationCategory.category)}
                            >
                              {integrationCategory.category}
                            </MenuItem>
                          )
                        })}
                      </Menu>
                    </div>
                  )}
                </Grid>
              )}

              {isBidsViewMode && (
                <Grid
                  item
                  xs
                  style={{
                    maxWidth: !activeBid ? '328px' : undefined,
                    minWidth: !activeBid ? '328px' : undefined,
                  }}
                >
                  <BidsBreadCrumb />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }, [
    clientId,
    brandId,
    projectId,
    client,
    brand,
    activeProject,
    activeBid,
    tabName,
    anchorIntegrationMenuEl,
    minWidth682,
    minWidth768,
    minWidth768,
    minWidth886,
    minWidth948,
    minWidth992,
    minWidth1020,
    minWidth1080,
    minWidth1112,
    minWidth1236,
    minWidth1324,
    minWidth1379,
    minWidth1432,
    minWidth1440,
    minWidth1620,
    minWidth1720,
    minWidth1820,
  ])

  return <div className={clsx(classes.root, 'pag-bread-crumb')}>{renderBreadcrumb}</div>
}

export default ProjectDashboardBreadCrumb
