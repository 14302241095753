import gql from 'graphql-tag'

const ADD_SAVING_TYPE = gql`
  mutation addSavingType($tenantId: Int!, $name: String!, $clientId: Int!) {
    addSavingType(tenantId: $tenantId, name: $name, clientId: $clientId) {
      id
      name
      clientId
    }
  }
`
const UPDATE_SAVING_TYPE = gql`
  mutation updateSavingType($tenantId: Int!, $id: Int!, $name: String, $clientId: Int) {
    updateSavingType(tenantId: $tenantId, id: $id, name: $name, clientId: $clientId)
  }
`

export { ADD_SAVING_TYPE, UPDATE_SAVING_TYPE }
