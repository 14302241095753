import React, { useContext, useLayoutEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

import { useAuth0 } from '@/auth/index'
import { Context } from '@/shared/store/reducers/global-reducer'
import { hasPermission } from '@/utils/role-permission'

import { USER_ROLES } from '@/shared/constants/roles'
import { MAIN_ROUTES } from '@/shared/constants/routes'

const routes = [
  {
    exact: true,
    path: '/search/:tabName',
    permission: 'read:tenants',
    auth: true,
  },
  {
    exact: true,
    path: '/:clientId/calendar',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/list',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/reports',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/views',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/list',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId',
    auth: true,
    permission: 'read:projects',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId/:tabName',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId/bids/:bidId',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/p/:slug',
    auth: true,
    permission: 'write:UploadDocuments',
  },
  {
    exact: true,
    path: '/p/:slug/new-project',
    auth: true,
    permission: 'write:UploadDocuments',
  },
  {
    exact: true,
    path: '/p/:slug/upload-files',
    auth: true,
    permission: 'write:UploadDocuments',
  },
  {
    exact: true,
    path: '/',
    auth: true,
    permission: 'read:tenants',
  },
]

const useProtected = () => {
  const { loading: auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const currentRoute = routes.find((route) => matchPath(route.path, location.pathname))
  const { store } = useContext(Context)
  const { userPermissions } = store

  useLayoutEffect(() => {
    if (!currentRoute?.auth || isAuthenticated || auth0Loading) return
    loginWithRedirect({
      appState: { targetUrl: location.pathname },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading, currentRoute, isAuthenticated, location])

  const hasPermissionForRoute = hasPermission(userPermissions, currentRoute?.permission || '')

  return {
    auth0Loading,
    hasPermission: hasPermissionForRoute,
    isEnabledRoute: currentRoute?.auth ? isAuthenticated : true,
  }
}

export default useProtected
