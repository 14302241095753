import gql from 'graphql-tag'

const GET_PROJECT_TEAM_ROLES = gql`
  query projectTeamRoles($tenantId: Int!, $offset: Int, $limit: Int) {
    projectTeamRoles(tenantId: $tenantId, offset: $offset, limit: $limit) {
      total
      data {
        id
        role
        clientId
      }
    }
  }
`

export { GET_PROJECT_TEAM_ROLES }
