import React, { useState, useEffect, useContext, useMemo } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'

import useMounted from '@/components/hooks/use-mounted'
import { Context } from '@/shared/store/reducers/global-reducer'
import { ALGOLIA_APPLICATION_ID } from '@/config/algolia.config'

const ProjectsSearchContainer = (props: any) => {
  const {
    clientId,
    brandId,

    filterable,
    filters,
    searchPhrase,
    children,
  } = props

  const { store } = useContext(Context)
  const [refresh, setRefresh] = useState(false)
  const [searchState, setSearchState] = useState({ page: 1 } as any)

  const { algoliaSearchSecuredApiKey } = store

  const searchClient = useMemo(() => {
    return algoliaSearchSecuredApiKey
      ? algoliasearch(ALGOLIA_APPLICATION_ID, algoliaSearchSecuredApiKey)
      : null
  }, [algoliaSearchSecuredApiKey])

  useEffect(() => {
    if (!searchClient) {
      return
    }

    handleForceRefresh()

    return () => {}
  }, [searchClient, clientId, brandId])

  useEffect(() => {
    if (refresh) {
      setRefresh(false)
    }
    return () => {}
  }, [refresh])

  const onSearchStateChange = (nextState: any) => {
    setSearchState(nextState)
  }

  const handleForceRefresh = () => {
    setSearchState({
      page: 1,
    })
    setRefresh(true)
  }

  if (!searchClient) return null

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="Projects"
      refresh={refresh}
      stalledSearchDelay={500}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
    >
      {filterable ? <Configure filters={filters} query={''} /> : <Configure query={searchPhrase} />}
      {children}
    </InstantSearch>
  )
}

export default ProjectsSearchContainer
