import gql from 'graphql-tag'

const GET_BIDS = gql`
  query bids($tenantId: Int!, $projectId: Int, $offset: Int, $limit: Int) {
    bids(tenantId: $tenantId, projectId: $projectId, offset: $offset, limit: $limit) {
      total
      data {
        id
        bidNumber
        bidType
        bidDate
        bidVersion
        bidTotalCost
        pdf
        importKey
        queuedBidId
        vendorId
        projectId
        bidCountryId
        currencyId
        vendor {
          id
          name
          diversity
        }
        bidCountry {
          id
          country
        }
        bidStatus {
          id
          status
        }
        currency {
          id
          currency
        }
      }
    }
  }
`

const GET_BIDS_SHORT = gql`
  query bids($tenantId: Int!, $projectId: Int, $offset: Int, $limit: Int) {
    bids(tenantId: $tenantId, projectId: $projectId, offset: $offset, limit: $limit) {
      total
      data {
        id
        bidNumber
        bidType
        bidDate
        bidTotalCost
        vendorId
        currency {
          id
          currency
        }
      }
    }
  }
`

const GET_BID = gql`
  query bid($tenantId: Int!, $id: Int!) {
    bid(tenantId: $tenantId, id: $id) {
      id
      bidNumber
      bidType
      bidDate
      bidVersion
      bidTotalCost
      pdf
      importKey
      queuedBidId
      vendorId
      projectId
      bidCountryId
      currencyId
      vendor {
        id
        name
        diversity
      }
      bidCountry {
        id
        country
      }
      bidStatus {
        id
        status
      }
      currency {
        id
        currency
      }
    }
  }
`

const GET_BENCHMARK_TOTALS_IN_BIDS = gql`
  query benchmarkTotalsInBids($tenantId: Int!, $bidIds: [Int!]!, $offset: Int, $limit: Int) {
    benchmarkTotalsInBids(tenantId: $tenantId, bidIds: $bidIds, offset: $offset, limit: $limit) {
      total
      data {
        bidId
        benchmarkBidTotal
      }
    }
  }
`

const GET_BID_ITEM_CATEGORY_GROUPS = gql`
  query bidItemCategoryGroups($tenantId: Int!, $bidId: Int, $offset: Int, $limit: Int) {
    bidItemCategoryGroups(tenantId: $tenantId, bidId: $bidId, offset: $offset, limit: $limit) {
      total
      data {
        serviceCategory
        bidTotalCost
        rows
      }
    }
  }
`

const GET_BID_ITEMS_BY_CATEGORY = gql`
  query bidItemsByCategory(
    $tenantId: Int!
    $bidId: Int!
    $serviceCategory: String!
    $offset: Int
    $limit: Int
  ) {
    bidItemsByCategory(
      tenantId: $tenantId
      bidId: $bidId
      serviceCategory: $serviceCategory
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        serviceQty
        serviceRate
        serviceCost
        serviceUnit
        originalService
        sortOrder
        bidId
        serviceItemId
        serviceItem {
          id
          rateId
          serviceCategory
          service
          unit
          serviceGroup
          slug
          benchmarkRate
        }
      }
    }
  }
`

const GET_BID_ITEM_CATEGORY_GROUPS_IN_BIDS = gql`
  query bidItemCategoryGroupsInBids($tenantId: Int!, $bidIds: [Int!]!, $offset: Int, $limit: Int) {
    bidItemCategoryGroupsInBids(
      tenantId: $tenantId
      bidIds: $bidIds
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        serviceGroup
        serviceCategory
        bidId
        bidTotalCost
        rows
        benchmarkServiceGroupTotal
      }
    }
  }
`

const GET_BID_ITEMS_BY_CATEGORY_GROUP = gql`
  query bidItemsByCategoryGroupInBids(
    $tenantId: Int!
    $bidIds: [Int!]!
    $serviceGroup: String!
    $serviceCategory: String!
    $offset: Int
    $limit: Int
  ) {
    bidItemsByCategoryGroupInBids(
      tenantId: $tenantId
      bidIds: $bidIds
      serviceGroup: $serviceGroup
      serviceCategory: $serviceCategory
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        serviceQty
        serviceRate
        serviceCost
        serviceUnit
        originalService
        sortOrder
        bidId
        serviceItemId
        serviceItem {
          id
          rateId
          serviceCategory
          service
          unit
          serviceGroup
          slug
          benchmarkRate
        }
      }
    }
  }
`

export {
  GET_BIDS,
  GET_BIDS_SHORT,
  GET_BID,
  GET_BENCHMARK_TOTALS_IN_BIDS,
  GET_BID_ITEM_CATEGORY_GROUPS,
  GET_BID_ITEMS_BY_CATEGORY,
  GET_BID_ITEM_CATEGORY_GROUPS_IN_BIDS,
  GET_BID_ITEMS_BY_CATEGORY_GROUP,
}
