import gql from 'graphql-tag'

const GET_SAVING_TYPES = gql`
  query savingTypes($tenantId: Int!, $clientId: Int, $name: String, $offset: Int, $limit: Int) {
    savingTypes(
      tenantId: $tenantId
      clientId: $clientId
      name: $name
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_SAVING_TYPES }
