import React, { useContext, useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import useMounted from '@/components/hooks/use-mounted'
import PagTextInput from '@/components/pag-form/pag-text-input'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'
import PagCheckbox from '@/components/pag-form/pag-checkbox'
import PagCircularIndeterminate from '@/components/pag-loading'
import PagDatePicker from '@/components/pag-form/pag-date-picker'
import PagAlertDialog from '@/components/pag-alert'
import { useAuth0 } from '@/auth/index'
import { errorHandler } from '@/shared/error-handler'
import { Context } from '@/shared/store/reducers/global-reducer'
import { callPagApi } from '@/shared/services/pag-api'
import { GET_BIDS_SHORT } from '@/shared/graphql/queryGetBids'
import { GET_SAVING_TYPES } from '@/shared/graphql/queryGetSavingTypes'
import {
  ADD_PROJECT_COST_ENTRY_ITEM,
  UPDATE_PROJECT_COST_ENTRY_ITEM,
  DELETE_PROJECT_COST_ENTRY_ITEMS,
} from '@/shared/graphql/mutations/mutationProjectCostEntryItem'
import { ADD_SAVING_TYPE } from '@/shared/graphql/mutations/mutationSavingTypes'
import { CURRENCIES } from '@/shared/constants/currency'
import { dateToString, dateStringToDate } from '@/utils/date-utils'
import { getWindowDimensions } from '@/utils/window-size'
import { stringToCurrency, stringToNumber } from '@/utils/numeric'
import { IBid } from '@/shared/models/bid'
import { ISavingType } from '@/shared/models/saving-type'
import {
  ProjectCostEntryItemType,
  ProjectCostItemTypesRelatedToBid,
  ProjectCostEntryItemSchema,
} from '../config'

const editorMaxWidth = 800
const editorMinWidth = 280
const DefaultFormValues: any = {
  amount: '',
  notes: '',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    marginTop: 12,
    width: 280,
    left: 0,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    textAlign: 'left',
    overflow: 'visible',
    zIndex: 1002,
  },
  form: {
    display: 'block',
    '& .MuiInputBase-inputMultiline': {
      resize: 'vertical',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardAction: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    background: theme.palette.grey[200],
  },
  button: {
    minWidth: 80,
  },
  deleteButton: {
    marginRight: 16,
  },
  circularProgress: {
    position: 'absolute',
  },
  groupLabelWrapper: {
    paddingBottom: '0!important',
  },
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
}))

const ProjectCostEntryItemEditComponent = (props: any) => {
  const {
    viewPortContainerRef,
    clientId,
    projectId,
    projectCostEntryId,
    customFieldsMetaData,
    projectCostEntryItem,
    projectCostEntryItemType,
    currency,
    sequence,
    handleSubmit: onHandleSubmit,
    handleCancel,
  } = props
  const classes = useStyles(props)

  const { getTokenSilently } = useAuth0()
  const { store } = useContext(Context)
  const { activeTenant } = store
  const mountedRef = useMounted(true)
  const editContainerRef = useRef(null as any)
  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DefaultFormValues,
    resolver: yupResolver(ProjectCostEntryItemSchema),
  })

  const {
    control,
    reset,
    setValue,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
  } = hookFormMethods

  const [bidOptions, setBidOptions] = useState<IBid[]>([])
  const [savingTypeOptions, setSavingTypeOptions] = useState<ISavingType[]>([])
  const [advanced, setAdvanced] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)

  const [getBids, getBidsRes] = useLazyQuery(GET_BIDS_SHORT, {
    onError: (err) => {
      if (!mountedRef.current) return
      setBidOptions([])
      errorHandler(err)
    },
    onCompleted: (res) => {},
    fetchPolicy: 'network-only',
  })

  const [getSavingTypes, getSavingTypesRes] = useLazyQuery(GET_SAVING_TYPES, {
    onError: (err) => {
      if (!mountedRef.current) return
      setSavingTypeOptions([])
      errorHandler(err)
    },
    onCompleted: (res) => {
      setSavingTypeOptions(res.savingTypes?.data || [])
    },
    fetchPolicy: 'network-only',
  })

  const [addSavingType, addSavingTypeRes] = useMutation(ADD_SAVING_TYPE, {
    onError: (err) => {
      errorHandler(err)
    },
    onCompleted: (res) => {},
  })

  const [addProjectCostEntryItem, addProjectCostEntryItemRes] = useMutation(
    ADD_PROJECT_COST_ENTRY_ITEM,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        const temp = _get(res, ['addProjectCostEntryItem'])
        if (temp) {
          onHandleSubmit(temp)
        }
      },
    }
  )

  const [updateProjectCostEntryItem, updateProjectCostEntryItemRes] = useMutation(
    UPDATE_PROJECT_COST_ENTRY_ITEM,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (mountedRef.current && res) {
          const temp = _get(res, ['updateProjectCostEntryItem'])
          if (temp) {
            onHandleSubmit(temp)
          }
        }
      },
    }
  )

  const [deleteProjectCostEntryItems, deleteProjectCostEntryItemsRes] = useMutation(
    DELETE_PROJECT_COST_ENTRY_ITEMS,
    {
      onError: (err) => {
        errorHandler(err)
      },
      onCompleted: (res) => {
        if (!mountedRef.current) return
        const success = _get(res, ['deleteProjectCostEntryItems', 'success'])
        if (success) {
          onHandleSubmit(null)
        } else {
          errorHandler('The overages were not deleted successfully. Please try again later.')
        }
      },
    }
  )

  useEffect(() => {
    const formValues: any = {
      amount: stringToCurrency(`${projectCostEntryItem?.amount}`) || '',
      notes: projectCostEntryItem?.notes || '',
      reportingPeriodStart: dateStringToDate(projectCostEntryItem?.reportingPeriodStart),
    }

    if (ProjectCostItemTypesRelatedToBid.indexOf(projectCostEntryItemType) >= 0) {
      formValues['bidNumber'] = projectCostEntryItem?.bidNumber || null
      formValues['bidDate'] = dateStringToDate(projectCostEntryItem?.bidDate)
      formValues['markupAmount'] = stringToCurrency(`${projectCostEntryItem?.markupAmount}`) || ''
      formValues['markupPercentage'] =
        stringToCurrency(`${projectCostEntryItem?.markupPercentage}`) || ''
    }

    if (projectCostEntryItemType === ProjectCostEntryItemType.SAVINGS) {
      formValues['savingTypeId'] = projectCostEntryItem?.savingTypeId || null
    }

    if (Boolean(customFieldsMetaData)) {
      const temp = projectCostEntryItem?.customFieldsMetaData

      if (typeof temp === 'string') {
        formValues['customFieldsMetaData'] = JSON.parse(temp)
      } else if (typeof temp === 'object') {
        formValues['customFieldsMetaData'] = temp
      }
    }

    reset(formValues)
    setEditorStyle(false)
  }, [])

  useEffect(() => {
    if (!mountedRef.current || !activeTenant?.id) return

    let abortController: any
    /**
     * Get Bids if the project cost entry item type is ORIGINAL
     */

    try {
      abortController = new AbortController()

      if (projectCostEntryItemType === ProjectCostEntryItemType.SAVINGS) {
        callPagApi(
          '',
          getTokenSilently,
          getSavingTypes,
          { tenantId: activeTenant.id, clientId: clientId },
          abortController.signal
        )
      }

      if (projectId && ProjectCostItemTypesRelatedToBid.indexOf(projectCostEntryItemType) >= 0) {
        callPagApi(
          '',
          getTokenSilently,
          getBids,
          { tenantId: activeTenant.id, projectId: projectId },
          abortController.signal
        )
      }
    } catch (err) {
      console.error('Error in Get Bids', err)
      if (abortController) abortController.abort()
    }

    return () => {
      if (abortController) abortController.abort()
    }
  }, [activeTenant, projectId, projectCostEntryItemType])

  useEffect(() => {
    if (!mountedRef.current || !getBidsRes.data) return

    const bids: IBid[] = (getBidsRes.data?.bids?.data || []).map((item: IBid) => {
      const bid = _cloneDeep(item)
      bid['bidDate'] = dateStringToDate(bid.bidDate as unknown as string)
      return bid
    })

    if (projectCostEntryItem && Boolean(projectCostEntryItem.bidNumber)) {
      const option = bids.find((item) => item.bidNumber === projectCostEntryItem.bidNumber)
      if (!option) {
        bids.push({
          bidNumber: projectCostEntryItem.bidNumber,
          bidDate: null,
        })
      }
    }

    setBidOptions(bids)
  }, [projectCostEntryItem, getBidsRes.data])

  useEffect(() => {
    if (!mountedRef.current || !addSavingTypeRes.data?.addSavingType) return
    const temp = addSavingTypeRes.data.addSavingType
    setSavingTypeOptions([...savingTypeOptions, temp])
    setValue('savingTypeId', temp.id, { shouldValidate: true, shouldDirty: true })
  }, [addSavingTypeRes.data])

  const handleAdvanced = async (e: any) => {
    setEditorStyle(!advanced)
    setAdvanced(!advanced)
    await trigger('amount')
  }

  const setEditorStyle = (expanded: boolean) => {
    const windowDimensions = getWindowDimensions()
    const viewPortContainerRect = viewPortContainerRef.current.getBoundingClientRect()
    const editContainerRect = editContainerRef.current.getBoundingClientRect()
    const width = Math.min(expanded ? editorMaxWidth : editorMinWidth, viewPortContainerRect.width)

    if (editContainerRect.x + width > viewPortContainerRect.x + viewPortContainerRect.width) {
      editContainerRef.current.style.left = `${Math.ceil(
        viewPortContainerRect.x + viewPortContainerRect.width - editContainerRect.x - width
      )}px`
    } else {
      editContainerRef.current.style.left = '0'
    }

    // if (editContainerRect.y + editContainerRect.height > windowDimensions.height - viewPortContainerRect.y) {
    //   editContainerRef.current.style.top = `${Math.ceil(windowDimensions.height - editContainerRect.height)}px`;
    // } else {
    //   editContainerRef.current.style.top = '0';
    // }

    editContainerRef.current.style.width = `${width}px`
  }

  const handleAddSavingType = (value: string) => {
    let abortController: any

    try {
      abortController = new AbortController()
      callPagApi(
        '',
        getTokenSilently,
        addSavingType,
        {
          tenantId: activeTenant?.id,
          clientId: clientId,
          name: value,
        },
        abortController.signal
      )
    } catch (err) {
      if (abortController) abortController.abort()
    }
  }

  const onSubmit = (data: any) => {
    let abortController: any

    try {
      abortController = new AbortController()
      let customFieldsData: any = null
      let bidId = null
      let bidNumber = null

      if (Boolean(customFieldsMetaData)) {
        customFieldsData = data.customFieldsMetaData || null
      }

      if (data.bidNumber) {
        const bidOption = bidOptions.find((bo: any) => bo.bidNumber === `${data.bidNumber}`)
        bidId = bidOption?.id || null
        bidNumber = data.bidNumber
      }

      const values: any = {
        tenantId: activeTenant?.id,
        projectCostEntryId: projectCostEntryId,
        type: projectCostEntryItemType,
        reportingPeriodStart: dateToString(data.reportingPeriodStart, true, false),
        reportingPeriodEnd: dateToString(data.reportingPeriodStart, false, true),
        sequence: sequence || 0,
        amount: stringToNumber(data.amount, 'float'),
        notes: data.notes || null,
        savingTypeId: data.savingTypeId || null,
        markupAmount: stringToNumber(data.markupAmount, 'float'),
        markupPercentage: stringToNumber(data.markupPercentage, 'float'),
        bidId: bidId,
        bidDate: dateToString(data.bidDate),
        bidNumber: bidNumber,
        customFieldsMetaData: customFieldsData || null,
      }

      if (projectCostEntryItem?.id) {
        values['id'] = projectCostEntryItem.id
        callPagApi('', getTokenSilently, updateProjectCostEntryItem, values, abortController.signal)
      } else {
        callPagApi('', getTokenSilently, addProjectCostEntryItem, values, abortController.signal)
      }
    } catch (err) {
      console.error('Error in Add Project', err)
      if (abortController) abortController.abort()
    }
  }

  const handleDeleteItems = () => {
    let abortController: any

    try {
      abortController = new AbortController()
      const { signal } = abortController

      callPagApi(
        '',
        getTokenSilently,
        deleteProjectCostEntryItems,
        { tenantId: activeTenant?.id, ids: [projectCostEntryItem.id] },
        signal
      )
    } catch (err) {
      console.error('Error in Deleting Project Cost Entry Item', err)
      if (abortController) abortController.abort()
    }
  }

  const renderForm = useMemo(() => {
    const isRelatedToBid = ProjectCostItemTypesRelatedToBid.indexOf(projectCostEntryItemType) >= 0
    const currencyStr = currency?.currency || ''

    return (
      <Card
        className={clsx(classes.root)}
        elevation={24}
        variant="outlined"
        ref={editContainerRef}
        onMouseEnter={(e) => e.stopPropagation()}
        onMouseLeave={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <form
          name="ProjectCostEntryItemOriginalForm"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent className={classes.cardContent}>
            {getBidsRes.loading && <PagCircularIndeterminate size={24} />}
            <Grid container spacing={2}>
              <Grid item sm={advanced ? 4 : 12} xs={12}>
                <Grid container spacing={2}>
                  {isRelatedToBid && (
                    <Grid item xs={12} className={classes.groupLabelWrapper}>
                      <Typography variant="subtitle1" component="h6">
                        <b>General</b>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      render={({ field, fieldState, formState }) => (
                        <PagTextInput
                          name="amount"
                          label="Amount"
                          placeholder="Amount"
                          size="small"
                          variant="outlined"
                          type="number"
                          value={field.value}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {CURRENCIES[currencyStr] || '$'}
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            step: '0.01',
                          }}
                          // InputProps = {{
                          //   inputComponent: PagNumberFormatInput
                          // }}
                          // inputProps = {{
                          //   allowNegative: true,
                          //   allowEmptyFormatting: true,
                          //   thousandSeparator: true,
                          //   isNumericString: true,
                          //   decimalScale: 2,
                          //   // fixedDecimalScale: true,
                          //   prefix: CURRENCIES[currencyStr] || '$',
                          // }}
                          error={fieldState.error}
                          onChange={field.onChange}
                          onBlur={(e: any) =>
                            field.onChange(stringToCurrency(e.target.value) || null)
                          }
                        />
                      )}
                      control={control}
                      name="amount"
                      defaultValue={projectCostEntryItem?.amount || null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      render={({ field, fieldState }) => (
                        <PagTextInput
                          label="Notes"
                          placeholder="Notes"
                          size="small"
                          variant="outlined"
                          multiline
                          error={fieldState.error}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                      )}
                      control={control}
                      name="notes"
                      defaultValue={projectCostEntryItem?.notes || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      render={({ field, fieldState, formState }) => (
                        <PagDatePicker
                          key={`project-cost-entry-item-reportingPeriodStart`}
                          margin="none"
                          name="reportingPeriodStart"
                          label="Reporting Period"
                          format="MM/yyyy"
                          value={field.value || null}
                          inputVariant="outlined"
                          allowKeyboardControl={true}
                          size="small"
                          fullWidth
                          views={['month', 'year']}
                          onChange={(e: any) => {
                            field.onChange(e)
                          }}
                          onBlur={field.onBlur}
                        />
                      )}
                      control={control}
                      name="reportingPeriodStart"
                      defaultValue={dateStringToDate(projectCostEntryItem?.reportingPeriodStart)}
                    />
                  </Grid>

                  {projectCostEntryItemType === ProjectCostEntryItemType.SAVINGS && (
                    <Grid item xs={12}>
                      <Controller
                        render={({ field, fieldState, formState }) => (
                          <PagAutocomplete
                            {...{
                              name: 'savingTypeId',
                              optionLabelKey: 'name',
                              optionValueKey: 'id',
                              options: savingTypeOptions,
                              error: fieldState.error,
                              value: field.value || null,
                              isCreatable: true,
                              loading: getSavingTypesRes.loading || addSavingTypeRes.loading,
                              AutocompleteProps: {
                                getOptionLabel: (option: ISavingType) => option.name,
                                // getOptionSelected: (option: any, value: any) => option.value === value,
                                size: 'small',
                                disableClearable: false,
                                onChange: (
                                  event: object,
                                  values: ISavingType & { newValue: any },
                                  reason: string
                                ) => {
                                  if (values?.isNew) {
                                    handleAddSavingType(values.newValue)
                                  } else {
                                    field.onChange(values?.id || null)
                                  }
                                },
                                onBlur: field.onBlur,
                              },
                              InputProps: {
                                variant: 'outlined',
                                label: 'Saving Type',
                                placeholder: 'Enter a Saving Type',
                              },
                            }}
                          />
                        )}
                        control={control}
                        name="savingTypeId"
                        defaultValue={projectCostEntryItem?.savingTypeId || null}
                      />
                    </Grid>
                  )}

                  {isRelatedToBid && (
                    <>
                      <Grid item xs={12} className={classes.groupLabelWrapper}>
                        <Typography variant="subtitle1" component="h6">
                          <b>Bid Details</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          render={({ field, fieldState, formState }) => (
                            <PagAutocomplete
                              {...{
                                name: 'bidNumber',
                                optionLabelKey: 'bidNumber',
                                optionValueKey: 'bidNumber',
                                options: bidOptions,
                                error: fieldState.error,
                                value: field.value || null,
                                isCreatable: true,
                                AutocompleteProps: {
                                  getOptionLabel: (option: IBid) => option.bidNumber,
                                  // getOptionSelected: (option: any, value: any) => option.value === value,
                                  size: 'small',
                                  disableClearable: false,
                                  onChange: (
                                    event: object,
                                    values: IBid & { newValue: any },
                                    reason: string
                                  ) => {
                                    if (values?.isNew) {
                                      setBidOptions([
                                        ...bidOptions,
                                        {
                                          bidNumber: values.newValue,
                                          bidDate: null,
                                        },
                                      ])
                                    }

                                    field.onChange(values?.bidNumber || null)
                                    setValue('bidDate', values?.bidDate || null)
                                  },
                                  onBlur: field.onBlur,
                                },
                                InputProps: {
                                  variant: 'outlined',
                                  label: 'BID',
                                  placeholder: 'BID',
                                },
                              }}
                            />
                          )}
                          control={control}
                          name="bidNumber"
                          defaultValue={projectCostEntryItem?.bidNumber || null}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          render={({ field, fieldState, formState }) => (
                            <PagDatePicker
                              key={`project-cost-entry-item-bidDate`}
                              margin="none"
                              name="bidDate"
                              label="Bid Date"
                              format="MM/dd/yyyy"
                              value={field.value || null}
                              inputVariant="outlined"
                              allowKeyboardControl={true}
                              size="small"
                              fullWidth
                              onChange={(e: any) => {
                                field.onChange(e)
                              }}
                              onBlur={field.onBlur}
                            />
                          )}
                          control={control}
                          name="bidDate"
                          defaultValue={dateStringToDate(projectCostEntryItem?.bidDate)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState }) => (
                                <PagTextInput
                                  label="Markup Amount"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name={`markupAmount`}
                              defaultValue={projectCostEntryItem?.markupAmount || ''}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Markup %"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">%</InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  value={field.value}
                                  error={fieldState.error}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="markupPercentage"
                              defaultValue={projectCostEntryItem?.markupPercentage || ''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              {advanced && (
                <>
                  <Grid item sm={4} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.groupLabelWrapper}>
                        <Typography variant="subtitle1" component="h6">
                          <b>Production</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field, fieldState }) => (
                            <PagTextInput
                              label="Directors Name"
                              placeholder="Directors Name"
                              size="small"
                              variant="outlined"
                              error={fieldState.error}
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                            />
                          )}
                          control={control}
                          name={`customFieldsMetaData.production.directorsName`}
                          defaultValue={
                            _get(
                              projectCostEntryItem,
                              'customFieldsMetaData.production.directorsName'
                            ) || ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Day Rate"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.production.dayRate"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'production',
                                  'dayRate',
                                ]) || ''
                              }
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="A-K Total"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.production.akToal"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'production',
                                  'akToal',
                                ]) || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Rush Charges"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.production.rushCharges"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'production',
                                  'rushCharges',
                                ]) || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.groupLabelWrapper}>
                        <Typography variant="subtitle1" component="h6">
                          <b>Talent</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Premium Charges"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.talent.premiumCharges"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'talent',
                                  'premiumCharges',
                                ]) || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.groupLabelWrapper}>
                        <Typography variant="subtitle1" component="h6">
                          <b>Post Production</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field, fieldState }) => (
                            <PagTextInput
                              label="Editors Name"
                              placeholder="Editors Name"
                              size="small"
                              variant="outlined"
                              error={fieldState.error}
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                            />
                          )}
                          control={control}
                          name={`customFieldsMetaData.postProduction.editorsName`}
                          defaultValue={
                            _get(projectCostEntryItem, [
                              'customFieldsMetaData',
                              'postProduction',
                              'editorsName',
                            ]) || ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Day Rate"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.postProduction.dayRate"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'postProduction',
                                  'dayRate',
                                ]) || ''
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controller
                              render={({ field, fieldState }) => (
                                <PagCheckbox
                                  label="Traveling"
                                  labelPlacement="end"
                                  size="small"
                                  checked={field.value}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                />
                              )}
                              control={control}
                              name={`customFieldsMetaData.postProduction.traveling`}
                              defaultValue={
                                _get(
                                  projectCostEntryItem,
                                  'customFieldsMetaData.postProduction.traveling'
                                ) || false
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Controller
                              render={({ field, fieldState, formState }) => (
                                <PagTextInput
                                  label="Rush Charges"
                                  placeholder="0.00"
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {CURRENCIES[currencyStr] || '$'}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{
                                    step: '0.01',
                                  }}
                                  error={fieldState.error}
                                  value={field.value}
                                  onChange={field.onChange}
                                  onBlur={(e: any) =>
                                    field.onChange(stringToCurrency(e.target.value) || '')
                                  }
                                />
                              )}
                              control={control}
                              name="customFieldsMetaData.postProduction.rushCharges"
                              defaultValue={
                                _get(projectCostEntryItem, [
                                  'customFieldsMetaData',
                                  'postProduction',
                                  'rushCharges',
                                ]) || ''
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <Grid container justifyContent="flex-end" spacing={0}>
              {isRelatedToBid && Boolean(customFieldsMetaData) && (
                <Grid item xs={6} style={{ textAlign: !advanced ? 'left' : 'right' }}>
                  <Button
                    size="medium"
                    aria-label="advanced"
                    color="primary"
                    disabled={
                      addProjectCostEntryItemRes.loading || updateProjectCostEntryItemRes.loading
                    }
                    onClick={(e) => handleAdvanced(e)}
                  >
                    {!advanced ? 'Advanced' : 'Hide Advanced'}
                  </Button>
                </Grid>
              )}
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {Boolean(projectCostEntryItem?.id) && (
                  <IconButton
                    className={classes.deleteButton}
                    color="inherit"
                    disableFocusRipple
                    size="small"
                    disabled={
                      addProjectCostEntryItemRes.loading ||
                      updateProjectCostEntryItemRes.loading ||
                      deleteProjectCostEntryItemsRes.loading
                    }
                    onClick={() => setOpenDeleteConfirm(true)}
                  >
                    <Icon style={{ fontSize: 18 }}>delete</Icon>
                  </IconButton>
                )}
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  aria-label="update"
                  variant="contained"
                  className={classes.button}
                  disabled={
                    !isValid ||
                    // bidDate == 'Invalid Date" ||
                    addProjectCostEntryItemRes.loading ||
                    updateProjectCostEntryItemRes.loading ||
                    deleteProjectCostEntryItemsRes.loading
                  }
                >
                  Update
                  {(addProjectCostEntryItemRes.loading ||
                    updateProjectCostEntryItemRes.loading) && (
                    <CircularProgress
                      size={16}
                      color="primary"
                      className={classes.circularProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </form>
        {openDeleteConfirm && (
          <PagAlertDialog
            title="Delete Project Cost Item"
            content="Are you sure to delete the Project Cost Item?"
            open={true}
            handleClose={(e: boolean) => {
              setOpenDeleteConfirm(false)
              if (e) {
                handleDeleteItems()
              }
            }}
          />
        )}
      </Card>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addProjectCostEntryItemRes.loading,
    updateProjectCostEntryItemRes.loading,
    deleteProjectCostEntryItemsRes.loading,
    getBidsRes.loading,
    getSavingTypesRes.loading,
    addSavingTypeRes.loading,
    isValid,
    errors,
    advanced,
    openDeleteConfirm,
    bidOptions,
    savingTypeOptions,
  ])

  return renderForm
}

ProjectCostEntryItemEditComponent.propTypes = {
  viewPortContainerRef: PropTypes.any,
  clientId: PropTypes.number,
  projectId: PropTypes.number,
  projectCostEntryId: PropTypes.number,
  customFieldsMetaData: PropTypes.object,
  projectCostEntryItem: PropTypes.object,
  projectCostEntryItemType: PropTypes.string.isRequired,
  currency: PropTypes.object,
  sequence: PropTypes.number,

  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

ProjectCostEntryItemEditComponent.defaultProps = {
  projectCostEntryItem: null,
  projectCostEntryItemType: '',
  sequence: 0,
  handleSubmit: (e: any) => {},
  handleCancel: () => {},
}

export default ProjectCostEntryItemEditComponent
