import gql from 'graphql-tag'

const GET_SPOT_TYPES = gql`
  query spotTypes($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    spotTypes(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_SPOT_TYPES }
