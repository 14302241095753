import React from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TitleLogo from '@/assets/imgs/prodigy-logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    fontSize: 16,
    height: '100%',
    maxWidth: 1024,
    margin: 'auto',
  },
  content: {
    height: '100%',
  },
  titleLogo: {
    display: 'flex',
    '& img': {
      width: 110,
    },
    [theme.breakpoints.up('md')]: {
      // marginLeft: theme.spacing(2),
      '& img': {
        width: 120,
      },
    },
  },
  textContent: {
    maxWidth: 292,
  },
  textBlod: {
    fontWeight: 500,
  },
  img: {
    maxWidth: '100%',
  },
}))

const NotPermission: React.FC<{ visibleLogo: boolean }> = ({ visibleLogo }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid className={classes.content} container>
        {visibleLogo && (
          <Grid item xs={12}>
            <div className={classes.titleLogo}>
              <img
                src={TitleLogo}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/`)}
                alt="logo"
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div className={classes.textContent}>
                <Typography
                  className={clsx(classes.textBlod, 'pag-blue')}
                  variant="h1"
                  gutterBottom
                >
                  Oops!
                </Typography>
                <Typography variant="h4">This page seems to be unavailable.</Typography>
                <br />
                <Typography variant="body2" gutterBottom style={{ fontSize: 14, fontWeight: 500 }}>
                  Please try again or contact your Prodigy support team. (#404)
                </Typography>
                <div>
                  Visit&nbsp;
                  <a href="https://www.pagprodigy.com" target="_blank">
                    www.pagprodigy.com
                  </a>
                  &nbsp;to learn more about Prodigy.
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx('pag-flex', 'pag-content')}>
                <img className={classes.img} src="/assets/imgs/not-found.png" alt="not found" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotPermission
