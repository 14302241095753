import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    maxWidth: 1024,
    margin: 'auto',
  },
  content: {
    height: '100%',
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
}))

const UsageTerms = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid className={classes.content} container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" gutterBottom>
            <b>PAG ProdigyTerms of Use</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Please read these terms of use(&quot;terms of use&quot;, &quot;agreement&quot;)
            carefully before using pag.prodigy.comwebsite (&quot;website&quot;,&quot;service&quot;)
            operated by PostAds Group, Inc.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Conditions of use
          </Typography>
          <Typography variant="body1">
            By using this website, you certify that you have read and reviewed this Agreement and
            that you agree to comply with its terms. If you do not want to be bound by the terms of
            this Agreement, you are advised to leave the website accordingly. PostAds Group, Inc.
            only grants use and access of this website, its products, and its services to those who
            have accepted its terms.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Intellectual property
          </Typography>
          <Typography variant="body1">
            You agree that the software, reports &amp; workflow provided on this website are the
            property of PostAds Group, Inc.,including all copyrights, trade secrets, trademarks,
            patents, and other intellectual property. You also agree that you will not reproduce or
            redistribute the PostAds Group’sintellectual propertyin any way, including electronic,
            digital, or new trademark registrations.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Applicable law
          </Typography>
          <Typography variant="body1">
            By visiting this website, you agree that the laws of the state of New Yorkwithout regard
            to principles of conflict laws, will govern these terms and conditions, or any dispute
            of any sort that might come between PostAds Group Inc,and you, or its business partners
            and associates.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Indemnification
          </Typography>
          <Typography variant="body1">
            You agree to indemnify PostAds Group and its affiliates harmless against legal claims
            and demands that may arise from your use or misuse of our services. We reserve the right
            to select our own legal counsel.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Limitation on liability
          </Typography>
          <Typography variant="body1" gutterBottom>
            PostAds Group is not liable for any damages that may occur to you as a result of your
            misuse of our website.
          </Typography>
          <Typography variant="body1">
            PostAds Group reserves the right to edit, modify, and change this Agreement any time. We
            shall let our users know of these changes through electronic mail. This Agreement is an
            understanding between PostAds Groupand the user, and this supersedes and replaces all
            prior agreements regarding the use of this website.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default UsageTerms
