import gql from 'graphql-tag'

const GET_MARKETS = gql`
  query markets($tenantId: Int!, $clientId: Int!, $name: String, $offset: Int, $limit: Int) {
    markets(tenantId: $tenantId, clientId: $clientId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
        clientId
      }
    }
  }
`

export { GET_MARKETS }
