import gql from 'graphql-tag'
enum Direction {
  Up,
  Down,
  Left,
  Right,
}

const ADD_PROJECT_COST_ENTRY = gql`
  mutation addProjectCostEntry(
    $tenantId: Int!
    $projectCostId: Int!
    $projectCostTypeId: Int!
    $vendorId: Int
    $currencyId: Int
    $description: String
  ) {
    addProjectCostEntry(
      tenantId: $tenantId
      projectCostId: $projectCostId
      projectCostTypeId: $projectCostTypeId
      vendorId: $vendorId
      currencyId: $currencyId
      description: $description
    ) {
      id
      bidId
      projectCostId
      projectCostTypeId
      vendorId
      currencyId
      description
      projectCost {
        id
        projectId
      }
      projectCostType {
        id
        name
        description
        customFieldsMetaData
      }
      projectCostEntryItems {
        id
        projectCostEntryId
        type
        sequence
        amount
        notes
        savingTypeId
        customFieldsMetaData
        reportingPeriodStart
        reportingPeriodEnd
        bidId
        bidDate
        bidNumber
        markupAmount
        markupPercentage
      }
      vendor {
        id
        name
        diversity
      }
      currency {
        id
        currency
      }
    }
  }
`
const UPDATE_PROJECT_COST_ENTRY = gql`
  mutation updateProjectCostEntry(
    $tenantId: Int!
    $id: Int!
    $projectCostId: Int!
    $projectCostTypeId: Int!
    $vendorId: Int
    $currencyId: Int
    $description: String
  ) {
    updateProjectCostEntry(
      tenantId: $tenantId
      id: $id
      projectCostId: $projectCostId
      projectCostTypeId: $projectCostTypeId
      vendorId: $vendorId
      currencyId: $currencyId
      description: $description
    ) {
      id
      bidId
      projectCostId
      projectCostTypeId
      vendorId
      currencyId
      description
      projectCost {
        id
        projectId
      }
      projectCostType {
        id
        name
        description
        customFieldsMetaData
      }
      projectCostEntryItems {
        id
        projectCostEntryId
        type
        sequence
        amount
        notes
        savingTypeId
        customFieldsMetaData
        reportingPeriodStart
        reportingPeriodEnd
        bidId
        bidDate
        bidNumber
        markupAmount
        markupPercentage
      }
      vendor {
        id
        name
        diversity
      }
      currency {
        id
        currency
      }
    }
  }
`

const DELETE_PROJECT_COST_ENTRY = gql`
  mutation deleteProjectCostEntry($tenantId: Int!, $id: Int!) {
    deleteProjectCostEntry(tenantId: $tenantId, id: $id) {
      success
      data
      error
    }
  }
`

export { ADD_PROJECT_COST_ENTRY, UPDATE_PROJECT_COST_ENTRY, DELETE_PROJECT_COST_ENTRY }
