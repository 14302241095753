import PropTypes from 'prop-types'

export const WidgetConfigType = PropTypes.shape({
  order: PropTypes.number,
  area: PropTypes.oneOf(['Normal', 'Main', 'Sidebar']),
  permissions: PropTypes.array,
  visible: PropTypes.bool,
  style: PropTypes.object,
}).isRequired

export const WidgetConfigDefaultProps = {
  order: 0,
  area: 'Sidebar',
  permissions: [],
  visible: true,
  style: {},
}
