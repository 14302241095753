import React, { useCallback, useContext, useRef } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'

import useMounted from '@/components/hooks/use-mounted'
import { ProjectItemWidgetContext } from '@/widgets/project-item-widget'
import { IContact } from '@/shared/models/contact'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  projectTeamMember: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  iconButton: {
    width: 10,
  },
}))

const ProjectItemProjectTeamComponent = (props: any) => {
  const {
    InputProps,
    projectTeamMemberRoles,
    showAddButton,
    handleCancel,
    handleChange,
    handleEdit,
    handleAdd,
  } = props

  const mountedRef = useMounted(true)
  const classes = useStyles()
  const { contacts } = useContext(ProjectItemWidgetContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const currentItem = useRef(null as any)

  const contactName = useCallback(
    (contactId: number) => {
      const contact = (contacts || []).find((o: IContact) => o.id === contactId)
      return contact?.name || ''
    },
    [contacts]
  )

  const projectTeamRoleName = useCallback(
    (projectTeamRoleId: number) => {
      const role = (projectTeamMemberRoles || []).find((o: any) => o.id === projectTeamRoleId)
      return role?.role || ''
    },
    [projectTeamMemberRoles]
  )

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleRemoveProjectTeamMember = (index: number) => {
    handleCloseMenu()
    handleChange(InputProps.value.filter((p: any, i: number) => i !== index))
  }

  return (
    <Grid container spacing={2}>
      {Boolean(InputProps?.value?.length) ? (
        <>
          {InputProps.value.map((teamMember: any, i: number) => (
            <Grid
              key={`project-team-member-${i}`}
              item
              xs={12}
              className={classes.projectTeamMember}
              onMouseEnter={() => {
                currentItem.current = { data: teamMember, index: i }
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6} className={'pag-text-ellipsis'}>
                  {contactName(teamMember.contactId)}
                </Grid>

                <Grid item xs={5} className={'pag-text-ellipsis'}>
                  {projectTeamRoleName(teamMember.projectTeamRoleId)}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="inherit"
                    disableFocusRipple
                    size="small"
                    className={classes.iconButton}
                    onClick={handleOpenMenu}
                  >
                    <MoreVertIcon style={{ fontSize: 14 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu()
                handleEdit(currentItem.current)
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={(e: any) => {
                if (currentItem.current && currentItem.current.index >= 0)
                  handleRemoveProjectTeamMember(currentItem.current.index)
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography color="inherit" variant="subtitle1" align="center">
            <b>You haven't added</b>
            <br />
            <b>team members yet.</b>
          </Typography>
          {!showAddButton && (
            <Typography color="inherit" variant="subtitle2" align="center">
              Click the + sign to add your first team member.
            </Typography>
          )}
        </Grid>
      )}
      {showAddButton && (
        <Grid item xs={12} className="pag-text-center">
          <Button color="primary" variant="outlined" onClick={handleAdd}>
            Add
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

ProjectItemProjectTeamComponent.defaultProps = {
  handleChange: (e: any) => {},
  handleCancel: (e: any) => {},
}

export default ProjectItemProjectTeamComponent
