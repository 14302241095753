import gql from 'graphql-tag'

const GET_TAGS = gql`
  query tags($tenantId: Int!, $visibleUploadPortal: Boolean, $offset: Int, $limit: Int) {
    tags(
      tenantId: $tenantId
      visibleUploadPortal: $visibleUploadPortal
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        description
        visibleUploadPortal
      }
    }
  }
`

export { GET_TAGS }
