import gql from 'graphql-tag'

const GET_CONTACTS = gql`
  query contacts($tenantId: Int!, $clientId: Int, $name: String, $offset: Int, $limit: Int) {
    contacts(
      tenantId: $tenantId
      clientId: $clientId
      name: $name
      offset: $offset
      limit: $limit
    ) {
      total
      data {
        id
        name
        email
      }
    }
  }
`

export { GET_CONTACTS }
