import gql from 'graphql-tag'

const GET_VENDORS = gql`
  query vendors($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    vendors(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_VENDORS }
