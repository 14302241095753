import React, { useState } from 'react'

import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PagTextInput from '@/components/pag-form/pag-text-input'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 80,
  },
}))

const ProjectItemTextComponent = (props: any) => {
  const { validationSchema, name, InputProps, handleCancel, handleChange } = props

  const classes = useStyles()
  const [errors, setErrors] = useState(null as any)

  const onChange = (e: any) => {
    if (validationSchema) {
      const itemValue: any = {}
      itemValue[name] = e.target.value
      validationSchema
        .validate(itemValue)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err)
          if (err.message) {
            setErrors({
              [name]: {
                message: err.message,
              },
            })
          } else {
            setErrors(null)
          }
        })
    }
  }

  const onBlur = (e: any) => {
    if (errors || !e.target.value) return

    handleChange(e.target.value)
  }

  return (
    <PagTextInput
      {...{
        key: `project-item-${name}`,
        error: errors?.[name],
        onChange: (e: any) => onChange(e),
        onBlur: (e: any) => onBlur(e),
        ...InputProps,
      }}
    />
  )
}

ProjectItemTextComponent.defaultProps = {
  handleChange: (e: any) => {},
}

export default ProjectItemTextComponent
