export const MAIN_ROUTES = [
  {
    exact: true,
    path: '/search/:tabName',
    permission: 'read:tenants',
    auth: true,
  },
  {
    exact: true,
    path: '/:clientId/calendar',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/list',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/reports',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/views',
    auth: true,
    permission: 'read:reports',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/list',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId',
    auth: true,
    permission: 'read:projects',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId/:tabName',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/:clientId/:brandId/:projectId/bids/:bidId',
    auth: true,
    permission: 'read:tenants',
  },
  {
    exact: true,
    path: '/',
    auth: true,
    permission: 'read:tenants',
  },
]

export const UPLOAD_PORTAL_ROUTES = [
  {
    exact: true,
    path: '/p/:slug',
    auth: true,
    permission: 'write:UploadDocuments',
  },
  {
    exact: true,
    path: '/p/:slug/new-project',
    auth: true,
    permission: 'write:UploadDocuments',
  },
  {
    exact: true,
    path: '/p/:slug/upload-files',
    auth: true,
    permission: 'write:UploadDocuments',
  },
]
