import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const useUrlParams = (props: any) => {
  const params = useParams()

  return useMemo(() => {
    const result: any = {}
    for (const [key, value] of Object.entries(props)) {
      result[key] = params[key]
        ? value
          ? parseInt(params[key] as string)
          : params[key]
        : undefined
    }
    if (result.bidId) {
      result.tabName = 'bids'
    }
    return result
  }, [props, params])
}

export default useUrlParams
