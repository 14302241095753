import React, { useCallback, useContext, useMemo, useState } from 'react'
import _keys from 'lodash/keys'

import { ProjectItemWidgetContext } from '@/widgets/project-item-widget'
import PagAutocomplete from '@/components/pag-form/pag-autocomplete'

const ProjectItemSelectComponent = (props: any) => {
  const {
    validationSchema,
    name,
    labelKey,
    valueKey,
    loading,
    isCreatable,
    InputProps: { AutocompleteProps, ...restInputProps },
    handleCancel,
    handleChange,
  } = props

  const { options } = useContext(ProjectItemWidgetContext)
  const [errors, setErrors] = useState(null as any)
  const autocompleteProps = _keys(AutocompleteProps).length > 0 ? AutocompleteProps : {}

  const onChange = (e: any) => {
    if (validationSchema) {
      const itemValue: any = {}
      itemValue[name] = e.target.value
      validationSchema
        .validate(itemValue)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err)
          if (err.message) {
            setErrors({
              [name]: {
                message: err.message,
              },
            })
          } else {
            setErrors(null)
          }
        })
    }
  }

  const optionLabelKey = useMemo(() => {
    switch (name) {
      case 'currencyId':
        return 'currency'
      case 'projectCategoryId':
        return 'category'
      case 'projectRegionId':
        return 'region'
      case 'projectStatusId':
        return 'status'
      case 'projectTypeId':
        return 'projectType'
      case 'bidTypeId':
        return 'bidType'
      default:
        return labelKey || 'name'
    }
  }, [name, labelKey])

  const optionValueKey = useMemo(() => {
    return valueKey || 'id'
  }, [valueKey])

  const newOptions = useMemo(() => {
    return options || [{ [optionValueKey]: 0, [optionLabelKey]: 'There is no options' }]
  }, [options, optionLabelKey, optionValueKey])

  const getOptionLabel = useCallback(
    (option: any) => {
      return option[optionLabelKey] || option['name']
    },
    [optionLabelKey]
  )

  const getOptionSelected = useCallback(
    (option: any, value: any) => {
      return option[optionValueKey] === (value[optionValueKey] || value)
    },
    [optionValueKey]
  )

  return (
    <PagAutocomplete
      {...{
        name,
        optionLabelKey,
        optionValueKey,
        options: newOptions,
        error: errors ? errors[name] : null,
        value:
          restInputProps.value || (AutocompleteProps && AutocompleteProps.multiple ? [] : null),
        key: `project-item-${restInputProps.defaultValue}`,
        loading,
        isCreatable,
        AutocompleteProps: {
          getOptionLabel,
          getOptionSelected,
          size: 'small',
          disableClearable: true,
          onChange: (event: object, values: any, reason: string) => {
            // onChange(values);
            handleChange(values)
          },
          ...autocompleteProps,
        },
        InputProps: {
          ...restInputProps,
        },
      }}
    />
  )
}

ProjectItemSelectComponent.defaultProps = {
  handleChange: (e: any) => {},
}

export default ProjectItemSelectComponent
