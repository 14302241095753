import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import tableau from 'tableau-api'
// const tableau = require('tableau-api');
import _join from 'lodash/join'
import _split from 'lodash/split'

import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'

import useMounted from '@/components/hooks/use-mounted'

declare var window: any

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '&.active': {
      width: '100%',
      height: '100%',
    },
  },
  reportHead: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.2rem',
    borderBottom: '1px solid lightgray',
  },
  reportTitle: {
    fontSize: '1.2rem',
  },
  reportContent: {
    width: '0',
    height: '0',
    overflow: 'hidden',

    '&.active': {
      width: '100%',
      height: '100%',
      minHeight: '300px',
    },
  },
  reportPreviewImage: {
    '&.active': {
      width: '100%',
      height: '0',
    },
    minWidth: 300,
    paddingTop: `${185 / 3}%`,
    cursor: 'pointer',
  },
  reportPreviewNoImage: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    height: '300px',
    justifyContent: 'center',
    fontSize: '1.5rem',
  },
}))

const ReportComponent = ({ report, isActive, containerSize, onViewReportDetail }: any) => {
  const mountedRef = useMounted(true)
  const classes = useStyles()
  const reportEle = useRef(null)
  const [viz, setViz] = useState(null as any)
  const [visibleReport, setVisibleReport] = useState(false)
  const [visibleViewDetail, setVisibleViewDetail] = useState(false)

  const width = useMemo(() => containerSize.width - 48, [containerSize])
  const height = useMemo(() => containerSize.height - 100, [containerSize])

  const initViz = () => {
    if (width <= 0 || !visibleReport) return

    try {
      // if (viz) {
      //   const workbook = viz.getWorkbook()
      //   if (workbook) {
      //     const activeSheet = workbook.getActiveSheet();
      //     if (activeSheet) {
      //       activeSheet.changeSizeAsync(
      //         {"behavior": "EXACTLY", "maxSize": { "height": height, "width": width }})
      //         .then(viz.setFrameSize(width, height));
      //     }
      //   }

      const tableaEntity = tableau
      const vizContainer = reportEle.current
      const tableauViewContentUrl = _split(report.tableauViewContentUrl || '', '/').filter(
        (s) => s !== 'sheets'
      )
      const viewPath = _join(tableauViewContentUrl, '/')

      const options = {
        hideTabs: report.hideTabs,
        hideToolbar: report.hideToolbar,
        width: width,
        height: height,
        onFirstInteractive: () => {
          setVisibleViewDetail(true)
        },
      }
      setViz(
        new window.tableau.Viz(
          vizContainer,
          encodeURI(`${process.env.REACT_APP_TABLEAU_URL}/${viewPath}`),
          options
        )
      )
    } catch (err) {
      console.error(err)
    }
  }

  useLayoutEffect(() => {
    if (!mountedRef.current) return
    setVisibleReport(isActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  useEffect(() => {
    if (!mountedRef.current) return
    if (visibleReport && !viz) initViz()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleReport])

  const previewImage = report.previewImage

  return (
    <>
      <div className={clsx('pag-card', classes.root, { active: isActive })}>
        <div className={classes.reportHead}>
          {visibleReport && (
            <div className="pag-link pag-go-back">
              <Icon onClick={() => onViewReportDetail(null)}>arrow_back</Icon>
              <span onClick={() => onViewReportDetail(null)}>&nbsp;&nbsp;&nbsp;Back</span>
            </div>
          )}

          <div
            className={clsx(classes.reportTitle)}
            style={{ paddingLeft: isActive ? '2rem' : '0' }}
          >
            {report.tableauViewName}
          </div>

          {!isActive && (
            <div className="pag-link pag-blue pag-right">
              <span onClick={() => onViewReportDetail(report)}>View Detail&nbsp;&nbsp;&nbsp;</span>
              <Icon onClick={() => onViewReportDetail(report)}>arrow_forward</Icon>
            </div>
          )}
        </div>

        <div className={clsx(classes.reportContent, { active: isActive })} ref={reportEle}></div>

        {!visibleReport ? (
          <>
            {previewImage ? (
              <div
                className={clsx(classes.reportPreviewImage, { active: isActive })}
                style={{
                  backgroundImage: `url('${report.previewImage}')`,
                  backgroundSize: 'cover',
                }}
                onClick={() => onViewReportDetail(report)}
              />
            ) : (
              <div
                className={clsx(classes.reportPreviewNoImage)}
                onClick={() => onViewReportDetail(report)}
              >
                No Preview Image
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  )
}

export default ReportComponent
