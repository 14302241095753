import React, { useState, useEffect } from 'react'

import PagDatePicker from '@/components/pag-form/pag-date-picker'
import { dateStringToDate } from '@/utils/date-utils'

const ProjectItemDateComponent = (props: any) => {
  const { validationSchema, label, name, InputProps, handleCancel, handleChange } = props
  const [value, setValue] = useState(null as any)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    setValue(dateStringToDate(InputProps.value))
    return () => {}
  }, [InputProps.value])

  return (
    <PagDatePicker
      {...{
        key: `project-item-${name}`,
        margin: 'none',
        name: name,
        label: InputProps.label,
        format: 'MM/dd/yyyy',
        value: value,
        inputVariant: 'outlined',
        size: InputProps.size,
        fullWidth: true,
        className: InputProps.className,
        emptyLabel: InputProps.emptyLabel || '',
        onChange: (e: any) => {
          setValue(e)
          handleChange(e)
        },
        KeyboardButtonProps: {
          'aria-label': 'change date',
        },
      }}
    />
  )
}

ProjectItemDateComponent.defaultProps = {
  handleChange: (e: any) => {},
}

export default ProjectItemDateComponent
