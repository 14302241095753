import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Icon from '@material-ui/core/Icon'

const ProjectsBreadCrumb = ({ projectsViewMode, activeClient, activeBrand }: any) => {
  const renderTabs = useMemo(() => {
    const activeTab = 'All'
    return (
      <div className="pag-tab-container pag-m-auto">
        <div className={clsx('pag-tab', { active: activeTab === 'All' })}>All Projects</div>
        {/* <div className={clsx('pag-tab', {'active': projectsViewType === 'Favorites'})}>Favorites (2)</div>
        <div className='pag-tab'>List</div>
        <div className='pag-tab'>Sort</div> */}
      </div>
    )
  }, [])

  return (
    <div className={clsx('pag-bread-crumb')}>
      <div className="pag-crumb-content">
        <div className="pag-flex">
          <span>Results for:&nbsp;&nbsp;&nbsp;</span>
          {activeClient ? (
            <>
              <b>{activeClient.name}</b>
              {activeBrand ? (
                <>
                  <Icon>chevron_right</Icon>
                  <b>{activeBrand.name}</b>
                </>
              ) : null}
            </>
          ) : (
            <b>All Projects</b>
          )}
        </div>
        {renderTabs}
      </div>
    </div>
  )
}

export default ProjectsBreadCrumb
