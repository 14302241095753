import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'

import PagCircularIndeterminate from '../pag-loading'

const PagAlgoliaConnectConditionalResults = (WrappedComponent: any) => {
  const Loading = connectStateResults(({ props, searching, searchState, searchResults }: any) => {
    return (
      <>
        {searching && <PagCircularIndeterminate />}
        <WrappedComponent
          {...props}
          searching={searching}
          searchPage={searchState.page}
          nbPages={searchResults ? searchResults.nbPages : 0}
        />
      </>
    )
  })

  return (props: any) => <Loading {...props} />
}

export default PagAlgoliaConnectConditionalResults
