import gql from 'graphql-tag'

const GET_MEDIUMS = gql`
  query mediums($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    mediums(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        name
      }
    }
  }
`

export { GET_MEDIUMS }
