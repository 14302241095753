import gql from 'graphql-tag'

const GET_DELIVERABLES = gql`
  query deliverables($tenantId: Int!, $projectId: Int!, $offset: Int, $limit: Int) {
    deliverables(tenantId: $tenantId, projectId: $projectId, offset: $offset, limit: $limit) {
      total
      data {
        id
        agencyName
        created
        jobNo
        language
        length
        linkedTo
        spotName
        spotId
        mediaType {
          id
          name
        }
        medium {
          id
          name
        }
        spotType {
          id
          name
        }
      }
    }
  }
`

export { GET_DELIVERABLES }
