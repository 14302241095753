import gql from 'graphql-tag'

const GET_FILE = gql`
  query getFile($path: String) {
    getFile(path: $path) {
      data
    }
  }
`

export { GET_FILE }
