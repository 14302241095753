import gql from 'graphql-tag'

const GET_PROJECT_CATEGORIES = gql`
  query projectCategories($tenantId: Int!, $name: String, $offset: Int, $limit: Int) {
    projectCategories(tenantId: $tenantId, name: $name, offset: $offset, limit: $limit) {
      total
      data {
        id
        category
      }
    }
  }
`

export { GET_PROJECT_CATEGORIES }
