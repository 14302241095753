import React, { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Index,
  Configure,
  connectHits,
  connectStateResults,
  connectInfiniteHits,
} from 'react-instantsearch-dom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _take from 'lodash/take'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles((theme) => ({
  menuTitle: {
    paddingTop: 6,
    paddingLeft: 16,
    paddingBottom: 6,
    paddingRight: 16,
    color: 'gray',
  },
  content: {
    position: 'relative',
    width: '100%',
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const BidsListView = (props: any) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const {
    hits,

    handleClose,
    searchPhrase,
  } = props

  const onDetailView = useCallback((bid: any) => {
    handleClose(null)

    const cId =
      _get(bid, ['clientId']) ||
      _get(bid, ['project', 'clientId']) ||
      _get(bid, ['project', 'brand', 'clientId'])
    const bId =
      _get(bid, ['brandId']) || _get(bid, ['project', 'brandId']) || _get(bid, ['brand', 'id'])
    const projectId = _get(bid, ['projectId'])
    const bidId = _get(bid, ['id'])

    if (projectId) {
      navigate(`/${cId}/${bId}/${projectId}/bids/${bidId}`)
    }
  }, [])

  const handleSearchMore = useCallback(() => {
    handleClose(null)
    navigate('/search/bids')
  }, [])

  const content = useMemo(() => {
    const dispDataList = _take(hits, 5)
    const hasHits = _get(hits, 'length') > 0

    return (
      <>
        {hasHits && (
          <div className={classes.menuTitle}>
            <b>BIDS</b>
          </div>
        )}

        {_map(dispDataList, (data: any, i: number) => (
          <ListItem key={`bids-search-${i}`} button onClick={() => onDetailView(data)}>
            <div className={classes.content}>
              <div className={classes.title}>
                <b>{_get(data, ['vendor', 'name']) || ''}</b>
                &nbsp;&nbsp;
                <b>{_get(data, ['bidNumber']) || ''}</b>
              </div>
            </div>
          </ListItem>
        ))}

        {hasHits && (
          <div className={classes.menuTitle}>
            <span className="pag-blue" onClick={() => handleSearchMore()}>
              Show More Bids
            </span>
          </div>
        )}
      </>
    )
  }, [hits])

  return <>{content}</>
}

const BidsListViewHits = connectHits(BidsListView)
// const BidsListViewHits = connectStateResults(connectHits(BidsListView));
// const BidsListViewHits = connectInfiniteHits(PagAlgoliaConnectConditionalResults(BidsListView));

const BidsPopper = (props: any) => {
  const { activeClient, activeBrand, clients, handleClose, searchPhrase } = props

  const content = useMemo(() => {
    return (
      <Index indexId="BidsInSearch" indexName="Bids">
        <Configure hitsPerPage={5} />
        <BidsListViewHits
          {...{
            handleClose,
            searchPhrase,
          }}
        />
      </Index>
    )
  }, [activeBrand, activeClient, clients, handleClose, searchPhrase])

  return <>{content}</>
}

export default BidsPopper
